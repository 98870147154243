import React, {useEffect, useState} from 'react';
import {
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonSpinner,
  IonIcon,
  IonButton,
} from '@ionic/react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import {searchOutline} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import {jsPDF} from 'jspdf';
import 'jspdf-autotable';
import useConfig from '../../../hooks/useConfig';

const ChargingReports = props => {
  const {t} = useTranslation('link_app_ikea');

  // API call
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);

  const [segment, setSegment] = useState('');
  const [lmDeliveryTags, setLmDeliveryTags] = useState([]);

  const [chargingTransactions, setChargingTransactions] = useState([]);
  const [filteredChargingTransactions, setFilteredChargingTransactions] =
    useState([]);
  const [loadTransactions, setLoadTransactions] = useState(true);
  const [isDateRangeChanged, setIsDateRangeChanged] = useState(false);
  const [isMonthYearChanged, setIsMonthYearChanged] = useState(false);

  // Copy place to local state
  const [place, setPlace] = useState();
  useEffect(() => {
    if (!_.isEmpty(props.place) && props.place?.id !== place?.id) {
      if (
        !loadTransactions &&
        !isLoading &&
        filteredChargingTransactions.length > 0
      ) {
        setLoadTransactions(true);
        setIsLoading(true);
      }
      setPlace(props.place);
    }
  }, [props.place]);

  useEffect(() => {
    if (!_.isEmpty(props.lmDeliveryTags)) {
      setLmDeliveryTags(props.lmDeliveryTags);
    }
  }, [props.lmDeliveryTags]);

  // Custom table head style
  const StyledTableHeadCell = withStyles(() => ({
    head: {
      backgroundColor: '#FAFAFA',
      padding: '0.5rem',
      border: 'none !important',
    },
  }))(TableCell);

  useEffect(() => {
    setSegment(props.segment);
    setLoadTransactions(true);
  }, [props.segment]);

  const [chargingStartTime, setChargingStartTimeChange] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toISOString().substring(0, 10);
  });
  const [chargingEndTime, setChargingEndTimeChange] = useState(() => {
    const date = new Date();
    return date.toISOString().substring(0, 10);
  });

  const handleChargingStartTimeChange = event => {
    setChargingStartTimeChange(event.target.value);
    setIsDateRangeChanged(true);
  };
  const handleChargingEndTimeChange = event => {
    setChargingEndTimeChange(event.target.value);
    setIsDateRangeChanged(true);
  };

  const [chargingYearMonth, setChargingYearMonth] = useState('');
  const [chargingYearMonthError, setChargingYearMonthError] = useState(false);
  const handleChargingYearMonthChange = event => {
    const newValue = event.target.value;

    const isValid = /^\d{4}-(0[1-9]|1[0-2])$/.test(newValue) || newValue === '';

    setChargingYearMonthError(!isValid);
    setChargingYearMonth(event.target.value);
    setIsMonthYearChanged(true);
  };

  const GetNiceDateForTransaction = dateTime => {
    const date = new Date(dateTime);
    const dateStr = date.toLocaleDateString('fi-FI', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const timeStr = date.toLocaleTimeString('fi-FI', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    const dateTimeStr = `${dateStr}, ${timeStr.replace(/\./g, ':')}`;
    if (dateStr === 'Invalid Date' || timeStr === 'Invalid Date') {
      return 'Ei aikaa saatavilla';
    }
    return dateTimeStr;
  };

  const {integrator} = useConfig();
  const fetchData = async (type, stationID) => {
    const fetchUrl = `${integrator}/stations`;
    if (type === 'dc') {
      try {
        const response = await fetch(
          fetchUrl +
            '/' +
            stationID +
            '/logs?ended=true&limit=1000&report_month=' +
            chargingYearMonth,
          {
            method: 'GET',
            headers: {
              Authorization:
                'Basic ' +
                Buffer.from(
                  process.env.REACT_APP_IG_USERNAME +
                    ':' +
                    process.env.REACT_APP_IG_PASSWORD,
                  'utf-8',
                ).toString('base64'),
            },
          },
        );
        if (!response.ok) {
          // Handle the error, including 404 Not Found
          throw new Error('HTTP error! Status: ' + response.status);
        }
        const res = await response.json();
        const removedEmptyTransactions = res.data.filter(
          trans =>
            trans.attributes.energy !== 0 && trans.attributes.energy !== null,
        );
        return removedEmptyTransactions;
      } catch (error) {
        console.error('Error in fetch', error.message);
        return [];
      }
    }
    if (type === 'ac') {
      // setIsLoading(false);
      const date = new Date(chargingStartTime);
      const endDate = new Date(chargingEndTime);
      const fromTime = date.getTime();
      const endTime = endDate.getTime();

      try {
        const response = await fetch(
          fetchUrl +
            '/' +
            stationID +
            '/logs?fromtime=' +
            fromTime +
            '&endtime=' +
            endTime,
          {
            method: 'GET',
            headers: {
              Authorization:
                'Basic ' +
                Buffer.from(
                  process.env.REACT_APP_IG_USERNAME +
                    ':' +
                    process.env.REACT_APP_IG_PASSWORD,
                  'utf-8',
                ).toString('base64'),
            },
          },
        );
        if (!response.ok) {
          // Handle the error, including 404 Not Found
          throw new Error('HTTP error! Status: ' + response.status);
        }
        const res = await response.json();
        return res.value;
      } catch (error) {
        console.error('Error in fetch', error.message);
        return [];
      }
    }
  };

  useEffect(() => {
    if (
      place !== undefined &&
      segment === 'lm_delivery' &&
      loadTransactions &&
      chargingYearMonth !== undefined &&
      !chargingYearMonthError
    ) {
      setLoadTransactions(false);
      setIsLoading(true);
      Promise.all(
        place?.doc?.stations
          ?.filter(val => val.type === 'kempower' || val.type === 'ocpp')
          .map((station, i) => {
            // if (station.kempower_id) {
            //   return fetchData('dc', station.kempower_id);
            // } else {
            return fetchData('dc', station.id);
            // }
          }),
      )
        .then(results => {
          const combinedResults = [].concat(...results);
          combinedResults.sort(
            (a, b) =>
              new Date(b.attributes?.starttime) -
              new Date(a.attributes?.starttime),
          );
          const duplicatesRemoved = combinedResults.filter(
            (obj, index, self) => {
              const key =
                obj.type + obj.attributes.starttime + obj.attributes.duration;

              // Use Set to keep track of unique keys
              return (
                index ===
                  self.findIndex(
                    o =>
                      o.type +
                        o.attributes.starttime +
                        o.attributes.duration ===
                      key,
                  ) && obj.attributes.status !== 'PluggedInIdle'
              );
            },
          );
          // duplicatesRemoved.sort(
          //   (a, b) => b.attributes?.starttime - a.attributes?.starttime,
          // );
          setChargingTransactions(duplicatesRemoved);
          setFilteredChargingTransactions(duplicatesRemoved);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
    if (
      place !== undefined &&
      segment === 'customer' &&
      loadTransactions &&
      chargingStartTime
    ) {
      setLoadTransactions(false);
      setIsLoading(true);
      Promise.all(
        place?.doc?.stations
          ?.filter(val => val.type === 'uc')
          .map((station, i) => {
            return fetchData('ac', station.id);
          }),
      )
        .then(results => {
          const combinedResults = [].concat(...results);
          combinedResults.sort((a, b) => b.date - a.date);
          setChargingTransactions(combinedResults);
          setFilteredChargingTransactions(combinedResults);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, [place, segment, loadTransactions]);

  // RowLimit per page handlers
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = newPage => {
    if (newPage.currentTarget.ariaLabel === 'Next page') setPage(page + 1);
    else setPage(page - 1);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, chargingTransactions.length - page * rowsPerPage);

  const [filters, setFilters] = useState({
    order: '',
    sortProperty: '',
    names: '',
    rfidTags: '',
    evMacs: '',
    chargers: '',
  });

  // Handle filter change
  const handleFilterChange = (value, filter) => {
    if (filter !== 'order') {
      setFilters({
        ...filters,
        [filter]: value,
      });
    } else {
      if (filters.sortProperty === value && filters.order === 'asc') {
        setFilters({
          ...filters,
          sortProperty: '',
          order: '',
        });
      } else {
        setFilters({
          ...filters,
          sortProperty: value,
          order:
            filters.order === ''
              ? 'desc'
              : filters.order === 'desc'
              ? 'asc'
              : '',
        });
      }
    }
  };

  // Filter options
  const [names, setNames] = useState([]);
  const [rfidTags, setRfidTags] = useState([]);
  const [evMacs, setEvMacs] = useState([]);
  const [chargers, setChargers] = useState([]);

  // Get names, rfidTags, charged amount, ev macs and chargers from ticket
  useEffect(() => {
    let filterNames = [];
    let filterTags = [];
    let filterEvMacs = [];
    let filterChargers = [];

    chargingTransactions.forEach(item => {
      const tempName = getNameForUser(
        item?.attributes?.idtag ?? item?.idToken?.idToken,
      );
      const evMac = item?.attributes?.evmac ?? item?.evMacAddress;
      const evseName = item?.attributes?.connectorid ?? item?.evseName;
      if (filterNames.indexOf(tempName) === -1) {
        filterNames.push(tempName);
      }
      if (filterTags.indexOf(tempName) === -1) {
        filterTags.push(tempName);
      }
      if (filterEvMacs.indexOf(evMac) === -1) {
        filterEvMacs.push(evMac);
      }
      if (filterChargers.indexOf(evseName) === -1) {
        filterChargers.push(evseName);
      }
    });

    setNames(filterNames.sort());
    setRfidTags(filterTags.sort());
    setEvMacs(filterEvMacs.sort());
    setChargers(filterChargers.sort());
  }, [chargingTransactions]);

  const energyToKWh = w => {
    return w / 1000 ?? '-';
  };

  const exportToExcel = () => {
    // Filter out the data to include only the properties you want
    let filteredData = [];
    if (segment === 'lm_delivery') {
      filteredData = filteredChargingTransactions.map(item => ({
        Name:
          getNameForUser(item?.attributes?.idtag ?? item?.idToken?.idToken) ??
          '-',
        'RFID tag': item?.attributes?.idtag ?? item?.idToken?.idToken ?? '-',
        'Charged kWh':
          item?.type === 'Ocpp'
            ? energyToKWh(item?.attributes?.energy)
            : item?.attributes?.energy ?? item?.chargedEnergyKwh ?? '-',
        'EV Mac': item?.attributes?.evmac ?? item?.evMacAddress ?? '-',
        Charger:
          getNameForStationConnector(
            item?.attributes?.stationid,
            item?.attributes?.connectorid,
          ) ??
          item?.attributes?.connectorid ??
          '-',
        DateTime: GetNiceDateForTransaction(
          item?.attributes?.starttime ?? item?.startTime,
        ),
      }));
    } else {
      filteredData = filteredChargingTransactions.map(item => ({
        Name: getNameForStation(item?.stationUid) ?? '-',
        'Charged kWh': item?.kwh ?? '-',
        Time: HoursMinutesAndSeconds(item?.time) ?? '-',
        DateTime: GetNiceDateForTransaction(item?.date) ?? '',
      }));
    }

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the filtered data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    const placeName = place?.doc?.name ?? 'No place information';

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, placeName);

    // Get current date and time
    const date = new Date();
    const formattedDate = date
      .toISOString()
      .slice(0, 19)
      .replace(/:/g, '-')
      .replace('T', ' ')
      .split('.')[0];

    // Write the workbook and force a download with the current date and time in filename
    XLSX.writeFile(
      workbook,
      `charging_report_${placeName}_from_${chargingStartTime}_to_${chargingEndTime}_${formattedDate}.xlsx`,
    );
  };

  const exportToPDF = () => {
    // Create a new PDF document
    const doc = new jsPDF();
    doc.setFontSize(18);

    // Add title and date range
    const title = place?.doc?.name ?? 'No place information';
    doc.text(title, 15, 15);

    doc.setFontSize(14);
    const formattedStart = (d =>
      `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`)(
      new Date(chargingStartTime),
    );
    const formattedEnd = (d =>
      `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`)(
      new Date(chargingEndTime),
    );
    const date_range = formattedStart + ' - ' + formattedEnd;

    // Calculate the position of the date range to align it to the right
    const pageWidth = doc.internal.pageSize.width;
    const dateRangeWidth = doc.getTextWidth(date_range);
    const dateRangePosition = pageWidth - dateRangeWidth - 15; // Subtract a bit more to add some margin

    doc.text(date_range, dateRangePosition, 15);

    // Filter out the data to include only the properties you want
    let filteredData = [];
    if (segment === 'lm_delivery') {
      filteredData = filteredChargingTransactions.map(item => ({
        Name:
          getNameForUser(item?.attributes?.idtag ?? item?.idToken?.idToken) ??
          '-',
        'RFID tag': item?.attributes?.idtag ?? item?.idToken?.idToken ?? '-',
        'Charged kWh':
          item?.type === 'Ocpp'
            ? energyToKWh(item?.attributes?.energy)
            : item?.attributes?.energy ?? item?.chargedEnergyKwh ?? '-',
        'EV Mac': item?.attributes?.evmac ?? item?.evMacAddress ?? '-',
        Charger:
          getNameForStationConnector(
            item?.attributes?.stationid,
            item?.attributes?.connectorid,
          ) ??
          item?.attributes?.connectorid ??
          '-',
        DateTime: GetNiceDateForTransaction(
          item?.attributes?.starttime ?? item?.startTime,
        ),
      }));
    } else {
      filteredData = filteredChargingTransactions.map(item => ({
        Name: getNameForStation(item?.stationUid) ?? '-',
        'Charged kWh': item?.kwh ?? '-',
        Time: HoursMinutesAndSeconds(item?.time) ?? '-',
        DateTime: GetNiceDateForTransaction(item?.date) ?? '',
      }));
    }

    // Create an array of the headings
    const headings = [Object.keys(filteredData[0])];

    // Use jspdf-autotable to add the table to the PDF
    doc.autoTable({
      head: headings,
      body: filteredData.map(Object.values),
      startY: 20,
      styles: {fillColor: [255, 255, 255]}, // set fill color to white
      theme: 'grid',
      headStyles: {fillColor: [0, 0, 0], padding: 20},
    });

    // Get current date and time
    const date = new Date();
    const formattedDate = date
      .toISOString()
      .slice(0, 19)
      .replace(/:/g, '-')
      .replace('T', ' ')
      .split('.')[0];

    // Save the PDF
    doc.save(
      `charging_report_${title}_from_${chargingStartTime}_to_${chargingEndTime}_${formattedDate}.pdf`,
    );
  };

  // Filters clearing button
  const clearButton = Object.values(filters).find(
    sortProperty => sortProperty !== '',
  ) ? (
    <IonCol className="ion-padding-left ion-padding-right shrink-width">
      <IonButton
        color="primary"
        fill="clear"
        className="ion-float-right"
        onClick={() => {
          setFilters({
            order: '',
            sortProperty: '',
          });
        }}>
        {t('app.clear_filters')}
      </IonButton>
    </IonCol>
  ) : (
    ''
  );

  const exportToExcelButton = (
    <IonButton
      color="primary"
      fill="clear"
      className="ion-float-right"
      disabled={filteredChargingTransactions?.length > 0 ? false : true}
      onClick={() => {
        exportToExcel();
      }}>
      {t('ikea.print_excel')}
    </IonButton>
  );

  const exportToPDFButton = (
    <IonButton
      color="primary"
      fill="clear"
      className="ion-float-right"
      disabled={filteredChargingTransactions?.length > 0 ? false : true}
      onClick={() => {
        exportToPDF();
      }}>
      {t('ikea.print_pdf')}
    </IonButton>
  );

  useEffect(() => {
    if (filters.sortProperty !== '') {
      const sortedData = [...chargingTransactions]; // create a copy of the data array

      sortedData.sort((a, b) => {
        let aPropValue = a.attributes ?? a;
        let bPropValue = b.attributes ?? b;
        const nestedProps = filters.sortProperty.split('.'); // split nested property by dot notation
        // Get the nested property value from the objects
        for (const nestedProp of nestedProps) {
          aPropValue = aPropValue[nestedProp];
          bPropValue = bPropValue[nestedProp];
        }
        let compareResult;

        // Compare the values based on their type
        if (typeof aPropValue === 'number' && typeof bPropValue === 'number') {
          compareResult = aPropValue - bPropValue;
        } else if (
          typeof aPropValue === 'string' &&
          typeof bPropValue === 'string'
        ) {
          compareResult = aPropValue.localeCompare(bPropValue);
        } else if (aPropValue instanceof Date && bPropValue instanceof Date) {
          compareResult = aPropValue.getTime() - bPropValue.getTime();
        } else {
          throw new Error(
            `Cannot sort by "${filters.sortProperty}" property. Incompatible types.`,
          );
        }

        // Apply the sorting order (asc or desc)
        if (filters.order === 'desc') {
          compareResult = -compareResult;
        }

        return compareResult;
      });

      setFilteredChargingTransactions(sortedData);
    } else {
      setFilteredChargingTransactions(chargingTransactions);
    }
  }, [filters]);

  const getNameForUser = rfid => {
    const nameForRfid = lmDeliveryTags.find(tag => tag.doc?.rfid === rfid);
    if (nameForRfid) {
      return nameForRfid.doc?.name;
    } else {
      return 'NAME NOT FOUND';
    }
  };

  const getNameForStationConnector = (stationId, connector) => {
    const station = place.doc?.stations.find(
      station => station.id === stationId,
    );
    if (station) {
      return station.connector_names
        ? station.connector_names[connector]
        : connector;
    }
    return 'Station not found';
  };

  const getNameForStation = stationUid => {
    const nameForStation = place.doc?.stations.find(
      station => station.id === stationUid,
    );
    if (nameForStation) {
      return nameForStation.name;
    } else {
      return 'NAME NOT FOUND';
    }
  };

  const tableHeads = {
    lm_delivery: [
      {translation: 'ikea.reports.name', tableKey: 'idtag'},
      {translation: 'ikea.reports.rfid', tableKey: 'idtag'},
      {translation: 'ikea.reports.charged', tableKey: 'energy'},
      {translation: 'ikea.reports.ev_mac', tableKey: 'evmac'},
      {translation: 'ikea.reports.charger', tableKey: 'connectorid'},
      {translation: 'ikea.reports.date', tableKey: 'starttime'},
      // {translation: 'ikea.reports.name', tableKey: 'stationId'},
      // {translation: 'ikea.reports.rfid', tableKey: 'idToken.idToken'},
      // {translation: 'ikea.reports.charged', tableKey: 'chargedEnergyKwh'},
      // {translation: 'ikea.reports.ev_mac', tableKey: 'evMacAddress'},
      // {translation: 'ikea.reports.charger', tableKey: 'evseName'},
      // {translation: 'ikea.reports.date', tableKey: 'startTime'},
    ],
    customer: [
      {translation: 'ikea.reports.name', tableKey: 'stationUid'},
      {translation: 'ikea.reports.charged', tableKey: 'kwh'},
      {translation: 'ikea.reports.time', tableKey: 'time'},
      {translation: 'ikea.reports.date', tableKey: 'date'},
    ],
  };

  const HoursMinutesAndSeconds = milliseconds => {
    let totalSeconds = Math.floor(milliseconds / 1000);
    let totalMinutes = Math.floor(totalSeconds / 60);
    let totalHours = Math.floor(totalMinutes / 60);

    let seconds = totalSeconds % 60;
    let minutes = totalMinutes % 60;
    let hours = totalHours;

    // Add leading zeroes if necessary
    seconds = seconds < 10 ? '0' + seconds : seconds;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    // Only show hours if necessary
    if (hours > 0) {
      hours = hours < 10 ? '0' + hours : hours;
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return `${minutes}:${seconds}`;
    }
  };

  const formatDateTimeFromTimestamp = timestamp => {
    let date = new Date(timestamp);

    let day = date.getDate();
    let month = date.getMonth() + 1; // Months are zero-based in JavaScript
    let year = date.getFullYear();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Add leading zeroes if necessary
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <h2 className="ion-margin-none ion-margin-top ion-margin-bottom-half">
            {t('ikea.reports.charging_reports')}
          </h2>
        </IonCol>
      </IonRow>
      <IonRow className="ikea-table-buttons">
        {segment === 'lm_delivery' ? (
          // <IonCol className="ion-margin-none ion-margin-top ion-margin-bottom-half">
          //   <h3 className="ion-margin-right ion-margin-none ion-padding-top-half ion-padding-bottom-half">
          //     Filtering by date is currently unavailable until January 2024.
          //   </h3>
          // </IonCol>
          <>
            <IonCol
              size="auto"
              className="ion-margin-none ion-margin-bottom-half">
              <h3 className="ion-margin-right ion-margin-none ion-padding-top-half ion-padding-bottom-half">
                Filter by month (YYYY-MM)
              </h3>
            </IonCol>
            <IonCol
              size="auto"
              className="ion-margin-none ion-margin-bottom-half ion-margin-left">
              <input
                name="charging-year-month"
                className="ion-padding-left ion-padding-right ion-padding-top-half ion-padding-bottom-half"
                value={chargingYearMonth}
                type="text"
                placeholder="YYYY-MM"
                onChange={handleChargingYearMonthChange}
              />
              {chargingYearMonthError && (
                <div style={{color: 'red'}}>Invalid format.</div>
              )}
              {/* <input
                name="charging-start"
                className="ion-padding-left ion-padding-right ion-padding-top-half ion-padding-bottom-half"
                value={chargingYearMonth}
                type="text"
                pattern="\d{4}-\d{2}"
                placeholder="YYYY-MM"
                onChange={handleChargingYearMonthChange}
              /> */}
            </IonCol>
            <IonCol className="ion-margin-none ion-margin-bottom-half shrink-width ion-margin-left">
              {isMonthYearChanged && (
                <IonButton
                  disabled={!isMonthYearChanged}
                  onClick={() => setLoadTransactions(true)}
                  className="ion-margin-none ion-margin-right"
                  fill="solid"
                  color="primary">
                  <IonIcon slot="icon-only" icon={searchOutline} />
                </IonButton>
              )}
            </IonCol>
            {chargingYearMonth !== '' && (
              <IonCol className="ion-padding-left ion-padding-right">
                <IonButton
                  color="primary"
                  fill="clear"
                  className="ion-float-left"
                  onClick={() => {
                    setChargingYearMonth('');
                    setLoadTransactions(true);
                  }}>
                  {t('app.clear_month')}
                </IonButton>
              </IonCol>
            )}
          </>
        ) : (
          <>
            <IonCol
              size="auto"
              className="ion-margin-none ion-margin-top ion-margin-bottom-half">
              <h3 className="ion-margin-right ion-margin-none ion-padding-top-half ion-padding-bottom-half">
                {t('ikea.reports.date')}
              </h3>
            </IonCol>
            <IonCol
              size="auto"
              className="ion-margin-none ion-margin-top ion-margin-bottom-half ion-margin-left">
              <input
                name="charging-start"
                className="ion-padding-left ion-padding-right ion-padding-top-half ion-padding-bottom-half"
                value={chargingStartTime}
                type="date"
                onChange={handleChargingStartTimeChange}></input>
            </IonCol>
            <IonCol
              size="auto"
              className="ion-margin-none ion-margin-top ion-margin-bottom-half ion-margin-left">
              <p className="ion-padding-top-half ion-padding-bottom-half">-</p>
            </IonCol>
            <IonCol
              size="auto"
              className="ion-margin-none ion-margin-top ion-margin-bottom-half ion-margin-left">
              <input
                name="charging-end"
                className="ion-padding-left ion-padding-right ion-padding-top-half ion-padding-bottom-half"
                value={chargingEndTime}
                type="date"
                onChange={handleChargingEndTimeChange}></input>
            </IonCol>
            <IonCol className="ion-margin-none ion-margin-top ion-margin-bottom-half ion-margin-left">
              {isDateRangeChanged && (
                <IonButton
                  disabled={!isDateRangeChanged}
                  onClick={() => setLoadTransactions(true)}
                  className="ion-margin-none ion-margin-right"
                  fill="solid"
                  color="primary">
                  <IonIcon slot="icon-only" icon={searchOutline} />
                </IonButton>
              )}
            </IonCol>
          </>
        )}
        <IonCol className="ion-padding-left ion-padding-right">
          {exportToPDFButton}
        </IonCol>
        <IonCol
          className="ion-padding-left ion-padding-right shrink-width"
          size="auto">
          {exportToExcelButton}
        </IonCol>
        {clearButton}
      </IonRow>
      <IonRow>
        <IonCol className="ion-padding-right">
          <TableContainer>
            <Table className="reports-table">
              <TableHead className="reports-table-head">
                <TableRow>
                  {!isLoading &&
                    tableHeads[segment].map((val, i) => {
                      return (
                        <StyledTableHeadCell key={i}>
                          <span
                            className={`triangle-wrap ${
                              filters.sortProperty === val.tableKey
                                ? 'active-sort'
                                : ''
                            }`}
                            onClick={() => {
                              handleFilterChange(val.tableKey, 'order');
                            }}>
                            {t(val.translation)}
                            <span
                              className={
                                filters.sortProperty === val.tableKey &&
                                filters.order === 'asc'
                                  ? 'triangle-down'
                                  : 'triangle-up'
                              }
                            />
                          </span>
                        </StyledTableHeadCell>
                        // <FormControl className="">
                        //   <InputLabel htmlFor="placeNames">
                        //     {t(val.translation)}
                        //   </InputLabel>
                        //   <NativeSelect
                        //     value={filters.names}
                        //     onChange={e =>
                        //       handleFilterChange(e.target.value, 'names')
                        //     }
                        //     inputProps={{
                        //       name: 'names',
                        //       id: 'names',
                        //     }}
                        //     className={
                        //       filters.names === ''
                        //         ? 'input-color-transparent'
                        //         : ''
                        //     }>
                        //     <option aria-label="All" value="">
                        //       {t('app.all')}
                        //     </option>
                        //     {names.map((item, i) => {
                        //       return (
                        //         <option key={i} value={item}>
                        //           {item}
                        //         </option>
                        //       );
                        //     })}
                        //   </NativeSelect>
                        // </FormControl>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading &&
                  filteredChargingTransactions.length > 0 &&
                  filteredChargingTransactions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, i) => {
                      // const name = item.doc.name?.split(' ');
                      // const firstName = item.doc.name?.split(' ')[0];
                      // const lastName = item.doc.name?.split(' ')[name.length - 1];
                      if (segment === 'lm_delivery') {
                        return (
                          <TableRow key={i} component="tr" hover={true}>
                            <TableCell>
                              {getNameForUser(
                                item?.attributes?.idtag ??
                                  item?.idToken?.idToken,
                              ) ?? '-'}
                              {/* {item.doc.name !== '' ? item.doc.name : '-'} */}
                            </TableCell>
                            <TableCell>
                              {/* {item.doc.title !== '' ? item.doc.title : '-'} */}
                              {item?.idToken?.idToken?.includes(':sha256:')
                                ? '-'
                                : item?.attributes?.idtag ??
                                  item?.idToken?.idToken}
                            </TableCell>
                            <TableCell>
                              {item?.type === 'Ocpp'
                                ? energyToKWh(item?.attributes?.energy)
                                : item?.attributes?.energy ??
                                  item?.chargedEnergyKwh ??
                                  '-'}
                            </TableCell>
                            <TableCell>
                              {item?.attributes?.evmac ??
                                item?.evMacAddress ??
                                '-'}
                            </TableCell>
                            <TableCell>
                              {getNameForStationConnector(
                                item?.attributes?.stationid,
                                item?.attributes?.connectorid,
                              ) ??
                                item?.attributes?.connectorid ??
                                '-'}
                            </TableCell>
                            <TableCell>
                              {GetNiceDateForTransaction(
                                item?.attributes?.starttime ?? item?.startTime,
                              ) ?? ''}
                            </TableCell>
                          </TableRow>
                        );
                      } else if (segment === 'customer') {
                        return (
                          <TableRow key={i} component="tr" hover={true}>
                            <TableCell>
                              {getNameForStation(item?.stationUid) ?? '-'}
                              {/* {item.doc.name !== '' ? item.doc.name : '-'} */}
                            </TableCell>
                            <TableCell>
                              {/* {item.doc.title !== '' ? item.doc.title : '-'} */}
                              {item?.kwh ?? '-'}
                            </TableCell>
                            <TableCell>
                              {/* {item.doc.title !== '' ? item.doc.title : '-'} */}
                              {HoursMinutesAndSeconds(item?.time) ?? '-'}
                            </TableCell>
                            <TableCell>
                              {GetNiceDateForTransaction(item?.date) ?? '-'}
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                {!isLoading && filteredChargingTransactions.length < 1 && (
                  <TableRow component="tr" hover={true}>
                    <TableCell>{t('ikea.reports.no_transactions')}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {filteredChargingTransactions?.length < 1 && isLoading && (
              <IonSpinner name="dots"></IonSpinner>
            )}
            <TablePagination
              colSpan={7}
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={filteredChargingTransactions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </IonCol>
      </IonRow>
      {/* {isLoading && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonSpinner name="crescent" color="primary" />
          </IonCol>
        </IonRow>
      )} */}
      {isError && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonText color="danger">{t('app.fetch_error')}</IonText>
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};
export default ChargingReports;

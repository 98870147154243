export const StatusOptions = [
  {label: 'Häiriöilmoitus', value: 'faulted', from_badge: false}, // Tätä ei voi itse määrittää, tulee suoraan alertin tiedoista
  {label: 'Avoinna', value: 'open', from_badge: false}, // Muutetaan status, kun tiketti avataan omaan näkymäänsä, jos tila vielä faulted
  {label: 'Analyysi aloitettu', value: 'analysis_started', from_badge: false}, // Kun siirrytään TicketSteps kohtaan 2, diagnosointi
  {label: 'Analyysi suoritettu', value: 'analysis_finished', from_badge: false}, // Kun vuokaaviot suoritettu/kirjoitettu kenttiin tekstiä ja diagnosointi tallennettu
  {
    label: 'Odottaa resursointia',
    value: 'waiting_for_resourcing',
    from_badge: false,
  }, // Siirtyy automaattisesti kun siirrytään TicketSteps kohtaan 3, resursointi
  {label: 'Kenttähuollossa', value: 'in_field_maintenance', from_badge: false}, // Resursoinnin tila valinta "Siirrä jonoon" tai "Resursoi" ja valitaan työnjohtaja
  {label: 'Korjaus aloitettu', value: 'repair_started', from_badge: false}, // Kun työnjohto resursoi työn kenttähuoltoon tai kun valvomo lähettää päivystäjän huoltokohteeseen
  {label: 'Korjaus valmis', value: 'repair_finished', from_badge: true}, // Korjaus valmis (Mistä tämä tieto tulee?), jos ei mobiilia
  {label: 'Odottaa varaosia', value: 'waiting_for_parts', from_badge: true}, // Tickets valinnasta, jos Kenttähuolto, Laitevalmistajalla tai Korjaus aloitettu keskeytyy tästä syystä. Valitaan itse?
  {label: 'Laitevalmistajalla', value: 'manufacturer', from_badge: true}, // Tickets listauksesta
  {label: 'Dokumentoitu', value: 'documented', from_badge: false}, // Kun dokumentointivaiheessa painetaan tallenna ja tietoa lisätty
  {label: 'Suljettu', value: 'closed', from_badge: false}, // Kun Dokumentoitu ja painetaan "Sulje työ"-nappia
  {label: 'Keskeytetty', value: 'suspended', from_badge: true}, // Valinta tickets-listauksesta vai jokin muu tapa?
];

export const GetTicketStatus = statusValue => {
  const ticket_status = StatusOptions.find(el => el.value === statusValue);

  return ticket_status.label ?? 'Häiriöilmoitus';
};

export const GetNewTicketStatus = async (
  ticket,
  currentStatus,
  step = false,
  isStepChange = false,
  newStatus = '',
  documentationSave = false,
) => {
  // if (nextStatus !== "") {
  //     const new_status = StatusOptions.filter((el) => el.value === nextStatus)
  //     return new_status.value ?? ""
  // } else {
  // console.log(
  //   'New Ticket status requested',
  //   'Current status:',
  //   currentStatus,
  //   'Step:',
  //   step,
  //   'New status:',
  //   newStatus,
  // );
  console.log(ticket, currentStatus, step);
  let newStatusValue = currentStatus;
  if (newStatus !== '') {
    const statusData = StatusOptions.find(el => el.value === newStatus);
    if (statusData?.from_badge) {
      newStatusValue = statusData.value;
    }
  } else {
    if (step !== false) {
      switch (step) {
        case 0:
          if (currentStatus === 'faulted' || currentStatus === '') {
            newStatusValue = 'open';
          }
          break;
        case 1:
          if (isStepChange && currentStatus !== 'analysis_finished') {
            newStatusValue = 'analysis_started';
          } else {
            newStatusValue = 'analysis_finished';
          }
          break;
        case 2:
          if (
            ticket.resource_status === 'resource' &&
            ticket.resourced.filter(el => !el.management).length > 0
          ) {
            newStatusValue = 'repair_started';
          } else if (
            (ticket.resource_status === 'resource' ||
              ticket.resource_status === 'queue_resourcing') &&
            ticket.resourced.filter(el => el.management).length > 0
          ) {
            newStatusValue = 'in_field_maintenance';
          } else {
            newStatusValue = 'waiting_for_resourcing';
          }
          // if (currentStatus !== "waiting_for_resourcing" && currentStatus !== "in_field_maintenance" && currentStatus !== "repair_started") {
          //     newStatusValue = "waiting_for_resourcing"
          // } else if (currentStatus === "waiting_for_resourcing") {
          //     newStatusValue = "in_field_maintenance"
          // }
          break;
        case 3:
          newStatusValue = 'repair_started';
          break;
        case 4:
          if (currentStatus === 'repair_finished') {
            newStatusValue = 'closed';
          } else {
            if (!isStepChange && !documentationSave) {
              newStatusValue = 'repair_finished';
            }
          }
          break;
        default:
          break;
      }
    } else {
      switch (currentStatus) {
        case 'waiting_for_resourcing':
          newStatusValue = 'in_field_maintenance';
          break;
        default:
          break;
      }
      // const old_status_index = StatusOptions.findIndex(el => el.value === currentStatus)
      // const new_status = StatusOptions[old_status_index+1]
      // return new_status.value ?? ""
    }
  }
  return newStatusValue;
  // }
};

const rolesData = [
  'contact',
  'maintenance_organization',
  'field_person',
  'customer_admin',
  'manager',
  'telemast_admin',
  'ikea',
];

export default rolesData;

import React, {useEffect, useState} from 'react';
import {
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonSpinner,
  IonIcon,
} from '@ionic/react';
import {
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import {chevronDownOutline} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';

const Events = props => {
  const {t} = useTranslation('link_app_ikea');
  const match = props.match;

  // API call
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [station, setStation] = useState(props.station ?? null);

  const [tickets, setTickets] = useState([]);
  const [errors, setErrors] = useState([]);

  // Copy place to local state
  const [place, setPlace] = useState();
  useEffect(() => {
    if (!_.isEmpty(props.place)) {
      setPlace(props.place);
    }
  }, [match, props.place]);

  useEffect(() => {
    if (props.tickets?.length > 0) {
      setTickets(props.tickets);
    }
  }, [props.tickets]);

  useEffect(() => {
    if (tickets.length > 0) {
      let copyOfTickets = [...tickets];
      let alertList = [];
      copyOfTickets = copyOfTickets.filter(
        ticket => ticket?.doc?.place?.site_id === place?.doc?.site_id,
      );
      copyOfTickets.forEach(ticket => {
        alertList = alertList.concat(ticket?.doc?.errors);
      });

      alertList.sort((a, b) => {
        const dateValueA =
          a.ALARM_RECEIVED !== undefined
            ? a.ALARM_RECEIVED
            : a.ALARM_END_TIME !== '' &&
              a.ALARM_END_TIME !== '0001-01-01T00:00:00Z'
            ? a.ALARM_END_TIME
            : a.ALARM_START_TIME;

        const dateValueB =
          b.ALARM_RECEIVED !== undefined
            ? b.ALARM_RECEIVED
            : b.ALARM_END_TIME !== '' &&
              b.ALARM_END_TIME !== '0001-01-01T00:00:00Z'
            ? b.ALARM_END_TIME
            : b.ALARM_START_TIME;

        return new Date(dateValueB) - new Date(dateValueA);
      });

      setErrors(alertList);
    }
  }, [tickets, place]);

  const charging_cable_statuses = {
    0: 'Connection loss',
    1: 'Unavailable',
    2: 'Disabled',
    3: 'Offline',
    4: 'Ready',
    5: 'Cable Connected',
    6: 'Car Connected',
    7: 'Charging',
    8: 'Charged',
    9: 'Cable Error',
    10: 'Voltage Error',
    11: 'Ventilation Error',
    12: 'Car Error',
    13: 'Meter error',
  };

  const getCableStatus = statusId => {
    return charging_cable_statuses[statusId] ?? statusId;
  };

  const [expanded, setExpanded] = React.useState('panel-error-0');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getErrorDate = error => {
    let date;
    let input =
      error.ALARM_RECEIVED !== undefined
        ? error.ALARM_RECEIVED
        : error.ALARM_END_TIME !== '' &&
          error.ALARM_END_TIME !== '0001-01-01T00:00:00Z'
        ? error.ALARM_END_TIME
        : error.ALARM_START_TIME;

    // Check if input is a number (UNIX timestamp)
    if (!isNaN(input)) {
      date = new Date(Number(input) * 1000); // JavaScript uses milliseconds, so we multiply by 1000
    } else {
      // Assume input is an ISO 8601 string
      date = new Date(input);
    }

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so we add 1
    const year = date.getUTCFullYear();

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  };

  return (
    <IonRow className="ion-margin-top ikea-events-data">
      <IonCol className="ion-margin-none ion-padding-left ion-padding-right">
        <h3 className="ion-margin-top">{t('ikea.error_messages')}</h3>
        {errors.length > 0 &&
          errors.map((error, i) => {
            return (
              <>
                <Accordion
                  key={'ticket-error' + i}
                  expanded={expanded === 'panel-error-' + i}
                  onChange={handleChange('panel-error-' + i)}>
                  <AccordionSummary
                    expandIcon={
                      <IonIcon
                        className="ion-margin-right-half"
                        slot="start"
                        icon={chevronDownOutline}
                      />
                    }
                    aria-controls={`panel-${error + i}`}
                    id={`panel-${error + i}`}>
                    <span
                      className={`status-badge status-badge-color-${
                        error.ALARM === 'on' ? 'red' : 'green'
                      }`}></span>
                    <IonText
                      className="ion-margin-right"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                      }}>
                      {t('ikea.error_message')}
                    </IonText>
                    <IonText
                      className="ion-margin-right ion-margin-left"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                      }}>
                      {getErrorDate(error)}
                    </IonText>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    <IonGrid className="ion-padding-none ion-margin-top">
                      <IonCol>
                        <IonGrid className="events-data-table ion-padding-none">
                          <IonRow>
                            <IonCol size="3">
                              {t('ikea.events.charging_cable')}
                            </IonCol>
                            <IonCol size="9">{error.CONNECTOR ?? '-'}</IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size="3">
                              {t('ikea.events.charging_cable_status')}
                            </IonCol>
                            {typeof error.STATION_ID === 'string' ? (
                              <IonCol size="9">
                                {station?.connectors?.[error.CONNECTOR]
                                  ?.status ?? '-'}
                              </IonCol>
                            ) : (
                              <IonCol size="9">
                                {station?.connectors?.[error.CONNECTOR]?.status
                                  ? getCableStatus(
                                      station?.connectors[error.CONNECTOR]
                                        ?.status,
                                    )
                                  : '-'}
                              </IonCol>
                            )}
                          </IonRow>
                          <IonRow>
                            <IonCol size="3">
                              {t('ikea.events.alert_name')}
                            </IonCol>
                            <IonCol size="9">{error.ALERTNAME ?? '-'}</IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size="3">
                              {t('ikea.events.alert_summary')}
                            </IonCol>
                            <IonCol size="9">{error.SUMMARY ?? '-'}</IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size="3">
                              {t('ikea.events.description')}
                            </IonCol>
                            <IonCol size="9">{error.DESCRIPTION ?? '-'}</IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size="3">
                              {t('ikea.events.fault_status')}
                            </IonCol>
                            <IonCol size="9">
                              {error.ALARM === 'on' ? 'ON' : 'OFF'}
                            </IonCol>
                          </IonRow>
                          {/* <IonRow>
                                        <IonCol size="3">
                                          {t('places.sla')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {StartSlaCounter(
                                            ticket?.sla,
                                            ticket?.time_when_created,
                                          ) ? (
                                            <>
                                              {ticket?.sla !== '' &&
                                                ticket?.is_active === false && (
                                                  <SlaDiff
                                                    id={ticket?._id}
                                                    end={
                                                      ticket?.time_when_completed
                                                    }
                                                    start={
                                                      ticket?.time_when_created
                                                    }
                                                    sla={ticket?.sla}
                                                  />
                                                )}
                                              {ticket?.sla !== '' &&
                                                ticket?.time_when_completed !==
                                                  '' &&
                                                ticket?.is_active === true && (
                                                  <SlaDiff
                                                    id={ticket?._id}
                                                    end={
                                                      ticket?.time_when_completed
                                                    }
                                                    start={
                                                      ticket?.time_when_created
                                                    }
                                                    sla={ticket?.sla}
                                                  />
                                                )}
                                              {ticket?.sla !== '' &&
                                                ticket?.time_when_completed ===
                                                  '' &&
                                                ticket?.time_when_created !==
                                                  '' &&
                                                ticket?.is_active === false && (
                                                  <IonBadge color="light">
                                                    {ticket?.sla}
                                                  </IonBadge>
                                                )}
                                              {ticket?.sla !== '' &&
                                                ticket?.time_when_completed ===
                                                  '' &&
                                                ticket?.time_when_created !==
                                                  '' &&
                                                ticket?.is_active === true &&
                                                GetSla(
                                                  ticket?.sla,
                                                  ticket?.time_when_created,
                                                  false,
                                                )}
                                            </>
                                          ) : (
                                            <p className="color-medium">
                                              {t('tickets.sla')} {ticket?.sla}
                                            </p>
                                          )}
                                        </IonCol>
                                      </IonRow> */}
                        </IonGrid>
                      </IonCol>
                    </IonGrid>
                  </AccordionDetails>
                </Accordion>
                <Divider />
              </>
            );
          })}
      </IonCol>
      {isLoading && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonSpinner name="crescent" color="primary" />
          </IonCol>
        </IonRow>
      )}
      {isError && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonText color="danger">{t('app.fetch_error')}</IonText>
          </IonCol>
        </IonRow>
      )}
    </IonRow>
  );
};
export default Events;

/* eslint-disable no-unreachable */
import React from 'react';
import {IonIcon} from '@ionic/react';
import {Select, FormControl, MenuItem} from '@material-ui/core';
import {StatusOptions} from '../tickets/TicketStatusController';
import {alertCircleOutline} from 'ionicons/icons';

const StatusBadge = props => {
  // const status_options = StatusOptions.filter((status) => status.value !== "faulted")
  const status_options = StatusOptions;

  return (
    <>
      <FormControl>
        <Select
          value={props?.doc.ticket_status || 'faulted'}
          onChange={e =>
            props.handleTicketStatusChange(props.doc, e.target.value)
          }
          name="ticket_status"
          inputProps={{'aria-label': 'age'}}
          className={`status-badge-select status-color-${
            props?.doc.ticket_status || 'faulted'
          }`}>
          {status_options.map((option, i) => {
            return (
              <MenuItem
                disabled={!option.from_badge}
                key={i}
                value={option.value}
                name={option.label}
                className={`status-badge-${option.value}`}>
                {!props?.doc?.ticket_status ||
                props.doc.ticket_status === 'faulted' ? (
                  <IonIcon
                    size="auto"
                    color="danger"
                    icon={alertCircleOutline}
                    className="ion-margin-left-half ion-margin-right-half"
                  />
                ) : (
                  <span className="status-badge-color"></span>
                )}
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default StatusBadge;

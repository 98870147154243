import React, {useState, useEffect, useRef, useContext} from 'react';
import {
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
  IonButton,
  IonModal,
  IonItem,
  IonThumbnail,
  IonImg,
} from '@ionic/react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  NativeSelect,
} from '@material-ui/core';
import {
  trashOutline,
  imageOutline,
  syncOutline,
  saveOutline,
  chevronDownOutline,
} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import useToast from '../../../hooks/useToast';
import Compressor from 'compressorjs';
import {DatabaseContext} from '../../../components/context/DatabaseContext';

const EditTicketModal = props => {
  const {permissions, user, places} = props;
  const {t} = useTranslation('link_app_ikea');
  const toast = useToast();

  // Databases
  const databases = useContext(DatabaseContext);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  const [modalTicket, setModalTicket] = useState(props.modalTicket);
  const [editIssue, setEditIssue] = useState({
    location: '',
    alert_name: '',
    severity: '',
    description: '',
    station: {},
    connector: '',
    images: [],
    _attachments: {},
  });

  useEffect(() => {
    if (!_.isEmpty(props.modalTicket) && props.modalTicket !== modalTicket) {
      setModalTicket(props.modalTicket);
      setEditIssue({
        location: props.modalTicket?.place?.site_id,
        alert_name:
          props.modalTicket?.errors[0].ALERTNAME.split(' IKEA error')[0] ?? '',
        severity: props.modalTicket?.errors[0].SEVERITY ?? '',
        description: props.modalTicket?.comments[0]?.text ?? '',
        station: props.modalTicket?.station ?? {},
        connector: props.modalTicket?.errors[0].CONNECTOR ?? '',
        images: props.modalTicket?.images ?? [],
        _attachments: props.modalTicket._attachments ?? {},
      });
    }
  }, [props.modalTicket]);

  const [place, setPlace] = useState(props.place);

  useEffect(() => {
    setPlace(props.place);
  }, [props.place]);

  const [stations, setStations] = useState([]);
  useEffect(() => {
    if (place?.doc?.stations?.length > 0) {
      setStations(place.doc.stations);
    } else {
      setStations([]);
    }
  }, [place]);

  const [connectors, setConnectors] = useState(null);
  useEffect(() => {
    if (editIssue?.station?.id) {
      let selectedStation = [...stations];
      selectedStation = selectedStation.find(station => {
        return editIssue.station?.id === station.id;
      });
      setConnectors(selectedStation?.connectorsList);
    } else {
      setConnectors(null);
    }
  }, [editIssue?.station]);

  const setNewPlaceLocation = placeId => {
    const newPlace = places.find(
      place => place.doc?.site_id.toString() === placeId.toString(),
    );
    setPlace(newPlace);
    setEditIssue({
      ...modalTicket,
      location: newPlace.doc?.site_id,
      station: {},
      connector: '',
    });
  };

  // Image upload and comment variables
  const fileInput = useRef(null);
  const [newImage, setNewImage] = useState({});
  const [imageCaption, setImageCaption] = useState('');

  // Compress image before upload
  const handleCompressedUpload = e => {
    const img = e.target.files[0];
    new Compressor(img, {
      quality: 0.6, // 0.6 can be used, but it is not recommended to go below
      success: result => {
        // result has the compressed file
        setNewImage(result);
      },
    });
  };

  // Add image and caption to ticket
  const handleImageUpload = () => {
    if (
      modalTicket.images.findIndex(x => x.attachment_id === newImage.name) ===
      -1
    ) {
      const timestamp = Math.round(new Date() / 1000).toString();
      const attachments = {
        ...modalTicket._attachments,
        [newImage.name !== 'image.jpg'
          ? newImage.name.replace(/ /g, '-')
          : `${timestamp}.jpg`]: {
          content_type: newImage.type,
          data: newImage,
        },
      };
      const images = [...modalTicket.images];
      let newId =
        images.length > 0
          ? (
              parseInt(
                Math.max.apply(
                  Math,
                  images.map(x => x.id),
                ),
              ) + 1
            ).toString()
          : '1';
      const newImages = [
        ...images,
        {
          id: newId,
          attachment_id:
            newImage.name !== 'image.jpg'
              ? newImage.name.replace(/ /g, '-')
              : `${timestamp}.jpg`,
          text: imageCaption !== '' ? imageCaption : '',
        },
      ];

      let newLogId =
        modalTicket.log?.length > 0
          ? (
              parseInt(
                Math.max.apply(
                  Math,
                  modalTicket.log?.map(x => x.id),
                ),
              ) + 1
            ).toString()
          : '1';

      const updatedTicket = {
        ...modalTicket,
        _attachments: attachments,
        images: newImages,
        log: modalTicket.log
          ? [
              ...modalTicket.log,
              {
                id: newLogId,
                time: timestamp,
                user: user,
                app: 'ikea',
                event: 'image_loaded',
              },
            ]
          : [
              {
                id: newLogId,
                time: timestamp,
                user: user,
                app: 'ikea',
                event: 'image_loaded',
              },
            ],
      };
      setModalTicket(updatedTicket);
      setIsEdited(true);
      setNewImage({});
      setImageCaption('');
    } else {
      toast(
        `${t('toast.image')} "${newImage.name}" ${t(
          'toast.image_already_added',
        )}`,
      );
      setNewImage({});
      setImageCaption('');
    }
  };

  // Handle image delete
  const handleImageDelete = imageId => {
    const images = [...modalTicket.images];
    const imageIndex = images.findIndex(
      image => image.attachment_id === imageId,
    );
    images.splice(imageIndex, 1);
    const attachments = modalTicket._attachments;
    delete attachments[imageId];
    setModalTicket({...modalTicket, images: images, _attachments: attachments});
    setIsEdited(true);
  };

  const checkSLA = (alert_name, severity) => {
    let correctSLA = '';
    if (alert_name === 'Kempower') {
      switch (severity) {
        case '1':
          correctSLA = 3;
          break;
        case '2':
          correctSLA = 24;
          break;
        case '3':
          correctSLA = 48;
          break;
        case '4':
          correctSLA = 72;
          break;
        case '5':
          break;
        default:
          break;
      }
    } else if (alert_name === 'Enersense') {
      switch (severity) {
        case '1':
          correctSLA = 3;
          break;
        case '2':
          correctSLA = 24;
          break;
        case '3':
          correctSLA = 72;
          break;
        case '4':
          correctSLA = 120;
          break;
        case '5':
          break;
        default:
          break;
      }
    }
    return correctSLA;
  };

  const saveModalChanges = () => {
    const timestamp = Math.round(new Date() / 1000).toString();
    let ticketCopy = {...modalTicket};

    const issuePlace = places.find(
      place => place.doc?.site_id === editIssue.location,
    );

    databases.tickets
      .get(modalTicket._id)
      .then(result => {
        let newLogId =
          modalTicket.log?.length > 0
            ? (
                parseInt(
                  Math.max.apply(
                    Math,
                    modalTicket.log?.map(x => x.id),
                  ),
                ) + 1
              ).toString()
            : '1';

        // Update _rev
        ticketCopy._rev = result._rev;

        // Check that log exists, and initialize if not
        if (!ticketCopy.log) {
          ticketCopy.log = [];
        }

        // Update log
        ticketCopy.log = [
          ...ticketCopy.log,
          {
            id: newLogId,
            time: timestamp,
            user: user,
            app: 'ikea',
            event: `ikea_updated_ticket`,
          },
        ];

        ticketCopy.comments = editIssue.description
          ? [
              {
                id: 1,
                time: timestamp,
                contact_id: user.name,
                text: editIssue.description,
              },
            ]
          : [];

        ticketCopy.sla = checkSLA(editIssue.alert_name, editIssue.severity);
        if (ticketCopy.errors.length > 0) {
          ticketCopy.errors[0].STATION_ID = editIssue.station.id ?? '';
          ticketCopy.errors[0].CONNECTOR = editIssue.connector ?? '-';
          ticketCopy.errors[0].SUMMARY = editIssue.station.name;
          ticketCopy.errors[0].APP_TYPE = editIssue.station.name;
          ticketCopy.errors[0].ALERTNAME = editIssue.alert_name + ' IKEA error';
          ticketCopy.errors[0].CATEGORY = issuePlace.doc?.name;
          ticketCopy.errors[0].SEVERITY = editIssue.severity;
        }

        ticketCopy.station = editIssue.station?.id
          ? {
              id: editIssue.station?.id ?? '',
              name: editIssue.station?.name ?? '',
            }
          : {};

        return databases.tickets.put(ticketCopy);
      })
      .then(() => {
        console.log(
          `${t('toast.ticket')} #${ticketCopy._id} ${t('toast.updated')}`,
        );
        toast(`${t('toast.ticket')} #${ticketCopy._id} ${t('toast.updated')}`);
        setIsEdited(false);
        setEditIssue({});
      })
      .catch(err => {
        console.log(
          `${t('toast.error_updating_ticket')} #${ticketCopy._id}:`,
          err,
        );
        toast(`${t('toast.error_updating_ticket')} #${ticketCopy._id}`, err);
      });
    props.setModal('');
  };

  const [expandedSeverity, setExpandedSeverity] = React.useState(true);

  const SeverityClassifications = props => {
    const infoType =
      editIssue.alert_name === 'Kempower' ? 'lm_delivery' : 'customer_charging';
    return (
      <Accordion
        className="ikea-form-accordion"
        expanded={editIssue.alert_name !== '' && expandedSeverity}
        onChange={() => setExpandedSeverity(!expandedSeverity)}>
        <AccordionSummary
          expandIcon={
            <IonIcon
              className="ion-margin-right-half"
              slot="start"
              icon={chevronDownOutline}
            />
          }>
          <IonText>{t('ikea.modal.severity_info')}</IonText>
        </AccordionSummary>
        <AccordionDetails>
          <IonGrid className="severity-specifications">
            <IonCol>
              <b>{t(`ikea.modal.${infoType}_modal_bullets.bullet_1`)}</b>
              <p>
                {t(`ikea.modal.${infoType}_modal_bullets.bullet_1_example`)}
              </p>
              <b>{t(`ikea.modal.${infoType}_modal_bullets.bullet_2`)}</b>
              <p>
                {t(`ikea.modal.${infoType}_modal_bullets.bullet_2_example`)}
              </p>
              <b>{t(`ikea.modal.${infoType}_modal_bullets.bullet_3`)}</b>
              <p>
                {t(`ikea.modal.${infoType}_modal_bullets.bullet_3_example`)}
              </p>
              <b>{t(`ikea.modal.${infoType}_modal_bullets.bullet_4`)}</b>
              <p>
                {t(`ikea.modal.${infoType}_modal_bullets.bullet_4_example`)}
              </p>
              <b>{t(`ikea.modal.${infoType}_modal_bullets.bullet_5`)}</b>
              <p>
                {t(`ikea.modal.${infoType}_modal_bullets.bullet_5_example`)}
              </p>
            </IonCol>
          </IonGrid>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <IonModal
      key={'modal-edit-ticket'}
      id={`modal-edit-ticket`}
      isOpen={props.modal === `edit-ticket-modal`}
      onDidDismiss={() => props.setModal('')}
      cssClass="station-panel-modal">
      <IonGrid className="ion-margin ion-padding">
        {editIssue && modalTicket ? (
          <IonCol>
            <h2>
              {t('ikea.modal.heading_edit')}
              {modalTicket._id}
            </h2>
            <h3 className="text-light">{t('ikea.modal.subheading')}</h3>
            <p className="ion-margin-top">
              <b>{t('ikea.modal.info_heading')}</b>
            </p>
            <p className="ion-margin-top">
              <b>{t('ikea.modal.info_text')}</b>
            </p>
            <IonRow className="row ion-margin-top">
              <FormControl className="row-input ion-margin-top">
                {/* Station Monitoring panels */}
                <div>
                  <label htmlFor="location">{t('ikea.modal.location')} *</label>
                </div>
                {user?.metadata?.ikea_location === 'manager' ||
                permissions?.places ? (
                  <NativeSelect
                    value={editIssue.location}
                    onChange={e => {
                      setEditIssue({
                        ...editIssue,
                        location: e.target.value,
                      });
                      setNewPlaceLocation(e.target.value);
                    }}
                    inputProps={{
                      name: 'active',
                      id: 'active',
                    }}
                    className="ikea-modal-place-select">
                    {places.map((place, i) => {
                      return (
                        <option key={i} value={place.doc?.site_id}>
                          {place.doc?.name}, {place.doc?.address}
                        </option>
                      );
                    })}
                  </NativeSelect>
                ) : (
                  <TextField
                    id="location"
                    value={editIssue.location}
                    onChange={e => {
                      setEditIssue({
                        ...editIssue,
                        location: e.target.value,
                      });
                    }}
                    disabled={!permissions?.places}
                  />
                )}
              </FormControl>
            </IonRow>
            <IonRow className="row half ion-margin-top">
              <FormControl className="row-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="station">
                    {t('ikea.modal.charging_station')}
                  </label>
                </div>
                <NativeSelect
                  value={
                    editIssue.station
                      ? JSON.stringify(editIssue.station)
                      : JSON.stringify({
                          id: editIssue.id,
                          name: editIssue.name,
                          type: editIssue.type,
                        })
                  }
                  onChange={e => {
                    setIsEdited(true);
                    setEditIssue({
                      ...editIssue,
                      station: JSON.parse(e.target.value),
                      connector: '',
                    });
                  }}
                  inputProps={{
                    name: 'station',
                    id: 'station',
                  }}>
                  <option value={JSON.stringify({})}>
                    {t('ikea.modal.charging_station_if_known')}...
                  </option>
                  {!_.isEmpty(editIssue.station) && (
                    <option value={JSON.stringify(editIssue.station)}>
                      {editIssue.station.name}
                    </option>
                  )}
                  {stations &&
                    stations.length > 0 &&
                    stations
                      .filter(station => {
                        return editIssue.station?.id !== station.id;
                      })
                      .map((station, i) => {
                        return (
                          <option
                            key={i}
                            value={JSON.stringify({
                              id: station.id,
                              name: station.name,
                              type: station.type,
                            })}>
                            {station.name}
                          </option>
                        );
                      })}
                </NativeSelect>
              </FormControl>
              <FormControl className="row-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="connector">{t('ikea.modal.connector')}</label>
                </div>
                <NativeSelect
                  value={editIssue.connector}
                  onChange={e => {
                    setIsEdited(true);
                    setEditIssue({
                      ...editIssue,
                      connector: e.target.value,
                    });
                  }}
                  disabled={_.isEmpty(editIssue.station)}
                  inputProps={{
                    name: 'connector',
                    id: 'connector',
                  }}>
                  <option value={''}>
                    {t('ikea.modal.choose')}{' '}
                    {t('ikea.modal.connector').toLowerCase()}
                  </option>
                  {editIssue.connector !== '' && (
                    <option value={editIssue.connector}>
                      {editIssue.connector}
                    </option>
                  )}
                  {connectors &&
                    Object.keys(connectors)
                      .filter(connector => editIssue.connector !== connector)
                      .map((connector, i) => {
                        return (
                          <option key={i} value={connector}>
                            {connector}
                          </option>
                        );
                      })}
                </NativeSelect>
              </FormControl>
            </IonRow>
            <IonRow className="row ion-margin-top">
              <FormControl className="row-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="choose_issue">
                    {t('ikea.modal.choose_charging_area')} *
                  </label>
                </div>
                <NativeSelect
                  value={editIssue.alert_name}
                  onChange={e => {
                    setIsEdited(true);
                    setExpandedSeverity(true);
                    setEditIssue({
                      ...editIssue,
                      alert_name: e.target.value,
                    });
                  }}
                  inputProps={{
                    name: 'alert_name',
                    id: 'alert_name',
                  }}>
                  <option value={''}>{t('ikea.modal.select_area')}</option>
                  <option key={'Kempower'} value="Kempower">
                    Last mile delivery
                  </option>
                  <option key={'Enersense'} value="Enersense">
                    Customer charging
                  </option>
                  {/* {connectors &&
                    Object.keys(connectors)
                        .filter(connector => newTicket.connector !== connector)
                        .map((connector, i) => {
                        return (
                            <option key={i} value={connector}>
                            {connector}
                            </option>
                        );
                        })} */}
                </NativeSelect>
              </FormControl>
            </IonRow>
            <IonRow>
              <SeverityClassifications />
            </IonRow>
            <IonRow className="row ion-margin-top">
              <FormControl className="row-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="choose_issue">
                    {t('ikea.modal.choose_severity')} *
                  </label>
                </div>
                <NativeSelect
                  value={editIssue.severity}
                  onChange={e => {
                    setIsEdited(true);
                    setEditIssue({
                      ...editIssue,
                      severity: e.target.value,
                    });
                  }}
                  inputProps={{
                    name: 'severity',
                    id: 'severity',
                  }}>
                  <option value={''}>{t('ikea.modal.select_type')}</option>
                  {/* {newIssue.severity !== '' && (
                    <option value={newIssue.severity}>{newIssue.severity}</option>
                    )} */}
                  {[1, 2, 3, 4, 5].map(number => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                  {/* {connectors &&
                    Object.keys(connectors)
                        .filter(connector => newTicket.connector !== connector)
                        .map((connector, i) => {
                        return (
                            <option key={i} value={connector}>
                            {connector}
                            </option>
                        );
                        })} */}
                </NativeSelect>
              </FormControl>
            </IonRow>
            <IonRow className="row ion-margin-top">
              <FormControl className="row-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="description">
                    {t('ikea.modal.description')} *
                  </label>
                </div>
                <textarea
                  id="description"
                  name="description"
                  value={editIssue.description}
                  onChange={e => {
                    setEditIssue({
                      ...editIssue,
                      description: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </IonRow>
            <IonRow className="row ion-margin-top half">
              {modalTicket._attachments &&
                modalTicket.images.length > 0 &&
                modalTicket.images.map((image, i) => {
                  let attachment;
                  if (modalTicket._attachments && image.attachment_id !== '') {
                    attachment = modalTicket._attachments[image.attachment_id];
                  }
                  return (
                    <IonItem key={i} lines="none" className="ion-margin-bottom">
                      <IonThumbnail slot="start">
                        {attachment && attachment.digest && (
                          <IonImg
                            src={`data:${attachment.content_type};base64,${attachment.data}`}
                            alt=""
                          />
                        )}
                        {attachment && !attachment.digest && (
                          <IonImg
                            src={window.URL.createObjectURL(attachment.data)}
                            alt=""
                          />
                        )}
                      </IonThumbnail>
                      <IonText className="ion-margin-left">
                        <p className="ion-margin-none">{image.text}</p>
                      </IonText>
                      {/* <a
                        slot="end"
                        className="ion-margin-none"
                        href={`data:${attachment.content_type};base64,${attachment.data}`}
                        download={image.attachment_id}>
                        <IonButton size="small" fill="clear" color="medium">
                            <IonIcon slot="icon-only" icon={downloadOutline} />
                        </IonButton>
                        </a> */}
                      <IonButton
                        disabled={false}
                        className="ion-margin-none delete-button"
                        slot="end"
                        onClick={() => handleImageDelete(image.attachment_id)}
                        size="small"
                        fill="clear"
                        color="medium">
                        <IonIcon slot="icon-only" icon={trashOutline} />
                      </IonButton>
                    </IonItem>
                  );
                })}
              <div className="file-upload">
                <div className="file-input-container">
                  <div className="file-input-wrap">
                    {newImage.name && (
                      <p className="ion-margin-none ion-margin-right-half">
                        {newImage.name}
                      </p>
                    )}
                    {!newImage.name && (
                      <>
                        <IonButton
                          size="default"
                          fill="outline"
                          color={'dark'}
                          onClick={() => fileInput.current.click()}>
                          <IonIcon
                            className="ion-margin-right-half"
                            slot="end"
                            icon={newImage.name ? syncOutline : imageOutline}
                          />
                          {t('ikea.modal.upload_images')}
                        </IonButton>
                        <input
                          ref={fileInput}
                          type="file"
                          accept="image/*"
                          hidden
                          value=""
                          onChange={e => handleCompressedUpload(e)}
                        />
                      </>
                    )}
                  </div>
                  {newImage.name && (
                    <div className="file-input-wrap">
                      <FormControl className="row-input">
                        {/* Station Monitoring panels */}
                        <div>
                          <label htmlFor="location">
                            {t('ikea.modal.image_desc')}
                          </label>
                        </div>
                        <TextField
                          id="image-desc"
                          placeholder={t('ikea.modal.image_desc')}
                          value={imageCaption}
                          onChange={e => setImageCaption(e.target.value)}
                          autoComplete="off"
                        />
                      </FormControl>
                      <IonButton
                        className="image-upload-button"
                        size="large"
                        fill="clear"
                        onClick={() => handleImageUpload()}>
                        {t('ikea.modal.save_image')}
                        <IonIcon slot="end" icon={saveOutline} />
                      </IonButton>
                    </div>
                  )}
                </div>
              </div>
            </IonRow>
          </IonCol>
        ) : (
          <IonCol>No ticket data</IonCol>
        )}
      </IonGrid>
      <IonRow className="ion-justify-content-end ion-align-items-center">
        <IonButton
          className="ion-margin"
          size="medium"
          color="primary"
          fill="outline"
          onClick={() => props.setModal('')}>
          {t('ikea.modal.cancel').toUpperCase()}
        </IonButton>
        <IonButton
          className="ion-margin-right"
          size="medium"
          color="primary"
          fill="solid"
          onClick={() => saveModalChanges()}>
          {t('ikea.modal.save').toUpperCase()}
        </IonButton>
      </IonRow>
    </IonModal>
  );
};
export default EditTicketModal;

import React, {useEffect, useContext, useState, useCallback} from 'react';
import './App.css';
import {IonApp, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Link} from 'react-router-dom';
import Profile from './components/contacts/Profile';
import Tabs from './components/navigation/Tabs';
import LoginTabs from './components/navigation/LoginTabs';
import {ReactComponent as LogoBlack} from './assets/logo-black.svg';
import {ReactComponent as LogoWhite} from './assets/logo-white.svg';
import {UserContext} from './components/context/UserContext';
import {DatabaseContext} from './components/context/DatabaseContext';
import _ from 'lodash';
import {AuthContext} from './components/context/AuthContext';
import {useServiceWorker} from './components/context/ServiceWorkerContext';
import useConfig from './hooks/useConfig';

// Core CSS required for Ionic components to work properly
import '@ionic/react/css/core.css';

// Basic CSS for apps built with Ionic
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

// Optional CSS utils that can be commented out
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

// Theme variables
import './theme/variables.css';
import subscribeChanges from './subscribe';
import GlobalAlertComponent from './components/utils/GlobalAlertComponent';

// Ionic setup
setupIonicReact({
  mode: 'md',
  rippleEffect: false,
});

const App = () => {
  // Databases
  const databases = useContext(DatabaseContext);

  // User permissions
  const [permissions, setPermissions] = useState(null);

  // Auth check
  const [isAuth, setIsAuth] = useContext(AuthContext);

  // Ikea UI
  const [isIkea, setIsIkea] = useState(false);
  const [isIkeaChecked, setIsIkeaChecked] = useState(false);

  // Update Service Worker on launch
  const serviceWorker = useServiceWorker();

  const getRegistration = useCallback(async () => {
    if ('serviceWorker' in navigator) {
      const promise = Promise.resolve(
        await navigator.serviceWorker.getRegistration(),
      );
      promise
        .then(result => {
          console.log('Service Worker registration: ', result);
          if (result && result.waiting) {
            serviceWorker.updateAssets();
          }
        })
        .catch(err => console.log(err));
    }
  }, [serviceWorker]);

  useEffect(() => {
    if (serviceWorker) {
      getRegistration();
    }
  }, [getRegistration, serviceWorker]);

  // Fetch user data & set permissions
  const [user, setUser] = useContext(UserContext);
  const {db} = useConfig();

  const fetchSession = useCallback(async () => {
    if (db === undefined) {
      return;
    }
    await fetch(`${db}/_session`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        if (
          res.ok &&
          !_.isEmpty(res.userCtx) &&
          res.userCtx.name &&
          res.userCtx.roles.length > 0
        ) {
          // Set the user
          setUser({name: res.userCtx.name, roles: res.userCtx.roles});
          setIsAuth(true);
          // Set permissions based on the user role
          if (res.userCtx.roles.includes('_admin')) {
            setPermissions({
              user: true,
              tickets: true,
              places: true,
              contacts: true,
              guides: true,
              canDelete: true,
              reports: true,
              ikea: true,
            });
          } else if (res.userCtx.roles.includes('telemast_admin')) {
            setPermissions({
              user: true,
              tickets: true,
              places: true,
              contacts: true,
              guides: true,
              canDelete: true,
              reports: true,
              ikea: true,
            });
          } else if (res.userCtx.roles.includes('manager')) {
            setPermissions({
              user: true,
              tickets: true,
              places: true,
              contacts: true,
              guides: true,
              canDelete: false,
              reports: true,
              ikea: true,
            });
          } else if (res.userCtx.roles.includes('customer_admin')) {
            setPermissions({
              user: true,
              tickets: true,
              places: true,
              contacts: true,
              guides: true,
              canDelete: false,
              reports: true,
              ikea: false,
            });
          } else if (res.userCtx.roles.includes('field_person')) {
            setPermissions({
              user: true,
              tickets: false,
              places: false,
              contacts: false,
              guides: false,
              canDelete: false,
              reports: false,
              ikea: false,
            });
          } else if (res.userCtx.roles.includes('ikea')) {
            setPermissions({
              user: true,
              tickets: false,
              places: false,
              contacts: true,
              guides: false,
              canDelete: false,
              reports: false,
              ikea: true,
            });
            setIsIkea(true);
          }
          if (!isIkeaChecked) setIsIkeaChecked(true);
        } else {
          setIsAuth(false);
        }
      })
      .catch(err => {
        console.log('Error fetching session: ', err);
        setIsAuth(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, isAuth, setUser, setPermissions, setIsAuth]);

  const authCheck = useCallback(() => {
    if (databases && user.name && _.isEmpty(user.metadata)) {
      databases.contacts
        .get(user.name)
        .then(result => {
          setUser({
            ...user,
            metadata: {
              ...user.metadata,
              firstname_lastname: result.name,
              phone_number: result.phone_number,
              title: result.title,
              company: result.company,
              area: result.area,
              auto_mode: result.auto_mode,
              dark_mode: result.dark_mode,
              ikea_location: result.ikea_location,
              ikea:
                result.ikea !== undefined
                  ? result.ikea
                  : result.roles.includes('ikea')
                  ? true
                  : false,
            },
          });
        })
        .then(() => {
          if (_.isEmpty(permissions)) {
            fetchSession();
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else if (_.isEmpty(user)) {
      fetchSession();
    }
  }, [databases, fetchSession, permissions, setUser, user]);

  useEffect(() => {
    authCheck();
    let interval = setInterval(() => {
      fetchSession();
    }, 3600000);
    return () => clearInterval(interval);
  }, [user, authCheck, fetchSession]);

  // Loading state for all dbs for listening updates
  const [isTicketsLoading, setIsTicketsLoading] = useState(false);
  const [isPlacesLoading, setIsPlacesLoading] = useState(false);
  const [isContactsLoading, setIsContactsLoading] = useState(false);
  const [isAccessoriesLoading, setIsAccessoriesLoading] = useState(false);

  // Switch between light and dark mode
  useEffect(() => {
    if (user.metadata) {
      if (user.metadata.auto_mode) {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        if (prefersDark.matches) {
          document.body.classList.add('dark');
        } else {
          document.body.classList.remove('dark');
        }
      } else if (user.metadata.dark_mode) {
        document.body.classList.add('dark');
      } else if (!user.metadata.dark_mode) {
        document.body.classList.remove('dark');
      }
    }
    if (!isAuth) {
      document.body.classList.remove('dark');
    }
  }, [user, isAuth]);

  // Switch between ikea and management
  useEffect(() => {
    if (user.metadata) {
      if (user.metadata.ikea !== undefined) {
        setIsIkea(user.metadata.ikea);
        if (!isIkeaChecked) setIsIkeaChecked(true);
      } else {
        if (permissions?.ikea) {
          setIsIkea(true);
        } else {
          setIsIkea(false);
        }
        if (!isIkeaChecked) setIsIkeaChecked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuth]);

  // Listen to database changes
  useEffect(() => {
    if (databases && isAuth) {
      subscribeChanges(
        databases.tickets,
        _change => {
          // console.log("Something changed in tickets db:", change)
          setIsTicketsLoading(true);
          setTimeout(() => setIsTicketsLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in tickets db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );

      subscribeChanges(
        databases.places,
        () => {
          setIsPlacesLoading(true);
          setTimeout(() => setIsPlacesLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in places db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );

      subscribeChanges(
        databases.contacts,
        () => {
          //console.log("Something changed in contacts db:", change)
          setIsContactsLoading(true);
          setTimeout(() => setIsContactsLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in contacts db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );

      subscribeChanges(
        databases.warehouses,
        change => {
          //console.log("Something changed in warehouses db:", change)
          setIsPlacesLoading(true);
          setTimeout(() => setIsPlacesLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in warehouses db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );

      subscribeChanges(
        databases.accessories,
        change => {
          //console.log("Something changed in accessories db:", change)
          setIsAccessoriesLoading(true);
          setTimeout(() => setIsAccessoriesLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in accessories db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );

      subscribeChanges(
        databases.studios,
        change => {
          //console.log("Something changed in studios db:", change)
          setIsPlacesLoading(true);
          setTimeout(() => setIsPlacesLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in studios db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );

      subscribeChanges(
        databases.guides,
        change => {
          //console.log("Something changed in guides db:", change)
          setIsAccessoriesLoading(true);
          setTimeout(() => setIsAccessoriesLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in guides db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );

      subscribeChanges(
        databases.ticket_history,
        change => {
          //console.log("Something changed in ticket history db:", change)
          setIsTicketsLoading(true);
          setTimeout(() => setIsTicketsLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in ticket history db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );

      subscribeChanges(
        databases.fm_services,
        change => {
          //console.log("Something changed in fm services db:", change)
          setIsPlacesLoading(true);
          setTimeout(() => setIsPlacesLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in fm services db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );

      subscribeChanges(
        databases.software_presets,
        change => {
          //console.log("Something changed in software presets db:", change)
          setIsAccessoriesLoading(true);
          setTimeout(() => setIsAccessoriesLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in software presets db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );

      subscribeChanges(
        databases.lm_delivery_tags,
        change => {
          //console.log("Something changed in software presets db:", change)
          setIsAccessoriesLoading(true);
          setTimeout(() => setIsAccessoriesLoading(false), 1000);
        },
        err => {
          console.log('Unhandled error in software presets db:', err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        },
      );
    }
  }, [databases, isAuth, setIsAuth]);

  const [ticketsUpdated, setTicketsUpdated] = useState([]);
  const [ticketsUpdatedTimers, setTicketsUpdatedTimers] = useState({});

  const onTicketUpdatedChanged = tickets => {
    setTicketsUpdated(ticketsUpdated.concat(tickets));

    setTimeout(() => {
      setTicketsUpdated([]);
    }, 300000);
  };

  const MainView = () => {
    if (isIkeaChecked) {
      return (
        <>
          <Link to={isIkea ? '/dashboard' : '/tickets'}>
            <div className="logo-wrap">
              <LogoWhite className="logo logo-white" />
              <LogoBlack className="logo logo-black" />
            </div>
          </Link>
          <Tabs
            permissions={permissions}
            isTicketsLoading={isTicketsLoading}
            isPlacesLoading={isPlacesLoading}
            isAccessoriesLoading={isAccessoriesLoading}
            isContactsLoading={isContactsLoading}
            isIkea={isIkea}
            ticketsUpdated={ticketsUpdated}
          />
          <Profile
            permissions={permissions}
            isTicketsLoading={isTicketsLoading}
            isIkea={isIkea}
            setIsIkea={setIsIkea}
          />
          {!isIkea && (
            <GlobalAlertComponent
              isTicketsLoading={isTicketsLoading}
              isPlacesLoading={isPlacesLoading}
              onTicketUpdatedChanged={onTicketUpdatedChanged}
            />
          )}
        </>
      );
      // }
    } else {
      return LoginView();
    }
  };

  const LoginView = () => {
    return <LoginTabs />;
  };

  return (
    <IonApp>
      <IonReactRouter>
        {isAuth === true ? MainView() : isAuth !== null ? LoginView() : <></>}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

import React, {useEffect, useState} from 'react';
import {
  IonText,
  IonGrid,
  IonCol,
  IonBadge,
  IonRow,
  IonIcon,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {StartSlaCounter, GetSla} from '../utils/SlaComponent';
import SlaDiff from '../../components/utils/SlaDiff';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {chevronDownOutline, caretDownOutline} from 'ionicons/icons';
import useConfig from '../../hooks/useConfig';

const Errors = props => {
  // Misc vars
  const {t} = useTranslation('link_app');
  const {ticket, place} = props;

  const [station, setStation] = useState(props.station);

  const [ticketDataFetchFail, setTicketDataFetchFail] = useState(false);

  useEffect(() => {
    setStation({...props.station, needsInfo: true});
  }, [props.station]);

  // Table headers
  const tableHeaders = [];

  if (ticket?.errors?.length > 0) {
    ticket.errors.forEach(err => {
      Object.keys(err).forEach(key => {
        if (tableHeaders.indexOf(key) === -1) {
          tableHeaders.push(key);
        }
      });
    });
  }

  const charging_cable_statuses = {
    0: 'Connection loss',
    1: 'Unavailable',
    2: 'Disabled',
    3: 'Offline',
    4: 'Ready',
    5: 'Cable Connected',
    6: 'Car Connected',
    7: 'Charging',
    8: 'Charged',
    9: 'Cable Error',
    10: 'Voltage Error',
    11: 'Ventilation Error',
    12: 'Car Error',
    13: 'Meter error',
  };

  const getCableStatus = statusId => {
    return charging_cable_statuses[statusId] ?? statusId;
  };

  const {integrator} = useConfig();

  useEffect(() => {
    const checkData = async () => {
      await fetch(`${integrator}/stations/${station.id}`, {
        method: 'GET',
        headers: {
          Authorization:
            'Basic ' +
            Buffer.from(
              process.env.REACT_APP_IG_USERNAME +
                ':' +
                process.env.REACT_APP_IG_PASSWORD,
              'utf-8',
            ).toString('base64'),
        },
      })
        .then(res => {
          return res.json();
        })
        .then(res => {
          if (res) {
            if (res.type === 'kempower' || res.type === 'ocpp') {
              setStation({
                ...station,
                needsInfo: false,
                connectors: res.connectors || null,
                status: res.status || null,
                currentPowerA: res.currentPowerA || null,
                currentPowerKw: res.currentPowerKw || null,
                acDcPowerRatio: res.acDcPowerRatio || null,
                model: res.model || null,
                manufacturingInfo: res.manufacturingInfo || null,
                devices: res.devices || null,
                activeAlerts: res.activeAlerts || null,
              });
            } else {
              setStation({
                ...station,
                needsInfo: false,
                connectors: res.connectors || null,
                sessions: res.sessions || null,
                totalKWh: res.totalKWh || null,
                totalTime: res.totalTime || null,
              });
            }
            setTicketDataFetchFail(false);
          } else {
            setTicketDataFetchFail(true);
          }
        })
        .catch(err => {
          setTicketDataFetchFail(true);
          console.log('Error fetching ticket data: ', err);
        });
    };
    if (station.id && station.needsInfo) {
      checkData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket, station]);

  const [devices, setDevices] = useState([]);
  useEffect(() => {
    if (station.devices) {
      let devicesBySatellite = [];
      station.devices.forEach((device, i) => {
        if (devicesBySatellite[device.satelliteId] === undefined) {
          devicesBySatellite[device.satelliteId] = [device];
        } else {
          devicesBySatellite[device.satelliteId] = [
            ...devicesBySatellite[device.satelliteId],
            device,
          ];
        }
      });
      setDevices(devicesBySatellite);
    }
  }, [station]);

  const GetNiceDateForTicket = dateTime => {
    const date = new Date(dateTime);
    const dateStr = date.toLocaleDateString('fi-FI', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const timeStr = date.toLocaleTimeString('fi-FI', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    const dateTimeStr = `${dateStr}, ${timeStr.replace(/\./g, ':')}`;
    if (dateStr === 'Invalid Date' || timeStr === 'Invalid Date') {
      return 'Ei aikaa saatavilla';
    }
    return dateTimeStr;
  };

  const [isAllExpanded, setAllExpanded] = React.useState(true);
  const [expanded, setExpanded] = React.useState('panel-error-0');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const NewTicketErrorsList = () => {
    return (
      <>
        {place && !station.needsInfo && (
          <>
            {ticketDataFetchFail && (
              <IonRow>
                <IonCol>
                  <IonText color={'danger'}>
                    <h4>{t('tickets.data_error')}</h4>
                  </IonText>
                </IonCol>
              </IonRow>
            )}
            <Accordion
              expanded={isAllExpanded}
              onChange={() => setAllExpanded(!isAllExpanded)}>
              <AccordionSummary
                expandIcon={
                  <IonIcon
                    className="ion-margin-right-half"
                    slot="start"
                    icon={chevronDownOutline}
                  />
                }
                aria-controls="panel1a-content">
                <IonText>
                  <h5>
                    <IonBadge
                      color="danger"
                      className="ion-margin-right ion-padding-top-half ion-padding-right-half ion-padding-bottom-half ion-padding-left-half">
                      {ticket?.errors.length}
                    </IonBadge>{' '}
                    {t('tickets.all_errors')}
                  </h5>
                </IonText>
              </AccordionSummary>
              <AccordionDetails>
                <IonGrid className="ion-padding-none">
                  <IonCol>
                    <IonGrid className="ticket-data-table ion-padding-none">
                      {ticket?.errors.length > 0 &&
                        ticket.errors.map((error, i) => {
                          // Removed maximum of 20 errors to show
                          return (
                            <Accordion
                              key={'ticket-error' + i}
                              expanded={expanded === 'panel-error-' + i}
                              onChange={handleChange('panel-error-' + i)}>
                              <AccordionSummary
                                className="ticket-error-datetime"
                                expandIcon={
                                  <IonIcon
                                    className="ion-margin-right-half"
                                    slot="start"
                                    icon={caretDownOutline}
                                    color="primary"
                                  />
                                }
                                aria-controls={`panel-${error + i}`}
                                id={`panel-${error + i}`}>
                                <IonText
                                  className="ion-margin-right"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                  }}>
                                  {t('places.error')}:{' '}
                                  {GetNiceDateForTicket(error.ALARM_RECEIVED)}
                                </IonText>
                              </AccordionSummary>
                              <AccordionDetails>
                                {/* <IonGrid className="ticket-error-details">
																<IonCol> */}
                                <IonGrid className="ion-padding-none ion-margin-top">
                                  <IonCol>
                                    <IonGrid className="ticket-data-table ion-padding-none">
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('tickets.alert_place')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {place.name},{' '}
                                          <span className="text-medium">
                                            ID {place.site_id}
                                          </span>
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('places.charging_station')}
                                        </IonCol>
                                        <IonCol size="9">
                                          <span className="text-medium">
                                            {station.name}, UID: {station.id}
                                          </span>
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('places.charging_cable')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {error.CONNECTOR ?? '-'}
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('places.charging_cable_status')}
                                        </IonCol>
                                        {station.type === 'kempower' ||
                                        station.type === 'ocpp' ? (
                                          <IonCol size="9">
                                            {station?.connectors?.[
                                              error.CONNECTOR
                                            ]?.status ?? '-'}
                                          </IonCol>
                                        ) : (
                                          <IonCol size="9">
                                            {station?.connectors?.[
                                              error.CONNECTOR
                                            ]?.status
                                              ? getCableStatus(
                                                  station?.connectors[
                                                    error.CONNECTOR
                                                  ]?.status,
                                                )
                                              : '-'}
                                          </IonCol>
                                        )}
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('tickets.alert_name')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {error.ALERTNAME ?? '-'}
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('tickets.alert_summary')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {error.SUMMARY ?? '-'}
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('tickets.description')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {error.DESCRIPTION ?? '-'}
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('tickets.fault_status')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {error.ALARM === 'on'
                                            ? 'Päällä'
                                            : 'Pois'}
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('places.sla')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {StartSlaCounter(
                                            ticket?.sla,
                                            ticket?.time_when_created,
                                          ) ? (
                                            <>
                                              {ticket?.sla !== '' &&
                                                ticket?.is_active === false && (
                                                  <SlaDiff
                                                    id={ticket?._id}
                                                    end={
                                                      ticket?.time_when_completed
                                                    }
                                                    start={
                                                      ticket?.time_when_created
                                                    }
                                                    sla={ticket?.sla}
                                                  />
                                                )}
                                              {ticket?.sla !== '' &&
                                                ticket?.time_when_completed !==
                                                  '' &&
                                                ticket?.is_active === true && (
                                                  <SlaDiff
                                                    id={ticket?._id}
                                                    end={
                                                      ticket?.time_when_completed
                                                    }
                                                    start={
                                                      ticket?.time_when_created
                                                    }
                                                    sla={ticket?.sla}
                                                  />
                                                )}
                                              {ticket?.sla !== '' &&
                                                ticket?.time_when_completed ===
                                                  '' &&
                                                ticket?.time_when_created !==
                                                  '' &&
                                                ticket?.is_active === false && (
                                                  <IonBadge color="light">
                                                    {ticket?.sla}
                                                  </IonBadge>
                                                )}
                                              {ticket?.sla !== '' &&
                                                ticket?.time_when_completed ===
                                                  '' &&
                                                ticket?.time_when_created !==
                                                  '' &&
                                                ticket?.is_active === true &&
                                                GetSla(
                                                  ticket?.sla,
                                                  ticket?.time_when_created,
                                                  false,
                                                )}
                                            </>
                                          ) : (
                                            <p className="color-medium">
                                              {t('tickets.sla')} {ticket?.sla}
                                            </p>
                                          )}
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('tickets.severity')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {error.SEVERITY ?? '-'}
                                        </IonCol>
                                      </IonRow>

                                      {/* Charger specific information */}
                                      {(station.type === 'kempower' ||
                                        station.type === 'ocpp') && (
                                        <>
                                          <IonRow className="color-medium">
                                            <IonCol size="3">
                                              {t('station.product_name')}
                                            </IonCol>
                                            <IonCol size="9">
                                              {station.manufacturingInfo
                                                ?.productName ?? '-'}
                                            </IonCol>
                                          </IonRow>
                                          <IonRow className="color-medium">
                                            <IonCol size="3">
                                              {t('station.model')}
                                            </IonCol>
                                            <IonCol size="9">
                                              {station.model ?? '-'}
                                            </IonCol>
                                          </IonRow>
                                          <IonRow className="color-medium">
                                            <IonCol size="3">
                                              {t('station.product_id')}
                                            </IonCol>
                                            <IonCol size="9">
                                              {station.manufacturingInfo
                                                ?.productId ?? '-'}
                                            </IonCol>
                                          </IonRow>
                                          <IonRow className="color-medium">
                                            <IonCol size="3">
                                              {t('station.order_number')}
                                            </IonCol>
                                            <IonCol size="9">
                                              {station.manufacturingInfo
                                                ?.orderNumber ?? '-'}
                                            </IonCol>
                                          </IonRow>
                                        </>
                                      )}
                                      {/* {station.type === 'uc' && (
                                        <IonRow className="color-medium">
                                          <IonCol size="3">
                                            {t('station.allocated_current')}
                                          </IonCol>
                                          <IonCol size="9">
                                            {station?.connectors[
                                              error.CONNECTOR
                                            ]
                                              ? station?.connectors[
                                                  error.CONNECTOR
                                                ].allocatedCurrent
                                              : '-'}
                                          </IonCol>
                                        </IonRow>
                                      )} */}
                                    </IonGrid>
                                  </IonCol>
                                </IonGrid>
                                {/* </IonCol>
															</IonGrid> */}
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                    </IonGrid>
                  </IonCol>
                </IonGrid>
              </AccordionDetails>
            </Accordion>
            <Divider className="ion-margin-top" />

            {/* ActiveAlerts */}
            {station?.activeAlerts?.length > 0 && (
              <>
                <Accordion>
                  <AccordionSummary
                    className="ticket-error-datetime"
                    expandIcon={
                      <IonIcon
                        className="ion-margin-right-half"
                        slot="start"
                        icon={chevronDownOutline}
                      />
                    }
                    aria-controls="panel-active-alerts-content">
                    <IonText>
                      <h5>{t('tickets.active_alerts')}</h5>
                    </IonText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <IonGrid className="ion-padding-none">
                      <IonCol>
                        <IonGrid className="ticket-data-table ion-padding-none">
                          <TableContainer>
                            <Table className="tickets-station-alerts-table">
                              <TableHead className="tickets-table-head">
                                <TableRow>
                                  <TableCell width="10%">
                                    {t('tickets.alert_id')}
                                  </TableCell>
                                  <TableCell width="20%">
                                    {t('accessories.type')}
                                  </TableCell>
                                  <TableCell width="20%">
                                    {t('place.tasks_title')}
                                  </TableCell>
                                  <TableCell width="20%">
                                    {t('place.startTime')}
                                  </TableCell>
                                  <TableCell>
                                    {t('tickets.ticket_message')}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {station.activeAlerts.map((alert, i) => {
                                  return (
                                    <TableRow>
                                      <TableCell width="10%">
                                        {alert.id}
                                      </TableCell>
                                      <TableCell width="20%">
                                        {alert.type}
                                      </TableCell>
                                      <TableCell width="20%">
                                        {alert.title}
                                      </TableCell>
                                      <TableCell width="20%">
                                        {GetNiceDateForTicket(alert.timestamp)}
                                      </TableCell>
                                      <TableCell>{alert.message}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </IonGrid>
                      </IonCol>
                    </IonGrid>
                  </AccordionDetails>
                </Accordion>
                <Divider className="ion-margin-top" />
              </>
            )}

            {/* Devices */}
            {devices.length > 0 && (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <IonIcon
                        className="ion-margin-right-half"
                        slot="start"
                        icon={chevronDownOutline}
                      />
                    }
                    aria-controls="panel-active-alerts-content">
                    <IonText>
                      <h5>{t('station.devices')}</h5>
                    </IonText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <IonGrid className="ticket-data-table ion-padding-none">
                      {devices.map((satellite, i) => {
                        return (
                          <Accordion
                            className="ticket-error-satellite"
                            key={'ticket-satellite-' + i}>
                            <AccordionSummary
                              className="ticket-error-satellite-summary"
                              expandIcon={
                                <IonIcon
                                  className="ion-margin-right-half"
                                  slot="start"
                                  icon={caretDownOutline}
                                  color="primary"
                                />
                              }
                              aria-controls={`panel-satellite-` + i}
                              id={`panel-satellite-` + i}>
                              <IonText
                                className="ion-margin-right"
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  flexDirection: 'column',
                                }}>
                                {t('station.satellite_id')}: {i ?? '-'}
                              </IonText>
                            </AccordionSummary>
                            <AccordionDetails>
                              {satellite.length > 0 &&
                                satellite.map((device, i) => {
                                  return (
                                    <div
                                      key={`device-${i}`}
                                      className="ticket-error-satellite-container ion-padding ion-margin-top">
                                      <IonRow className="ion-margin-top-half">
                                        <IonCol size="3">
                                          {t('station.model')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {device.modelName ?? '-'}
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('station.serial_number')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {device.serialNumber ?? '-'}
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="3">
                                          {t('station.device_type_short')}
                                        </IonCol>
                                        <IonCol size="9">
                                          {device.deviceTypeShort ?? '-'}
                                        </IonCol>
                                      </IonRow>
                                      {(device.deviceTypeShort === 'cs' ||
                                        device.deviceTypeShort === 'sc') && (
                                        <>
                                          <IonRow>
                                            <IonCol size="3">
                                              {t('station.role')}
                                            </IonCol>
                                            <IonCol size="9">
                                              {device.details?.role ?? '-'}
                                            </IonCol>
                                          </IonRow>
                                          <IonRow>
                                            <IonCol size="3">
                                              {t('station.sw_version')}
                                            </IonCol>
                                            <IonCol size="9">
                                              {device.details?.swVersion ?? '-'}
                                            </IonCol>
                                          </IonRow>
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </IonGrid>
                  </AccordionDetails>
                </Accordion>
                <Divider className="ion-margin-top" />
              </>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <NewTicketErrorsList />
    </>
  );
};

export default Errors;

import React from 'react';
import {IonText, IonGrid, IonCol} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import DateTime from '../utils/DateTime';

const Log = props => {
  const {t} = useTranslation('link_app');
  const {ticket} = props;

  // Table headers
  const tableHeaders = [];
  if (ticket?.log?.length > 0) {
    ticket.log.forEach(item => {
      Object.keys(item).forEach(key => {
        if (tableHeaders.indexOf(key) === -1) {
          tableHeaders.push(key);
        }
      });
    });
  }

  return (
    <IonGrid className="ion-padding-none ion-margin-top">
      <IonCol size="12">
        <IonText>
          <h5>{t('tickets.log_segment')}</h5>
        </IonText>
        {tableHeaders?.length > 0 && (
          <table className="errors-table ion-margin-bottom">
            <tbody>
              <tr>
                {tableHeaders
                  .filter(key => {
                    return key !== 'id';
                  })
                  .map((key, i) => {
                    return <th key={i}>{t(`tickets.ticket_log_${key}`)}</th>;
                  })}
              </tr>
              {ticket?.log
                ?.sort((a, b) => {
                  return parseInt(b.id) - parseInt(a.id);
                })
                .map(item => {
                  return (
                    <tr key={item.id}>
                      {Object.values(item).map((value, i) => {
                        const key = Object.keys(item).find(
                          key => item[key] === value,
                        );
                        if (key === 'user') {
                          return (
                            <td key={i}>
                              <IonText>
                                <p>{value.name}</p>
                              </IonText>
                            </td>
                          );
                        } else if (key === 'time') {
                          return (
                            <td key={i}>
                              <IonText>
                                <DateTime timestamp={value} />
                              </IonText>
                            </td>
                          );
                        } else if (key === 'app') {
                          return (
                            <td key={i}>
                              <IonText>
                                <p>{t(`tickets.log_app_${value}`)}</p>
                              </IonText>
                            </td>
                          );
                        } else if (key === 'event') {
                          return (
                            <td key={i}>
                              <IonText>
                                {value.includes('phase') ? (
                                  <p>
                                    {t('tickets.log_event_phase')}{' '}
                                    {value.replace('phase_', '')}
                                  </p>
                                ) : value.includes('accessory') ? (
                                  <p>
                                    {t('tickets.log_event_accessory')}{' '}
                                    {value.replace('accessory_', '')}
                                  </p>
                                ) : (
                                  <p>{t(`tickets.log_event_${value}`)}</p>
                                )}
                              </IonText>
                            </td>
                          );
                        } else return null;
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </IonCol>
    </IonGrid>
  );
};

export default Log;

import React, {useState, useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import FlowInfo from '../../components/accessories/FlowInfo';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';


const Flow = props => {
  // Misc vars
  const {match, permissions} = props;

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Fetch the error type
  const [doc, setDoc] = useState({});
  useEffect(() => {
    databases.guides
      .get(match.params.id)
      .then(result => {
        setDoc(result);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.guides, match, props.isLoading, setIsAuth]);

  // Get flow index
  const [flowIndex, setFlowIndex] = useState(null);
  useEffect(() => {
    if (doc._id && doc.flows && doc.flows.length > 0 && !flowIndex) {
      setFlowIndex(
        doc.flows.findIndex(flow => flow.id === match.params.flowId),
      );
    }
  }, [doc, flowIndex, match]);

  useTitleEffect(flowIndex !== null ? doc.flows[flowIndex].title : '');

  return (
    <IonPage>
      <Header
        title={flowIndex !== null ? doc.flows[flowIndex].title : ''}
        backButton
      />
      <IonContent>
        {doc._id && flowIndex !== null && flowIndex > -1 ? (
          <div className="container-narrow">
            <FlowInfo
              doc={doc}
              setDoc={setDoc}
              flowIndex={flowIndex}
              setFlowIndex={setFlowIndex}
              match={match}
              history={props.history}
              permissions={permissions}
            />
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Flow;

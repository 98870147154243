import React, {useState} from 'react';
import {IonIcon} from '@ionic/react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Network} from '@capacitor/network';
import {cloudOfflineOutline} from 'ionicons/icons';
import ResetPassword from '../../pages/contacts/ResetPassword';
import LogIn from '../../pages/contacts/LogIn';
import ForgotPassword from '../../pages/contacts/ForgotPassword';

const LoginTabs = () => {
  const {t} = useTranslation('link_app');

  // Network status
  const [isConnected, setIsConnected] = useState(true);

  Network.addListener('networkStatusChange', status => {
    status.connected ? setIsConnected(true) : setIsConnected(false);
  });

  return (
    <Router>
      <Switch>
        <Route
          path="/reset-password/:token"
          render={props => {
            return <ResetPassword {...props} />;
          }}
        />
        <Route
          path="/forgot-password"
          render={props => {
            return <ForgotPassword {...props} />;
          }}
        />
        <Route path="*" component={LogIn} />
      </Switch>
      {!isConnected && (
        <>
          <div className="no-connection-background" />
          <div className="no-connection-notice">
            <IonIcon
              className="ion-margin-right-half"
              icon={cloudOfflineOutline}
            />
            {t('app.no_connection')}
          </div>
        </>
      )}
    </Router>
  );
};

export default LoginTabs;

import React, {useState} from 'react';
import Header from '../components/navigation/Header';
import {IonPage, IonContent, IonButton, IonIcon} from '@ionic/react';
import {chevronBack, chevronForward} from 'ionicons/icons';
import _ from 'lodash';
import {Document, Page} from 'react-pdf';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import manualFile from '../../src/assets/documents/IKEA_usermanual_1_2_EN.pdf';
import useTitleEffect from '../hooks/useTitle';

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const DocumentPreview = props => {
  const {match} = props;

  useTitleEffect(match.params.attachmentId);

  // Get attachment

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(nextNumPages) {
    setNumPages(nextNumPages._pdfInfo.numPages);
  }

  return (
    <IonPage>
      <Header title={'IKEA User Manual'} backButton />
      <IonContent>
        <div className="container-wide">
          <>
            <div className="pdf-document">
              <Document
                onLoadSuccess={onDocumentLoadSuccess}
                // file={`data:application/pdf;base64,${attachment.data}`}
                file={manualFile}
                onLoadError={console.error}>
                <Page key={`page_${pageNumber}`} pageNumber={pageNumber} />
              </Document>
              <div className="page-controls">
                <IonButton
                  size="medium"
                  onClick={() => setPageNumber(pageNumber - 1)}
                  disabled={pageNumber <= 1}>
                  <IonIcon
                    color={pageNumber <= 1 ? 'medium' : 'white'}
                    icon={chevronBack}
                  />
                </IonButton>
                <span>
                  {pageNumber} of {numPages}
                </span>
                <IonButton
                  size="medium"
                  onClick={() => setPageNumber(pageNumber + 1)}
                  disabled={pageNumber >= numPages}>
                  <IonIcon
                    color={pageNumber >= numPages ? 'medium' : 'white'}
                    icon={chevronForward}
                  />
                </IonButton>
              </div>
            </div>
          </>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DocumentPreview;

import React, {useState, useEffect} from 'react';
import {FormControl, TextField, NativeSelect} from '@material-ui/core';
import {IonText, IonGrid, IonRow, IonCol, IonButton} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';

const cleanPlace = {
  _id: '',
  type: 'site',
  sub_type: 'mast',
  name: '',
  site_id: '',
  address: '',
  postal_address: '',
  coordinates: ['', ''],
  contact: '',
  owner_phone: '',
  images: [],
  general: {
    passage: {
      images: [],
      comment: '',
    },
    entry: {
      images: [],
      comment: '',
    },
    alert_system: {
      images: [],
      values: [],
      comment: '',
    },
    device_location: {
      images: [],
      comment: '',
    },
    mast: {
      images: [],
      values: [],
      comment: '',
    },
  },
  fm_services: [],
  systems: {
    telecommunications: {
      images: [],
      values: [],
    },
    electricity: {
      images: [],
      values: [],
    },
    monitoring_system: {
      images: [],
      values: [],
    },
    ups: {
      images: [],
      values: [],
    },
  },
  comments: [],
  tasks: [],
  notes: [],
};

const PlaceInfo = props => {
  const {t} = useTranslation('link_app');
  const match = props.match;
  const permissions = props.permissions;

  // Copy place to local state
  const [place, setPlace] = useState(cleanPlace);
  useEffect(() => {
    if (!_.isEmpty({...props.place})) {
      setPlace(props.place);
    } else {
      setPlace(cleanPlace);
    }
  }, [match, props.place]);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  return (
    <>
      <IonText>
        <h1>{t('place.site_details')}</h1>
      </IonText>
      <IonGrid className="ion-padding-none">
        {/* <div className="row">
					<FormControl className="row-input">
						<div>
							<label htmlFor="sub-type">{t("place.subtype")}</label>
						</div>
						<NativeSelect
							id="sub-type"
							value={place.sub_type}
							onChange={(e) => {
								setPlace({ ...place, sub_type: e.target.value })
								setIsEdited(true)
							}}
							disabled={!permissions?.places}
						>
							<option value={"mast"}>{t(`places.mast`)}</option>
							<option value={"location"}>{t(`places.location`)}</option>
						</NativeSelect>
					</FormControl>
				</div> */}
        <div className="row half">
          <FormControl className="row-input">
            {/* Place Name */}
            <div>
              <label htmlFor="name">{t('place.name') + ' *'}</label>
            </div>
            <TextField
              id="name"
              value={place.name}
              onChange={e => {
                setPlace({...place, name: e.target.value});
                setIsEdited(true);
              }}
              disabled={!permissions?.places}
            />
            {place.name === '' && (
              <IonText className="place-item-errortext" color="danger">
                {t('helper_texts.field_can_not_be_empty')}
              </IonText>
            )}
          </FormControl>

          <FormControl className="row-input">
            {/* Place Site-ID */}
            <div>
              <label htmlFor="site-id">{t('place.site_id')}</label>
            </div>
            <TextField
              id="site-id"
              disabled={!permissions?.places}
              value={place.site_id}
              onChange={e => {
                setPlace({...place, site_id: e.target.value});
                setIsEdited(true);
              }}
            />
            {place.site_id === '' && (
              <IonText className="place-item-errortext" color="danger">
                {t('helper_texts.field_can_not_be_empty')}
              </IonText>
            )}
          </FormControl>
        </div>
        <div className="row half">
          <FormControl className="row-input">
            {/* ADDRESS*/}
            <div>
              <label htmlFor="address">{t('place.address')}</label>
            </div>
            <TextField
              id="address"
              value={place.address || ''}
              onChange={e => {
                setPlace({...place, address: e.target.value});
                setIsEdited(true);
              }}
              disabled={!permissions?.places}
            />
          </FormControl>
          <FormControl className="row-input">
            {/* POSTAL ADDRESS */}
            <div>
              <label htmlFor="postal-address">
                {t('place.postal_address')}
              </label>
            </div>
            <TextField
              id="postal-address"
              value={place.postal_address || ''}
              onChange={e => {
                setPlace({...place, postal_address: e.target.value});
                setIsEdited(true);
              }}
              disabled={!permissions?.places}
            />
          </FormControl>
        </div>
        <div className="row half">
          <FormControl className="row-input">
            {/* Device Support */}
            <div>
              <label htmlFor="device-support">
                {t('place.device_support')}
              </label>
            </div>
            <TextField
              id="device-support"
              value={place.owner_phone || ''}
              onChange={e => {
                setPlace({...place, owner_phone: e.target.value});
                setIsEdited(true);
              }}
              disabled={!permissions?.places}
            />
          </FormControl>
          <FormControl className="row-input">
            {/* OWNER */}
            <div>
              <label htmlFor="contact">{t('place.contact')}</label>
            </div>
            <TextField
              id="contact"
              value={place.contact || ''}
              onChange={e => {
                setPlace({...place, contact: e.target.value});
                setIsEdited(true);
              }}
              disabled={!permissions?.places}
            />
          </FormControl>
        </div>
        {props.user?.roles?.includes('telemast_admin') && (
          <div className="row half">
            <FormControl className="row-input">
              {/* Device Support */}
              <div>
                <label htmlFor="customer-service-teams">
                  {t('place.customer_service_teams')}
                </label>
              </div>
              <TextField
                id="customer-service-teams"
                value={place.customer_service_teams || ''}
                onChange={e => {
                  setPlace({...place, customer_service_teams: e.target.value});
                  setIsEdited(true);
                }}
                disabled={!permissions?.places}
              />
            </FormControl>
            <FormControl className="row-input">
              {/* OWNER */}
              <div>
                <label htmlFor="lm-delivery-teams">
                  {t('place.lm_delivery_teams')}
                </label>
              </div>
              <TextField
                id="lm-delivery-teams"
                value={place.lm_delivery_teams || ''}
                onChange={e => {
                  setPlace({...place, lm_delivery_teams: e.target.value});
                  setIsEdited(true);
                }}
                disabled={!permissions?.places}
              />
            </FormControl>
          </div>
        )}
        <div className="row half">
          <FormControl className="row-input">
            {/* Device Support */}
            <div>
              <label htmlFor="place-manager">{t('roles.manager')}</label>
            </div>
            {/* <TextField
                id="place-manager"
                value={place.place_manager || ''}
                onChange={e => {
                  setPlace({...place, place_manager: e.target.value});
                  setIsEdited(true);
                }}
                disabled={!permissions?.places}
              /> */}
            <NativeSelect
              id="place-manager"
              value={JSON.stringify(place.place_manager)}
              onChange={e => {
                setPlace({
                  ...place,
                  place_manager:
                    e.target.value !== '' ? JSON.parse(e.target.value) : '',
                });
                setIsEdited(true);
              }}
              disabled={!permissions?.places}>
              <option key={'contact-default'} value={''}>
                {t(`place.place_manager`)}
              </option>
              {props.contacts.map((contact, i) => {
                return (
                  <option
                    key={i}
                    value={JSON.stringify({
                      id: contact.doc._id,
                      name: contact.doc.name,
                      management: true,
                    })}>
                    {contact.doc?.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <div></div>
        </div>

        <IonRow>
          {/* <IonCol>
						<IonButton routerLink="/places" className="ion-margin-right" fill="outline" color="danger">
							{t("app.abort")}
						</IonButton>
					</IonCol> */}
          <IonCol>
            <IonButton
              onClick={() => {
                props.savePlaceChanges(place);
                setIsEdited(false);
              }}
              fill="solid"
              color="success"
              disabled={!isEdited}>
              {t('app.save')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default PlaceInfo;

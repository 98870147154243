import React from 'react';
import Countdown from 'react-countdown';
import {IonBadge} from '@ionic/react';

const today = new Date();
const currentDay = today.getDate();
const currentMonth = today.getMonth();
const currentYear = today.getFullYear();
const currentHour = today.getHours();
const currentWeekday = today.getDay();

const isFriday = currentWeekday === 5;
const isSaturday = currentWeekday === 6;
const isSunday = currentWeekday === 0;

export const StartSlaCounter = (ticket_sla, time_when_created) => {
  let startSlaCounter = true;

  const dateCreated = new Date(parseInt(time_when_created) * 1000);
  const dayCreated = dateCreated.getDate();
  const monthCreated = dateCreated.getMonth();
  const yearCreated = dateCreated.getFullYear();
  const hourCreated = dateCreated.getHours();
  const weekdayCreated = dateCreated.getDay();

  const isCreatedToday =
    dayCreated === currentDay &&
    monthCreated === currentMonth &&
    yearCreated === currentYear;
  const isCreatedYesterday =
    dayCreated === currentDay - 1 &&
    monthCreated === currentMonth &&
    yearCreated === currentYear;
  const isCreatedDayBeforeYesterday =
    dayCreated === currentDay - 2 &&
    monthCreated === currentMonth &&
    yearCreated === currentYear;

  const isCreatedOnWeekend =
    weekdayCreated === 0 ||
    weekdayCreated === 6 ||
    (ticket_sla === 8 && weekdayCreated === 5 && hourCreated >= 17);

  if (ticket_sla === 6) {
    if (isCreatedToday && currentHour >= 22 && hourCreated >= 22) {
      startSlaCounter = false;
    } else if (isCreatedToday && currentHour < 8 && hourCreated < 8) {
      startSlaCounter = false;
    } else if (isCreatedYesterday && currentHour < 8 && hourCreated >= 22) {
      startSlaCounter = false;
    }
  }

  // SLA8 (+1), 08:00-17:00, Mon-Fri
  if (ticket_sla === 8) {
    if (isCreatedOnWeekend) {
      if (
        isFriday &&
        isCreatedToday &&
        currentHour >= 17 &&
        hourCreated >= 17
      ) {
        startSlaCounter = false;
      } else if (isSaturday && isCreatedYesterday && hourCreated >= 17) {
        startSlaCounter = false;
      } else if (isSaturday && isCreatedToday) {
        startSlaCounter = false;
      } else if (
        (isSunday && isCreatedYesterday) ||
        (isSunday && isCreatedDayBeforeYesterday && hourCreated >= 17)
      ) {
        startSlaCounter = false;
      } else if (isSunday && isCreatedToday) {
        startSlaCounter = false;
      }
    } else if (isCreatedToday && currentHour >= 17 && hourCreated >= 17) {
      startSlaCounter = false;
    } else if (isCreatedToday && currentHour < 8 && hourCreated < 8) {
      startSlaCounter = false;
    } else if (isCreatedYesterday && currentHour < 8 && hourCreated >= 17) {
      startSlaCounter = false;
    }
  }
  return startSlaCounter;
};

export const GetSla = (ticket_sla, time_when_created, has_badge = false) => {
  // SLA count

  const dateCreated = new Date(parseInt(time_when_created) * 1000);
  const dayCreated = dateCreated.getDate();
  const hourCreated = dateCreated.getHours();
  const minuteCreated = dateCreated.getMinutes();
  const weekdayCreated = dateCreated.getDay();

  const countToMonday = weekdayCreated === 0 ? 1 : 7 - weekdayCreated;

  const isCreatedOnWeekend =
    weekdayCreated === 0 ||
    weekdayCreated === 6 ||
    (ticket_sla === 8 && weekdayCreated === 5 && hourCreated >= 17);

  // SLA4 (+1)
  // if (ticket_sla === 4) {
  dateCreated.setHours(hourCreated + ticket_sla);
  dateCreated.setMinutes(minuteCreated + 30);
  // }

  // SLA6 (+1), 08:00-22:00
  if (ticket_sla === 6) {
    if (hourCreated >= 22) {
      dateCreated.setDate(dayCreated + 1);
      dateCreated.setHours(8 + ticket_sla);
      dateCreated.setMinutes(minuteCreated + 30);
      dateCreated.setMinutes(0);
      dateCreated.setSeconds(0);
    } else if (hourCreated < 8) {
      dateCreated.setHours(8 + ticket_sla);
      dateCreated.setMinutes(minuteCreated + 30);
      dateCreated.setMinutes(0);
      dateCreated.setSeconds(0);
    } else {
      dateCreated.setHours(hourCreated + ticket_sla);
      dateCreated.setMinutes(minuteCreated + 30);
    }
  }

  // SLA8 (+1), 08:00-17:00, Mon-Fri
  if (ticket_sla === 8) {
    if (isCreatedOnWeekend) {
      dateCreated.setDate(dayCreated + countToMonday);
      dateCreated.setHours(8 + ticket_sla);
      dateCreated.setMinutes(minuteCreated + 30);
      dateCreated.setMinutes(0);
      dateCreated.setSeconds(0);
    } else if (hourCreated >= 17) {
      dateCreated.setDate(dayCreated + 1);
      dateCreated.setHours(8 + ticket_sla);
      dateCreated.setMinutes(minuteCreated + 30);
      dateCreated.setMinutes(0);
      dateCreated.setSeconds(0);
    } else if (hourCreated < 8) {
      dateCreated.setHours(8 + ticket_sla);
      dateCreated.setMinutes(minuteCreated + 30);
      dateCreated.setMinutes(0);
      dateCreated.setSeconds(0);
    } else {
      dateCreated.setHours(hourCreated + ticket_sla);
      dateCreated.setMinutes(minuteCreated + 30);
    }
  }

  return (
    <>
      {has_badge ? (
        <Countdown
          overtime={true}
          date={dateCreated}
          renderer={({days, hours, minutes, completed}) => {
            if (completed) {
              return (
                <IonBadge color="danger">
                  {ticket_sla}
                  &nbsp;
                  <span className="badge-date">
                    +{days ? `${days} d ` : ''}
                    {hours ? `${hours} h ` : ''}
                    {minutes ? `${minutes} min` : ''}
                  </span>
                </IonBadge>
              );
            } else {
              return (
                <IonBadge color="warning">
                  {ticket_sla}
                  &nbsp;
                  <span className="badge-date">
                    -{days ? `${days} d ` : ''}
                    {hours ? `${hours} h ` : ''}
                    {minutes ? `${minutes} min` : ''}
                  </span>
                </IonBadge>
              );
            }
          }}
        />
      ) : (
        <Countdown
          overtime={true}
          date={dateCreated}
          renderer={({days, hours, minutes, completed}) => {
            if (completed) {
              return (
                <p>
                  {ticket_sla}
                  &nbsp;
                  <span className="badge-date">
                    +{days ? `${days} d ` : ''}
                    {hours ? `${hours} h ` : ''}
                    {minutes ? `${minutes} min` : ''}
                  </span>
                </p>
              );
            } else {
              return (
                <p>
                  {ticket_sla}
                  &nbsp;
                  <span className="badge-date">
                    -{days ? `${days} d ` : ''}
                    {hours ? `${hours} h ` : ''}
                    {minutes ? `${minutes} min` : ''}
                  </span>
                </p>
              );
            }
          }}
        />
      )}
    </>
  );
};

import {useState, useEffect, useMemo, useContext} from 'react';
import {UserContext} from '../components/context/UserContext';

let settingsCache = undefined;

export default function useConfig() {
  const [user] = useContext(UserContext);
  const theme = user.metadata?.dark_mode ? 'dark' : 'light';
  const [config, setConfig] = useState(settingsCache);
  useEffect(() => {
    if (settingsCache) {
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;
    fetch('/static/site-config.json', {signal}).then(async res => {
      const config = await res.json();
      settingsCache = config;
      setConfig(config);
    });

    return () => controller && controller.abort();
  }, []);

  return useMemo(
    () =>
      config
        ? Object.fromEntries(
            Object.entries(config).map(([part, v]) => [
              part,
              typeof v === 'object'
                ? Object.fromEntries(
                    Object.entries(v).map(([type, url]) => [
                      type,
                      url.replaceAll('{theme}', theme),
                    ]),
                  )
                : v,
            ]),
          )
        : {},
    [config, theme],
  );
}

import React, {useState, useEffect, useContext} from 'react';
import {
  IonPage,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonIcon,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import Header from '../../components/navigation/Header';
import {Link} from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  NativeSelect,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import {trashOutline} from 'ionicons/icons';
import {UserContext} from '../../components/context/UserContext';
import useToast from '../../hooks/useToast';
import useAlert from '../../hooks/useAlert';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';


const Contacts = props => {
  const {t} = useTranslation('link_app');
  const {match, permissions} = props;
  const toast = useToast();
  const alert = useAlert();

  useTitleEffect(t('nav.contacts'));
  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Get current user from user context
  const [user] = useContext(UserContext);

  // Filters state
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    title: '',
    company: '',
    role: '',
  });

  // Handle filter change
  const handleFilterChange = (value, filter) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  // Fetching state
  const [isFetching, setIsFetching] = useState(true);

  // Fetch contacts
  const [contacts, setContacts] = useState([]);
  useEffect(() => {
    databases.contacts
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setContacts(docs);
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.contacts, match, props.isLoading, setIsAuth]);

  // Get areas, titles, companies from contacts

  const companies = [];
  const titles = [];
  const roles = [];
  contacts.forEach(item => {
    if (item.doc) {
      if (
        companies.indexOf(item.doc.company) === -1 &&
        item.doc.company !== ''
      ) {
        companies.push(item.doc.company);
      }
      if (titles.indexOf(item.doc.title) === -1 && item.doc.title !== '') {
        titles.push(item.doc.title);
      }
      item.doc.roles.forEach(role => {
        if (roles.indexOf(role) === -1) {
          roles.push(role);
        }
      });
    }
  });

  // Contact property filtering
  let filteredContacts = [...contacts];
  if (search !== '') {
    filteredContacts = filteredContacts.filter(item => {
      return (
        item.doc.name.toLowerCase().includes(search.toLowerCase()) ||
        item.doc.phone_number
          .toLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        item.doc.email.toLowerCase().includes(search.toLowerCase())
      );
    });
  }
  if (filters.title !== '') {
    filteredContacts = filteredContacts.filter(item => {
      return item.doc.title === filters.title;
    });
  }
  if (filters.company !== '') {
    filteredContacts = filteredContacts.filter(item => {
      return item.doc.company === filters.company;
    });
  }
  if (filters.role !== '') {
    filteredContacts = filteredContacts.filter(item => {
      return item.doc.roles.includes(filters.role);
    });
  }

  // Custom table head style
  const StyledTableHeadCell = withStyles(() => ({
    head: {
      backgroundColor: '#FAFAFA',
      padding: '0.5rem',
      border: 'none !important',
    },
  }))(TableCell);

  // Filters clearing button
  const clearButton = Object.values(filters).find(x => x !== '') ? (
    <IonButton
      color="primary"
      fill="clear"
      className="ion-float-right"
      onClick={() => {
        setFilters({
          title: '',
          company: '',
          role: '',
        });
      }}>
      {t('app.clear_filters')}
    </IonButton>
  ) : (
    ''
  );

  const deleteContact = selectedContact => {
    databases.contacts
      .get(selectedContact._id)
      .then(result => {
        result._deleted = true;
        return databases.contacts.put(result);
      })
      .then(result => {
        return databases.users
          .get(`org.couchdb.user:${selectedContact._id}`)
          .then(result => {
            result._deleted = true;
            return databases.users.put(result);
          })
          .then(result => {
            console.log(result);
            toast(
              ` "${selectedContact.name}" ${t('toast.removed_from_database')}`,
            );
          })
          .catch(err => {
            console.log(err);
            if (err.status === 404) {
              toast(
                ` "${selectedContact.name}" ${t(
                  'toast.removed_from_database',
                )}`,
              );
            }
          });
      })
      .catch(err => {
        console.log(
          `${t('toast.error_updating_contact')} "${selectedContact.name}":`,
          err,
        );
        toast(
          `${t('toast.error_updating_contact')} "${selectedContact.name}"`,
          err,
        );
      });
  };

  // RowLimit per page handlers
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = newPage => {
    if (newPage.currentTarget.ariaLabel === 'Next page') setPage(page + 1);
    else setPage(page - 1);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, contacts.length - page * rowsPerPage);

  return (
    <IonPage>
      <Header
        title={t('contacts.contact_list')}
        searchBar
        search={search}
        setSearch={setSearch}
        placeholder={t('contacts.search')}
        buttons={[
          {
            link: '/new-contact',
            permission: 'contacts',
            name: t('contacts.new_contact'),
          },
        ]}
        permissions={permissions}
      />
      <IonContent>
        {!isFetching ? (
          <IonGrid>
            <IonRow>
              <IonCol className="ion-padding-left ion-padding-right">
                {clearButton}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-padding-left ion-padding-right">
                <TableContainer>
                  <Table className="contacts-table">
                    <TableHead className="contacts-table-head">
                      <TableRow>
                        <StyledTableHeadCell></StyledTableHeadCell>
                        <StyledTableHeadCell>
                          {t('contacts.name')}
                        </StyledTableHeadCell>
                        <StyledTableHeadCell>
                          <FormControl className="">
                            <InputLabel htmlFor="role">
                              {t('contacts.role')}
                            </InputLabel>
                            <NativeSelect
                              value={filters.role}
                              onChange={e =>
                                handleFilterChange(e.target.value, 'role')
                              }
                              inputProps={{
                                name: 'role',
                                id: 'role',
                              }}
                              className={
                                filters.role === ''
                                  ? 'input-color-transparent'
                                  : ''
                              }>
                              <option aria-label="All" value="">
                                {t('app.all')}
                              </option>
                              {roles.map((item, i) => {
                                return (
                                  <option key={i} value={item}>
                                    {t(`roles.${item}`)}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </FormControl>
                        </StyledTableHeadCell>
                        <StyledTableHeadCell>
                          <FormControl className="">
                            <InputLabel htmlFor="title">
                              {t('contacts.title')}
                            </InputLabel>
                            <NativeSelect
                              value={filters.title}
                              onChange={e =>
                                handleFilterChange(e.target.value, 'title')
                              }
                              inputProps={{
                                name: 'title',
                                id: 'title',
                              }}
                              className={
                                filters.title === ''
                                  ? 'input-color-transparent'
                                  : ''
                              }>
                              <option aria-label="All" value="">
                                {t('app.all')}
                              </option>
                              {titles.map((item, i) => {
                                return (
                                  <option key={i} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </FormControl>
                        </StyledTableHeadCell>
                        <StyledTableHeadCell>
                          {t('contacts.phone')}
                        </StyledTableHeadCell>
                        <StyledTableHeadCell>
                          {t('contacts.email')}
                        </StyledTableHeadCell>
                        <StyledTableHeadCell>
                          <FormControl className="">
                            <InputLabel htmlFor="company">
                              {t('contacts.company')}
                            </InputLabel>
                            <NativeSelect
                              value={filters.company}
                              onChange={e =>
                                handleFilterChange(e.target.value, 'company')
                              }
                              inputProps={{
                                name: 'company',
                                id: 'company',
                              }}
                              className={
                                filters.company === ''
                                  ? 'input-color-transparent'
                                  : ''
                              }>
                              <option aria-label="All" value="">
                                {t('app.all')}
                              </option>
                              {companies.map((item, i) => {
                                return (
                                  <option key={i} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </FormControl>
                        </StyledTableHeadCell>
                        <StyledTableHeadCell></StyledTableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredContacts
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((item, i) => {
                          const name = item.doc.name?.split(' ');
                          const firstName = item.doc.name?.split(' ')[0];
                          const lastName =
                            item.doc.name?.split(' ')[name.length - 1];
                          const link =
                            item.doc._id !== user.name
                              ? `/contacts/${item.doc._id}`
                              : '/user';
                          return (
                            <TableRow key={i} component="tr" hover={true}>
                              <TableCell>
                                <Link
                                  to={link}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 0,
                                  }}>
                                  <div className="icon-wrap primary ion-margin-none">
                                    {firstName?.charAt(0)}
                                    {lastName?.charAt(0)}
                                  </div>
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={link}>
                                  {item.doc.name !== '' ? item.doc.name : '-'}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={link}>
                                  {item.doc.roles.length > 0
                                    ? item.doc.roles.map(role => {
                                        return t(`roles.${role}`);
                                      })
                                    : '-'}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={link}>
                                  {item.doc.title !== '' ? item.doc.title : '-'}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={link}>
                                  {item.doc.phone_number !== ''
                                    ? item.doc.phone_number
                                    : '-'}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={link}>
                                  {item.doc.email !== '' ? item.doc.email : '-'}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={link}>
                                  {item.doc.company !== ''
                                    ? item.doc.company
                                    : '-'}
                                </Link>
                              </TableCell>
                              <TableCell align="right">
                                <IonButton
                                  size="default"
                                  fill="clear"
                                  color="medium"
                                  disabled={!permissions?.canDelete}
                                  onClick={() =>
                                    alert(
                                      t('alert.notice'),
                                      `${t(
                                        'alert.are_you_sure_you_want_to_delete',
                                      )} ${t('alert.contact')} "${
                                        item.doc.name
                                      }"`,
                                      [
                                        t('app.go_back'),
                                        {
                                          text: 'Ok',
                                          handler: () =>
                                            deleteContact(item.doc),
                                        },
                                      ],
                                    )
                                  }>
                                  <IonIcon
                                    slot="icon-only"
                                    color="medium"
                                    style={{height: '22px'}}
                                    icon={trashOutline}
                                  />
                                </IonButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{height: 53 * emptyRows}}>
                          <TableCell colSpan={7} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    colSpan={7}
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={contacts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Contacts;

import {useIonAlert} from '@ionic/react';

const useAlert = () => {
  const [present] = useIonAlert();

  const toast = async (header, message, buttons) => {
    return present({
      header,
      message,
      buttons,
    });
  };

  return toast;
};

export default useAlert;

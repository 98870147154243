import React, {useState, useContext, useEffect} from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonToggle,
} from '@ionic/react';
import {lockClosedOutline, closeOutline} from 'ionicons/icons';
import {Paper, FormControl, TextField, NativeSelect} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {DatabaseContext} from '../context/DatabaseContext';
import useToast from '../../hooks/useToast';
import generator from 'generate-password';

const UserInfo = props => {
  const {t} = useTranslation('link_app');
  const match = props.match;
  const permissions = props.permissions;
  // const initialState = props.user
  const toast = useToast();

  // Databases
  const databases = useContext(DatabaseContext);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  // New PW state
  const [isPwActive, setIsPwActive] = useState(false);
  const [newPw, setNewPw] = useState('');
  useEffect(() => {
    setNewPw(
      generator.generate({
        length: 24,
        numbers: true,
        symbols: true,
        lowercase: true,
        uppercase: true,
      }),
    );
  }, [match, isPwActive]);

  // Dark mode change
  const handleAutoModeChange = () => {
    props.setUser({
      ...props.user,
      metadata: {
        ...props.user.metadata,
        auto_mode: !props.user.metadata.auto_mode,
      },
    });
    setIsEdited(true);
  };
  const handleModeChange = () => {
    props.setUser({
      ...props.user,
      metadata: {
        ...props.user.metadata,
        dark_mode: !props.user.metadata.dark_mode,
      },
    });
    setIsEdited(true);
  };

  // Save edited user
  const handleSave = () => {
    databases.contacts
      .get(props.user.name)
      .then(result => {
        const doc = {
          ...result,
          roles: props.user.roles,
          name: props.user.metadata.firstname_lastname,
          phone_number: props.user.metadata.phone_number,
          email: props.user.name,
          title: props.user.metadata.title,
          company: props.user.metadata.company,
          auto_mode: props.user.metadata.auto_mode,
          dark_mode: props.user.metadata.dark_mode,
        };
        return databases.contacts.put(doc);
      })
      .then(() => {
        console.log(t('toast.user_updated'));
        toast(t('toast.user_updated'));
        setIsEdited(false);

        if (isPwActive && newPw.length >= 12) {
          databases.users
            .get(`org.couchdb.user:${props.user.name}`)
            .then(result => {
              const editedUser = {...result, password: newPw};
              return databases.users.put(editedUser);
            })
            .then(result => {
              setIsPwActive(false);
              setTimeout(() => window.location.reload(), 1000);
            })
            .catch(err => {
              console.log(err);
              toast(t('toast.error_updating_user'), err);
            });
        } else if (isPwActive && newPw.length < 12) {
          toast(t('toast.password_too_short'));
        }
      })
      .catch(err => {
        console.log(t('toast.error_updating_user:', err));
        toast(t('toast.error_updating_user'), err);
      });
  };

  if (props.user.metadata) {
    return (
      <>
        <Paper style={{marginTop: '32px'}}>
          <IonGrid>
            <IonRow>
              <IonCol className="ion-margin-none">
                <div className="ion-avatar-container">
                  <div className="contact-avatar ion-margin-bottom">
                    <h1 className="ion-margin-none">
                      {props.user.metadata.firstname_lastname &&
                      props.user.metadata.firstname_lastname !== '' &&
                      props.user.metadata.firstname_lastname.split(' ')
                        .length >= 1 ? (
                        <>
                          {props.user.metadata.firstname_lastname.split(' ')
                            .length >= 1 &&
                            props.user.metadata.firstname_lastname
                              .split(' ')[0]
                              .charAt(0)}
                          {props.user.metadata.firstname_lastname.split(' ')
                            .length > 1 &&
                            props.user.metadata.firstname_lastname
                              .split(' ')[1]
                              .charAt(0)}
                        </>
                      ) : (
                        '?'
                      )}
                    </h1>
                  </div>
                  <IonText>
                    <h4 className="ion-margin-bottom-half">
                      {props.user.metadata.firstname_lastname !== ''
                        ? props.user.metadata.firstname_lastname
                        : t('contacts.name')}
                    </h4>
                  </IonText>
                  <IonText>
                    <p className="ion-margin-none">
                      {props.user.metadata.title !== ''
                        ? props.user.metadata.title
                        : t('contacts.title')}
                    </p>
                  </IonText>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonList>
            <IonItem lines="none" className="ion-margin-top">
              <IonText>
                <h4 className="ion-margin-none">{t('contacts.details')}</h4>
              </IonText>
            </IonItem>
            <IonItem lines="none">
              <div className="row">
                <FormControl className="row-input">
                  <div>
                    <label htmlFor="name">{t('contacts.name')}</label>
                  </div>
                  <TextField
                    id="name"
                    value={
                      props.user.metadata.firstname_lastname
                        ? props.user.metadata.firstname_lastname
                        : ''
                    }
                    onChange={e => {
                      setIsEdited(true);
                      props.setUser({
                        ...props.user,
                        metadata: {
                          ...props.user.metadata,
                          firstname_lastname: e.target.value,
                        },
                      });
                    }}
                    disabled={!permissions?.user}
                  />
                </FormControl>
              </div>
            </IonItem>

            <IonItem lines="none">
              <div className="row">
                <FormControl className="row-input">
                  <div>
                    <label htmlFor="title">{t('contacts.title')}</label>
                  </div>
                  <TextField
                    id="title"
                    placeholder={t('contacts.title')}
                    value={
                      props.user.metadata.title ? props.user.metadata.title : ''
                    }
                    onChange={e => {
                      setIsEdited(true);
                      props.setUser({
                        ...props.user,
                        metadata: {
                          ...props.user.metadata,
                          title: e.target.value,
                        },
                      });
                    }}
                    disabled={!permissions?.user}
                  />
                </FormControl>
              </div>
            </IonItem>

            <IonItem lines="none">
              <div className="row">
                <FormControl className="row-input">
                  <div>
                    <label htmlFor="company">{t('contacts.company')}</label>
                  </div>
                  <TextField
                    id="company"
                    placeholder={t('contacts.company')}
                    value={
                      props.user.metadata.company
                        ? props.user.metadata.company
                        : ''
                    }
                    onChange={e => {
                      setIsEdited(true);
                      props.setUser({
                        ...props.user,
                        metadata: {
                          ...props.user.metadata,
                          company: e.target.value,
                        },
                      });
                    }}
                    disabled={!permissions?.user}
                  />
                </FormControl>
              </div>
            </IonItem>

            <IonItem lines="none">
              <div className="row">
                <FormControl className="row-input">
                  <div>
                    <label htmlFor="phone">{t('contacts.phone')}</label>
                  </div>
                  <TextField
                    id="phone"
                    placeholder={t('contacts.phone')}
                    value={
                      props.user.metadata.phone_number
                        ? props.user.metadata.phone_number
                        : ''
                    }
                    onChange={e => {
                      setIsEdited(true);
                      props.setUser({
                        ...props.user,
                        metadata: {
                          ...props.user.metadata,
                          phone_number: e.target.value,
                        },
                      });
                    }}
                    disabled={!permissions?.user}
                  />
                </FormControl>
              </div>
            </IonItem>

            <IonItem lines="none">
              <div className="row">
                <FormControl className="row-input">
                  <div>
                    <label htmlFor="email">{t('contacts.email')}</label>
                  </div>
                  <TextField
                    id="email"
                    placeholder={t('contacts.email')}
                    value={props.user.name ? props.user.name : ''}
                    onChange={e => {
                      setIsEdited(true);
                      props.setUser({...props.user, name: e.target.value});
                    }}
                    disabled={!permissions?.user}
                  />
                </FormControl>
              </div>
            </IonItem>

            <IonItem lines="none">
              <div className="row">
                <FormControl className="row-input">
                  <div>
                    <label htmlFor="role">{t('contacts.role')}</label>
                  </div>
                  <NativeSelect
                    id="role"
                    value={props.user?.roles?.toString()}
                    onChange={e => {
                      setIsEdited(true);
                      props.setUser({...props.user, name: e.target.value});
                    }}
                    inputProps={{
                      name: 'role',
                      id: 'role',
                    }}
                    disabled={true}>
                    <option value={props.user?.roles?.toString()}>
                      {t(`roles.${props.user?.roles}`)}
                    </option>
                  </NativeSelect>
                </FormControl>
              </div>
            </IonItem>

            <IonItem lines="none" className="ion-margin-top">
              <IonText>
                <h4 className="ion-margin-none">
                  {t('contacts.login_details')}
                </h4>
              </IonText>
            </IonItem>
            {!isPwActive && (
              <IonItem
                button
                lines="none"
                className="ion-padding-top-half ion-padding-bottom-half"
                disabled={!permissions?.user}
                onClick={() => {
                  setIsPwActive(true);
                  setIsEdited(true);
                }}>
                <IonIcon
                  icon={lockClosedOutline}
                  color="primary"
                  slot="start"
                  className="ion-margin-right"
                />
                <IonLabel>{t('contacts.change_password')}</IonLabel>
              </IonItem>
            )}
            {isPwActive && (
              <IonItem lines="none" className="ion-margin-bottom-half">
                <div className="row">
                  <FormControl className="row-input">
                    <div>
                      <label htmlFor="new-pw">
                        {t('contacts.new_password')}
                      </label>
                    </div>
                    <input
                      id="new-pw"
                      className="ticket-step-input"
                      type="text"
                      value={newPw}
                      onChange={e => {
                        setNewPw(e.target.value);
                        setIsEdited(true);
                      }}
                      disabled={!permissions?.user}
                    />
                    {newPw.length < 12 && (
                      <IonText className="place-item-errortext" color="danger">
                        {t('toast.password_too_short')}
                      </IonText>
                    )}
                  </FormControl>
                </div>
                <IonButton
                  fill="clear"
                  className="ion-margin-none ion-margin-left-half"
                  onClick={() => setIsPwActive(false)}>
                  <IonIcon
                    slot="icon-only"
                    color="medium"
                    icon={closeOutline}
                  />
                </IonButton>
              </IonItem>
            )}
            <IonItem lines="none" className="ion-margin-top">
              <IonText>
                <h4 className="ion-margin-none">{t('user.app_appearance')}</h4>
              </IonText>
            </IonItem>
            <IonItem
              lines="none"
              className="ion-padding-top-half ion-padding-bottom-half">
              <IonToggle
                checked={props.user.metadata.auto_mode}
                disabled={
                  permissions?.user === true
                    ? props.user.metadata.dark_mode
                    : true
                }
                onIonChange={() => handleAutoModeChange()}
              />
              <IonLabel>{t('user.automatic')}</IonLabel>
            </IonItem>
            <IonItem
              lines="none"
              className="ion-padding-top-half ion-padding-bottom-half">
              <IonToggle
                checked={props.user.metadata.dark_mode}
                disabled={
                  permissions?.user === true
                    ? props.user.metadata.auto_mode
                    : true
                }
                onIonChange={() => handleModeChange()}
              />
              <IonLabel>{t('user.dark_mode')}</IonLabel>
            </IonItem>
          </IonList>
        </Paper>
        <div>
          {/* <IonButton
						style={{ marginTop: "16px", marginBottom: "32px" }}
						onClick={() => {
							props.setUser(initialState)
						}}
						className="ion-margin-right"
						fill="outline"
						color="danger"
					>
						{t("app.abort")}
					</IonButton> */}
          <IonButton
            style={{marginTop: '16px', marginBottom: '32px'}}
            disabled={!isEdited}
            onClick={() => handleSave()}
            className="ion-margin-right"
            fill="solid"
            color="success">
            {t('app.save')}
          </IonButton>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default UserInfo;

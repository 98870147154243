import React, {useState, useEffect, useContext} from 'react';
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonImg,
  IonButton,
  IonIcon,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {downloadOutline, chevronBack, chevronForward} from 'ionicons/icons';

import {Document, Page, pdfjs} from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';

import Header from '../../components/navigation/Header';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {Paper} from '@material-ui/core';
import _ from 'lodash';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';


pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const AccessoryAttachment = props => {
  const {t} = useTranslation('link_app');
  const {match} = props;

  useTitleEffect(match.params.attachmentId);

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Fetch the ticket
  const [doc, setDoc] = useState({});
  useEffect(() => {
    databases.accessories
      .get(match.params.id, {attachments: true})
      .then(result => {
        setDoc(result);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databases.tickets, match, props.isLoading, setIsAuth]);

  // Get attachment

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(nextNumPages) {
    setNumPages(nextNumPages._pdfInfo.numPages);
  }

  let attachment;
  if (!_.isEmpty(doc._attachments)) {
    attachment = doc._attachments[match.params.attachmentId];
  }

  return (
    <IonPage>
      <Header title={match.params.attachmentId} backButton />
      <IonContent>
        {attachment ? (
          <div className="container-wide">
            {attachment.content_type === 'application/pdf' ? (
              // <Paper
              //   style={{marginTop: '32px', marginBottom: '16px'}}
              //   className="ion-padding">
              <>
                <div className="pdf-document">
                  <a
                    href={`data:${attachment.content_type};base64,${attachment.data}`}
                    download={match.params.attachmentId}>
                    <IonButton size="default">
                      <IonIcon slot="start" icon={downloadOutline} />
                      {t('app.download')}
                    </IonButton>
                  </a>
                  <Document
                    onLoadSuccess={onDocumentLoadSuccess}
                    file={`data:application/pdf;base64,${attachment.data}`}
                    onLoadError={console.error}>
                    <Page key={`page_${pageNumber}`} pageNumber={pageNumber} />
                  </Document>
                  <div className="page-controls">
                    <IonButton
                      size="medium"
                      onClick={() => setPageNumber(pageNumber - 1)}
                      disabled={pageNumber <= 1}>
                      <IonIcon
                        color={pageNumber <= 1 ? 'medium' : 'white'}
                        icon={chevronBack}
                      />
                    </IonButton>
                    <span>
                      {pageNumber} of {numPages}
                    </span>
                    <IonButton
                      size="medium"
                      onClick={() => setPageNumber(pageNumber + 1)}
                      disabled={pageNumber >= numPages}>
                      <IonIcon
                        color={pageNumber >= numPages ? 'medium' : 'white'}
                        icon={chevronForward}
                      />
                    </IonButton>
                  </div>
                </div>
              </>
            ) : (
              // </Paper>
              <>
                <Paper style={{marginTop: '32px', marginBottom: '16px'}}>
                  <IonList>
                    <IonItem lines="none">
                      <div className="inner-wrap">
                        <IonImg
                          src={`data:${attachment.content_type};base64,${attachment.data}`}
                          alt=""
                        />
                      </div>
                    </IonItem>
                  </IonList>
                </Paper>
                <a
                  href={`data:${attachment.content_type};base64,${attachment.data}`}
                  download={match.params.attachmentId}>
                  <IonButton size="default">
                    <IonIcon slot="start" icon={downloadOutline} />
                    {t('app.download')}
                  </IonButton>
                </a>
              </>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AccessoryAttachment;

import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonSearchbar,
  IonButton,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonText,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {addCircleOutline} from 'ionicons/icons';
import {Link} from 'react-router-dom';

const Header = ({
  backButton,
  place,
  search,
  placeholder,
  searchBar,
  setSearch,
  setSegment,
  title,
  badge,
  segment,
  segments,
  permissions,
  buttons,
  ticket,
}) => {
  const {t} = useTranslation('link_app');

  return (
    <IonHeader>
      <IonToolbar>
        {backButton && (
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
        )}
        <div className="title-container" slot="start">
          <h3>{title}</h3> <span>{badge}</span>
          {ticket && (
            // <IonButton className="ion-margin-none ion-margin-left-half" fill="clear" color="primary" routerLink={`/places/${place?.doc?.type}/${place?.doc?._id}`}>
            // 	<IonIcon color="primary" icon={locationOutline} slot="start" />
            // 	{t("tickets.show_site")}
            // </IonButton>
            <IonText className="ion-margin-left">
              {t('tickets.error')}: {ticket?.error?.name ?? '-'}
            </IonText>
          )}
          {place && (
            // <IonButton className="ion-margin-none ion-margin-left-half" fill="clear" color="primary" routerLink={`/places/${place?.doc?.type}/${place?.doc?._id}`}>
            // 	<IonIcon color="primary" icon={locationOutline} slot="start" />
            // 	{t("tickets.show_site")}
            // </IonButton>
            <Link to={`/places/${place?.doc?._id}`}>
              <IonText className="ion-margin-left">{place?.doc?.name}</IonText>
            </Link>
          )}
        </div>
        {segments?.length > 0 && (
          <div slot="start" className="header-segment">
            <IonSegment
              className=""
              onIonChange={e => setSegment(e.detail.value)}
              value={segment}>
              {segments.map((item, i) => {
                return (
                  <IonSegmentButton key={i} value={item.value}>
                    <IonLabel color="secondary">{item.name}</IonLabel>
                  </IonSegmentButton>
                );
              })}
            </IonSegment>
          </div>
        )}
        {buttons?.length > 0 && permissions && (
          <div slot="secondary" className="header-buttons">
            {buttons.map((button, i) => {
              return (
                <IonButton
                  key={i}
                  color="primary"
                  fill="clear"
                  routerLink={button.link}
                  disabled={!permissions[button.permission]}>
                  <IonIcon slot="start" icon={addCircleOutline} />
                  {button.name}
                </IonButton>
              );
            })}
          </div>
        )}
        {searchBar && (
          <IonSearchbar
            slot="secondary"
            autocomplete="off"
            autocorrect="off"
            placeholder={placeholder}
            className="list-search"
            value={search}
            onIonChange={e => setSearch(e.detail.value)}
          />
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;

const placeTypesData = [
  {
    id: 'site',
    name: 'places.mast',
  },
  {
    id: 'warehouse',
    name: 'places.warehouse',
  },
  {
    id: 'studio',
    name: 'places.studio',
  },
];

export default placeTypesData;

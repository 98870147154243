import React, {useState, useEffect, useContext} from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonThumbnail,
  IonImg,
  IonText,
  IonReorderGroup,
  IonReorder,
  IonSegmentButton,
  IonSegment,
} from '@ionic/react';
import {
  trashOutline,
  addOutline,
  imageOutline,
  reorderTwoOutline,
} from 'ionicons/icons';
import {Paper, FormControl, Slide} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useToast from '../../hooks/useToast';
import {DatabaseContext} from '../context/DatabaseContext';
import Compressor from 'compressorjs';

const ManualInfo = props => {
  // Misc vars
  const match = props.match;
  const permissions = props.permissions;

  const {t} = useTranslation('link_app');
  const toast = useToast();
  const [segment, setSegment] = useState('edit');

  // Databases
  const databases = useContext(DatabaseContext);

  // Copy accessory
  const [accessory, setAccessory] = useState(props.doc);
  useEffect(() => {
    setAccessory(props.doc);
  }, [match, props.doc]);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  // Save edited or new manual
  const handleSave = () => {
    // Save accessory with updated manuals
    databases.accessories
      .get(accessory._id)
      .then(() => {
        return databases.accessories.put(accessory);
      })
      .then(() => {
        console.log(
          `${t('toast.manual')} "${
            accessory.manuals[props.manualIndex].title
          }" ${t('toast.updated')}`,
        );
        toast(
          `${t('toast.manual')} "${
            accessory.manuals[props.manualIndex].title
          }" ${t('toast.updated')}`,
        );
        setIsEdited(false);
        return databases.accessories.get(accessory._id, {attachments: true});
      })
      .then(doc => {
        // Update edited accessory state to match new _rev
        if (match.path !== '/accessories/:id/new-manual') {
          setAccessory(doc);
          props.setDoc(doc);
        } else {
          props.setDoc({});
          props.setManualIndex(null);
          props.history.push('/accessories');
        }
      })
      .catch(err => {
        console.log(
          `${t('toast.error_updating_manual')} "${
            accessory.manuals[props.manualIndex].title
          }":`,
          err,
        );
        toast(
          `${t('toast.error_updating_manual')} "${
            accessory.manuals[props.manualIndex].title
          }"`,
          err,
        );
      });
  };

  // Add images to state to be uploaded later
  const handleFileAdd = (e, phaseId) => {
    const img = e.target.files[0];
    // Compress image before upload
    new Compressor(img, {
      quality: 0.6, // 0.6 can also be used, but it is not recommended to go below
      success: result => {
        // result has the compressed file
        const phases = [...accessory.manuals[props.manualIndex].phases];
        const phaseIndex = phases.findIndex(phase => phase.id === phaseId);
        const keyToDelete = phases[phaseIndex].attachment_id;
        phases[phaseIndex] = {
          ...phases[phaseIndex],
          attachment_id: result.name,
        };
        const attachments = {
          ...accessory._attachments,
        };
        delete attachments[keyToDelete];
        const newAttachments = {
          ...attachments,
          [result.name]: {
            content_type: result.type,
            data: result,
          },
        };
        const manuals = [...accessory.manuals];
        manuals[props.manualIndex] = {
          ...accessory.manuals[props.manualIndex],
          phases: phases,
        };
        setAccessory({
          ...accessory,
          _attachments: newAttachments,
          manuals: manuals,
        });
        setIsEdited(true);
      },
    });
  };

  // Add blank phase to state
  const addPhase = () => {
    const phases = [...accessory.manuals[props.manualIndex].phases];
    let newId =
      phases.length > 0
        ? (
            parseInt(
              Math.max.apply(
                Math,
                phases.map(x => x.id),
              ),
            ) + 1
          ).toString()
        : '1';
    phases.push({id: newId, text: '', attachment_id: ''});
    const manuals = [...accessory.manuals];
    manuals[props.manualIndex] = {
      ...accessory.manuals[props.manualIndex],
      phases: phases,
    };
    setAccessory({
      ...accessory,
      manuals: manuals,
    });
    setIsEdited(true);
  };

  // Remove phase from state
  const removePhase = phaseId => {
    const phases = [...accessory.manuals[props.manualIndex].phases];
    const phaseIndex = phases.findIndex(phase => phase.id === phaseId);
    const attachmentId = phases[phaseIndex].attachment_id;
    const attachments = {
      ...accessory._attachments,
    };
    if (attachmentId !== '') {
      delete attachments[attachmentId];
    }
    phases.splice(
      phases.findIndex(phase => phase.id === phaseId),
      1,
    );
    const manuals = [...accessory.manuals];
    manuals[props.manualIndex] = {
      ...accessory.manuals[props.manualIndex],
      phases: phases,
    };
    setAccessory({
      ...accessory,
      _attachments: attachments,
      manuals: manuals,
    });
    setIsEdited(true);
  };

  // Handle manual phase reordering
  const handleReorder = e => {
    const phases = [...accessory.manuals[props.manualIndex].phases];
    const tmp = phases[e.detail.from];
    phases[e.detail.from] = phases[e.detail.to];
    phases[e.detail.to] = tmp;
    e.detail.complete();
    const manuals = [...accessory.manuals];
    manuals[props.manualIndex].phases = phases;
    setAccessory({
      ...accessory,
      manuals: manuals,
    });
    setIsEdited(true);
  };

  return (
    <>
      <Paper style={{marginTop: '32px'}}>
        <IonGrid className="accessory-info ion-padding-none ion-padding-top ion-padding-right ion-padding-left">
          <IonRow>
            <IonCol className="ion-margin-none">
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div>
                    <label htmlFor="title">{t('accessories.name')}</label>
                  </div>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    autoComplete="off"
                    value={accessory.manuals[props.manualIndex].title}
                    onChange={e => {
                      const manuals = [...accessory.manuals];
                      manuals[props.manualIndex].title = e.target.value;
                      setAccessory({
                        ...accessory,
                        manuals: manuals,
                      });
                      setIsEdited(true);
                    }}
                    disabled={!permissions?.guides}
                  />
                </FormControl>
              </div>
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div>
                    <label htmlFor="description">
                      {t('accessories.description')}
                    </label>
                  </div>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    value={accessory.manuals[props.manualIndex].description}
                    autoComplete="off"
                    onChange={e => {
                      const manuals = [...accessory.manuals];
                      manuals[props.manualIndex].description = e.target.value;
                      setAccessory({
                        ...accessory,
                        manuals: manuals,
                      });
                      setIsEdited(true);
                    }}
                    disabled={!permissions?.guides}
                  />
                </FormControl>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className="accessory-info ion-padding-none ion-padding-right ion-padding-left">
          <IonSegment
            scrollable
            onIonChange={e => setSegment(e.detail.value)}
            value={segment}>
            <IonSegmentButton value="edit">
              <IonLabel>{t('accessories.edit')}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="preview">
              <IonLabel>{t('accessories.preview')}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonGrid>
        <div
          className={
            segment === 'edit' ? 'manual-phases' : 'manual-phases hidden'
          }>
          <IonList>
            <div className="ticket-step-input ion-margin-left ion-margin-top">
              <label>{t('accessories.phases')}</label>
            </div>
            <IonReorderGroup
              disabled={!permissions?.guides}
              onIonItemReorder={e => handleReorder(e)}>
              {accessory.manuals[props.manualIndex].phases.map((phase, i) => {
                let attachment;
                if (accessory._attachments && phase.attachment_id !== '') {
                  attachment = accessory._attachments[phase.attachment_id];
                }
                return (
                  <Slide direction="up" in={true} key={phase.id}>
                    <IonItem lines="full">
                      <IonText
                        className="ion-margin-right ion-weight-500"
                        slot="start">
                        {i + 1}.
                      </IonText>
                      <IonThumbnail
                        className="file-upload-thumbnail"
                        slot="start"
                        onClick={() =>
                          document.getElementById(`file-${phase.id}`).click()
                        }>
                        {attachment && attachment.digest && (
                          <IonImg
                            src={`data:${attachment.content_type};base64,${attachment.data}`}
                            alt=""
                          />
                        )}
                        {attachment && !attachment.digest && (
                          <IonImg
                            src={window.URL.createObjectURL(attachment.data)}
                            alt=""
                          />
                        )}
                        <IonIcon color="medium" icon={imageOutline} />
                        <input
                          id={`file-${phase.id}`}
                          type="file"
                          accept="image/*"
                          value=""
                          hidden
                          onChange={e => {
                            handleFileAdd(e, phase.id);
                          }}
                          disabled={!permissions?.guides}
                        />
                      </IonThumbnail>
                      <IonLabel className="ion-padding-top-half ion-padding-bottom-half">
                        <textarea
                          value={phase.text}
                          onChange={e => {
                            const phases = [
                              ...accessory.manuals[props.manualIndex].phases,
                            ];
                            const phaseIndex = phases.findIndex(
                              x => x.id === phase.id,
                            );
                            phases[phaseIndex].text = e.target.value;
                            const manuals = [...accessory.manuals];
                            manuals[props.manualIndex] = {
                              ...accessory.manuals[props.manualIndex],
                              phases: phases,
                            };
                            setAccessory({
                              ...accessory,
                              manuals: manuals,
                            });
                            setIsEdited(true);
                          }}
                          disabled={!permissions?.guides}
                        />
                      </IonLabel>
                      <IonButton
                        className="ion-margin-left-half"
                        fill="clear"
                        slot="end"
                        onClick={() => removePhase(phase.id)}
                        disabled={!permissions?.guides}>
                        <IonIcon
                          slot="icon-only"
                          color="medium"
                          icon={trashOutline}
                        />
                      </IonButton>
                      <IonReorder slot="end" className="ion-margin-left-half">
                        <IonIcon
                          color="medium"
                          icon={reorderTwoOutline}
                          style={{fontSize: '24px'}}
                        />
                      </IonReorder>
                    </IonItem>
                  </Slide>
                );
              })}
            </IonReorderGroup>
          </IonList>
          <IonButton
            onClick={() => addPhase()}
            fill="clear"
            color="primary"
            disabled={!permissions?.guides}>
            <IonIcon slot="start" color="primary" icon={addOutline} />
            {t('accessories.add_phase')}
          </IonButton>
        </div>
        <div
          className={
            segment === 'preview' ? 'manual-phases' : 'manual-phases hidden'
          }>
          <ol>
            {accessory.manuals[props.manualIndex].phases.map((phase, i) => {
              let attachment;
              if (accessory._attachments && phase.attachment_id !== '') {
                attachment = accessory._attachments[phase.attachment_id];
              }
              const text = phase.text?.replace(/\bhttps?:\/\/\S+/gi, '');
              const videoUrls = phase.text?.match(/\bhttps?:\/\/\S+/gi);
              return (
                <li key={i}>
                  {text && (
                    <IonText>
                      <p>{text}</p>
                    </IonText>
                  )}
                  {videoUrls?.map((url, i) => {
                    return (
                      <div
                        className="responsive-iframe-container ion-margin-bottom"
                        key={i}>
                        <iframe
                          title={`video-${i}`}
                          className="responsive-iframe"
                          src={url}
                        />
                      </div>
                    );
                  })}
                  {attachment && (
                    <IonImg
                      className="ion-margin-bottom"
                      src={`data:${attachment.content_type};base64,${attachment.data}`}
                      alt=""
                    />
                  )}
                </li>
              );
            })}
          </ol>
        </div>
      </Paper>
      <div>
        {/* <IonButton
					style={{ marginTop: "16px", marginBottom: "32px" }}
					onClick={() => {
						props.history.goBack()
					}}
					className="ion-margin-right"
					fill="outline"
					color="danger"
				>
					{t("app.abort")}
				</IonButton> */}
        <IonButton
          style={{marginTop: '16px', marginBottom: '32px'}}
          disabled={!isEdited}
          onClick={() => handleSave()}
          className="ion-margin-right"
          fill="solid"
          color="success">
          {t('app.save')}
        </IonButton>
      </div>
    </>
  );
};

export default ManualInfo;

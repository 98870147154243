import React, {useState, useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import ManualInfo from '../../components/accessories/ManualInfo';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';


const Manual = props => {
  // Misc vars
  const {match, permissions} = props;

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Fetch the accessory
  const [doc, setDoc] = useState({});
  useEffect(() => {
    databases.accessories
      .get(match.params.id, {attachments: true})
      .then(result => {
        setDoc(result);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.accessories, match, props.isLoading, setIsAuth]);

  // Get manual index
  const [manualIndex, setManualIndex] = useState(null);
  useEffect(() => {
    if (doc._id && doc.manuals && doc.manuals.length > 0 && !manualIndex) {
      setManualIndex(
        doc.manuals.findIndex(manual => manual.id === match.params.manualId),
      );
    }
  }, [doc, manualIndex, match]);

  useTitleEffect(manualIndex !== null ? doc.manuals[manualIndex].title : '');

  return (
    <IonPage>
      <Header
        title={manualIndex !== null ? doc.manuals[manualIndex].title : ''}
        backButton
      />
      <IonContent>
        {doc._id && manualIndex !== null && manualIndex > -1 ? (
          <div className="container-narrow">
            <ManualInfo
              doc={doc}
              setDoc={setDoc}
              manualIndex={manualIndex}
              match={match}
              history={props.history}
              permissions={permissions}
            />
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Manual;

import React from 'react';
import {FormControl, NativeSelect, Slide} from '@material-ui/core';
import MissingValueAlert from '../utils/MissingValueAlert';
import {useTranslation} from 'react-i18next';
import {IonText, IonItem, IonButton} from '@ionic/react';

const Diagnostics = props => {
  // Misc vars
  const {t} = useTranslation('link_app');
  const match = props.match;
  const permissions = props.permissions;

  let flows = [];
  if (
    props.guides.length > 0 &&
    props.guides.findIndex(guide => guide.doc._id === props.ticket.error.id) >
      -1
  ) {
    flows =
      props.guides[
        props.guides.findIndex(guide => guide.doc._id === props.ticket.error.id)
      ].doc.flows;
  }

  // useEffect(() => {
  // 	if (props.ticket && (props.ticket.ticket_status !== "analysis_finished")) {
  // 		GetNewTicketStatus(props.ticket.ticket_status)
  // 			.then(new_status => {
  // 				console.log("updating with", new_status)
  // 				props.handleTicketStatusUpdate(props.ticket, new_status)
  // 			})
  // 		}
  //   }, []);

  const getQuestion = () => {
    let question;
    if (props.ticket.flow.questions) {
      question = props.ticket.flow.questions[props.ticket.flow_step];
    }
    if (question) {
      return (
        <>
          <Slide direction="right" in={true} key={question.id}>
            <div className="question-wrap ion-border ion-border-radius">
              <IonItem
                color={question.answers.length === 0 ? 'success' : ''}
                lines="none"
                style={{position: 'relative'}}>
                {question.answers.length > 0 && (
                  <IonText
                    className="ion-margin-right flow-number ion-weight-500"
                    slot="start">
                    {parseInt(props.ticket.flow_step) + 1}.
                  </IonText>
                )}
                <div
                  className={
                    question.answers.length > 0
                      ? 'ion-padding-top ion-padding-left ion-padding-bottom ion-margin-left'
                      : 'ion-padding-top ion-padding-bottom'
                  }>
                  <div>
                    <IonText className="ion-margin-bottom">
                      <p className="ion-margin-bottom-half">
                        {question.question}
                      </p>
                    </IonText>
                    <div>
                      {question.answers.map((answer, i) => {
                        return (
                          <IonButton
                            disabled={
                              props.ticket.is_active === true
                                ? !permissions?.tickets
                                : true
                            }
                            key={i}
                            onClick={() => {
                              props.setIsEdited(true);
                              props.handleSave({
                                ...props.ticket,
                                flow_step: (
                                  parseInt(answer.leads_to) - 1
                                ).toString(),
                              });
                            }}
                            className="ion-margin-right">
                            {answer.text}
                          </IonButton>
                        );
                      })}
                      {props.ticket.flow_step !== '0' && (
                        <IonButton
                          disabled={
                            props.ticket.is_active === true
                              ? !permissions?.tickets
                              : true
                          }
                          onClick={() => {
                            props.setIsEdited(true);
                            props.handleSave({...props.ticket, flow_step: '0'});
                          }}
                          fill="clear"
                          style={{marginLeft: '-8px'}}
                          color={
                            question.answers.length === 0 ? 'light' : 'primary'
                          }>
                          {t('tickets.back_to_start')}
                        </IonButton>
                      )}
                    </div>
                  </div>
                </div>
              </IonItem>
            </div>
          </Slide>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="ticket-step-row">
        {props.ticket.flow.questions ? (
          <FormControl className="ticket-step-input">
            <div>
              <label htmlFor="flow">{t('tickets.select_flow')}</label>
              {match.url !== '/new-ticket' &&
                props.guides.findIndex(
                  guide => guide.doc._id === props.ticket.error.id,
                ) === -1 && <MissingValueAlert />}{' '}
              {/* Alert user if initial value is deleted from database */}
            </div>
            <NativeSelect
              disabled={
                props.ticket.is_active === true ? !permissions?.tickets : true
              }
              value={JSON.stringify(props.ticket.flow)}
              onChange={e => {
                props.setIsEdited(true);
                props.setTicket({
                  ...props.ticket,
                  flow: JSON.parse(e.target.value),
                });
              }}
              inputProps={{
                name: 'flow',
                id: 'flow',
              }}>
              <option value={JSON.stringify(props.ticket.flow)}>
                {props.ticket.flow.title}
              </option>
              {flows
                .filter(flow => {
                  return props.ticket.flow.id !== flow.id;
                })
                .map((flow, i) => {
                  return (
                    <option key={i} value={JSON.stringify(flow)}>
                      {flow.title}
                    </option>
                  );
                })}
            </NativeSelect>
          </FormControl>
        ) : (
          <IonText>{t('tickets.select_error_type')}</IonText>
        )}
      </div>
      <div className="ion-margin-bottom diagnostics-wrap">
        <IonText>
          <p>{props.ticket.flow.description}</p>
        </IonText>
        {props.ticket.flow.questions && <>{getQuestion()}</>}
      </div>
    </>
  );
};

export default Diagnostics;

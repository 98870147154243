import React, {useEffect, useState, useContext} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {DatabaseContext} from '../context/DatabaseContext';
import useConfig from '../../hooks/useConfig';
import {AuthContext} from '../context/AuthContext';

const statusMapping = {
  loading: {color: 'yellow', text: 'Tarkistetaan yhteyksiä ...'},
  ok: {color: 'green', text: 'Yhteys ok.'},
  error: {
    color: 'red',
    text: 'Palvelussa katkos. Yhteys palautuu automaattisesti.',
  },
};

const SystemStatus = props => {
  const [status, setStatus] = useState('loading');
  const databases = useContext(DatabaseContext);
  const [, setIsAuth] = useContext(AuthContext);

  const {db} = useConfig();

  const ensureUtc = isoString => {
    return isoString.endsWith('Z') ? isoString : `${isoString}Z`;
  };

  const getStatusFromTime = (connect_time, last_error_time) => {
    const currentTime = new Date();
    const utcCurrentTime = currentTime.toISOString();
    const timeLimit = 61 * 60 * 1000; // 61 minutes in milliseconds

    connect_time = ensureUtc(connect_time);

    const time1Difference =
      new Date(utcCurrentTime).getTime() - new Date(connect_time).getTime();
    const recentError = connect_time < last_error_time;

    if (time1Difference > timeLimit || recentError) {
      //   console.log('error', time1Difference, timeLimit, recentError);
      return 'error';
    }
    return 'ok';
  };

  // Fetch health ticket
  const [healthTicket, setHealthTicket] = useState(undefined);
  useEffect(() => {
    databases.tickets
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        let docs = result.rows.filter(row => {
          return row.id.includes('health');
        });
        if (docs && docs.length > 0) {
          const tempHealth = docs[0].doc;
          if (
            tempHealth?.couchdb_connect_time !== '' &&
            tempHealth?.steve_connect_time !== ''
          ) {
            setHealthTicket(tempHealth);
          }
        }
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.tickets, props.isLoading, setIsAuth]);

  useEffect(() => {
    if (healthTicket) {
      const currentStatus = getStatusFromTime(
        healthTicket.steve_connect_time,
        healthTicket.steve_last_error_time,
      );
      setStatus(currentStatus);
    } else {
      setStatus('loading');
    }
  }, [healthTicket]);

  return (
    <div className="status-wrapper">
      <div style={{display: 'flex', alignItems: 'center'}}>
        <span
          style={{
            marginRight: '8px',
            textTransform: 'none',
            fontSize: '14px',
          }}>
          Yhteyden tila:
        </span>
        <Tooltip
          title={
            <p
              style={{
                fontSize: '18px',
                lineHeight: '15px',
                padding: '14px 8px 0px 8px',
                margin: '0 !important',
              }}>
              {statusMapping[status].text}
            </p>
          }
          leaveDelay={200}
          arrow>
          <span
            className="status-circle"
            style={{backgroundColor: statusMapping[status].color}}></span>
        </Tooltip>
      </div>
    </div>
  );
};

export default SystemStatus;

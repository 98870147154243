import React, {useEffect, useState, useContext} from 'react';
import {
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonSpinner,
  IonIcon,
  IonButton,
} from '@ionic/react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import {searchOutline} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import {jsPDF} from 'jspdf';
import 'jspdf-autotable';

const AlertReports = props => {
  const {t} = useTranslation('link_app_ikea');
  const {match, permissions} = props;

  // API call
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [tickets, setTickets] = useState([]);
  const [segment, setSegment] = useState();

  const [isDateRangeChanged, setIsDateRangeChanged] = useState(false);

  // Copy place to local state
  const [place, setPlace] = useState();
  useEffect(() => {
    if (!_.isEmpty(props.place)) {
      setPlace(props.place);
    }
  }, [match, props.place]);

  useEffect(() => {
    if (!_.isEmpty(props.tickets)) {
      setTickets(props.tickets);
    }
  }, [props.tickets]);

  useEffect(() => {
    setSegment(props.segment);
  }, [props.segment]);

  const [chargingStartTime, setChargingStartTimeChange] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toISOString().substring(0, 10);
  });
  const [chargingEndTime, setChargingEndTimeChange] = useState(() => {
    const date = new Date();
    return date.toISOString().substring(0, 10);
  });

  const handleChargingStartTimeChange = event => {
    setChargingStartTimeChange(event.target.value);
    setIsDateRangeChanged(true);
  };
  const handleChargingEndTimeChange = event => {
    setChargingEndTimeChange(event.target.value);
    setIsDateRangeChanged(true);
  };

  // Custom table head style
  const StyledTableHeadCell = withStyles(() => ({
    head: {
      backgroundColor: '#FAFAFA',
      padding: '0.5rem',
      border: 'none !important',
    },
  }))(TableCell);

  const [filteredTickets, setFilteredTickets] = useState();
  useEffect(() => {
    let tempFilteredTickets = tickets.filter(
      ticket => ticket?.doc?.place?.site_id === place?.doc?.site_id,
    );
    tempFilteredTickets = tempFilteredTickets.filter(
      ticket =>
        ticket?.doc?.is_active === false &&
        (ticket?.doc?.ticket_status === 'closed' ||
          ticket?.doc?.time_when_completed !== ''),
    );
    tempFilteredTickets = tempFilteredTickets.filter(ticket => {
      const alertname = ticket.doc?.errors
        ? ticket.doc?.errors[0].ALERTNAME
        : '';
      if (alertname && alertname !== '') {
        if (alertname.match('Kempower') && segment === 'lm_delivery') {
          return ticket;
        } else if (alertname.match('Enersense') && segment === 'customer') {
          return ticket;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    setFilteredTickets(tempFilteredTickets);
  }, [tickets, place, segment]);

  // RowLimit per page handlers
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = newPage => {
    if (newPage.currentTarget.ariaLabel === 'Next page') setPage(page + 1);
    else setPage(page - 1);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tickets.length - page * rowsPerPage);

  const HoursMinutesAndSeconds = milliseconds => {
    let totalSeconds = Math.floor(milliseconds / 1000);
    let totalMinutes = Math.floor(totalSeconds / 60);
    let totalHours = Math.floor(totalMinutes / 60);

    let seconds = totalSeconds % 60;
    let minutes = totalMinutes % 60;
    let hours = totalHours;

    // Add leading zeroes if necessary
    seconds = seconds < 10 ? '0' + seconds : seconds;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    // Only show hours if necessary
    if (hours > 0) {
      hours = hours < 10 ? '0' + hours : hours;
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return `${minutes}:${seconds}`;
    }
  };

  const GetNiceDateForTransaction = dateTime => {
    const date = new Date(dateTime);
    const dateStr = date.toLocaleDateString('fi-FI', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const timeStr = date.toLocaleTimeString('fi-FI', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    const dateTimeStr = `${dateStr}, ${timeStr.replace(/\./g, ':')}`;
    if (dateStr === 'Invalid Date' || timeStr === 'Invalid Date') {
      return 'Ei aikaa saatavilla';
    }
    return dateTimeStr;
  };

  const getAlertDuration = ticketDoc => {
    if (
      ticketDoc?.errors[ticketDoc?.errors.length - 1]?.ALARM_END_TIME &&
      ticketDoc?.errors[ticketDoc?.errors.length - 1]?.ALARM_END_TIME !== '' &&
      ticketDoc?.errors[ticketDoc?.errors.length - 1]?.ALARM_END_TIME !==
        '0001-01-01T00:00:00Z'
    ) {
      const tempEndDateTime = new Date(
        ticketDoc?.errors[ticketDoc?.errors.length - 1]?.ALARM_END_TIME,
      );
      const tempStartDateTime = new Date(
        ticketDoc?.errors[0]?.ALARM_START_TIME,
      );
      return HoursMinutesAndSeconds(
        tempEndDateTime.getTime() - tempStartDateTime.getTime(),
      );
    } else if (
      ticketDoc.time_when_completed !== '' &&
      ticketDoc.time_when_created !== ''
    ) {
      return HoursMinutesAndSeconds(
        (ticketDoc.time_when_completed - ticketDoc.time_when_created) * 1000,
      );
    } else {
      return '-';
    }
  };

  const [filters, setFilters] = useState({
    order: '',
    sortProperty: '',
  });

  // Handle filter change
  const handleFilterChange = value => {
    if (filters.sortProperty === value && filters.order === 'asc') {
      setFilters({
        ...filters,
        sortProperty: '',
        order: '',
      });
    } else {
      setFilters({
        ...filters,
        sortProperty: value,
        order:
          filters.order === '' ? 'desc' : filters.order === 'desc' ? 'asc' : '',
      });
    }
  };

  // Filters clearing button
  const clearButton = Object.values(filters).find(
    sortProperty => sortProperty !== '',
  ) ? (
    <IonCol className="ion-padding-left ion-padding-right shrink-width">
      <IonButton
        color="primary"
        fill="clear"
        className="ion-float-right"
        onClick={() => {
          setFilters({
            order: '',
            sortProperty: '',
          });
        }}>
        {t('app.clear_filters')}
      </IonButton>
    </IonCol>
  ) : (
    ''
  );

  const exportToExcelButton = (
    <IonButton
      color="primary"
      fill="clear"
      className="ion-float-right"
      disabled={filteredTickets?.length > 0 ? false : true}
      onClick={() => {
        exportToExcel();
      }}>
      {t('ikea.print_excel')}
    </IonButton>
  );

  const exportToPDFButton = (
    <IonButton
      color="primary"
      fill="clear"
      className="ion-float-right"
      disabled={filteredTickets?.length > 0 ? false : true}
      onClick={() => {
        exportToPDF();
      }}>
      {t('ikea.print_pdf')}
    </IonButton>
  );

  useEffect(() => {
    if (filters.sortProperty !== '') {
      const sortedData = [...filteredTickets]; // create a copy of the data array

      sortedData.sort((a, b) => {
        let aPropValue = a.doc;
        let bPropValue = b.doc;
        const nestedProps = filters.sortProperty.split('.'); // split nested property by dot notation

        //Get the nested property value from the objects
        for (const nestedProp of nestedProps) {
          aPropValue = aPropValue[nestedProp];
          bPropValue = bPropValue[nestedProp];
        }

        let compareResult;

        // Compare the values based on their type
        if (typeof aPropValue === 'number' && typeof bPropValue === 'number') {
          compareResult = aPropValue - bPropValue;
        } else if (
          typeof aPropValue === 'string' &&
          typeof bPropValue === 'string'
        ) {
          compareResult = aPropValue.localeCompare(bPropValue);
        } else if (aPropValue instanceof Date && bPropValue instanceof Date) {
          compareResult = aPropValue.getTime() - bPropValue.getTime();
        } else {
          throw new Error(
            `Cannot sort by "${filters.sortProperty}" property. Incompatible types.`,
          );
        }

        // Apply the sorting order (asc or desc)
        if (filters.order === 'desc') {
          compareResult = -compareResult;
        }

        return compareResult;
      });

      setFilteredTickets(sortedData);
    } else {
      setFilteredTickets(filteredTickets);
    }
  }, [filters]);

  const exportToExcel = () => {
    // Filter out the data to include only the properties you want
    const filteredData = filteredTickets.map(item => ({
      Location: place.doc?.name ?? '-',
      'Alert name': item?.doc?.errors[0].ALERTNAME ?? '-',
      'Station UID': item?.doc?.errors[0].STATION_ID ?? '-',
      Connector: item?.doc?.errors[0].CONNECTOR ?? '-',
      Duration: getAlertDuration(item?.doc) ?? '-',
      'Start time':
        item?.doc?.errors[0]?.ALARM_START_TIME &&
        item?.doc?.errors[0]?.ALARM_START_TIME !== ''
          ? GetNiceDateForTransaction(item?.doc?.errors[0]?.ALARM_START_TIME)
          : '',
      'End time':
        item?.doc?.errors[item?.doc?.errors.length - 1]?.ALARM_END_TIME &&
        item?.doc?.errors[item?.doc?.errors.length - 1]?.ALARM_END_TIME !==
          '' &&
        item?.doc?.errors[item?.doc?.errors.length - 1]?.ALARM_END_TIME !==
          '0001-01-01T00:00:00Z'
          ? GetNiceDateForTransaction(
              item?.doc?.errors[item?.doc?.errors.length - 1]?.ALARM_END_TIME,
            )
          : item?.doc?.time_when_completed !== '' &&
            item?.doc?.time_when_created !== ''
          ? GetNiceDateForTransaction(item?.doc?.time_when_completed * 1000)
          : '',
    }));

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the filtered data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    const placeName = place?.doc?.name ?? 'No place information';

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, placeName);

    // Get current date and time
    const date = new Date();
    const formattedDate = date
      .toISOString()
      .slice(0, 19)
      .replace(/:/g, '-')
      .replace('T', ' ')
      .split('.')[0];

    // Write the workbook and force a download with the current date and time in filename
    XLSX.writeFile(
      workbook,
      `alerts_report_${placeName}_from_${chargingStartTime}_to_${chargingEndTime}_${formattedDate}.xlsx`,
    );
  };

  const exportToPDF = () => {
    // Create a new PDF document
    const doc = new jsPDF('landscape');
    doc.setFontSize(18);

    // Add title and date range
    const title = place?.doc?.name ?? 'No place information';
    doc.text(title, 15, 15);

    doc.setFontSize(14);
    const formattedStart = (d =>
      `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`)(
      new Date(chargingStartTime),
    );
    const formattedEnd = (d =>
      `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`)(
      new Date(chargingEndTime),
    );
    const date_range = formattedStart + ' - ' + formattedEnd;

    // Calculate the position of the date range to align it to the right
    const pageWidth = doc.internal.pageSize.width;
    const dateRangeWidth = doc.getTextWidth(date_range);
    const dateRangePosition = pageWidth - dateRangeWidth - 15; // Subtract a bit more to add some margin

    doc.text(date_range, dateRangePosition, 15);

    // Filter out the data to include only the properties you want
    const filteredData = filteredTickets.map(item => ({
      Location: place.doc?.name ?? '-',
      'Alert name': item?.doc?.errors[0].ALERTNAME ?? '-',
      'Station UID': item?.doc?.errors[0].STATION_ID ?? '-',
      Connector: item?.doc?.errors[0].CONNECTOR ?? '-',
      Duration: getAlertDuration(item?.doc) ?? '-',
      'Start time':
        item?.doc?.errors[0]?.ALARM_START_TIME &&
        item?.doc?.errors[0]?.ALARM_START_TIME !== ''
          ? GetNiceDateForTransaction(item?.doc?.errors[0]?.ALARM_START_TIME)
          : '',
      'End time':
        item?.doc?.errors[item?.doc?.errors.length - 1]?.ALARM_END_TIME &&
        item?.doc?.errors[item?.doc?.errors.length - 1]?.ALARM_END_TIME !==
          '' &&
        item?.doc?.errors[item?.doc?.errors.length - 1]?.ALARM_END_TIME !==
          '0001-01-01T00:00:00Z'
          ? GetNiceDateForTransaction(
              item?.doc?.errors[item?.doc?.errors.length - 1]?.ALARM_END_TIME,
            )
          : item?.doc?.time_when_completed !== '' &&
            item?.doc?.time_when_created !== ''
          ? GetNiceDateForTransaction(item?.doc?.time_when_completed * 1000)
          : '',
    }));

    // Create an array of the headings
    const headings = [Object.keys(filteredData[0])];

    // Use jspdf-autotable to add the table to the PDF
    doc.autoTable({
      head: headings,
      body: filteredData.map(Object.values),
      startY: 20,
      styles: {fillColor: [255, 255, 255]}, // set fill color to white
      theme: 'grid',
      headStyles: {fillColor: [0, 0, 0], padding: 20},
    });

    // Get current date and time
    const date = new Date();
    const formattedDate = date
      .toISOString()
      .slice(0, 19)
      .replace(/:/g, '-')
      .replace('T', ' ')
      .split('.')[0];

    // Save the PDF
    doc.save(
      `alerts_report_from_${chargingStartTime}_to_${chargingEndTime}_${formattedDate}.pdf`,
    );
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <h2 className="ion-margin-none ion-margin-top ion-margin-bottom-half">
            {t('ikea.reports.alert_reports')}
          </h2>
        </IonCol>
      </IonRow>
      <IonRow className="ikea-table-buttons">
        <IonCol
          size="auto"
          className="ion-margin-none ion-margin-top ion-margin-bottom-half">
          <h3 className="ion-margin-right ion-margin-none ion-padding-top-half ion-padding-bottom-half">
            {t('ikea.reports.date')}
          </h3>
        </IonCol>
        <IonCol
          size="auto"
          className="ion-margin-none ion-margin-top ion-margin-bottom-half ion-margin-left">
          <input
            name="charging-start"
            className="ion-padding-left ion-padding-right ion-padding-top-half ion-padding-bottom-half"
            value={chargingStartTime}
            type="date"
            onChange={handleChargingStartTimeChange}></input>
        </IonCol>
        <IonCol
          size="auto"
          className="ion-margin-none ion-margin-top ion-margin-bottom-half ion-margin-left">
          <p className="ion-padding-top-half ion-padding-bottom-half">-</p>
        </IonCol>
        <IonCol
          size="auto"
          className="ion-margin-none ion-margin-top ion-margin-bottom-half ion-margin-left">
          <input
            name="charging-end"
            className="ion-padding-left ion-padding-right ion-padding-top-half ion-padding-bottom-half"
            value={chargingEndTime}
            type="date"
            onChange={handleChargingEndTimeChange}></input>
        </IonCol>
        <IonCol className="ion-margin-none ion-margin-top ion-margin-bottom-half ion-margin-left">
          {isDateRangeChanged && (
            <IonButton
              disabled={!isDateRangeChanged}
              onClick={() => console.log('filter by date range')}
              className="ion-margin-none ion-margin-right"
              fill="solid"
              color="primary">
              <IonIcon slot="icon-only" icon={searchOutline} />
            </IonButton>
          )}
        </IonCol>
        <IonCol className="ion-padding-left ion-padding-right shrink-width">
          {exportToPDFButton}
        </IonCol>
        <IonCol className="ion-padding-left ion-padding-right shrink-width">
          {exportToExcelButton}
        </IonCol>
        {clearButton}
      </IonRow>
      <IonRow>
        <IonCol className="ion-padding-right">
          <TableContainer>
            <Table className="reports-table">
              <TableHead className="reports-table-head">
                <TableRow>
                  <StyledTableHeadCell>
                    <span>{t('tickets.id')}</span>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    <span
                      className={`triangle-wrap ${
                        filters.sortProperty === 'location' ? 'active-sort' : ''
                      }`}
                      onClick={() => {
                        handleFilterChange('location');
                      }}>
                      {t('ikea.reports.location')}
                      <span
                        className={
                          props.filters.location === ''
                            ? 'triangle-down'
                            : 'triangle-up'
                        }
                      />
                    </span>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    <span>{t('ikea.reports.alert_name')}</span>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    <span>{t('ikea.reports.station_id')}</span>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    <span>{t('ikea.reports.connector')}</span>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    <span>{t('ikea.reports.duration')}</span>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    <span>{t('ikea.reports.start_time')}</span>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    <span>{t('ikea.reports.end_time')}</span>
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTickets?.length > 0 ? (
                  filteredTickets
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, i) => {
                      return (
                        <TableRow key={i} component="tr" hover={true}>
                          <TableCell>{item?.id ?? '-'}</TableCell>
                          <TableCell>{place.doc?.name ?? '-'}</TableCell>
                          <TableCell>
                            {item?.doc?.errors[0].ALERTNAME ?? '-'}
                          </TableCell>
                          <TableCell>
                            {item?.doc?.errors[0].STATION_ID ?? '-'}
                          </TableCell>
                          <TableCell>
                            {item?.doc?.errors[0].CONNECTOR ?? '-'}
                          </TableCell>
                          <TableCell>
                            {getAlertDuration(item?.doc) ?? '-'}
                          </TableCell>
                          <TableCell>
                            {item?.doc?.errors[0]?.ALARM_START_TIME &&
                            item?.doc?.errors[0]?.ALARM_START_TIME !== ''
                              ? GetNiceDateForTransaction(
                                  item?.doc?.errors[0]?.ALARM_START_TIME,
                                )
                              : ''}
                          </TableCell>
                          <TableCell>
                            {item?.doc?.errors[item?.doc?.errors.length - 1]
                              ?.ALARM_END_TIME &&
                            item?.doc?.errors[item?.doc?.errors.length - 1]
                              ?.ALARM_END_TIME !== '' &&
                            item?.doc?.errors[item?.doc?.errors.length - 1]
                              ?.ALARM_END_TIME !== '0001-01-01T00:00:00Z'
                              ? GetNiceDateForTransaction(
                                  item?.doc?.errors[
                                    item?.doc?.errors.length - 1
                                  ]?.ALARM_END_TIME,
                                )
                              : item?.doc?.time_when_completed !== '' &&
                                item?.doc?.time_when_created !== ''
                              ? GetNiceDateForTransaction(
                                  item?.doc?.time_when_completed * 1000,
                                )
                              : ''}
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow component="tr" hover={true}>
                    <TableCell>No alerts to show</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {filteredTickets?.length < 1 && isLoading && (
              <IonSpinner name="dots"></IonSpinner>
            )}
            {filteredTickets?.length > 0 && (
              <TablePagination
                colSpan={7}
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={filteredTickets?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        </IonCol>
      </IonRow>
      {isLoading && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonSpinner name="crescent" color="primary" />
          </IonCol>
        </IonRow>
      )}
      {isError && (
        <IonRow
          style={{marginBottom: '32px', marginTop: '32px'}}
          className="ion-justify-content-center">
          <IonCol size="12" className="ion-text-center">
            <IonText color="danger">{t('app.fetch_error')}</IonText>
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};
export default AlertReports;

import React from 'react';
import DateTime from './DateTime';
import {IonBadge} from '@ionic/react';
import {useTranslation} from 'react-i18next';

const Badge = props => {
  const {t} = useTranslation('link_app');

  if (props.doc.is_active === false) {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-open">
          <span className="status-badge status-badge-color"></span>
          {t('tickets.moved_to_history')}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'faulted') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray background-light-gray status-badge status-badge-faulted">
          <span className="status-badge-color"></span>
          {t('tickets.faulted')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'open' || !props.doc.ticket_status) {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray background-light-gray status-badge status-badge-open">
          <span className="status-badge-color"></span>
          {t('tickets.open')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'analysis_started') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-analysis_started">
          <span className="status-badge-color"></span>
          {t('tickets.analysis_started')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'analysis_finished') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-analysis_finished">
          <span className="status-badge-color"></span>
          {t('tickets.analysis_finished')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'waiting_for_resourcing') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-waiting_for_resourcing">
          <span className="status-badge-color"></span>
          {t('tickets.waiting_for_resourcing')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'in_field_maintenance') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-in_field_maintenance">
          <span className="status-badge-color"></span>
          {t('tickets.in_field_maintenance')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'repair_started') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-repair_started">
          <span className="status-badge-color"></span>
          {t('tickets.repair_started')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'repair_finished') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-repair_finished">
          <span className="status-badge-color"></span>
          {t('tickets.repair_finished')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'waiting_for_parts') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-waiting_for_parts">
          <span className="status-badge-color"></span>
          {t('tickets.waiting_for_parts')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'manufacturer') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-manufacturer">
          <span className="status-badge-color"></span>
          {t('tickets.manufacturer')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'documented') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-documented">
          <span className="status-badge-color"></span>
          {t('tickets.documented')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'closed') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-closed">
          <span className="status-badge-color"></span>
          {t('tickets.closed')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );
  } else if (props.doc.ticket_status === 'suspended') {
    return (
      <>
        <IonBadge className="ion-margin-left-half background-light-gray status-badge status-badge-suspended">
          <span className="status-badge-color"></span>
          {t('tickets.suspended')}
          &nbsp;
          {props.timestamp && (
            <span className="badge-date">
              <DateTime timestamp={props.doc.time_when_created} />
            </span>
          )}
        </IonBadge>
        {props.doc.is_scheduled && (
          <IonBadge className="ion-margin-left-half background-light-gray background-light-gray">
            {t('tickets.scheduled')}
          </IonBadge>
        )}
      </>
    );

    // if (props.doc.is_active === false) {
    // 	return (
    // 		<>
    // 			<IonBadge className="ion-margin-left-half status-badge-open" color="success">
    // 				<span className="status-badge-color"></span>
    // 				{t("tickets.moved_to_history")}
    // 			</IonBadge>
    // 			{props.doc.is_scheduled &&
    // 				<IonBadge className="ion-margin-left-half background-light-gray">
    // 					{t("tickets.scheduled")}
    // 				</IonBadge>
    // 			}
    // 		</>
    // 	)
    // } else if (props.doc.is_scheduled === true && props.doc.time_when_started === "") {
    // 	return (
    // 		<>
    // 			<IonBadge className="ion-margin-left-half background-light-gray">
    // 				{t("tickets.scheduled")}
    // 				&nbsp;
    // 				{props.timestamp && (
    // 					<span className="badge-date">
    // 						<DateTime timestamp={props.doc.time_when_created} />
    // 					</span>
    // 				)}
    // 			</IonBadge>
    // 		</>
    // 	)
    // } else if (props.doc.time_when_paused !== "") {
    // 	return (
    // 		<>
    // 			<IonBadge className="ion-margin-left-half" color="warning">
    // 				{t("tickets.paused")}
    // 				&nbsp;
    // 				{props.timestamp && (
    // 					<span className="badge-date">
    // 						<DateTime timestamp={props.doc.time_when_paused} />
    // 					</span>
    // 				)}
    // 			</IonBadge>
    // 			{props.doc.is_scheduled &&
    // 				<IonBadge className="ion-margin-left-half background-light-gray">
    // 					{t("tickets.scheduled")}
    // 				</IonBadge>
    // 			}
    // 		</>
    // 	)
    // } else if (props.doc.time_when_documented !== "" && props.doc.time_when_completed !== "") {
    // 	return (
    // 		<>
    // 			<IonBadge className="ion-margin-left-half" color="success">
    // 				{t("tickets.documented")}
    // 				&nbsp;
    // 				{props.timestamp && (
    // 					<span className="badge-date">
    // 						<DateTime timestamp={props.doc.time_when_documented} />
    // 					</span>
    // 				)}
    // 			</IonBadge>
    // 			{props.doc.is_scheduled &&
    // 				<IonBadge className="ion-margin-left-half background-light-gray">
    // 					{t("tickets.scheduled")}
    // 				</IonBadge>
    // 			}
    // 		</>
    // 	)
    // } else if (props.doc.time_when_completed !== "" && props.doc.time_when_documented === "") {
    // 	return (
    // 		<>
    // 			<IonBadge className="ion-margin-left-half" color="success">
    // 				{t("tickets.completed")}
    // 				&nbsp;
    // 				{props.timestamp && (
    // 					<span className="badge-date">
    // 						<DateTime timestamp={props.doc.time_when_completed} />
    // 					</span>
    // 				)}
    // 			</IonBadge>
    // 			{props.doc.is_scheduled &&
    // 				<IonBadge className="ion-margin-left-half background-light-gray">
    // 					{t("tickets.scheduled")}
    // 				</IonBadge>
    // 			}
    // 		</>
    // 	)
    // } else if (props.doc.time_when_started !== "" && props.doc.time_when_paused === "" && props.doc.time_when_documented === "" && props.doc.time_when_completed === "") {
    // 	return (
    // 		<>
    // 			<IonBadge className="ion-margin-left-half" color="warning">
    // 				{t("tickets.started")}
    // 				&nbsp;
    // 				{props.timestamp && (
    // 					<span className="badge-date">
    // 						<DateTime timestamp={props.doc.time_when_started} />
    // 					</span>
    // 				)}
    // 			</IonBadge>
    // 			{props.doc.is_scheduled &&
    // 				<IonBadge className="ion-margin-left-half background-light-gray">
    // 					{t("tickets.scheduled")}
    // 				</IonBadge>
    // 			}
    // 		</>
    // 	)
    // } else if (props.doc.time_when_paused === "" && props.doc.time_when_completed === "" && props.doc.time_when_started === "" && props.doc.resourced.length > 0) {
    // 	return (
    // 		<>
    // 			<IonBadge className="ion-margin-left-half status-badge-open" color="none">
    // 				<span className="status-badge-color"></span>
    // 				{t("tickets.not_started")}
    // 				&nbsp;
    // 				{props.timestamp && (
    // 					<span className="badge-date">
    // 						<DateTime timestamp={props.doc.time_when_resourced} />
    // 					</span>
    // 				)}
    // 			</IonBadge>
    // 			{props.doc.is_scheduled &&
    // 				<IonBadge className="ion-margin-left-half background-light-gray">
    // 					{t("tickets.scheduled")}
    // 				</IonBadge>
    // 			}
    // 		</>
    // 	)
    // } else if (props.doc.time_when_paused === "" && props.doc.time_when_completed === "" && props.doc.time_when_started === "" && props.doc.resourced.length === 0) {
    // 	return (
    // 		<>
    // 			<IonBadge className="ion-margin-left-half status-badge-open" color="none">
    // 				<span className="status-badge-color"></span>
    // 				{t("tickets.not_assigned")}
    // 				&nbsp;
    // 				{props.timestamp && (
    // 					<span className="badge-date">
    // 						<DateTime timestamp={props.doc.time_when_created} />
    // 					</span>
    // 				)}
    // 			</IonBadge>
    // 			{props.doc.is_scheduled &&
    // 				<IonBadge className="ion-margin-left-half background-light-gray">
    // 					{t("tickets.scheduled")}
    // 				</IonBadge>
    // 			}
    // 		</>
    // 	)
  } else {
    return null;
  }
};

export default Badge;

import React, {useState} from 'react';
import {IonText, IonRow, IonCol, IonGrid, IonIcon} from '@ionic/react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import {chevronDownOutline} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import PictureList from './PictureList';
import CommentField from './CommentField';

const PlaceGeneral = props => {
  const {t} = useTranslation('link_app');
  const {permissions} = props;

  // Databases
  // const databases = useContext(DatabaseContext)

  // Copy place to local state
  const [place, setPlace] = useState(props.place);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  // Fetch contacts
  // const [contacts, setContacts] = useState([])
  // useEffect(() => {
  // 	databases.contacts
  // 		.allDocs({
  // 			include_docs: true,
  // 		})
  // 		.then((result) => {
  // 			const docs = result.rows.filter((row) => {
  // 				return !row.id.includes("_design")
  // 			})
  // 			setContacts(docs)
  // 		})
  // 		.catch((err) => {
  // 			console.log(err)
  // 		})
  // }, [databases.contacts, match, isLoading])

  const handleDelete = commentId => {
    const comments = [...place.comments];
    const commentIndex = comments.findIndex(x => x.id === commentId);
    comments.splice(commentIndex, 1);
    props.savePlaceChanges({...place, comments: comments});
  };

  return (
    <IonGrid className="ion-padding-none">
      {/* <IonRow>
				<IonCol size="12">
					<Accordion>
						<AccordionSummary expandIcon={<IonIcon className="ion-margin-right-half" slot="start" icon={chevronDownOutline} />} aria-controls="panel1a-content" id="panel1a-header">
							<IonText>
								<h6 className="ion-margin-none">{t("place.comments")}</h6>
							</IonText>
						</AccordionSummary>
						<AccordionDetails>
							<IonList>
								{place.comments?.map((comment, i) => {
									const contactIndex = contacts.findIndex((contact) => contact.doc._id === comment.contact_id)
									const contact = contacts[contactIndex]
									return <CommentCard permissions={permissions} key={i} contact={contact && contact.doc} comment={comment} handleDelete={handleDelete} />
								})}
							</IonList>
						</AccordionDetails>
					</Accordion>
					<Divider className="ion-margin-top ion-margin-bottom" />
				</IonCol>
			</IonRow> */}
      <IonRow>
        <IonCol size="12">
          <Accordion>
            <AccordionSummary
              expandIcon={
                <IonIcon
                  className="ion-margin-right-half"
                  slot="start"
                  icon={chevronDownOutline}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header">
              <IonText>
                <h3 className="ion-margin-none">
                  {t('place.passage_and_entry')}
                </h3>
              </IonText>
            </AccordionSummary>
            <AccordionDetails>
              <PictureList
                place={place}
                setPlace={setPlace}
                savePlaceChanges={props.savePlaceChanges}
                tabName="general"
                listName="passage"
                isEdited={isEdited}
                setIsEdited={setIsEdited}
                selectionType={props.selectionType}
                permissions={permissions}
              />
              <CommentField
                place={place}
                setPlace={setPlace}
                savePlaceChanges={props.savePlaceChanges}
                tabName="general"
                listName="passage"
                isEdited={isEdited}
                setIsEdited={setIsEdited}
                selectionType={props.selectionType}
                permissions={permissions}
              />
            </AccordionDetails>
          </Accordion>
          <Divider className="ion-margin-top ion-margin-bottom" />
        </IonCol>
      </IonRow>
      {/* <IonRow>
				<IonCol size="12">
					<Accordion>
						<AccordionSummary expandIcon={<IonIcon className="ion-margin-right-half" slot="start" icon={chevronDownOutline} />} aria-controls="panel2a-content" id="panel2a-header">
							<IonText>
								<h6 className="ion-margin-none"></h6>
							</IonText>
						</AccordionSummary>
						<AccordionDetails>
							<PictureList place={place} setPlace={setPlace} savePlaceChanges={props.savePlaceChanges} tabName="general" listName="entry" isEdited={isEdited} setIsEdited={setIsEdited} selectionType={props.selectionType} permissions={permissions} />
							<CommentField place={place} setPlace={setPlace} savePlaceChanges={props.savePlaceChanges} tabName="general" listName="entry" isEdited={isEdited} setIsEdited={setIsEdited} selectionType={props.selectionType} permissions={permissions} />
						</AccordionDetails>
					</Accordion>
					<Divider className="ion-margin-top ion-margin-bottom" />
				</IonCol>
			</IonRow> */}
      {/* <IonRow>
				<IonCol size="12">
					<Accordion>
						<AccordionSummary expandIcon={<IonIcon className="ion-margin-right-half" slot="start" icon={chevronDownOutline} />} aria-controls="panel3a-content" id="panel3a-header">
							<IonText>
								<h6 className="ion-margin-none">{t("place.device_location")}</h6>
							</IonText>
						</AccordionSummary>
						<AccordionDetails>
							<PictureList place={place} setPlace={setPlace} savePlaceChanges={props.savePlaceChanges} tabName="general" listName="device_location" isEdited={isEdited} setIsEdited={setIsEdited} selectionType={props.selectionType} permissions={permissions} />
							<CommentField place={place} setPlace={setPlace} savePlaceChanges={props.savePlaceChanges} tabName="general" listName="device_location" isEdited={isEdited} setIsEdited={setIsEdited} selectionType={props.selectionType} permissions={permissions} />
						</AccordionDetails>
					</Accordion>
					<Divider className="ion-margin-top ion-margin-bottom" />
				</IonCol>
			</IonRow> */}
    </IonGrid>
  );
};
export default PlaceGeneral;

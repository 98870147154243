import React, {createContext, useState, useEffect} from 'react';
import PouchDB from 'pouchdb';
import useConfig from '../../hooks/useConfig';

export const DatabaseContext = createContext();

export const DatabaseProvider = props => {
  const {db} = useConfig();
  const [databases, setDatabases] = useState();

  useEffect(() => setDatabases({
    accessories: new PouchDB(
      `${db}/accessories`,
    ),
    contacts: new PouchDB(
      `${db}/contacts`,
    ),
    guides: new PouchDB(
      `${db}/guides`,
    ),
    places: new PouchDB(
      `${db}/places`,
    ),
    studios: new PouchDB(
      `${db}/studios`,
    ),
    tickets: new PouchDB(
      `${db}/tickets`,
    ),
    warehouses: new PouchDB(
      `${db}/warehouses`,
    ),
    ticket_history: new PouchDB(
      `${db}/ticket_history`,
    ),
    fm_services: new PouchDB(
      `${db}/fm_services`,
    ),
    users: new PouchDB(
      `${db}/_users`,
    ),
    software_presets: new PouchDB(
      `${db}/software_presets`,
    ),
    scheduled_emails: new PouchDB(
      `${db}/scheduled_emails`,
    ),
    lm_delivery_tags: new PouchDB(
      `${db}/lm_delivery_tags`,
    ),
  }), [db]);

  return (
    <DatabaseContext.Provider value={databases}>
      {props.children}
    </DatabaseContext.Provider>
  );
};

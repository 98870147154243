import React from 'react';
import {IonText, IonGrid, IonCol} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import DateTime from '../utils/DateTime';

const Messages = props => {
  // Misc vars
  const {t} = useTranslation('link_app');

  // Ticket
  const ticket = props.doc;

  // Table headers
  const tableHeaders = [];
  if (ticket?.alert_messages?.length > 0) {
    ticket.alert_messages.forEach(message => {
      Object.keys(message).forEach(key => {
        if (tableHeaders.indexOf(key) === -1) {
          tableHeaders.push(key);
        }
      });

    });
  }

  return (
    <IonGrid className="ion-padding-none ion-margin-top">
      <IonCol size="12">
        <IonText>
          <h5>{t('tickets.message_details')}</h5>
        </IonText>
        {tableHeaders?.length > 0 && (
          <table className="errors-table">
            <tbody>
              <tr>
                {tableHeaders.map((key, i) => {
                  return (
                    <th key={i}>
                      <IonText>
                        <p>{t(`tickets.ticket_messages_${key}`)}</p>
                      </IonText>
                    </th>
                  );
                })}
              </tr>
              {ticket?.alert_messages
                .sort((a, b) => {
                  return b.time - a.time;
                })
                .map((message, i) => {
                  if (!message.time) {
                    message.time = null;
                  }
                  return (
                    <tr key={i}>
                      {Object.values(message).map((value, i) => {
                        const key = Object.keys(message).find(
                          key => message[key] === value,
                        );
                        if (Array.isArray(value)) {
                          return (
                            <td key={i}>
                              <IonText>
                                {value.map((val, i) => {
                                  return (
                                    <p key={i}>{`${val}${
                                      i !== value.length - 1 ? ', ' : ''
                                    }`}</p>
                                  );
                                })}
                              </IonText>
                            </td>
                          );
                        } else if (key === 'time') {
                          return (
                            <td key={i}>
                              <IonText>
                                {value !== null && (
                                  <DateTime timestamp={value} />
                                )}
                              </IonText>
                            </td>
                          );
                        } else {
                          return (
                            <td key={i}>
                              <IonText>
                                <p>{value}</p>
                              </IonText>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </IonCol>
    </IonGrid>
  );
};

export default Messages;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import link_app_fi from './translations/fi/link-app.json';
import link_app_ikea from './translations/fi/link-app-ikea.json';
import {UserProvider} from './components/context/UserContext';
import {DatabaseProvider} from './components/context/DatabaseContext';
import {AuthProvider} from './components/context/AuthContext';
import {ServiceWorkerProvider} from './components/context/ServiceWorkerContext';

i18next.init({
  interpolation: {escapeValue: false}, // React already does escaping
  lng: 'fi', // language to use
  resources: {
    fi: {
      link_app: link_app_fi, // 'link_app' is our custom namespace
      link_app_ikea: link_app_ikea,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ServiceWorkerProvider>
      <I18nextProvider i18n={i18next}>
        <UserProvider>
          <DatabaseProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
          </DatabaseProvider>
        </UserProvider>
      </I18nextProvider>
    </ServiceWorkerProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {useState, useEffect} from 'react';
import {
  IonRow,
  IonCol,
  IonButton,
  IonBadge,
  IonIcon,
  IonText,
  IonModal,
} from '@ionic/react';
import {
  alertCircleOutline,
  volumeHighOutline,
  closeCircleOutline,
  volumeMuteOutline,
} from 'ionicons/icons';
import ControlRoomBadge from '../../components/utils/ControlRoomBadge';
import {useTranslation} from 'react-i18next';
import SlaDiff from '../../components/utils/SlaDiff';
import DateTime from '../../components/utils/DateTime';
import {Link} from 'react-router-dom';
import {StartSlaCounter, GetSla} from '../../components/utils/SlaComponent';

const ControlRoomTickets = props => {
  const {t} = useTranslation('link_app');
  const {user} = props;
  const [tickets, setTickets] = useState([]);
  const [places, setPlaces] = useState([]);

  const [guides, setGuides] = useState([]);

  const [modal, setModal] = useState('');
  const [modalTicket, setModalTicket] = useState(null);

  useEffect(() => {
    setTickets(props.tickets);
  }, [props.tickets]);

  useEffect(() => {
    setPlaces(props.places);
  }, [props.places]);

  useEffect(() => {
    setGuides(props.guides);
  }, [props.guides]);

  const getPlace = station_id => {
    if (places.length > 0) {
      let stationPlace = null;
      for (let place of places) {
        for (let station of place.doc?.stations) {
          if (station?.id?.toString() === station_id) {
            stationPlace = place;
            return stationPlace;
          }
        }
      }
      return null;
    } else {
      return null;
    }
  };

  const GetErrorName = props => {
    const ticket = props.item;
    let errorName = ticket.doc?.error.name ?? '';
    if (errorName === '') {
      if (ticket?.doc?.error?.id === '' && guides?.length > 0) {
        const alertname = ticket.doc?.errors
          ? ticket.doc?.errors[0].ALERTNAME
          : '';
        if (alertname && alertname !== '') {
          let alertTypes = [...guides];
          if (alertname.match('Kempower')) {
            alertTypes = alertTypes.filter(val => val.doc?.name.match('DC -'));
          } else if (alertname.match('Enersense')) {
            alertTypes = alertTypes.filter(val => val.doc?.name.match('AC -'));
          }
          let alertNameFound = false;
          alertTypes.forEach((val, i) => {
            const guideErrorName = val.doc?.name?.split(/AC - |DC - /).pop();
            if (alertname.toLowerCase().match(guideErrorName.toLowerCase())) {
              errorName = val.doc?.name;
              alertNameFound = true;
              return;
            }
          });
          if (!alertNameFound) {
            let chargerType = '';
            if (alertname.match('Kempower')) {
              chargerType = 'DC';
            } else if (alertname.match('Enersense')) {
              chargerType = 'AC';
            }
            const guide = guides.find(
              guide => guide.doc.name === chargerType + ' - Faulted',
            );
            errorName = guide.doc?.name;
          }
        }
      }
    }
    return <IonText>{errorName !== '' ? errorName : '-'}</IonText>;
  };

  // Set info modal position to clicked parent ticket element on list
  const openModal = (e, ticket) => {
    const clickedElemMeasurements = e.target
      .closest('.control-room-ticket-wrapper')
      .getBoundingClientRect();
    const modalElement = document.getElementById(
      'control-room-ticket-modal-' + props.ticketsListIndex,
    );

    if (clickedElemMeasurements && modalElement) {
      modalElement.shadowRoot.querySelector('.modal-wrapper').style.position =
        'absolute';
      modalElement.shadowRoot.querySelector(
        '.modal-wrapper',
      ).style.top = `${clickedElemMeasurements.y.toString()}px`;
      modalElement.shadowRoot.querySelector(
        '.modal-wrapper',
      ).style.left = `${clickedElemMeasurements.x.toString()}px`;
      modalElement.shadowRoot.querySelector(
        '.modal-wrapper',
      ).style.width = `${clickedElemMeasurements.width.toString()}px`;
      modalElement.shadowRoot.querySelector(
        '.modal-wrapper',
      ).style.maxWidth = `450px`;
      modalElement.shadowRoot.querySelector(
        '.modal-wrapper',
      ).style.height = `auto`;

      setModalTicket(ticket);
      setModal('control-room-ticket-modal-' + props.ticketsListIndex);
    } else {
      console.log('No modal element found');
    }
  };

  const toggleTicketAlertMute = ticket_id => {
    let mutedTicketsCopy = [...props.mutedTickets];
    const indexOfTicket = mutedTicketsCopy.findIndex(
      elem => elem.id === ticket_id,
    );
    if (indexOfTicket !== undefined && indexOfTicket > -1) {
      mutedTicketsCopy = mutedTicketsCopy.filter(elem => elem.id !== ticket_id);
    } else {
      mutedTicketsCopy = [...mutedTicketsCopy, {id: ticket_id, muted: true}];
    }
    props.setMutedTickets(mutedTicketsCopy);
  };

  return (
    <>
      {tickets.length > 0 &&
        tickets.map((ticket, i) => {
          const place = getPlace(ticket.doc?.errors[0].STATION_ID?.toString());
          return (
            <div
              className={`control-room-ticket-wrapper ${
                !ticket.doc?.ticket_status ||
                ticket.doc?.ticket_status === 'faulted'
                  ? 'faulted'
                  : ''
              }`}
              key={i}>
              <IonRow>
                <IonCol className="datetime" size="9">
                  {/* <p><strong>{t('tickets.work')} {ticket.doc?.errors[0].STATION_ID}</strong>, <DateTime timestamp={ticket.doc?.time_when_created} /></p> */}
                  <p>
                    <strong>
                      {t('tickets.work')} {ticket.doc?._id}
                    </strong>
                    , <DateTime timestamp={ticket.doc?.time_when_created} />
                  </p>
                </IonCol>
                <IonCol size="3">
                  <IonButton
                    className="ion-margin-none"
                    fill="clear"
                    color="primary"
                    size="small"
                    onClick={e => openModal(e, ticket)}>
                    <IonIcon
                      icon={alertCircleOutline}
                      color={user?.metadata?.dark_mode ? 'primary' : 'dark'}
                      slot="end"
                    />
                  </IonButton>
                  <IonButton
                    className="ion-margin-none"
                    fill="clear"
                    color="primary"
                    size="small"
                    onClick={() => toggleTicketAlertMute(ticket.doc?._id)}>
                    {!props.alertSounds ||
                    props.mutedTickets.some(e => e.id === ticket.doc?._id) ? (
                      <IonIcon
                        icon={volumeMuteOutline}
                        color={user?.metadata?.dark_mode ? 'primary' : 'dark'}
                        slot="end"
                      />
                    ) : (
                      <IonIcon
                        icon={volumeHighOutline}
                        color={user?.metadata?.dark_mode ? 'primary' : 'dark'}
                        slot="end"
                      />
                    )}
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="9">
                  <IonText>
                    <IonBadge
                      color={
                        props.ticketsUpdated.some(e => e === ticket.id)
                          ? 'error'
                          : 'primary'
                      }
                      className={'ion-margin-right-half'}>
                      {ticket.doc?.errors.length}
                    </IonBadge>
                    {t('tickets.error')}: <GetErrorName item={ticket} />
                    {/* {t('tickets.error')}: {ticket.doc?.error.name ? ticket.doc?.error.name : "-"} */}
                  </IonText>
                </IonCol>
                <IonCol size="3">&nbsp;</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="9">
                  <ControlRoomBadge doc={ticket.doc} timestamp />
                </IonCol>
                <IonCol size="3">&nbsp;</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="9">
                  {ticket.doc?.sla === '' && '-'}
                  {StartSlaCounter(
                    ticket.doc?.sla,
                    ticket.doc?.time_when_created,
                  ) ? (
                    <>
                      {ticket.doc?.sla !== '' &&
                        ticket.doc?.is_active === false && (
                          <SlaDiff
                            id={ticket.doc?._id}
                            end={ticket.doc?.time_when_completed}
                            start={ticket.doc?.time_when_created}
                            sla={ticket.doc?.sla}
                          />
                        )}
                      {ticket.doc?.sla !== '' &&
                        ticket.doc?.time_when_completed !== '' &&
                        ticket.doc?.is_active === true && (
                          <SlaDiff
                            id={ticket.doc?._id}
                            end={ticket.doc?.time_when_completed}
                            start={ticket.doc?.time_when_created}
                            sla={ticket.doc?.sla}
                          />
                        )}
                      {ticket.doc?.sla !== '' &&
                        ticket.doc?.time_when_completed === '' &&
                        ticket.doc?.time_when_created !== '' &&
                        ticket.doc?.is_active === false && (
                          <p>{ticket.doc?.sla}</p>
                        )}
                      {ticket.doc?.sla !== '' &&
                        ticket.doc?.time_when_completed === '' &&
                        ticket.doc?.time_when_created !== '' &&
                        ticket.doc?.is_active === true && (
                          <IonText>
                            SLA{' '}
                            {GetSla(
                              ticket.doc?.sla,
                              ticket.doc.time_when_created,
                              false,
                            )}
                          </IonText>
                        )}
                    </>
                  ) : (
                    <IonText>
                      {t('tickets.sla')} {ticket.doc?.sla}
                    </IonText>
                  )}
                </IonCol>

                <IonCol size="3">&nbsp;</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="9">
                  <p>
                    <Link to={`/places/${place?.id}`}>
                      {place ? place.doc.name : '-'}
                    </Link>
                  </p>
                </IonCol>
                <IonCol size="3">
                  <p>
                    <Link to={`/tickets/${ticket.id}`}>
                      {t('app.open_ticket')}
                    </Link>
                  </p>
                </IonCol>
              </IonRow>
            </div>
          );
        })}
      {/* Info Modal */}
      <IonModal
        key={'control-room-ticket-modal-' + props.ticketsListIndex}
        id={`control-room-ticket-modal-` + props.ticketsListIndex}
        isOpen={modal === `control-room-ticket-modal-` + props.ticketsListIndex}
        onDidDismiss={() => {
          setModal('');
          setModalTicket(null);
        }}
        cssClass="control-room-ticket-modal">
        {modalTicket !== null && (
          <>
            <IonRow className="ion-align-items-center">
              <IonCol>
                <h2 className="ion-margin-none">{t('tickets.active_alert')}</h2>
              </IonCol>
              <IonCol size="auto">
                <IonButton
                  size="large"
                  fill="clear"
                  color={user?.metadata?.dark_mode ? 'primary' : 'dark'}
                  title="Close window"
                  onClick={() => {
                    setModal('');
                    setModalTicket(null);
                  }}>
                  <IonIcon
                    slot="start"
                    color={user?.metadata?.dark_mode ? 'primary' : 'dark'}
                    icon={closeCircleOutline}
                  />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <p className="ion-margin-none ">{t('tickets.alert_id')}</p>
            </IonRow>
            <IonRow>
              <p>
                <b>{modalTicket?.id ?? '-'}</b>
              </p>
            </IonRow>

            <IonRow>
              <p className="ion-margin-none">{t('tickets.charging_station')}</p>
            </IonRow>
            <IonRow>
              <p>
                <b>{modalTicket?.doc?.errors[0]?.APP_TYPE ?? '-'}</b>
              </p>
            </IonRow>

            <IonRow>
              <p className="ion-margin-none">{t('place.connector')}</p>
            </IonRow>
            <IonRow>
              <p>
                <b>{modalTicket?.doc?.errors[0]?.CONNECTOR ?? '-'}</b>
              </p>
            </IonRow>

            <IonRow>
              <p className="ion-margin-none">
                {t('tickets.ticket_messages_subject')}
              </p>
            </IonRow>
            <IonRow>
              <p>
                <b>{modalTicket?.doc?.errors[0]?.SUMMARY ?? '-'}</b>
              </p>
            </IonRow>
            <IonRow>
              <p className="ion-margin-none">{t('tickets.ticket_message')}</p>
            </IonRow>
            <IonRow>
              <p>
                <b>{modalTicket?.doc?.errors[0]?.DESCRIPTION ?? '-'}</b>
              </p>
            </IonRow>

            <IonRow>
              <p className="ion-margin-none">{t('tickets.ticket_log_time')}</p>
            </IonRow>
            <IonRow>
              <b>
                <DateTime timestamp={modalTicket.doc?.time_when_created} />
              </b>
            </IonRow>
          </>
        )}
      </IonModal>
    </>
  );
};
export default ControlRoomTickets;

import React from 'react';
import {trashOutline} from 'ionicons/icons';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonCard,
  IonCardContent,
} from '@ionic/react';
import DateTime from './DateTime';
import {useTranslation} from 'react-i18next';

const CommentCard = props => {
  const {t} = useTranslation('link_app');
  const permissions = props.permissions;

  return (
    <IonCard className="ion-border">
      <IonItem
        lines="none"
        className="ion-margin-top-half ion-margin-bottom-half">
        <div className="icon-wrap primary" slot="start">
          {props.contact ? (
            <>
              {props.contact.name.split(' ')[0].charAt(0)}
              {props.contact.name.split(' ')[1].charAt(0)}
            </>
          ) : (
            '?'
          )}
        </div>
        <IonLabel>
          {props.contact ? props.contact.name : t('app.deleted_contact')}
          <br />
          <span className="comment-date">
            <DateTime timestamp={props.comment && props.comment.time} />
          </span>
        </IonLabel>
        <IonButton
          disabled={!permissions?.canDelete}
          className="no-margin"
          slot="end"
          size="small"
          fill="clear"
          color="medium"
          onClick={() => {
            props.handleDelete(props.comment.id);
          }}>
          <IonIcon slot="icon-only" icon={trashOutline} />
        </IonButton>
      </IonItem>
      <IonCardContent className="ion-padding-none ion-margin-right ion-margin-bottom ion-margin-left">
        {props.comment && props.comment.text}
      </IonCardContent>
    </IonCard>
  );
};

export default CommentCard;

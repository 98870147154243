/* eslint-disable import/no-webpack-loader-syntax */
import React, {useState, useContext, useEffect} from 'react';
import {
  IonPage,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {FormControl, NativeSelect} from '@material-ui/core';
import {DatabaseContext} from '../../../components/context/DatabaseContext';
import Loading from '../../../components/utils/Loading';
import _ from 'lodash';
import {AuthContext} from '../../../components/context/AuthContext';
import {UserContext} from '../../../components/context/UserContext';
import AlertReports from './AlertReports';
import ChargingReports from './ChargingReports';
import useTitleEffect from '../../../hooks/useTitle';

const Reports = props => {
  const {t} = useTranslation('link_app_ikea');
  const [user] = useContext(UserContext);
  const {match, permissions} = props;
  const [segment, setSegment] = useState('lm_delivery');

  useTitleEffect(t('nav.tickets'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  const [filters, setFilters] = useState({
    status: '',
    error: '',
    sla: '',
    isActive: '',
    operator: '',
    year: '',
    month: '',
    order: '',
    fmService: '',
    placeNames: '',
    chargingStation: '',
    location: '',
    alert_name: '',
    station_id: '',
    connector: '',
    duration: '',
    start_time: '',
    end_time: '',
  });

  // Handle filter change
  const handleFilterChange = (value, filter) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  // Fetching state
  const [isFetching, setIsFetching] = useState(true);

  // Fetch tickets
  const [tickets, setTickets] = useState([]);
  useEffect(() => {
    databases.tickets
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design') && !row.id.includes('health');
        });
        setTickets(docs);
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.tickets, match, props.isLoading, setIsAuth]);

  // Fetch places
  const [places, setPlaces] = useState([]);
  useEffect(() => {
    if (user.metadata !== undefined) {
      databases.places
        .allDocs({
          include_docs: true,
        })
        .then(result => {
          let docs = result.rows.filter(row => {
            return !row.id.includes('_design');
          });
          if (
            user?.metadata?.ikea_location &&
            user?.metadata?.ikea_location !== null &&
            user?.metadata?.ikea_location !== 'manager'
          ) {
            docs = docs.filter(
              place =>
                place.doc.site_id.toString() === user.metadata.ikea_location,
            );
          }
          setPlaces(docs);
        })
        .catch(err => {
          console.log(err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        });
    }
  }, [databases.places, match, props.isLoading, setIsAuth]);

  // Fetch delivery tags
  const [lmDeliveryTags, setLmDeliveryTags] = useState([]);
  useEffect(() => {
    databases.lm_delivery_tags
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setLmDeliveryTags(docs);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.lm_delivery_tags, match, props.isLoading, setIsAuth]);

  // Filter options
  const [statuses, setStatuses] = useState([]);
  const [months, setMonths] = useState([]);
  const [placeNames, setPlaceNames] = useState([]);
  const [chargingStations, setChargingStations] = useState([]);

  // Get place, contact ids, errors etc from ticket
  useEffect(() => {
    tickets.forEach(item => {
      if (
        statuses.indexOf(item.doc?.ticket_status) === -1 &&
        item.doc?.ticket_status !== ''
      ) {
        if (!item.doc?.ticket_status || item.doc?.ticket_status === 'faulted') {
          if (statuses.indexOf('faulted') === -1) {
            setStatuses([...statuses, 'faulted']);
          }
        } else {
          setStatuses([...statuses, item.doc?.ticket_status]);
        }
      }
      const place = places.find(place =>
        place.doc?.stations.some(
          station =>
            station?.id?.toString() ===
            item.doc?.errors[0].STATION_ID?.toString(),
        ),
      );
      if (place) {
        if (
          placeNames.indexOf(place.doc?.name) === -1 &&
          place.doc?.name !== ''
        ) {
          setPlaceNames([...placeNames, place.doc?.name].sort());
        }
        const station = place.doc?.stations.find(
          station =>
            station.id.toString() ===
            item.doc?.errors[0].STATION_ID?.toString(),
        );
        if (station) {
          if (
            chargingStations.indexOf(station.name) === -1 &&
            station.name !== ''
          ) {
            setChargingStations([...chargingStations, station.name].sort());
          }
        }
      }
    });
  }, [places, tickets]);

  // Get months on year change
  useEffect(() => {
    if (filters.year !== '') {
      tickets.forEach(item => {
        const year = new Date(
          parseInt(item.doc.time_when_created) * 1000,
        ).getFullYear();
        const month = new Date(
          parseInt(item.doc.time_when_created) * 1000,
        ).getMonth();
        if (year.toString() === filters.year) {
          if (months.indexOf(month.toString()) === -1) {
            setMonths([...months, month.toString()]);
          }
        }
      });
    } else if (months.length > 0) {
      setMonths([]);
    }
  }, [filters.year, tickets, months]);

  // Filters clearing button
  const clearButton = Object.values(filters).find(x => x !== '') ? (
    <IonButton
      color="primary"
      fill="clear"
      className="ion-float-right"
      onClick={() => {
        setFilters({
          status: '',
          error: '',
          sla: '',
          isActive: '',
          operator: '',
          year: '',
          month: '',
          order: filters.order,
          fmService: '',
          placeNames: '',
          chargingStation: '',
        });
      }}>
      {t('app.clear_filters')}
    </IonButton>
  ) : (
    ''
  );

  const [selectedPlace, setSelectedPlace] = useState(null);
  useEffect(() => {
    if (places.length > 0) {
      setSelectedPlace(places[0]);
    }
  }, [places]);

  const handlePlaceSelectChange = id => {
    const newPlace = places.filter(place => place.id === id);
    setSelectedPlace(newPlace[0]);
  };

  // Handle the filtering
  let filteredTickets = tickets;
  if (filters.year !== '') {
    filteredTickets = filteredTickets.filter(item => {
      const year = new Date(
        parseInt(item.doc.time_when_created) * 1000,
      ).getFullYear();
      return year.toString() === filters.year;
    });
  }
  if (filters.month !== '') {
    filteredTickets = filteredTickets.filter(item => {
      const month = new Date(
        parseInt(item.doc.time_when_created) * 1000,
      ).getMonth();
      return month.toString() === filters.month;
    });
  }
  if (filters.chargingStation !== '') {
    filteredTickets = filteredTickets.filter(item => {
      const place = places.find(place =>
        place.doc?.stations.some(
          station =>
            station.id.toString() ===
            item.doc?.errors[0].STATION_ID?.toString(),
        ),
      );
      if (place) {
        const station = place.doc?.stations.find(
          station =>
            station.id.toString() ===
            item.doc?.errors[0].STATION_ID?.toString(),
        );
        if (station) {
          return station.name.toString() === filters.chargingStation;
        }
      }
      return false;
    });
  }

  // RowLimit per page handlers
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = newPage => {
    if (newPage.currentTarget.ariaLabel === 'Next page') setPage(page + 1);
    else setPage(page - 1);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredTickets.length - page * rowsPerPage);

  // Filter tickets by active
  const SelectPlaceInput = () => {
    return (
      <FormControl className="ion-margin-none">
        <NativeSelect
          value={selectedPlace?.id}
          onChange={e => handlePlaceSelectChange(e.target.value)}
          inputProps={{
            name: 'active',
            id: 'active',
          }}
          className="ikea-place-select">
          {places.map((place, i) => {
            return (
              <option key={i} value={place.id}>
                {place.doc?.name}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
    );
  };

  return (
    <IonPage className="ikea">
      <IonContent>
        {!isFetching ? (
          <IonGrid>
            <IonRow>
              <IonCol className="ion-padding-left ion-margin-bottom ion-margin-top ion-margin-right ion-padding-right ">
                <SelectPlaceInput />
              </IonCol>
            </IonRow>
            <IonRow className="ion-padding-left ion-margin-top-half">
              <IonSegment
                scrollable
                onIonChange={e => setSegment(e.detail.value)}
                value={segment}
                className="segment-blue ikea-segment-select">
                <IonSegmentButton value="lm_delivery">
                  <IonLabel>{t('ikea.lm_delivery_charging')}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="customer">
                  <IonLabel>{t('ikea.customer_charging')}</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonRow>
            <IonRow>
              <IonCol>
                {segment === 'customer' && (
                  <>
                    <ChargingReports
                      tickets={tickets}
                      match={match}
                      permissions={permissions}
                      place={selectedPlace}
                      segment={segment}
                    />
                    <AlertReports
                      tickets={tickets}
                      match={match}
                      permissions={permissions}
                      filters={filters}
                      setFilters={setFilters}
                      place={selectedPlace}
                      segment={segment}
                    />
                  </>
                )}
                {segment === 'lm_delivery' && (
                  <>
                    <ChargingReports
                      tickets={tickets}
                      lmDeliveryTags={lmDeliveryTags}
                      match={match}
                      permissions={permissions}
                      filters={filters}
                      setFilters={setFilters}
                      place={selectedPlace}
                      segment={segment}
                    />
                    <AlertReports
                      tickets={tickets}
                      match={match}
                      permissions={permissions}
                      filters={filters}
                      setFilters={setFilters}
                      place={selectedPlace}
                      segment={segment}
                    />
                  </>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Reports;

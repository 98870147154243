import React, {useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import AccessoryInfo from '../../components/accessories/AccessoryInfo';
import {useTranslation} from 'react-i18next';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';


const NewAccessory = props => {
  // Misc vars
  const {match, permissions} = props;
  const {t} = useTranslation('link_app');

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  useTitleEffect(t('accessories.new_accessory'));
  useEffect(() => {
    databases.contacts.info().then(res => {
      if (res.error === 'unauthorized') {
        setIsAuth(false);
      }
    });
  }, [match, t, databases.contacts, setIsAuth]);

  // Create blank accessory
  const doc = {
    _id: '',
    name: '',
    type: '',
    warehouse_ids: [],
    manuals: [],
  };

  return (
    <IonPage>
      <Header title={t('accessories.new_accessory')} backButton />
      <IonContent>
        <div className="container-narrow">
          <AccessoryInfo
            doc={doc}
            match={match}
            history={props.history}
            permissions={permissions}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NewAccessory;

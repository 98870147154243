import React, {useState, useEffect, useContext, useRef} from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonText,
} from '@ionic/react';
import {
  documentTextOutline,
  trashOutline,
  addOutline,
  chevronDownOutline,
  downloadOutline,
  syncOutline,
  sendOutline,
  documentOutline,
} from 'ionicons/icons';
import {
  Paper,
  FormControl,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useToast from '../../hooks/useToast';
import {DatabaseContext} from '../context/DatabaseContext';
import {Link} from 'react-router-dom';
import Loading from '../../components/utils/Loading';
import _ from 'lodash';

const AccessoryInfo = ({match, permissions, doc, history}) => {
  // Misc vars
  const {t} = useTranslation('link_app');
  const toast = useToast();

  // Databases
  const databases = useContext(DatabaseContext);

  // Copy accessory to local state
  const [accessory, setAccessory] = useState(doc);
  useEffect(() => {
    setAccessory(doc);
  }, [match, doc]);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  const fileInput = useRef(null);
  const [newFile, setNewFile] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Add image and caption to accessory
  const handleFileUpload = () => {
    if (
      accessory.files &&
      accessory?.files?.findIndex(x => x.attachment_id === newFile.name) !== -1
    ) {
      toast(
        `${t('toast.attachment')} "${newFile.name}" ${t(
          'toast.attachment_already_added',
        )}`,
      );
    } else {
      const timestamp = Math.round(new Date() / 1000).toString();
      const attachments = {
        ...accessory._attachments,
        [newFile.name !== 'file.pdf'
          ? newFile.name.replace(/ /g, '-')
          : `${timestamp}.jpg`]: {
          // content_type: newFile.type,
          // data: newFile,
          content_type: newFile.data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0],
          data: newFile.data.split(';base64,')[1],
        },
      };
      const files = accessory.files ? [...accessory.files] : [];
      let newId =
        files.length > 0
          ? (
              parseInt(
                Math.max.apply(
                  Math,
                  files.map(x => x.id),
                ),
              ) + 1
            ).toString()
          : '1';
      const newFiles = [
        ...files,
        {
          id: newId,
          attachment_id:
            newFile.name !== 'image.jpg'
              ? newFile.name.replace(/ /g, '-')
              : `${timestamp}.jpg`,
          text: '',
        },
      ];

      const updatedAccessory = {
        ...accessory,
        _attachments: attachments,
        files: newFiles,
      };

      setAccessory(updatedAccessory);
      setIsEdited(true);
      setNewFile({});
    }
  };

  // Handle image delete
  const handleFileDelete = imageId => {
    const files = [...accessory.files];
    const imageIndex = files.findIndex(
      image => image.attachment_id === imageId,
    );
    files.splice(imageIndex, 1);
    const attachments = accessory._attachments;
    delete attachments[imageId];
    setAccessory({...accessory, files: files, _attachments: attachments});
    setIsEdited(true);
  };

  const fileToDataUri = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  // Compress image before upload
  const handleCompressedUpload = e => {
    const fileToConvert = e.target.files[0];
    const fileName = e.target.files[0]?.name;

    setIsLoading(true);
    if (!fileToConvert) {
      setNewFile(null);
      setIsLoading(false);
      return;
    }

    fileToDataUri(fileToConvert).then(dataUri => {
      // setFile({ data: dataUri, name: fileName })
      setNewFile({data: dataUri, name: fileName});
      setIsLoading(false);
      setIsEdited(true);
    });

    // setNewFile(file)
    // new Compressor(img, {
    // 	quality: 0.6, // 0.6 can be used, but it is not recommended to go below
    // 	success: (result) => {
    // 		// result has the compressed file
    // 		setNewFile(result)
    // 	},
    // })
  };

  // Save edited or new accessory
  const handleSave = () => {
    if (match.url !== '/new-accessory') {
      databases.accessories
        .get(accessory._id)
        .then(() => {
          return databases.accessories.put(accessory);
        })
        .then(() => {
          console.log(
            `${t('toast.accessory')} "${accessory.name}" ${t('toast.updated')}`,
          );
          toast(
            `${t('toast.accessory')} "${accessory.name}" ${t('toast.updated')}`,
          );
          setIsEdited(false);
          return databases.accessories.get(accessory._id, {attachments: true});
        })
        .then(doc => {
          // Update edited accessory state to match new _rev
          setAccessory(doc);
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_accessory')} "${accessory.name}":`,
            err,
          );
          toast(
            `${t('toast.error_updating_accessory')} "${accessory.name}"`,
            err,
          );
        });
    } else {
      databases.accessories
        .allDocs()
        .then(result => {
          // Auto incerement _id
          const accessoryCopy = {...accessory};
          const docs = result.rows.filter(row => {
            return !row.id.includes('_design');
          });
          accessoryCopy._id =
            docs.length > 0
              ? (
                  parseInt(
                    Math.max.apply(
                      Math,
                      docs.map(x => x.id),
                    ),
                  ) + 1
                ).toString()
              : '1';

          databases.accessories
            .put(accessoryCopy)
            .then(() => {
              console.log(
                `${t('toast.accessory')} "${accessory.name}" ${t(
                  'toast.updated',
                )}`,
              );
              toast(
                `${t('toast.accessory')} "${accessory.name}" ${t(
                  'toast.updated',
                )}`,
              );
              setIsEdited(false);
              history.push('/accessories');
            })
            .then(() => {
              // Update edited accessory state to blank accessory
              setAccessory(doc);
            })
            .catch(err => {
              console.log(
                `${t('toast.error_updating_accessory')} "${accessory.name}":`,
                err,
              );
              toast(
                `${t('toast.error_updating_accessory')} "${accessory.name}"`,
                err,
              );
            });
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_accessory')} "${accessory.name}":`,
            err,
          );
          toast(
            `${t('toast.error_updating_accessory')} "${accessory.name}"`,
            err,
          );
        });
    }
  };

  const handleDownload = async file_id => {
    setIsLoading(true);
    const attachments = accessory?._attachments;
    if (!_.isEmpty(attachments)) {
      databases.accessories
        .getAttachment(accessory._id, file_id)
        .then(response => {
          if (response) {
            const url = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file_id);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Paper style={{marginTop: '32px'}}>
        <IonGrid className="accessory-info ion-padding-none ion-padding-top ion-padding-right ion-padding-left">
          <IonRow>
            <IonCol className="ion-margin-none">
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div>
                    <label htmlFor="name">{t('accessories.name')}</label>
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    autoComplete="off"
                    value={accessory.name}
                    onChange={e => {
                      setIsEdited(true);
                      setAccessory({...accessory, name: e.target.value});
                    }}
                    disabled={!permissions?.guides}
                  />
                </FormControl>
              </div>
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div>
                    <label htmlFor="type">{t('accessories.type')}</label>
                  </div>
                  <input
                    type="text"
                    id="type"
                    name="type"
                    value={accessory.type}
                    autoComplete="off"
                    onChange={e => {
                      setIsEdited(true);
                      setAccessory({...accessory, type: e.target.value});
                    }}
                    disabled={!permissions?.guides}
                  />
                </FormControl>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonList>
          {match.url === '/new-accessory' && (
            <IonText>
              <p className="ion-margin-top ion-margin-left">
                {t('accessories.save_before_adding_phases')}
              </p>
            </IonText>
          )}
          {accessory.manuals.map((manual, i) => {
            return (
              <IonItem key={i} lines="full">
                <IonItem
                  style={{width: '100%'}}
                  lines="none"
                  routerLink={`/accessories/${accessory._id}/manuals/${manual.id}`}>
                  <IonIcon
                    slot="start"
                    color="primary"
                    icon={documentTextOutline}
                  />
                  <IonLabel className="ion-padding-top-half ion-padding-bottom-half">
                    {manual.title}
                  </IonLabel>
                </IonItem>
                <IonButton
                  slot="end"
                  fill="clear"
                  disabled={!permissions?.canDelete}
                  onClick={() => {
                    const accessoryCopy = {...accessory};
                    const manualIndex = accessoryCopy.manuals.findIndex(
                      x => x.id === manual.id,
                    );
                    accessoryCopy.manuals.splice(manualIndex, 1);
                    setAccessory(accessoryCopy);
                    setIsEdited(true);
                  }}>
                  <IonIcon
                    slot="icon-only"
                    color="medium"
                    icon={trashOutline}
                  />
                </IonButton>
              </IonItem>
            );
          })}
        </IonList>
        {match.url !== '/new-accessory' && (
          <IonButton
            routerLink={`/accessories/${accessory._id}/new-manual`}
            fill="clear"
            color="primary"
            disabled={!permissions?.guides}>
            <IonIcon slot="start" color="primary" icon={addOutline} />
            {t('accessories.add_manual')}
          </IonButton>
        )}
        {match.url !== '/new-accessory' && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={
                <IonIcon icon={chevronDownOutline} color="primary" />
              }>
              <IonText>
                <h3 className="ion-margin-none">
                  {t('app.files')} (
                  {accessory._attachments
                    ? Object.keys(accessory._attachments).length
                    : '0'}
                  )
                </h3>
              </IonText>
            </AccordionSummary>
            <AccordionDetails>
              {accessory._attachments &&
                accessory.files.length > 0 &&
                accessory.files.map((file, i) => {
                  // let attachment
                  // if (accessory._attachments && file.attachment_id !== "") {
                  // 	attachment = accessory._attachments[file.attachment_id]
                  // }
                  return (
                    <IonItem key={i} lines="none" className="ion-margin-bottom">
                      <Link
                        className="attachment-link"
                        to={`/accessories/${accessory._id}/attachments/${file.attachment_id}`}>
                        <IonIcon
                          className="ion-margin-right-half"
                          color="medium"
                          fill="clear"
                          slot="start"
                          icon={newFile.name ? syncOutline : documentOutline}
                        />
                        <IonText className="ion-margin-left">
                          <p className="ion-margin-none">
                            {file.attachment_id}
                          </p>
                        </IonText>
                      </Link>
                      <IonButton
                        size="small"
                        fill="clear"
                        color="medium"
                        onClick={() => handleDownload(file.attachment_id)}>
                        <IonIcon slot="icon-only" icon={downloadOutline} />
                      </IonButton>
                      {/* </a> */}
                      <IonButton
                        disabled={!permissions?.canDelete}
                        className="ion-margin-none"
                        slot="end"
                        onClick={() => handleFileDelete(file.attachment_id)}
                        size="small"
                        fill="clear"
                        color="medium">
                        <IonIcon slot="icon-only" icon={trashOutline} />
                      </IonButton>
                    </IonItem>
                  );
                })}
              {isLoading ? (
                <Loading />
              ) : (
                <div className="file-upload">
                  <div className="file-input-container">
                    <div className="file-input-wrap">
                      {newFile.name && (
                        <p className="ion-margin-none ion-margin-left ion-margin-right-half">
                          <IonIcon
                            className="ion-margin-right-half"
                            slot="start"
                            icon={documentOutline}
                          />
                          {newFile.name}
                        </p>
                      )}
                      <IonButton
                        disabled={!permissions?.guides}
                        size="default"
                        fill="clear"
                        color={newFile.name ? 'medium' : 'primary'}
                        onClick={() => fileInput.current.click()}>
                        <IonIcon
                          className="ion-margin-right-half"
                          slot="start"
                          icon={newFile.name ? syncOutline : documentOutline}
                        />
                        {newFile.name
                          ? t('app.change_file')
                          : t('app.add_file')}
                      </IonButton>
                      <input
                        ref={fileInput}
                        type="file"
                        hidden
                        value=""
                        onChange={e => handleCompressedUpload(e)}
                      />
                    </div>
                    {newFile.name && (
                      <div className="file-input-wrap">
                        <IonButton
                          className="image-upload-button"
                          color="success"
                          size="large"
                          fill="clear"
                          onClick={() => handleFileUpload()}>
                          <IonText
                            className="ion-margin-right-half"
                            color="success">
                            {t('accessories.save_file')}
                          </IonText>
                          <IonIcon slot="end" icon={sendOutline} />
                        </IonButton>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </Paper>
      <div>
        {/* <IonButton style={{ marginTop: "16px", marginBottom: "32px" }} routerLink={"/accessories"} className="ion-margin-right" fill="outline" color="danger">
					{t("app.abort")}
				</IonButton> */}
        <IonButton
          style={{marginTop: '16px', marginBottom: '32px'}}
          disabled={!isEdited}
          onClick={() => handleSave()}
          className="ion-margin-right"
          fill="solid"
          color="success">
          {t('app.save')}
        </IonButton>
      </div>
    </>
  );
};

export default AccessoryInfo;

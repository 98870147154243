import React, {useContext, useEffect} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import UserInfo from '../../components/contacts/UserInfo';
import {useTranslation} from 'react-i18next';
import {UserContext} from '../../components/context/UserContext';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';

const User = props => {
  const {match, permissions} = props;
  const [user, setUser] = useContext(UserContext);
  const {t} = useTranslation('link_app');

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);
  useTitleEffect(user?.metadata?.firstname_lastname);

  useEffect(() => {
    databases.contacts.info().then(res => {
      if (res.error === 'unauthorized') {
        setIsAuth(false);
      }
    });
  }, [match, t, user, setIsAuth, databases.contacts]);

  return (
    <IonPage>
      <Header title={t('nav.profile')} backButton />
      <IonContent>
        <div className="container-narrow">
          <UserInfo
            user={user}
            setUser={setUser}
            match={match}
            history={props.history}
            permissions={permissions}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default User;

import React, {useState, useEffect} from 'react';
import {IonRow, IonCol, IonButton} from '@ionic/react';
import {FormControl} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const CommentField = props => {
  const {t} = useTranslation('link_app');

  const isEdited = props.isEdited;
  const setIsEdited = props.setIsEdited;
  const permissions = props.permissions;

  // Get tab & list names
  const [tabName, setTabName] = useState('');
  const [listName, setListName] = useState('');
  useEffect(() => {
    if (props.tabName && props.listName) {
      setTabName(props.tabName);
      setListName(props.listName);
      if (!props.place[tabName]) {
        props.place[tabName] = {};
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <div className="row ion-margin-top">
        {tabName && listName && (
          <FormControl className="row-input">
            <div>
              <label htmlFor="more-info">{t('place.more_info')}</label>
            </div>
            <textarea
              disabled={!permissions?.places}
              id="more-info"
              name="more-info"
              value={
                props.place[tabName] && props.place[tabName][listName]
                  ? props.place[tabName][listName].comment
                  : ''
              }
              onChange={e => {
                props.setPlace({
                  ...props.place,
                  [tabName]: {
                    ...props.place[tabName],
                    [listName]: {
                      ...props.place[tabName][listName],
                      comment: e.target.value,
                    },
                  },
                });
                setIsEdited(true);
              }}
            />
          </FormControl>
        )}
      </div>
      <IonRow>
        <IonCol class="ion-text-right">
          <IonButton
            disabled={!permissions?.places}
            size="default"
            fill="clear"
            color="medium"
            onClick={() => {
              props.setPlace({
                ...props.place,
                [tabName]: {
                  ...props.place[tabName],
                  [listName]: {...props.place[tabName][listName], comment: ''},
                },
              });
              setIsEdited(true);
            }}>
            {t('app.clear')}
          </IonButton>

          <IonButton
            disabled={!isEdited}
            size="default"
            fill="solid"
            color="success"
            onClick={() => {
              props.savePlaceChanges(props.place);
              setIsEdited(false);
            }}>
            {t('app.save')}
          </IonButton>
        </IonCol>
      </IonRow>
    </>
  );
};
export default CommentField;

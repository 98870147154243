/* eslint-disable import/no-webpack-loader-syntax */
import React, {useState, useContext, useEffect} from 'react';
import {
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonBadge,
  IonButton,
  IonIcon,
  IonSpinner,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import DateTime from '../../../components/utils/DateTime';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  NativeSelect,
  TablePagination,
} from '@material-ui/core';
import {createOutline} from 'ionicons/icons';
import {DatabaseContext} from '../../../components/context/DatabaseContext';
import _ from 'lodash';
import SlaDiff from '../../../components/utils/SlaDiff';
import {StartSlaCounter, GetSla} from '../../../components/utils/SlaComponent';
import {StatusOptions} from '../../../components/tickets/TicketStatusController';
import useTitleEffect from '../../../hooks/useTitle';

const IkeaTickets = props => {
  const {t} = useTranslation('link_app_ikea');
  const {match, isFetching} = props;

  useTitleEffect(t('nav.tickets'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Status options
  // const status_options = StatusOptions.filter((status) => status.value !== "faulted")
  const status_options = StatusOptions;

  // Filters state
  const [filters, setFilters] = useState({
    status: '',
    error: '',
    sla: '',
    isActive: '',
    order: '',
    placeNames: '',
    chargingStation: '',
  });

  // Handle filter change
  const handleFilterChange = (value, filter) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  // Copy place to local state
  const [place, setPlace] = useState();
  useEffect(() => {
    if (!_.isEmpty(props.place)) {
      setPlace(props.place);
    }
  }, [match, props.place]);

  // Fetch tickets
  const [activeTickets, setActiveTickets] = useState([]);
  useEffect(() => {
    if (place && props.tickets) {
      let tempFilteredTickets = [...props.tickets];
      tempFilteredTickets = tempFilteredTickets.filter(ticket => {
        return ticket?.doc?.place?.site_id === place?.doc?.site_id;
      });
      tempFilteredTickets = tempFilteredTickets.filter(ticket => {
        return (
          ticket?.doc?.is_active === true &&
          ticket?.doc?.ticket_status !== 'closed'
        );
      });
      tempFilteredTickets = tempFilteredTickets.filter(ticket => {
        const alertname = ticket.doc?.errors
          ? ticket.doc?.errors[0].ALERTNAME
          : '';
        if (alertname && alertname !== '') {
          if (
            alertname.match('Kempower') &&
            props.chargerTypes.includes('kempower')
          ) {
            return ticket;
          } else if (
            alertname.match('Enersense') &&
            props.chargerTypes.includes('uc')
          ) {
            return ticket;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      setActiveTickets(tempFilteredTickets);
    }
  }, [props.tickets, place]);

  // Filter options
  const [statuses, setStatuses] = useState([]);
  const [errors, setErrors] = useState([]);
  const [slas, setSlas] = useState([]);
  const [placeNames, setPlaceNames] = useState([]);
  const [chargingStations, setChargingStations] = useState([]);

  // Fetch all guides
  const [guides, setGuides] = useState([]);
  useEffect(() => {
    databases.guides
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setGuides(docs);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.guides]);

  // Get place, contact ids, errors etc from ticket
  useEffect(() => {
    activeTickets.forEach(item => {
      if (
        statuses.indexOf(item.doc?.ticket_status) === -1 &&
        item.doc?.ticket_status !== ''
      ) {
        if (!item.doc?.ticket_status || item.doc?.ticket_status === 'faulted') {
          if (statuses.indexOf('faulted') === -1) {
            setStatuses([...statuses, 'faulted']);
          }
        } else {
          setStatuses([...statuses, item.doc?.ticket_status]);
        }
      }
      if (
        errors.findIndex(error => error.id === item.doc?.error?.id) === -1 &&
        !_.isEmpty(item.doc?.error) &&
        item.doc?.error.name !== ''
      ) {
        setErrors([...errors, item.doc?.error]);
      }
      if (
        slas.indexOf(item.doc?.sla) === -1 &&
        item.doc?.sla !== '' &&
        item.doc?.sla !== undefined
      ) {
        setSlas([...slas, item.doc?.sla]);
      }
      if (place) {
        if (
          placeNames.indexOf(place.doc?.name) === -1 &&
          place.doc?.name !== ''
        ) {
          setPlaceNames([...placeNames, place.doc?.name].sort());
        }
        const station = place.doc?.stations.find(
          station =>
            station.id.toString() ===
            item.doc?.errors[0].STATION_ID?.toString(),
        );
        if (station) {
          if (
            chargingStations.indexOf(station.name) === -1 &&
            station.name !== ''
          ) {
            setChargingStations([...chargingStations, station.name].sort());
          }
        }
      }
    });
  }, [place, activeTickets, statuses, errors, slas, placeNames]);

  // Filters clearing button
  const clearButton = Object.values(filters).find(x => x !== '') ? (
    <IonButton
      color="primary"
      fill="clear"
      className="ion-float-right"
      onClick={() => {
        setFilters({
          status: '',
          error: '',
          sla: '',
          isActive: '',
          order: filters.order,
          placeNames: '',
          chargingStation: '',
        });
      }}>
      {t('app.clear_filters')}
    </IonButton>
  ) : (
    ''
  );

  // Handle the filtering
  const [filteredTickets, setFilteredTickets] = useState([]);
  useEffect(() => {
    let filteredTicketsCopy = [...activeTickets];
    if (filters.status !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        if (filters.status === 'faulted') {
          return (
            !item.doc?.ticket_status || item.doc?.ticket_status === 'faulted'
          );
        }
        return item.doc.ticket_status === filters.status;
      });
    }
    if (filters.sla !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return item.doc.sla?.toString() === filters.sla;
      });
    }
    if (filters.error !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return item.doc.error.name === filters.error;
      });
    }
    if (filters.placeNames !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        if (place) {
          return place.doc?.name.toString() === filters.placeNames;
        }
        return false;
      });
    }
    if (filters.chargingStation !== '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        if (place) {
          const station = place.doc?.stations.find(
            station =>
              station.id.toString() ===
              item.doc?.errors[0].STATION_ID?.toString(),
          );
          if (station) {
            return station.name.toString() === filters.chargingStation;
          }
        }
        return false;
      });
    }
    if (filters.isActive === '') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return item.doc.is_active === true;
      });
    } else if (filters.isActive === 'false') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return item.doc.is_active === false;
      });
    } else if (filters.isActive === 'all') {
      filteredTicketsCopy = filteredTicketsCopy.filter(item => {
        return item;
      });
    }
    setFilteredTickets(filteredTicketsCopy);
  }, [filters, activeTickets]);

  // RowLimit per page handlers
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const handleChangePage = newPage => {
    if (newPage.currentTarget.ariaLabel === 'Next page') setPage(page + 1);
    else setPage(page - 1);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Group tickets by site id

  const GetErrorName = props => {
    const ticket = props.item;
    let errorName = ticket.doc?.error.name ?? '';
    if (errorName === '') {
      if (ticket?.doc?.error?.id === '' && guides?.length > 0) {
        const alertname = ticket.doc?.errors
          ? ticket.doc?.errors[0].ALERTNAME
          : '';
        if (alertname && alertname !== '') {
          let alertTypes = [...guides];
          if (alertname.match('Kempower')) {
            alertTypes = alertTypes.filter(val => val.doc?.name.match('DC -'));
          } else if (alertname.match('Enersense')) {
            alertTypes = alertTypes.filter(val => val.doc?.name.match('AC -'));
          }
          let alertNameFound = false;
          alertTypes.forEach((val, i) => {
            const guideErrorName = val.doc?.name?.split(/AC - |DC - /).pop();
            if (alertname.toLowerCase().match(guideErrorName.toLowerCase())) {
              errorName = val.doc?.name;
              alertNameFound = true;
              return;
            }
          });
          if (!alertNameFound) {
            let chargerType = '';
            if (alertname.match('Kempower')) {
              chargerType = 'DC';
            } else if (alertname.match('Enersense')) {
              chargerType = 'AC';
            }
            const guide = guides.find(
              guide => guide.doc.name === chargerType + ' - Faulted',
            );
            errorName = guide.doc?.name;
          }
        }
      }
    }
    return <IonText>{errorName !== '' ? errorName : '-'}</IonText>;
  };

  return (
    <IonGrid className="ion-padding-none">
      <IonRow className="ion-padding-left ">
        <IonCol>
          <h2 className="ion-margin-none ion-margin-top ion-margin-bottom-half">
            {t('ikea.tickets.active_issues')}
          </h2>
        </IonCol>
        <IonCol className="ion-padding-left ion-padding-right">
          {clearButton}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-padding-left ion-padding-right">
          <TableContainer>
            <Table className="tickets-table">
              <TableHead className="tickets-table-head">
                <TableRow>
                  <TableCell>
                    <span style={{position: 'relative', top: '2px'}}>
                      {t('tickets.id')}
                    </span>
                  </TableCell>
                  <TableCell>
                    <FormControl className="">
                      <InputLabel htmlFor="status">
                        {t('ikea.tickets.status')}
                      </InputLabel>
                      <NativeSelect
                        value={filters.status}
                        onChange={e =>
                          handleFilterChange(e.target.value, 'status')
                        }
                        inputProps={{
                          name: 'status',
                          id: 'status',
                        }}
                        className={
                          filters.status === '' ? 'input-color-transparent' : ''
                        }>
                        <option aria-label="All" value="">
                          {t('ikea.tickets.all')}
                        </option>
                        {status_options
                          .filter(item => statuses.includes(item.value))
                          .map((option, i) => {
                            return (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            );
                          })}
                      </NativeSelect>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl className="">
                      <InputLabel htmlFor="placeNames">
                        {t('ikea.tickets.location')}
                      </InputLabel>
                      <NativeSelect
                        value={filters.placeNames}
                        onChange={e =>
                          handleFilterChange(e.target.value, 'placeNames')
                        }
                        inputProps={{
                          name: 'placeNames',
                          id: 'placeNames',
                        }}
                        className={
                          filters.placeNames === ''
                            ? 'input-color-transparent'
                            : ''
                        }>
                        <option aria-label="All" value="">
                          {t('ikea.tickets.all')}
                        </option>
                        {placeNames.map((item, i) => {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl className="">
                      <InputLabel htmlFor="chargingStation">
                        {t('ikea.tickets.charging_station')}
                      </InputLabel>
                      <NativeSelect
                        value={filters.chargingStation}
                        onChange={e =>
                          handleFilterChange(e.target.value, 'chargingStation')
                        }
                        inputProps={{
                          name: 'chargingStation',
                          id: 'chargingStation',
                        }}
                        className={
                          filters.chargingStation === ''
                            ? 'input-color-transparent'
                            : ''
                        }>
                        <option aria-label="All" value="">
                          {t('ikea.tickets.all')}
                        </option>
                        {chargingStations.map((item, i) => {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl className="">
                      <InputLabel htmlFor="sla">{t('tickets.sla')}</InputLabel>
                      <NativeSelect
                        value={filters.sla}
                        onChange={e =>
                          handleFilterChange(e.target.value, 'sla')
                        }
                        inputProps={{
                          name: 'sla',
                          id: 'sla',
                        }}
                        className={
                          filters.sla === '' ? 'input-color-transparent' : ''
                        }>
                        <option aria-label="All" value="">
                          {t('ikea.tickets.all')}
                        </option>
                        {slas.map((item, i) => {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl className="">
                      <InputLabel htmlFor="error">
                        {t('ikea.tickets.error_type')}
                      </InputLabel>
                      <NativeSelect
                        value={filters.error}
                        onChange={e =>
                          handleFilterChange(e.target.value, 'error')
                        }
                        inputProps={{
                          name: 'error',
                          id: 'error',
                        }}
                        className={
                          filters.error === '' ? 'input-color-transparent' : ''
                        }>
                        <option aria-label="All" value="">
                          {t('ikea.tickets.all')}
                        </option>
                        {errors.map((item, i) => {
                          return (
                            <option key={i} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </TableCell>
                  <TableCell>{t('ikea.tickets.errors')}</TableCell>
                  <TableCell>{t('ikea.tickets.handlers')}</TableCell>
                  <TableCell>
                    <span
                      className="triangle-wrap"
                      onClick={() => {
                        if (filters.order === '') {
                          setFilters({...filters, order: 'ascending'});
                        } else {
                          setFilters({...filters, order: ''});
                        }
                      }}>
                      {t('ikea.tickets.created')}
                      <span
                        className={
                          filters.order === '' ? 'triangle-down' : 'triangle-up'
                        }
                      />
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTickets
                  .sort((a, b) => {
                    if (filters.order === '') {
                      return parseInt(b.id) - parseInt(a.id);
                    } else {
                      return parseInt(a.id) - parseInt(b.id);
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, i) => {
                    // Place operator
                    // const place = places.find((place) => place.doc?.site_id === item.doc?.place?.site_id)
                    // let operator;
                    let station;
                    if (place) {
                      // operator = place.doc?.systems?.telecommunications?.values[0]?.text
                      station = place.doc?.stations.find(
                        station =>
                          station.id?.toString() ===
                            item.doc?.errors[0]?.STATION_ID?.toString() ||
                          station.id?.toString() ===
                            item.doc?.station?.id?.toString(),
                      );
                    }

                    // Ticket Handlers
                    let handlers = [];
                    if (item.doc?.log?.length > 0) {
                      item.doc.log.forEach(logElem => {
                        if (
                          handlers.filter(h => h.name === logElem.user.name)
                            .length < 1
                        ) {
                          handlers.push({
                            name: logElem.user.name,
                            metadata: {
                              firstname_lastname:
                                logElem.user.metadata?.firstname_lastname ??
                                '-',
                            },
                          });
                        }
                      });
                    }

                    return (
                      <TableRow
                        key={i}
                        className={
                          item.doc.is_active === false &&
                          filters.isActive === 'all'
                            ? 'history-row'
                            : ''
                        }
                        component="tr"
                        hover={true}>
                        <TableCell>{item.doc._id}</TableCell>
                        <TableCell>
                          <IonBadge
                            className={`status-badge-select status-color-${
                              item?.doc.ticket_status || 'faulted'
                            }`}>
                            <span className="status-badge-color"></span>
                            {item.doc?.ticket_status
                              ? t(
                                  `ikea.ticket_statuses.${item.doc?.ticket_status}`,
                                )
                              : t('ikea.ticket_statuses.faulted')}
                          </IonBadge>
                        </TableCell>
                        <TableCell>
                          {place?.doc?.name ? place.doc?.name : '-'}
                        </TableCell>
                        <TableCell>
                          {station?.name ? station.name : '-'}
                        </TableCell>
                        <TableCell>
                          {item.doc?.sla === '' && '-'}
                          {StartSlaCounter(
                            item.doc?.sla,
                            item.doc?.time_when_created,
                          ) ? (
                            <>
                              {item.doc?.sla !== '' &&
                                item.doc?.is_active === false && (
                                  <SlaDiff
                                    id={item.doc?._id}
                                    end={item.doc?.time_when_completed}
                                    start={item.doc?.time_when_created}
                                    sla={item.doc?.sla}
                                  />
                                )}
                              {item.doc?.sla !== '' &&
                                item.doc?.time_when_completed !== '' &&
                                item.doc?.is_active === true && (
                                  <SlaDiff
                                    id={item.doc?._id}
                                    end={item.doc?.time_when_completed}
                                    start={item.doc?.time_when_created}
                                    sla={item.doc?.sla}
                                  />
                                )}
                              {item.doc?.sla !== '' &&
                                item.doc?.time_when_completed === '' &&
                                item.doc?.time_when_created !== '' &&
                                item.doc?.is_active === false && (
                                  <IonBadge color="light">
                                    {item.doc?.sla}
                                  </IonBadge>
                                )}
                              {item.doc?.sla !== '' &&
                                item.doc?.time_when_completed === '' &&
                                item.doc?.time_when_created !== '' &&
                                item.doc?.is_active === true &&
                                GetSla(
                                  item.doc?.sla,
                                  item.doc.time_when_created,
                                  true,
                                )}
                            </>
                          ) : (
                            <IonBadge color="light">
                              {t('tickets.sla')} {item.doc?.sla}
                            </IonBadge>
                          )}
                        </TableCell>
                        <TableCell>
                          <GetErrorName item={item} />
                        </TableCell>
                        <TableCell>
                          {item.doc?.errors.length > 0
                            ? item.doc?.errors.length
                            : '-'}
                        </TableCell>
                        <TableCell className="ticket-table-handlers">
                          {handlers.map((handler, j) => {
                            return (
                              <IonButton
                                fill="clear"
                                style={{height: 'unset'}}
                                size={'small'}
                                key={'handler-' + i + '-' + j}>
                                <div
                                  className="icon-wrap primary ion-margin-none"
                                  slot="start">
                                  {handler.metadata &&
                                  handler.metadata.firstname_lastname &&
                                  handler.metadata.firstname_lastname !== '' &&
                                  handler.metadata.firstname_lastname.split(' ')
                                    .length >= 1 ? (
                                    <>
                                      {handler.metadata.firstname_lastname.split(
                                        ' ',
                                      ).length >= 1 &&
                                        handler.metadata.firstname_lastname
                                          .split(' ')[0]
                                          .charAt(0)}
                                      {handler.metadata.firstname_lastname.split(
                                        ' ',
                                      ).length > 1 &&
                                        handler.metadata.firstname_lastname
                                          .split(' ')[1]
                                          .charAt(0)}
                                    </>
                                  ) : (
                                    '?'
                                  )}
                                </div>
                                {/* <IonIcon className="ion-margin-left-half" slot="end" icon={ellipsisVertical} color="medium" style={{ fontSize: "22px" }} /> */}
                              </IonButton>
                            );
                          })}
                        </TableCell>
                        <TableCell>
                          <DateTime timestamp={item.doc?.time_when_created} />
                          {item.doc?.log?.length > 0 &&
                            item.doc?.log[0].app === 'ikea' && (
                              <IonButton
                                disabled={item.doc?.log[0]?.app !== 'ikea'}
                                className="ion-margin-left"
                                style={{float: 'right'}}
                                slot="end"
                                onClick={() => props.openEditTicketModal(item)}
                                size="small"
                                fill="clear"
                                color="medium">
                                Update issue
                                <IonIcon slot="end" icon={createOutline} />
                              </IonButton>
                            )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {filteredTickets.length === 0 && !isFetching && (
                  <TableRow style={{height: 53 * 1}}>
                    <TableCell colSpan={11}>
                      {t('ikea.tickets.no_issues')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {isFetching && <IonSpinner name="dots"></IonSpinner>}
            {filteredTickets.length > 5 && (
              <TablePagination
                colSpan={9}
                rowsPerPageOptions={[5]}
                component="div"
                count={filteredTickets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={({from, to, count}) => {
                  return (
                    '' + from + '-' + to + ' yhteensä ' + count + ' tuloksesta'
                  );
                }}
                labelRowsPerPage={'Riviä sivulla:'}
              />
            )}
          </TableContainer>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default IkeaTickets;

const hoursMinutesAndSeconds = milliseconds => {
  let totalSeconds = Math.floor(milliseconds / 1000);
  let totalMinutes = Math.floor(totalSeconds / 60);
  let totalHours = Math.floor(totalMinutes / 60);

  let seconds = totalSeconds % 60;
  let minutes = totalMinutes % 60;
  let hours = totalHours;

  // Add leading zeroes if necessary
  //   seconds = seconds < 10 ? '0' + seconds : seconds;
  //   minutes = minutes < 10 ? '0' + minutes : minutes;

  // Only show hours if necessary
  if (hours > 0) {
    // hours = hours < 10 ? hours : hours;
    return `${hours}h ${minutes}min ${seconds}s`;
  } else {
    return `${minutes}m ${seconds}s`;
  }
};

export default hoursMinutesAndSeconds;

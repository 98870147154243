import React, {useState, useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import ManualInfo from '../../components/accessories/ManualInfo';
import {useTranslation} from 'react-i18next';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import _ from 'lodash';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';


const NewManual = props => {
  // Misc vars
  const {match, permissions} = props;
  const {t} = useTranslation('link_app');

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  useTitleEffect(t('accessories.new_manual'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Fetch the accessory
  const [doc, setDoc] = useState({});

  useEffect(() => {
    if (_.isEmpty(doc)) {
      databases.accessories
        .get(match.params.id)
        .then(result => {
          let newId =
            result.manuals.length > 0
              ? (
                  parseInt(
                    Math.max.apply(
                      Math,
                      result.manuals.map(x => x.id),
                    ),
                  ) + 1
                ).toString()
              : '1';
          const newManual = {
            id: newId,
            title: '',
            description: '',
            phases: [],
          };
          setDoc({...result, manuals: [...result.manuals, newManual]});
        })
        .catch(err => {
          console.log(err);
          if (err.status === 401) {
            setIsAuth(false);
          }
        });
    }
  }, [doc, databases.accessories, match, props.isLoading, setIsAuth]);

  // Get manual index
  const [manualIndex, setManualIndex] = useState(null);
  useEffect(() => {
    if (doc._id && doc.manuals && doc.manuals.length > 0) {
      setManualIndex(doc.manuals.length - 1);
    }
  }, [doc]);

  return (
    <IonPage>
      <Header title={t('accessories.new_manual')} backButton />
      <IonContent>
        {doc._id && manualIndex !== null ? (
          <div className="container-narrow">
            <ManualInfo
              doc={doc}
              setDoc={setDoc}
              manualIndex={manualIndex}
              setManualIndex={setManualIndex}
              match={match}
              history={props.history}
              permissions={permissions}
            />
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default NewManual;

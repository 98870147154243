import React, {useState, useContext, useEffect} from 'react';
import {IonPage, IonContent, IonCol, IonGrid, IonRow} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import Loading from '../../components/utils/Loading';
import 'mapbox-gl/dist/mapbox-gl.css';
import useWindowSize from '../../hooks/useWindowSize';
import ControlRoomMonitoring from '../../components/tickets/ControlRoomMonitoring';
import {UserContext} from '../../components/context/UserContext';
import useTitleEffect from '../../hooks/useTitle';


const MultiScreenMonitoring = props => {
  const {t} = useTranslation('link_app');
  const {match} = props;
  const [, height] = useWindowSize();
  const [multiScreen, setMultiScreen] = useState(false);
  const [user] = useContext(UserContext);

  useTitleEffect(t('nav.control_room'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  // const [, setIsAuth] = useContext(AuthContext)

  // Fetching state
  const [isFetching, setIsFetching] = useState(true);

  // Fetch places
  const [places, setPlaces] = useState([]);
  const [currentPlace, setCurrentPlace] = useState(null);
  useEffect(() => {
    databases.places
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        // setPlaces(docs)
        // setCurrentPlace(docs[0].doc)
        setPlaces(docs);
        setCurrentPlace({
          site_id: 'all',
          name: 'Kaikki',
        });
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.places, match]);

  const handleMonitorPlaceChange = placeId => {
    if (placeId === 'all') {
      setCurrentPlace({
        site_id: 'all',
        name: 'Kaikki',
      });
    } else if (placeId !== null) {
      let newPlace = places.find(
        place => place.doc.site_id.toString() === placeId.toString(),
      );
      setCurrentPlace(newPlace.doc);
    } else {
      console.warn('No panel ID found');
    }
  };

  const [currentACPanelIndex, setCurrentACPanelIndex] = useState(0);
  const [currentDCPanelIndex, setCurrentDCPanelIndex] = useState(0);
  const [stationPanels, setStationPanels] = useState([]);

  useEffect(() => {
    if (currentPlace !== null && currentPlace.site_id !== 'all') {
      const stationsWithMonitoring = currentPlace.stations.filter(
        station => station?.monitoring_panels?.control_room,
      );
      const ACStations = stationsWithMonitoring.filter(
        station => station.type === 'uc',
      );
      const DCStations = stationsWithMonitoring.filter(
        station => station.type === 'kempower' || station.type === 'ocpp',
      );

      let acPanels = [];
      let dcPanels = [];

      if (ACStations.length > 0) {
        ACStations.forEach((station, i) => {
          acPanels.push({
            type: station.type,
            name: station.name,
            panelID: station.monitoring_panels.control_room,
            station_alias: station.station_alias ?? null,
          });
        });
      }

      if (DCStations.length > 0) {
        DCStations.forEach((station, i) => {
          if (station.monitoring_panels.control_room)
            dcPanels.push({
              type: station.type,
              name: station.name,
              panelID: station.monitoring_panels.control_room,
              station_alias: station.station_alias ?? null,
            });
          if (station.monitoring_panels.control_room_satellite_status)
            dcPanels.push({
              type: station.type,
              name: station.name,
              panelID: station.monitoring_panels.control_room_satellite_status,
              station_alias: station.station_alias ?? null,
            });
        });
      }

      setStationPanels({ac: acPanels, dc: dcPanels});

      setCurrentACPanelIndex(0);
      setCurrentDCPanelIndex(0);
    }
    if (currentPlace !== null && currentPlace.site_id === 'all') {
      setStationPanels({
        ac: [
          {
            type: 'uc',
            name: 'AC Latausasemat',
            panelID: '5',
          },
        ],
        dc: [
          {
            type: 'kempower',
            name: 'DC Latausasemat',
            panelID: '50',
          },
          {
            type: 'ocpp',
            name: 'DC Latausasemat',
            panelID: '50',
          },
        ],
      });
    }
  }, [currentPlace]);

  const handleACMonitorStationChange = index => {
    if (index < stationPanels?.ac?.length) {
      setCurrentACPanelIndex(index);
      // setCurrentPlace(station)
    } else {
      console.warn('No panel ID found');
    }
  };
  const handleDCMonitorStationChange = index => {
    if (index < stationPanels?.dc?.length) {
      setCurrentDCPanelIndex(index);
      // setCurrentPlace(station)
    } else {
      console.warn('No panel ID found');
    }
  };
  return (
    <IonPage className="multiscreen-monitoring-page">
      <IonContent>
        {!isFetching && match.url === '/multiscreen-monitoring' ? (
          <IonGrid>
            <IonRow>
              <IonCol
                className="half-n-half control-room-monitoring"
                size=""
                style={{height: `${height - 130}px`}}>
                <ControlRoomMonitoring
                  user={user}
                  match={match}
                  places={places}
                  stationPanels={stationPanels}
                  currentACPanelIndex={currentACPanelIndex}
                  currentDCPanelIndex={currentDCPanelIndex}
                  handleACMonitorStationChange={handleACMonitorStationChange}
                  handleDCMonitorStationChange={handleDCMonitorStationChange}
                  currentPlace={currentPlace}
                  handleMonitorPlaceChange={handleMonitorPlaceChange}
                  multiScreen={multiScreen}
                  setMultiScreen={setMultiScreen}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default MultiScreenMonitoring;

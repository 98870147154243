import React, {useContext, useEffect, useState} from 'react';
import Header from '../components/navigation/Header';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonList,
  IonItem,
  IonButton,
} from '@ionic/react';
import {Paper, FormControl, TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {UserContext} from '../components/context/UserContext';
import {DatabaseContext} from '../components/context/DatabaseContext';
import {AuthContext} from '../components/context/AuthContext';
import useTitleEffect from '../hooks/useTitle';

const Settings = props => {
  const {match, permissions} = props;
  const [user, setUser] = useContext(UserContext);
  const {t} = useTranslation('link_app');

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  useTitleEffect(t('app.settings'));

  useEffect(() => {
    databases.contacts.info().then(res => {
      if (res.error === 'unauthorized') {
        setIsAuth(false);
      }
    });
  }, [match, t, user, setIsAuth, databases.contacts]);

  const [panels, setPanels] = useState({
    ac: [
      {
        type: 'uc',
        name: 'AC Latausasemat',
        panelID: '5',
      },
      {
        type: 'uc',
        name: 'AC Latausasemat 2',
        panelID: '5',
      },
    ],
    dc: [
      {
        type: 'kempower',
        name: 'DC Latausasemat',
        panelID: '50',
      },
    ],
  });

  // Save edited settings
  const handleSave = () => {
    console.log('save settings');
    // databases.contacts
    // 	.get(user.name)
    // 	.then((result) => {
    // 		const doc = {
    // 			...result,
    // 			roles: user.roles,
    // 			name: user.metadata.firstname_lastname,
    // 			phone_number: user.metadata.phone_number,
    // 			email: user.name,
    // 			title: user.metadata.title,
    // 			company: user.metadata.company,
    // 			auto_mode: user.metadata.auto_mode,
    // 			dark_mode: user.metadata.dark_mode,
    // 		}
    // 		return databases.contacts.put(doc)
    // 	})
    // 	.then(() => {
    // 		console.log(t("toast.user_updated"))
    // 		toast(t("toast.user_updated"))
    // 		setIsEdited(false)

    // 		if (isPwActive && newPw.length >= 12) {
    // 			databases.users
    // 				.get(`org.couchdb.user:${user.name}`)
    // 				.then((result) => {
    // 					const editedUser = { ...result, password: newPw }
    // 					return databases.users.put(editedUser)
    // 				})
    // 				.then((result) => {
    // 					setIsPwActive(false)
    // 					setTimeout(() => window.location.reload(), 1000)
    // 				})
    // 				.catch((err) => {
    // 					console.log(err)
    // 					toast(t("toast.error_updating_user"), err)
    // 				})
    // 		} else if (isPwActive && newPw.length < 12) {
    // 			toast(t("toast.password_too_short"))
    // 		}
    // 	})
    // 	.catch((err) => {
    // 		console.log(t("toast.error_updating_user:", err))
    // 		toast(t("toast.error_updating_user"), err)
    // 	})
  };

  return (
    <IonPage>
      <Header title={t('nav.settings')} backButton />
      <IonContent>
        <div className="container-narrow">
          <Paper style={{marginTop: '32px'}}>
            <IonItem lines="none" className="ion-margin-top">
              <IonText>
                <h1 className="ion-margin-none">{t('nav.settings')}</h1>
              </IonText>
            </IonItem>
            <IonList>
              <IonItem lines="full" className="ion-margin-top">
                <IonText>
                  <h2 className="ion-margin-none">
                    {t('settings.control_room_panels')}
                  </h2>
                </IonText>
              </IonItem>
              <IonItem lines="none" className="ion-margin-top">
                <IonText>
                  <h3 className="ion-margin-none">{t('settings.ac_panels')}</h3>
                </IonText>
              </IonItem>
              {panels?.ac?.length > 0 &&
                panels?.ac?.map((panel, i) => {
                  return (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <FormControl>
                            <div>
                              <label htmlFor="name">{t('settings.type')}</label>
                            </div>
                            <TextField
                              id="name"
                              value={panel.type ? panel.type : ''}
                              onChange={e => {
                                setIsEdited(true);
                                // setUser({ ...user, metadata: { ...user.metadata, firstname_lastname: e.target.value } })
                              }}
                              disabled={!permissions?.canDelete}
                            />
                          </FormControl>
                          <FormControl>
                            <div>
                              <label htmlFor="name">{t('settings.name')}</label>
                            </div>
                            <TextField
                              id="name"
                              value={panel.name ? panel.name : ''}
                              onChange={e => {
                                setIsEdited(true);
                                // setUser({ ...user, metadata: { ...user.metadata, firstname_lastname: e.target.value } })
                              }}
                              disabled={!permissions?.canDelete}
                            />
                          </FormControl>
                          <FormControl>
                            <div>
                              <label htmlFor="name">
                                {t('settings.panel_id')}
                              </label>
                            </div>
                            <TextField
                              id="name"
                              value={panel.panelID ? panel.panelID : ''}
                              onChange={e => {
                                setIsEdited(true);
                                // setUser({ ...user, metadata: { ...user.metadata, firstname_lastname: e.target.value } })
                              }}
                              disabled={!permissions?.canDelete}
                            />
                          </FormControl>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  );
                })}
              <IonItem lines="none" className="ion-margin-top">
                <IonText>
                  <h3 className="ion-margin-none">{t('settings.dc_panels')}</h3>
                </IonText>
              </IonItem>
              {panels?.dc?.length > 0 &&
                panels?.dc?.map((panel, i) => {
                  return (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <FormControl>
                            <div>
                              <label htmlFor="name">{t('settings.type')}</label>
                            </div>
                            <TextField
                              id="name"
                              value={panel.type ? panel.type : ''}
                              onChange={e => {
                                setIsEdited(true);
                                // setUser({ ...user, metadata: { ...user.metadata, firstname_lastname: e.target.value } })
                              }}
                              disabled={!permissions?.canDelete}
                            />
                          </FormControl>
                          <FormControl>
                            <div>
                              <label htmlFor="name">{t('settings.name')}</label>
                            </div>
                            <TextField
                              id="name"
                              value={panel.name ? panel.name : ''}
                              onChange={e => {
                                setIsEdited(true);
                                // setUser({ ...user, metadata: { ...user.metadata, firstname_lastname: e.target.value } })
                              }}
                              disabled={!permissions?.canDelete}
                            />
                          </FormControl>
                          <FormControl>
                            <div>
                              <label htmlFor="name">
                                {t('settings.panel_id')}
                              </label>
                            </div>
                            <TextField
                              id="name"
                              value={panel.panel_id ? panel.panel_id : ''}
                              onChange={e => {
                                setIsEdited(true);
                                // setUser({ ...user, metadata: { ...user.metadata, firstname_lastname: e.target.value } })
                              }}
                              disabled={!permissions?.canDelete}
                            />
                          </FormControl>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  );
                })}
            </IonList>
            <IonList>
              <IonItem lines="full" className="ion-margin-top">
                <IonText>
                  <h2 className="ion-margin-none">Muut asetukset</h2>
                </IonText>
              </IonItem>
              <IonItem lines="none">
                <IonText>stuff</IonText>
              </IonItem>
              <IonItem lines="none">
                <IonText>more stuff</IonText>
              </IonItem>
            </IonList>
          </Paper>
          <div>
            {/* <IonButton
                            style={{ marginTop: "16px", marginBottom: "32px" }}
                            onClick={() => {
                                setUser(initialState)
                            }}
                            className="ion-margin-right"
                            fill="outline"
                            color="danger"
                        >
                            {t("app.abort")}
                        </IonButton> */}
            <IonButton
              style={{marginTop: '16px', marginBottom: '32px'}}
              disabled={!isEdited}
              onClick={() => handleSave()}
              className="ion-margin-right"
              fill="solid"
              color="success">
              {t('app.save')}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Settings;

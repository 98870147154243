import React, {useState, useEffect, useContext} from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonText,
  IonReorder,
  IonReorderGroup,
} from '@ionic/react';
import {
  documentTextOutline,
  addOutline,
  trashOutline,
  reorderTwoOutline,
} from 'ionicons/icons';
import {Paper, FormControl, Slide, NativeSelect} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useToast from '../../hooks/useToast';
import MissingValueAlert from '../utils/MissingValueAlert';
import {DatabaseContext} from '../context/DatabaseContext';

const ErrorTypeInfo = ({match, permissions, doc, history, accessories}) => {
  // Misc vars
  const {t} = useTranslation('link_app');
  const toast = useToast();

  // Databases
  const databases = useContext(DatabaseContext);

  // Copy error type to local state
  const [errorType, setErrorType] = useState(doc);
  useEffect(() => {
    setErrorType(doc);
  }, [match, doc]);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  // Save edited or new accessory
  const handleSave = () => {
    if (match.path !== '/new-error-type') {
      databases.guides
        .get(errorType._id)
        .then(() => {
          return databases.guides.put(errorType);
        })
        .then(() => {
          console.log(
            `${t('toast.error_type')} "${errorType.name}" ${t(
              'toast.updated',
            )}`,
          );
          toast(
            `${t('toast.error_type')} "${errorType.name}" ${t(
              'toast.updated',
            )}`,
          );
          setIsEdited(false);
          return databases.guides.get(errorType._id, {attachments: true});
        })
        .then(doc => {
          history.push('/accessories');
          // Update edited error ype state to match new _rev
          setErrorType(doc);
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_error_type')} "${errorType.name}":`,
            err,
          );
          toast(
            `${t('toast.error_updating_error_type')} "${errorType.name}"`,
            err,
          );
        });
    } else {
      databases.guides
        .allDocs()
        .then(result => {
          // Auto incerement _id
          const error = {...errorType};
          const docs = result.rows.filter(row => {
            return !row.id.includes('_design');
          });
          error._id =
            docs.length > 0
              ? (
                  parseInt(
                    Math.max.apply(
                      Math,
                      docs.map(x => x.id),
                    ),
                  ) + 1
                ).toString()
              : '1';
          databases.guides
            .put(error)
            .then(() => {
              console.log(
                `${t('toast.error_type')} ${errorType.name} ${t(
                  'toast.updated',
                )}`,
              );
              toast(
                `${t('toast.error_type')} ${errorType.name} ${t(
                  'toast.updated',
                )}`,
              );
              setIsEdited(false);
              history.push('/accessories');
            })
            .then(() => {
              // Update edited error type state to blank error type
              setErrorType(doc);
            })
            .catch(err => {
              console.log(
                `${t('toast.error_updating_error_type')} ${errorType.name}:`,
                err,
              );
              toast(
                `${t('toast.error_updating_error_type')} ${errorType.name}`,
                err,
              );
            });
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_error_type')} ${errorType.name}:`,
            err,
          );
          toast(
            `${t('toast.error_updating_error_type')} ${errorType.name}`,
            err,
            err,
          );
        });
    }
  };

  // Handle accessory selection change
  const handleAccessoryChange = (prevValue, newValue) => {
    const index = errorType.accessories.findIndex(
      accessory => accessory.id === prevValue.id,
    );
    if (index > -1) {
      const accessories = [...errorType.accessories];
      if (newValue !== '') {
        accessories[index] = JSON.parse(newValue);
        setErrorType({...errorType, accessories: accessories});
        setIsEdited(true);
      } else {
        accessories.splice(index, 1);
        setErrorType({...errorType, accessories: accessories});
        setIsEdited(true);
      }
    }
  };

  // Add blank phase to state
  const addPhase = () => {
    const phases = [...errorType.phases];
    let newId =
      phases.length > 0
        ? (
            parseInt(
              Math.max.apply(
                Math,
                phases.map(x => x.id),
              ),
            ) + 1
          ).toString()
        : '1';
    phases.splice(phases.length, 0, {id: newId, text: ''});
    setErrorType({...errorType, phases: phases});
    setIsEdited(true);
  };

  // Remove phase from state
  const removePhase = phaseId => {
    const phases = [...errorType.phases];
    phases.splice(
      phases.findIndex(phase => phase.id === phaseId),
      1,
    );
    setErrorType({...errorType, phases: phases});
    setIsEdited(true);
  };

  // Handle error type phase reordering
  const handleReorder = e => {
    const phases = [...errorType.phases];
    const tmp = phases[e.detail.from - 1];
    phases[e.detail.from - 1] = phases[e.detail.to - 1];
    phases[e.detail.to - 1] = tmp;
    setIsEdited(true);
    e.detail.complete();
    setErrorType({...errorType, phases: phases});
  };

  return (
    <>
      <Paper style={{marginTop: '32px'}}>
        <IonGrid className="accessory-info ion-padding-none ion-padding-top ion-padding-right ion-padding-left">
          <IonRow>
            <IonCol className="ion-margin-none">
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div>
                    <label htmlFor="name">{t('error_types.name')}</label>
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    autoComplete="off"
                    value={errorType.name}
                    onChange={e => {
                      setIsEdited(true);
                      setErrorType({...errorType, name: e.target.value});
                    }}
                    disabled={!permissions?.guides}
                  />
                </FormControl>
              </div>
              {errorType.accessories.map((accessory, i) => {
                return (
                  <div className="ticket-step-row" key={i}>
                    <FormControl className="ticket-step-input">
                      <div>
                        <label htmlFor="accessory">
                          {t('error_types.accessory')} {i + 1}
                          {accessories.findIndex(
                            item => item.doc._id === accessory.id,
                          ) === -1 && <MissingValueAlert />}{' '}
                          {/* Alert user if initial value is deleted from database */}
                        </label>
                      </div>
                      <NativeSelect
                        value={JSON.stringify(accessory)}
                        onChange={e => {
                          handleAccessoryChange(accessory, e.target.value);
                        }}
                        inputProps={{
                          name: 'accessory',
                          id: 'accessory',
                        }}
                        disabled={!permissions?.guides}>
                        <option aria-label="None" value="" />
                        <option value={JSON.stringify(accessory)}>
                          {accessory.name}
                        </option>
                        {accessories
                          .filter(item => {
                            return (
                              item.doc._id !== accessory.id &&
                              errorType.accessories.findIndex(
                                accessory => accessory.id === item.doc._id,
                              ) === -1
                            );
                          })
                          .map((item, i) => {
                            return (
                              <option
                                key={i}
                                value={JSON.stringify({
                                  id: item.doc._id,
                                  name: item.doc.name,
                                })}>
                                {item.doc.name}
                              </option>
                            );
                          })}
                      </NativeSelect>
                    </FormControl>
                  </div>
                );
              })}
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div>
                    <label htmlFor="new-accessory">
                      {t('error_types.add_accessory')}
                    </label>
                  </div>
                  <NativeSelect
                    value=""
                    onChange={e => {
                      if (e.target.value !== '') {
                        setIsEdited(true);
                        setErrorType({
                          ...errorType,
                          accessories: [
                            ...errorType.accessories,
                            JSON.parse(e.target.value),
                          ],
                        });
                      }
                    }}
                    inputProps={{
                      name: 'new-accessory',
                      id: 'new-accessory',
                    }}
                    disabled={!permissions?.guides}>
                    <option aria-label="None" value="" />
                    {accessories
                      .filter(accessory => {
                        return (
                          errorType.accessories.findIndex(
                            item => item.id === accessory.doc._id,
                          ) === -1
                        );
                      })
                      .map((item, i) => {
                        return (
                          <option
                            key={i}
                            value={JSON.stringify({
                              id: item.doc._id,
                              name: item.doc.name,
                            })}>
                            {item.doc.name}
                          </option>
                        );
                      })}
                  </NativeSelect>
                </FormControl>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonReorderGroup
          disabled={!permissions?.guides}
          onIonItemReorder={e => {
            handleReorder(e);
          }}>
          <div className="ticket-step-input ion-margin-left ion-margin-top">
            <label>{t('error_types.phases')}</label>
          </div>
          {errorType.phases.map((phase, i) => {
            return (
              <Slide direction="up" in={true} key={phase.id}>
                <IonItem lines="full">
                  <IonText
                    className="ion-margin-right ion-weight-500"
                    slot="start">
                    {i + 1}.
                  </IonText>
                  <IonLabel className="ion-padding-top-half ion-padding-bottom-half">
                    <textarea
                      value={phase.text}
                      onChange={e => {
                        setIsEdited(true);
                        const phases = [...errorType.phases];
                        phases[phases.findIndex(x => x.id === phase.id)] = {
                          id: phases[phases.findIndex(x => x.id === phase.id)]
                            .id,
                          text: e.target.value,
                        };
                        const editedErrorType = {
                          ...errorType,
                          phases: [...phases],
                        };
                        setErrorType(editedErrorType);
                      }}
                      disabled={!permissions?.guides}
                    />
                  </IonLabel>
                  <IonButton
                    className="ion-margin-left-half"
                    fill="clear"
                    slot="end"
                    onClick={() => removePhase(phase.id)}
                    disabled={!permissions?.canDelete}>
                    <IonIcon
                      slot="icon-only"
                      color="medium"
                      icon={trashOutline}
                    />
                  </IonButton>
                  <IonReorder slot="end" className="ion-margin-left-half">
                    <IonIcon
                      color="medium"
                      icon={reorderTwoOutline}
                      style={{fontSize: '24px'}}
                    />
                  </IonReorder>
                </IonItem>
              </Slide>
            );
          })}
        </IonReorderGroup>
        <IonButton
          onClick={() => addPhase()}
          fill="clear"
          color="primary"
          disabled={!permissions?.guides}>
          <IonIcon slot="start" color="primary" icon={addOutline} />
          {t('error_types.add_phase')}
        </IonButton>
        <IonList className="ion-border-top">
          <div className="ticket-step-input ion-margin-left ion-margin-top ion-margin-bottom-half">
            <label>{t('error_types.flows')}</label>
          </div>
          {match.url === '/new-error-type' && (
            <IonText>
              <p className="ion-margin-top ion-margin-left">
                {t('error_types.save_before_adding_flows')}
              </p>
            </IonText>
          )}
          {errorType.flows.map((flow, i) => {
            return (
              <IonItem key={i} lines="full">
                <IonItem
                  style={{width: '100%'}}
                  lines="none"
                  routerLink={`/error-types/${errorType._id}/flows/${flow.id}`}>
                  <IonIcon
                    slot="start"
                    color="primary"
                    icon={documentTextOutline}
                  />
                  <IonLabel className="ion-padding-top-half ion-padding-bottom-half">
                    {flow.title}
                  </IonLabel>
                </IonItem>
                <IonButton
                  disabled={!permissions?.canDelete}
                  slot="end"
                  fill="clear"
                  onClick={() => {
                    const errorTypeCopy = {...errorType};
                    const flowIndex = errorTypeCopy.flows.findIndex(
                      x => x.id === flow.id,
                    );
                    errorTypeCopy.flows.splice(flowIndex, 1);
                    setErrorType(errorTypeCopy);
                    setIsEdited(true);
                  }}>
                  <IonIcon
                    slot="icon-only"
                    color="medium"
                    icon={trashOutline}
                  />
                </IonButton>
              </IonItem>
            );
          })}
        </IonList>
        {match.url !== '/new-error-type' && (
          <IonButton
            routerLink={`/error-types/${errorType._id}/new-flow`}
            fill="clear"
            color="primary"
            disabled={!permissions?.guides}>
            <IonIcon slot="start" color="primary" icon={addOutline} />
            {t('error_types.add_flow')}
          </IonButton>
        )}
      </Paper>
      <div>
        {/* <IonButton style={{ marginTop: "16px", marginBottom: "32px" }} routerLink={"/accessories"} className="ion-margin-right" fill="outline" color="danger">
					{t("app.abort")}
				</IonButton> */}
        <IonButton
          style={{marginTop: '16px', marginBottom: '32px'}}
          disabled={!isEdited}
          onClick={() => handleSave()}
          className="ion-margin-right"
          fill="solid"
          color="success">
          {t('app.save')}
        </IonButton>
      </div>
    </>
  );
};

export default ErrorTypeInfo;

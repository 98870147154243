import React from 'react';
import {ReactComponent as MarkerIcon} from '../../assets/marker.svg';

const Marker = props => {
  if (props.doc?.is_active === false) {
    return <MarkerIcon className="map-marker primary" />;
  } else if (props.doc?.time_when_paused !== '') {
    return <MarkerIcon className="map-marker warning" />;
  } else if (
    props.doc?.time_when_documented !== '' &&
    props.doc?.time_when_completed !== ''
  ) {
    return <MarkerIcon className="map-marker success" />;
  } else if (
    props.doc?.time_when_completed !== '' &&
    props.doc?.time_when_documented === ''
  ) {
    return <MarkerIcon className="map-marker success" />;
  } else if (
    props.doc?.time_when_started !== '' &&
    props.doc?.time_when_paused === '' &&
    props.doc?.time_when_documented === '' &&
    props.doc?.time_when_completed === ''
  ) {
    return <MarkerIcon className="map-marker warning" />;
  } else if (
    props.doc?.time_when_paused === '' &&
    props.doc?.time_when_completed === '' &&
    props.doc?.time_when_started === '' &&
    props.doc?.resourced?.length > 0
  ) {
    return <MarkerIcon className="map-marker warning" />;
  } else if (
    props.doc?.time_when_paused === '' &&
    props.doc?.time_when_completed === '' &&
    props.doc?.time_when_started === '' &&
    props.doc?.resourced?.length === 0
  ) {
    return <MarkerIcon className="map-marker light" />;
  } else {
    return null;
  }
};

export default Marker;

import React, {useState, useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonImg,
  IonButton,
  IonIcon,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {downloadOutline} from 'ionicons/icons';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {Paper} from '@material-ui/core';
import _ from 'lodash';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';


const PlaceAttachment = props => {
  const {t} = useTranslation('link_app');
  const {match} = props;
  const selectionType = match.params.type;

  useTitleEffect(match.params.attachmentId);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Databases
  const databases = useContext(DatabaseContext);

  // Fetch the ticket
  const [doc, setDoc] = useState({});
  useEffect(() => {
    switch (selectionType) {
      case 'site':
        databases.places
          .get(match.params.id, {attachments: true})
          .then(result => {
            setDoc(result);
          })
          .catch(err => {
            console.log(err);
            if (err.status === 401) {
              setIsAuth(false);
            }
          });
        break;
      case 'warehouse':
        databases.warehouses
          .get(match.params.id, {attachments: true})
          .then(result => {
            setDoc(result);
          })
          .catch(err => {
            console.log(err);
            if (err.status === 401) {
              setIsAuth(false);
            }
          });
        break;
      case 'studio':
        databases.studios
          .get(match.params.id, {attachments: true})
          .then(result => {
            setDoc(result);
          })
          .catch(err => {
            console.log(err);
            if (err.status === 401) {
              setIsAuth(false);
            }
          });
        break;
      case 'fm-service':
        databases.fm_services
          .get(match.params.id, {attachments: true})
          .then(result => {
            setDoc(result);
          })
          .catch(err => {
            console.log(err);
            if (err.status === 401) {
              setIsAuth(false);
            }
          });
        break;
      default:
    }
  }, [databases, match, props.isLoading, selectionType, setIsAuth]);

  // Get attachment
  let attachment;
  if (!_.isEmpty(doc._attachments)) {
    attachment = doc._attachments[match.params.attachmentId];
  }

  return (
    <IonPage>
      <Header title={match.params.attachmentId} backButton />
      <IonContent>
        {attachment ? (
          <div className="container-narrow">
            <Paper style={{marginTop: '32px', marginBottom: '16px'}}>
              <IonList>
                <IonItem lines="none">
                  <div className="inner-wrap">
                    {attachment.content_type && attachment.data && (
                      <IonImg
                        src={`data:${attachment.content_type};base64,${attachment.data}`}
                        alt=""
                      />
                    )}
                  </div>
                </IonItem>
              </IonList>
            </Paper>
            <a
              href={`data:${attachment.content_type};base64,${attachment.data}`}
              download={match.params.attachmentId}>
              <IonButton size="default">
                <IonIcon slot="start" icon={downloadOutline} />
                {t('app.download')}
              </IonButton>
            </a>
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default PlaceAttachment;

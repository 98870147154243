import React from 'react';
import {useTranslation} from 'react-i18next';
import {IonIcon} from '@ionic/react';
import {alertCircleOutline} from 'ionicons/icons';
import Tooltip from '@material-ui/core/Tooltip';

const MissingValueAlert = props => {
  const {t} = useTranslation('link_app');

  return (
    <div className="missing-value-alert">
      <IonIcon
        icon={alertCircleOutline}
        color="danger"
        style={{fontSize: '20px'}}
        slot="icon-only"
      />
      <Tooltip
        title={
          <p
            style={{
              fontSize: '16px',
              paddingTop: '0rem',
              marginBottom: '0.5rem',
              lineHeight: '1.2em',
            }}>
            {t('tickets.missing_alert')}
          </p>
        }
        placement="right">
        <div />
      </Tooltip>
    </div>
  );
};

export default MissingValueAlert;

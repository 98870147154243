import React, {useState, useContext} from 'react';
import {
  IonButton,
  IonIcon,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/react';
import {
  ellipsisVertical,
  personCircleOutline,
  logOutOutline,
  eyeOutline,
  documentOutline,
} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import {UserContext} from '../context/UserContext';
import useToast from '../../hooks/useToast';
import packageJson from '../../../package.json';
import {buildInfo} from '../../buildInfo';
import {DatabaseContext} from '../context/DatabaseContext';
import {useHistory} from 'react-router-dom';
import useConfig from '../../hooks/useConfig';
import SystemStatus from '../utils/SystemStatus';

const Profile = ({permissions, isTicketsLoading, isIkea, setIsIkea}) => {
  const {t} = useTranslation(['link_app', 'link_app_ikea']);
  const [showMenu, setShowMenu] = useState(null);
  const [user, setUser] = useContext(UserContext);
  const [, setIsEdited] = useState(false);
  const toast = useToast();

  const history = useHistory();

  // Databases
  const databases = useContext(DatabaseContext);

  // Handle the language selection
  // const setAppLang = (language) => {
  // 	if (language === "fi") {
  // 		i18n.changeLanguage("fi")
  // 	} else if (language === "en") {
  // 		i18n.changeLanguage("en")
  // 	}
  // 	document.documentElement.lang = language
  // }

  const {db} = useConfig();

  const logOut = async () => {
    await fetch(`${db}/_session`, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        document.body.classList.remove('dark');
        setUser({});
        toast(t('toast.logged_out'));
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const changeIkeaStatus = () => {
    setIsEdited(true);
    setUser({
      ...user,
      metadata: {
        ...user.metadata,
        ikea: !isIkea,
      },
    });
    databases.contacts
      .get(user.name)
      .then(result => {
        const doc = {
          ...result,
          ikea: !isIkea,
        };
        return databases.contacts.put(doc);
      })
      .then(() => {
        console.log(t('toast.user_updated'));
        toast(t('toast.user_updated'));
        setIsEdited(false);
        if (!isIkea) {
          history.replace(`/dashboard`);
        } else {
          history.replace(`/tickets`);
        }
      })
      .catch(err => {
        console.log(t('toast.error_updating_user:', err));
        toast(t('toast.error_updating_user'), err);
      });
  };

  return (
    <div className="profile">
      <SystemStatus isLoading={isTicketsLoading} />
      <div className="inner-wrap">
        <IonButton
          onClick={e => {
            e.preventDefault();
            setShowMenu(e);
          }}
          fill="clear"
          style={{height: 'unset'}}>
          <div className="icon-wrap primary ion-margin-none" slot="start">
            {user.metadata &&
            user.metadata.firstname_lastname &&
            user.metadata.firstname_lastname !== '' &&
            user.metadata.firstname_lastname.split(' ').length >= 1 ? (
              <>
                {user.metadata.firstname_lastname.split(' ').length >= 1 &&
                  user.metadata.firstname_lastname.split(' ')[0].charAt(0)}
                {user.metadata.firstname_lastname.split(' ').length > 1 &&
                  user.metadata.firstname_lastname.split(' ')[1].charAt(0)}
              </>
            ) : (
              '?'
            )}
          </div>
          <IonIcon
            className="ion-margin-left-half"
            slot="end"
            icon={ellipsisVertical}
            color="medium"
            style={{fontSize: '22px'}}
          />
        </IonButton>
        <IonPopover
          event={showMenu}
          isOpen={!!showMenu}
          onDidDismiss={() => setShowMenu(null)}>
          <IonContent>
            <IonList>
              <IonItem
                onClick={() => {
                  setShowMenu(null);
                }}
                routerLink={`/user`}
                lines="full">
                <IonLabel
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <IonIcon
                    icon={personCircleOutline}
                    slot="start"
                    className="ion-margin-right-half"
                    style={{fontSize: '22px'}}
                  />
                  {t('nav.profile', {
                    ns: isIkea ? 'link_app_ikea' : 'link_app',
                  })}
                </IonLabel>
              </IonItem>
              {/* <IonItem
								onClick={() => {
									setShowMenu(null)
								}}
								routerLink={`/settings`}
								lines="full"
							>
								<IonLabel
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<IonIcon icon={settingsOutline} slot="start" className="ion-margin-right-half" style={{ fontSize: "22px" }} />
									{t("nav.settings")}
								</IonLabel>
							</IonItem> */}
              {permissions?.ikea &&
                (user?.roles?.includes('telemast_admin') ||
                  user?.roles?.includes('_admin') ||
                  user?.roles?.includes('manager')) && (
                  <IonItem
                    onClick={() => {
                      changeIkeaStatus();
                    }}
                    button
                    lines="full">
                    <IonLabel
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <IonIcon
                        icon={eyeOutline}
                        slot="start"
                        className="ion-margin-right-half"
                        style={{fontSize: '22px'}}
                      />
                      {isIkea ? t('nav.ev_mgmt') : t('nav.ikea')}
                    </IonLabel>
                  </IonItem>
                )}
              {permissions?.ikea && isIkea && (
                <IonItem
                  onClick={() => {
                    setShowMenu(null);
                  }}
                  routerLink={`/user-manual`}
                  button
                  lines="full">
                  <IonLabel
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <IonIcon
                      icon={documentOutline}
                      slot="start"
                      className="ion-margin-right-half"
                      style={{fontSize: '22px'}}
                    />
                    {t('nav.user_manual', {
                      ns: isIkea ? 'link_app_ikea' : 'link_app',
                    })}
                  </IonLabel>
                </IonItem>
              )}
              <IonItem
                onClick={() => {
                  logOut();
                  setShowMenu(null);
                }}
                button
                lines="full">
                <IonLabel
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <IonIcon
                    icon={logOutOutline}
                    slot="start"
                    className="ion-margin-right-half"
                    style={{fontSize: '22px'}}
                  />
                  {t('nav.log_out', {
                    ns: isIkea ? 'link_app_ikea' : 'link_app',
                  })}
                </IonLabel>
              </IonItem>
              {/* {i18n.language === "en" ? (
									<IonItem
										onClick={() => {
											setAppLang("fi")
										}}
										button
										lines="none"
									>
										<IonLabel
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<IonIcon icon={globeOutline} slot="start" className="ion-margin-right-half" style={{ fontSize: "22px" }} />
											Suomeksi
										</IonLabel>
									</IonItem>
								) : (
									<IonItem
										onClick={() => {
											setAppLang("en")
										}}
										button
										lines="none"
									>
										<IonLabel
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<IonIcon icon={globeOutline} slot="start" className="ion-margin-right-half" style={{ fontSize: "22px" }} />
											In English
										</IonLabel>
									</IonItem>
								)} */}
            </IonList>
            <div className="build-info">
              <IonText>
                <p>
                  {t('app.version', {
                    ns: isIkea ? 'link_app_ikea' : 'link_app',
                  })}
                  : {packageJson.version}
                </p>
              </IonText>
              {buildInfo?.buildDate && (
                <IonText>
                  <p>
                    {t('app.build_date', {
                      ns: isIkea ? 'link_app_ikea' : 'link_app',
                    })}
                    : {buildInfo.buildDate}
                  </p>
                </IonText>
              )}
              {buildInfo?.buildNumber && (
                <IonText>
                  <p>
                    {t('app.build_number', {
                      ns: isIkea ? 'link_app_ikea' : 'link_app',
                    })}
                    : {buildInfo.buildNumber}
                  </p>
                </IonText>
              )}
            </div>
          </IonContent>
        </IonPopover>
      </div>
    </div>
  );
};

export default Profile;

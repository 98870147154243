import React, {useState, useContext, useEffect} from 'react';
import Header from '../../components/navigation/Header';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonSegmentButton,
  IonSegment,
  IonLabel,
} from '@ionic/react';
import StepperHeader from '../../components/tickets/StepperHeader';
import TicketSteps from '../../components/tickets/TicketSteps';
import Badge from '../../components/utils/Badge';
import {useTranslation} from 'react-i18next';
import useWindowSize from '../../hooks/useWindowSize';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import Loading from '../../components/utils/Loading';
import Monitoring from '../../components/utils/Monitoring';
import Errors from '../../components/tickets/Errors';
import Messages from '../../components/tickets/Messages';
import _ from 'lodash';
import useToast from '../../hooks/useToast';
import {AuthContext} from '../../components/context/AuthContext';
import Log from '../../components/tickets/Log';
import {UserContext} from '../../components/context/UserContext';
import TicketDeviceManagement from '../../components/tickets/TicketDeviceManagement';
import {GetNewTicketStatus} from '../../components/tickets/TicketStatusController';
import useTitleEffect from '../../hooks/useTitle';

const Ticket = props => {
  // Misc vars
  const {t} = useTranslation('link_app');
  const {match, permissions} = props;
  const [, height] = useWindowSize();
  const [user] = useContext(UserContext);
  const toast = useToast();

  useTitleEffect(t('tickets.work'));
  useEffect(() => {}, [match, t]);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Databases
  const databases = useContext(DatabaseContext);

  // Stepper state
  const [activeStep, setActiveStep] = useState(null);
  const steps = [
    t('tickets.work_details'),
    t('tickets.diagnostics'),
    t('tickets.resourcing'),
    t('tickets.alerting'),
    t('tickets.documenting'),
  ];

  // Fetch the ticket
  const [ticket, setTicket] = useState({});

  useEffect(() => {
    databases.tickets
      .get(match.params.id, {attachments: true})
      .then(result => {
        if (
          result.stepper_step &&
          result.stepper_step !== '' &&
          activeStep === null
        ) {
          setActiveStep(parseInt(result.stepper_step));
        } else if (!result.stepper_step && activeStep === null) {
          result.stepper_step = '0';
          setActiveStep(0);
        }
        setTicket(result);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
        if (err.status === 404) {
          databases.tickets
            .allDocs()
            .then(result => {
              // Auto incerement _id
              const docs = result.rows.filter(row => {
                return (
                  !row.id.includes('_design') && !row.id.includes('health')
                );
              });
              console.log(docs);
              const id =
                docs.length > 0
                  ? (
                      parseInt(
                        Math.max.apply(
                          Math,
                          docs.map(x => x.id),
                        ),
                      ) + 1
                    ).toString()
                  : '1';
              // Add created timestamp
              const created = Math.round(new Date() / 1000).toString();

              setTicket({
                _id: id,
                is_active: true,
                error: {},
                errors: [],
                category: 'EV',
                sla: '',
                place: {},
                fm_service: {},
                resourced: [],
                maintenance_phases: [],
                flow: {},
                flow_step: '',
                accessories: [],
                more_info: '',
                summary: '',
                documentation_guide: '',
                time_when_created: created,
                time_when_resourced: '',
                time_when_paused: '',
                time_when_started: '',
                time_when_accessories: '',
                time_when_at_place: '',
                time_when_completed: '',
                time_when_documented: '',
                comments: [],
                images: [],
                warehouse: {},
                alert_messages: [],
                stepper_step: '0',
                log: [],
                is_scheduled: false,
                scheduled_selected_frequencies: [],
                scheduled_start: '',
                scheduled_end: '',
                scheduled_alt_start: '',
                scheduled_alt_end: '',
                using_alt_date: false,
                station: {},
                ticket_status: '',
                resource_status: '',
              });
              setActiveStep(0);
            })
            .catch(err => console.log(err));
        }
      });
  }, [databases.tickets, match, props.isLoading, setIsAuth]);

  // Fetch all places
  const [places, setPlaces] = useState([]);
  useEffect(() => {
    databases.places
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setPlaces(docs);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.places]);

  // Fetch all studios
  const [studios, setStudios] = useState([]);
  useEffect(() => {
    databases.studios
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setStudios(docs);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.studios]);

  // Get place
  const [place, setPlace] = useState({});
  useEffect(() => {
    if (
      !_.isEmpty(ticket.place) &&
      places?.length > 0 &&
      ticket.errors.length > 0 &&
      ticket.errors[0].STATION_ID !== ''
    ) {
      const site = places.find(place =>
        place.doc?.stations.some(
          station =>
            station?.id?.toString() ===
              ticket?.errors[0].STATION_ID?.toString() ||
            station?.id?.toString() === ticket?.station?.id?.toString(),
        ),
      );
      if (site) {
        setPlace(site);
      }
    } else if (
      !_.isEmpty(ticket.place) &&
      places?.length > 0 &&
      ticket.place.site_id
    ) {
      const site = places.find(
        place => place.doc.site_id === ticket?.place.site_id,
      );
      if (site) {
        setPlace(site);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket.place, places, studios]);

  //Get station
  const [station, setStation] = useState({});
  useEffect(() => {
    if (!_.isEmpty(place) && ticket.errors.length > 0) {
      let station;
      if (ticket?.errors[0].STATION_ID?.toString() !== '') {
        station = place.doc.stations.find(
          station =>
            station?.id?.toString() ===
            ticket?.errors[0].STATION_ID?.toString(),
        );
      } else {
        station = place.doc.stations.find(
          station =>
            station?.id?.toString() === ticket?.station?.id?.toString(),
        );
      }
      if (station) {
        setStation(station);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  // Fetch all guides
  const [guides, setGuides] = useState([]);
  useEffect(() => {
    databases.guides
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setGuides(docs);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.guides]);

  // Fetch all accessories
  const [accessories, setAccessories] = useState([]);
  useEffect(() => {
    databases.accessories
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setAccessories(docs);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.accessories]);

  // Fetch all contacts
  const [contacts, setContacts] = useState([]);
  useEffect(() => {
    databases.contacts
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setContacts(docs);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.contacts]);

  // Fetch all warehouses
  const [warehouses, setWarehouses] = useState([]);
  useEffect(() => {
    databases.warehouses
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setWarehouses(docs);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.warehouses]);

  // Segment state
  const [segment, setSegment] = useState('errors');

  // Handle step change and save it to the db
  const handleActiveStep = async step => {
    if (match.url !== '/new-ticket') {
      if (
        step > parseInt(ticket.stepper_step) &&
        step - ticket.stepper_step < 4
      ) {
        let status = ticket.ticket_status ?? '';
        await GetNewTicketStatus(ticket, status, step, true).then(
          new_status => {
            status = new_status;
          },
        );
        databases.tickets
          .get(ticket._id)
          .then(result => {
            let ticketCopy = {
              ...ticket,
              _rev: result._rev,
              // stepper_step: step.toString(),
            };
            // if (status !== '' && status !== ticket.ticket_status) {
            //   ticketCopy = {
            //     ...ticket,
            //     _rev: result._rev,
            //     stepper_step: step.toString(),
            //     ticket_status: status,
            //   };
            // }

            // Only save log if we're moving forward
            if (step > parseInt(ticket.stepper_step)) {
              const timestamp = Math.round(new Date() / 1000).toString();
              let newId =
                ticket.log?.length > 0
                  ? (
                      parseInt(
                        Math.max.apply(
                          Math,
                          ticket.log?.map(x => x.id),
                        ),
                      ) + 1
                    ).toString()
                  : '1';
              // if (isEdited) {
              let eventName = `save_step_${ticket.stepper_step}`;
              if (eventName === 'save_step_3') {
                eventName = 'save_step_3_1';
              }
              ticketCopy = {
                ...ticketCopy,
                stepper_step: step.toString(),
                ticket_status: status,
                log: ticket.log
                  ? [
                      ...ticket.log,
                      {
                        id: newId,
                        time: timestamp,
                        user: user,
                        app: 'management',
                        event: eventName,
                      },
                    ]
                  : [
                      {
                        id: newId,
                        time: timestamp,
                        user: user,
                        app: 'management',
                        event: eventName,
                      },
                    ],
              };
            }
            // } else {
            //   ticketCopy = {
            //     ...ticketCopy,
            //     stepper_step: step.toString(),
            //     log: ticket.log
            //       ? [
            //           ...ticket.log,
            //           {
            //             id: newId,
            //             time: timestamp,
            //             user: user,
            //             app: 'management',
            //             event: 'save_step_change',
            //           },
            //         ]
            //       : [
            //           {
            //             id: newId,
            //             time: timestamp,
            //             user: user,
            //             app: 'management',
            //             event: 'save_step_change',
            //           },
            //         ],
            //   };
            // }
            return databases.tickets.put(ticketCopy);
          })
          .then(result => {
            console.log(
              `${t('toast.ticket')} #${ticket._id} ${t('toast.updated')}`,
            );
            toast(`${t('toast.ticket')} #${ticket._id} ${t('toast.updated')}`);
            setIsEdited(false);
            setActiveStep(step);
          })
          .catch(err => {
            console.log(
              `${t('toast.error_updating_ticket')} #${ticket._id}:`,
              err,
            );
            toast(`${t('toast.error_updating_ticket')} #${ticket._id}`, err);
          });
      } else {
        console.log('ei tallennusta');
        setActiveStep(step);
      }
    } else {
      const currentDateTime = new Date();
      const timestamp = Math.round(currentDateTime / 1000).toString();
      let newId =
        ticket.log?.length > 0
          ? (
              parseInt(
                Math.max.apply(
                  Math,
                  ticket.log?.map(x => x.id),
                ),
              ) + 1
            ).toString()
          : '1';
      const ticketCopy = {
        ...ticket,
        stepper_step: step.toString(),
        log: ticket.log
          ? [
              ...ticket.log,
              {
                id: newId,
                time: timestamp,
                user: user,
                app: 'management',
                event: 'save',
              },
            ]
          : [
              {
                id: newId,
                time: timestamp,
                user: user,
                app: 'management',
                event: 'save',
              },
            ],
      };
      if (!ticketCopy.errors[0]) {
        ticketCopy.errors[0] = {
          ALARM: 'on',
          STATION_ID: ticketCopy.station.id ?? '',
          ALARM_END_TIME: '',
          ALARM_START_TIME: currentDateTime.toISOString().slice(0, -5) + 'Z',
          CONNECTOR: '1',
          DESCRIPTION: '',
          SUMMARY: '',
          APP_TYPE: '',
          ALERTNAME: '',
          CATEGORY: '',
          SEVERITY: '',
        };
      }
      databases.tickets
        .post(ticketCopy)
        .then(() => {
          console.log(
            `${t('toast.ticket')} #${ticket._id} ${t('toast.updated')}`,
          );
          toast(`${t('toast.ticket')} #${ticket._id} ${t('toast.updated')}`);
          setIsEdited(false);
          setTicket({});
          props.history.replace(`/tickets/${ticketCopy._id}`);
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_ticket')} #${ticket._id}:`,
            err,
          );
          toast(`${t('toast.error_updating_ticket')} #${ticket._id}`, err);
        });
    }
  };

  useEffect(() => {
    if (ticket?.error?.id === '' && guides?.length > 0) {
      const alertname = ticket.errors ? ticket.errors[0].ALERTNAME : '';
      if (alertname && alertname !== '') {
        let alertTypes = [...guides];
        if (alertname.match('Kempower')) {
          alertTypes = alertTypes.filter(val => val.doc?.name.match('DC -'));
        } else if (alertname.match('Enersense')) {
          alertTypes = alertTypes.filter(val => val.doc?.name.match('AC -'));
        }
        let alertNameFound = false;
        alertTypes.forEach((val, i) => {
          const errorName = val.doc?.name?.split(/AC - |DC - /).pop();
          if (alertname.toLowerCase().match(errorName.toLowerCase())) {
            const guide = guides.find(guide => guide.doc._id === val.doc?._id);
            alertNameFound = true;
            setTicket({
              ...ticket,
              error: {name: val.doc?.name, id: val.doc?._id},
              flow: guide?.doc?.flows?.length > 0 ? guide?.doc?.flows[0] : {},
              maintenance_phases:
                guide?.doc?.phases?.length > 0 ? guide?.doc?.phases : [],
              accessories:
                guide?.doc?.accessories?.length > 0
                  ? guide?.doc?.accessories
                  : [],
              flow_step: '0',
            });
          }
        });
        if (!alertNameFound) {
          console.log('Set ticket error as default: Faulted');
          let chargerType = '';
          if (alertname.match('Kempower')) {
            chargerType = 'DC';
          } else if (alertname.match('Enersense')) {
            chargerType = 'AC';
          }
          const guide = guides.find(
            guide => guide.doc.name === chargerType + ' - Faulted',
          );
          setTicket({
            ...ticket,
            error: {name: guide.doc?.name, id: guide.doc?._id},
            flow: guide?.doc?.flows?.length > 0 ? guide?.doc?.flows[0] : {},
            maintenance_phases:
              guide?.doc?.phases?.length > 0 ? guide?.doc?.phases : [],
            accessories:
              guide?.doc?.accessories?.length > 0
                ? guide?.doc?.accessories
                : [],
            flow_step: '0',
          });
        }
      }
    }
  }, [ticket, guides]);

  return (
    <IonPage>
      <Header
        title={`${t('tickets.work')} ${ticket._id}`}
        history={props.history}
        backButton
        badge={<Badge doc={ticket} timestamp />}
        place={place}
        ticket={ticket}
      />
      <IonContent>
        {ticket._id ? (
          <IonGrid className="ion-padding-none">
            <IonRow>
              <IonCol
                className="half-n-half"
                size="4"
                style={{height: `${height - 130}px`}}>
                <div className="half-fixed-header">
                  <StepperHeader
                    placeId={place.doc?.id}
                    steps={steps}
                    activeStep={activeStep}
                    handleActiveStep={handleActiveStep}
                  />
                </div>
                <div className="half-inner-wrap">
                  <TicketSteps
                    match={match}
                    history={props.history}
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    handleActiveStep={handleActiveStep}
                    ticket={ticket}
                    station={station}
                    setTicket={setTicket}
                    places={places}
                    studios={studios}
                    guides={guides}
                    accessories={accessories}
                    contacts={contacts}
                    warehouses={warehouses}
                    isEdited={isEdited}
                    setIsEdited={setIsEdited}
                    user={user}
                    place={place}
                    permissions={permissions}
                  />
                </div>
              </IonCol>
              <IonCol
                className="half-n-half background-light-gray"
                size="8"
                style={{height: `${height - 130}px`}}>
                <div className="half-fixed-header">
                  <IonSegment
                    scrollable
                    onIonChange={e => setSegment(e.detail.value)}
                    value={segment}
                    className="segment-blue">
                    <IonSegmentButton value="errors">
                      <IonLabel>{t('tickets.errors_segment')}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="messages">
                      <IonLabel>{t('tickets.messages_segment')}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="monitoring">
                      <IonLabel>
                        {t('tickets.monitoring_data_segment')}
                      </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="log">
                      <IonLabel>{t('tickets.log_segment')}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="device_management">
                      <IonLabel>{t('place.device_management')}</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </div>
                <div className="half-inner-wrap">
                  {segment === 'errors' && (
                    <Errors
                      ticket={ticket}
                      place={place.doc}
                      station={station}
                      setPlace={setPlace}
                      permissions={permissions}
                    />
                  )}
                  {segment === 'messages' && (
                    <Messages doc={ticket} permissions={permissions} />
                  )}
                  {segment === 'monitoring' && (
                    <Monitoring
                      match={props.match}
                      place={place.doc}
                      station={station}
                      permissions={permissions}
                    />
                  )}
                  {segment === 'log' && (
                    <Log
                      match={props.match}
                      ticket={ticket}
                      permissions={permissions}
                    />
                  )}
                  {segment === 'device_management' && (
                    <TicketDeviceManagement
                      match={props.match}
                      place={place.doc}
                      station={station}
                      permissions={permissions}
                    />
                  )}
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <Loading />
        )}
        {/* <Prompt
          when={isEdited}
          message={`You have unsaved changes? Would you like to leave without saving?`}
        /> */}
      </IonContent>
    </IonPage>
  );
};

export default Ticket;

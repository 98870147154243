import React, {useState, useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import ContactInfo from '../../components/contacts/ContactInfo';
import Loading from '../../components/utils/Loading';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';


const Contact = props => {
  const {match, permissions} = props;

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Fetch contact
  const [doc, setDoc] = useState({});
  useEffect(() => {
    databases.contacts
      .get(match.params.id)
      .then(result => {
        setDoc(result);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.contacts, match, props.isLoading, setIsAuth]);

  useTitleEffect(doc.name);

  return (
    <IonPage>
      <Header title={doc.name} backButton />
      <IonContent>
        {doc._id ? (
          <div className="container-narrow">
            <ContactInfo
              key={doc}
              doc={doc}
              match={match}
              history={props.history}
              permissions={permissions}
            />
          </div>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Contact;

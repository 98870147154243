import React, {useState, useEffect, useContext, useRef} from 'react';
import {
  AccordionDetails,
  FormControl,
  NativeSelect,
  Accordion,
  AccordionSummary,
  Divider,
} from '@material-ui/core';
import {
  IonText,
  IonItem,
  IonThumbnail,
  IonButton,
  IonIcon,
  IonList,
  IonLabel,
  IonImg,
  IonCheckbox,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import MissingValueAlert from '../utils/MissingValueAlert';
import StepperFooter from './StepperFooter';
import {
  trashOutline,
  downloadOutline,
  personCircleOutline,
  chevronDownOutline,
  imageOutline,
  syncOutline,
  sendOutline,
} from 'ionicons/icons';
import {Link} from 'react-router-dom';
import CommentCard from '../utils/CommentCard';
import Diagnostics from './Diagnostics';
import _ from 'lodash';
import {DatabaseContext} from '../context/DatabaseContext';
import useToast from '../../hooks/useToast';
import useConfig from '../../hooks/useConfig';
import generator from 'generate-password';
import {DateTimeString} from '../utils/DateTime';
import {GetNewTicketStatus} from '../../components/tickets/TicketStatusController';
import {useHistory} from 'react-router-dom';
import Compressor from 'compressorjs';
import axios from 'axios';

const TicketSteps = props => {
  // Misc vars
  const {
    ticket,
    setTicket,
    isEdited,
    setIsEdited,
    user,
    match,
    place,
    station,
    permissions,
  } = props;
  const {t} = useTranslation('link_app');
  const [isButtonActive, setIsButtonActive] = useState(true);
  // const [isScheduleButtonActive, setIsScheduleButtonActive] = useState(true)
  const toast = useToast();
  const [newResourcedContacts, setNewResourcedContacts] = useState([]);
  const [showNewResourcedContact, setShowNewResourcedContact] = useState(false);

  const history = useHistory();

  // Image upload and comment variables
  const fileInput = useRef(null);
  const [newImage, setNewImage] = useState({});
  const [imageCaption, setImageCaption] = useState('');
  const [comment, setComment] = useState('');

  // Databases
  const databases = useContext(DatabaseContext);

  // Fetch fm services
  const [fmServices, setFmServices] = useState([]);

  useEffect(() => {
    if (place?.doc?.fm_services?.length > 0) {
      databases.fm_services
        .allDocs({include_docs: true, keys: place.doc.fm_services})
        .then(result => {
          setFmServices(result.rows);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setFmServices([]);
    }
  }, [place, databases.fm_services]);

  const [stations, setStations] = useState([]);
  useEffect(() => {
    if (place?.doc?.stations?.length > 0) {
      setStations(place.doc.stations);
    } else {
      setStations([]);
    }
  }, [place]);

  // Alert message subject
  const [alertMessageSubject, setAlertMessageSubject] = useState('');
  useEffect(() => {
    const subject = `Häiriöviesti ${
      ticket?.place?.name
        ? ticket?.place?.name
        : place?.doc?.name
        ? place.doc?.name
        : ''
    }`;
    setAlertMessageSubject(subject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket, fmServices]);

  // Alert message content
  const [alertMessageContent, setAlertMessageContent] = useState('');
  useEffect(() => {
    const content = `Sijainti: ${
      ticket?.place?.name
        ? ticket?.place?.name
        : place?.doc?.name
        ? place.doc?.name
        : ''
    }\nSyy: Häiriö latauspistoolissa\nAlkanut: ${
      ticket?.errors[0]?.ALARM_START_TIME
        ? DateTimeString(ticket?.errors[0]?.ALARM_START_TIME)
        : ''
    }\nArvioitu korjausaika: \nKorjattu: \n\nLisätietoja: linkappsd@telemast.com\n\nTerveisin\nTelemast Nordic Oy\n0105011000`;
    setAlertMessageContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket, fmServices]);

  // // Alert message recipients
  const [alertMessages, setAlertMessages] = useState([]);
  const [alertMessageRecipients, setAlertMessageRecipients] = useState([]);
  useEffect(() => {
    if (alertMessageRecipients.length > 0) {
      const messages = alertMessageRecipients.map(message => {
        return {
          to: [message.id],
          subject: alertMessageSubject,
          body: alertMessageContent,
        };
      });
      setAlertMessages(messages);
    } else {
      setAlertMessages([]);
    }
  }, [
    alertMessageRecipients,
    alertMessageSubject,
    alertMessageContent,
    ticket,
  ]);

  // Manual alert message recipients
  const [manualMessages, setManualMessages] = useState([]);
  const [manualMessageRecipients, setManualMessageRecipients] = useState('');

  useEffect(() => {
    if (manualMessageRecipients.length > 0) {
      const messages = manualMessageRecipients.split(',').map(recipient => {
        return {
          to: [recipient.replace(' ', '')],
          subject: alertMessageSubject,
          body: alertMessageContent,
        };
      });
      setManualMessages(messages);
    } else {
      setManualMessages([]);
    }
  }, [
    manualMessageRecipients,
    alertMessageSubject,
    alertMessageContent,
    ticket,
  ]);

  // Alert message type and handle type change
  const [alertUseType, setAlertUseType] = useState('teams');
  const handleAlertMessageChannelChange = typeValue => {
    setAlertUseType(typeValue);
  };

  // Teams alert message recipient
  const [teamsAlertMessageRecipient, setTeamsAlertMessageRecipient] =
    useState('');
  useEffect(() => {
    if (alertUseType === 'teams') {
      if (
        station.type === 'uc' &&
        place.doc?.customer_service_teams !== undefined &&
        place.doc?.customer_service_teams !== ''
      ) {
        setTeamsAlertMessageRecipient(place.doc?.customer_service_teams);
      }
      if (
        (station.type === 'kempower' || station.type === 'ocpp') &&
        (place.doc?.lm_delivery_teams !== undefined ||
          place.doc?.lm_delivery_teams !== '')
      ) {
        setTeamsAlertMessageRecipient(place.doc?.lm_delivery_teams);
      }
    } else {
      setTeamsAlertMessageRecipient('');
    }
  }, [ticket, alertUseType]);

  const [estimatedRepairTime, setEstimatedRepairTime] = useState();
  useEffect(() => {
    if (ticket.sla) {
      const now = new Date(ticket.time_when_created * 1000);
      const updatedDateTime = new Date(
        now.getTime() + ticket.sla * 60 * 60 * 1000,
      );
      const year = updatedDateTime.getFullYear();
      const month = ('0' + (updatedDateTime.getMonth() + 1)).slice(-2);
      const day = ('0' + updatedDateTime.getDate()).slice(-2);
      const hours = ('0' + updatedDateTime.getHours()).slice(-2);
      const minutes = ('0' + updatedDateTime.getMinutes()).slice(-2);
      const formattedDateTimeLocal = `${year}-${month}-${day}T${hours}:${minutes}`;
      setEstimatedRepairTime(formattedDateTimeLocal);
    } else {
      const now = new Date();
      const year = now.getFullYear();
      const month = ('0' + (now.getMonth() + 1)).slice(-2);
      const day = ('0' + now.getDate()).slice(-2);
      const hours = ('0' + now.getHours()).slice(-2);
      const minutes = ('0' + now.getMinutes()).slice(-2);
      const formattedDateTimeLocal = `${year}-${month}-${day}T${hours}:${minutes}`;
      setEstimatedRepairTime(formattedDateTimeLocal);
    }
  }, [ticket.sla]);

  const {integrator} = useConfig();

  const handleDateTimeChange = event => {
    setEstimatedRepairTime(event.target.value);
  };

  // Teams alert message
  const [teamsAlertMessageContent, setTeamsAlertMessageContent] = useState('');
  useEffect(() => {
    const esimatedRepairDate = new Date(estimatedRepairTime);
    const content = {
      '@context': 'https://schema.org/extensions',
      '@type': 'AdaptiveCard',
      themeColor: '0072C6',
      title: `Häiriöviesti ${place.doc?.name ?? '-'} (ID:${ticket?._id})`,
      text: `Uusi häiriöviesti kohteesta ${
        place.doc?.name ?? '-'
      }. Lähetetty osoitteesta https://mgmt.enersense.telemast.com/`,
      sections: [
        {
          facts: [
            {
              name: 'Sijainti',
              value: place.doc?.name ?? '-',
            },
            {
              name: 'Syy',
              value: 'Häiriö latauspistoolissa',
            },
            {
              name: 'Latausaseman tyyppi',
              value: station?.type ?? '-',
            },
            {
              name: 'Alkanut',
              value: ticket?.errors[0]?.ALARM_START_TIME
                ? DateTimeString(ticket?.errors[0]?.ALARM_START_TIME)
                : '',
            },
            {
              name: 'Arvioitu korjausaika',
              value: DateTimeString(esimatedRepairDate.getTime()),
            },
            {
              name: 'Korjattu',
              value: '',
            },
            {
              name: 'Tämän viestin ohjasi',
              value: user?.name,
            },
          ],
        },
      ],
    };
    setTeamsAlertMessageContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket, place, estimatedRepairTime]);

  // Teams alert messages
  const [teamsAlertMessages, setTeamsAlertMessages] = useState();
  useEffect(() => {
    if (teamsAlertMessageRecipient !== '') {
      const message = {
        to:
          station.type === 'uc'
            ? 'Teams - Asiakaspalvelu'
            : 'Teams - Last mile delivery',
        subject: `Uusi häiriöviesti kohteesta ${place.doc?.name ?? '-'}`,
        body: teamsAlertMessageContent,
      };
      setTeamsAlertMessages(message);
    } else {
      setTeamsAlertMessages();
    }
  }, [
    teamsAlertMessageRecipient,
    teamsAlertMessageContent,
    ticket,
    place,
    station,
  ]);

  // SLA values
  const [slas, setSlas] = useState([]);
  useEffect(() => {
    if (station.type === 'uc') {
      setSlas([3, 24, 48, 72]);
    } else if (station.type === 'kempower' || station.type === 'ocpp') {
      setSlas([3, 24, 72, 120]);
    } else {
      setSlas([3, 24, 48, 72, 120]);
    }
  }, [ticket, station]);

  // Handle contact selection change
  const handleContactChange = (prevValue, newValue) => {
    const index = ticket.resourced.findIndex(
      resourced => resourced.id === prevValue.id,
    );
    console.log(index);
    if (index > -1) {
      const current = [...ticket.resourced];
      const newResourced = [...newResourcedContacts];
      if (newValue !== '') {
        const prevValueIndex = newResourced.findIndex(
          x => x.id === prevValue.id,
        );
        if (prevValueIndex > -1) {
          newResourced.splice(prevValueIndex, 1);
        }
        const value = JSON.parse(newValue);
        const newValueIndex = newResourced.findIndex(x => x.id === value.id);
        if (newValueIndex === -1) {
          newResourced.push(value);
        }
        setNewResourcedContacts(newResourced);
        current[index] = value;
        setTicket({...ticket, resourced: current});
        setIsEdited(true);
      } else {
        const prevValueIndex = newResourced.findIndex(
          x => x.id === prevValue.id,
        );
        if (prevValueIndex > -1) {
          newResourced.splice(prevValueIndex, 1);
        }
        setNewResourcedContacts(newResourced);
        current.splice(index, 1);
        setTicket({...ticket, resourced: current});
        setIsEdited(true);
      }
    } else {
      if (newValue !== '') {
        const current = [...ticket.resourced];
        const value = JSON.parse(newValue);
        setNewResourcedContacts([...newResourcedContacts, value]);
        setTicket({...ticket, resourced: current.concat(value)});
        setIsEdited(true);
      } else {
        toast(`Using default manager.`);
      }
    }
  };

  // Handle contact add
  const handleContactAdd = (value, manager = false) => {
    const current = [...ticket.resourced];
    const newValue = JSON.parse(value);
    if (!manager) {
      if (!current.find(x => x.id === newValue.id)) {
        setNewResourcedContacts([...newResourcedContacts, newValue]);
        setShowNewResourcedContact(false);
      }
    }
    setTicket({...ticket, resourced: current.concat(newValue)});
    setIsEdited(true);
  };

  const handleShowContactAdd = () => {
    setShowNewResourcedContact(true);
  };

  // Compress image before upload
  const handleCompressedUpload = e => {
    const img = e.target.files[0];
    new Compressor(img, {
      quality: 0.6, // 0.6 can be used, but it is not recommended to go below
      success: result => {
        // result has the compressed file
        setNewImage(result);
      },
    });
  };

  // Add image and caption to ticket
  const handleImageUpload = () => {
    if (
      ticket.images.findIndex(x => x.attachment_id === newImage.name) === -1
    ) {
      const timestamp = Math.round(new Date() / 1000).toString();
      const attachments = {
        ...ticket._attachments,
        [newImage.name !== 'image.jpg'
          ? newImage.name.replace(/ /g, '-')
          : `${timestamp}.jpg`]: {
          content_type: newImage.type,
          data: newImage,
        },
      };
      const images = [...ticket.images];
      let newId =
        images.length > 0
          ? (
              parseInt(
                Math.max.apply(
                  Math,
                  images.map(x => x.id),
                ),
              ) + 1
            ).toString()
          : '1';
      const newImages = [
        ...images,
        {
          id: newId,
          attachment_id:
            newImage.name !== 'image.jpg'
              ? newImage.name.replace(/ /g, '-')
              : `${timestamp}.jpg`,
          text: imageCaption !== '' ? imageCaption : '',
        },
      ];

      let newLogId =
        ticket.log?.length > 0
          ? (
              parseInt(
                Math.max.apply(
                  Math,
                  ticket.log?.map(x => x.id),
                ),
              ) + 1
            ).toString()
          : '1';
      const updatedTicket = {
        ...ticket,
        _attachments: attachments,
        images: newImages,
        log: ticket.log
          ? [
              ...ticket.log,
              {
                id: newLogId,
                time: timestamp,
                user: user,
                app: 'management',
                event: 'image_loaded',
              },
            ]
          : [
              {
                id: newLogId,
                time: timestamp,
                user: user,
                app: 'management',
                event: 'image_loaded',
              },
            ],
      };

      setTicket(updatedTicket);
      setIsEdited(true);
      setNewImage({});
      setImageCaption('');
    } else {
      toast(
        `${t('toast.image')} "${newImage.name}" ${t(
          'toast.image_already_added',
        )}`,
      );
    }
  };

  // Function that handles comment upload to ticket
  const handleCommentUpload = () => {
    const comments = [...ticket.comments];
    const timestamp = Math.round(new Date() / 1000).toString();
    let newId =
      comments.length > 0
        ? (
            parseInt(
              Math.max.apply(
                Math,
                comments.map(x => x.id),
              ),
            ) + 1
          ).toString()
        : '1';
    const newComment = {
      id: newId,
      time: timestamp,
      contact_id: user.name,
      text: comment !== '' ? comment : '',
    };

    let newLogId =
      ticket.log?.length > 0
        ? (
            parseInt(
              Math.max.apply(
                Math,
                ticket.log?.map(x => x.id),
              ),
            ) + 1
          ).toString()
        : '1';
    const updatedTicket = {
      ...ticket,
      comments: [...comments, newComment],
      log: ticket.log
        ? [
            ...ticket.log,
            {
              id: newLogId,
              time: timestamp,
              user: user,
              app: 'management',
              event: 'commented',
            },
          ]
        : [
            {
              id: newLogId,
              time: timestamp,
              user: user,
              app: 'management',
              event: 'commented',
            },
          ],
    };
    setTicket(updatedTicket);
    setIsEdited(true);
  };

  // Handle comment delete
  const handleCommentDelete = commentId => {
    const comments = [...ticket.comments];
    const commentIndex = comments.findIndex(
      comment => comment.id === commentId,
    );
    comments.splice(commentIndex, 1);
    setTicket({...ticket, comments: comments});
    setIsEdited(true);
  };

  // Handle image delete
  const handleImageDelete = imageId => {
    const images = [...ticket.images];
    const imageIndex = images.findIndex(
      image => image.attachment_id === imageId,
    );
    images.splice(imageIndex, 1);
    const attachments = ticket._attachments;
    delete attachments[imageId];
    setTicket({...ticket, images: images, _attachments: attachments});
    setIsEdited(true);
  };

  const handleResourcingTime = (ticket, timestamp) => {
    if (ticket.time_when_resourced === '' && ticket.resourced.length > 0) {
      return timestamp;
    } else if (
      ticket.time_when_resourced !== '' &&
      ticket.resourced.length === 0
    ) {
      return '';
    } else return '';
  };

  const handleChangeResourcingState = resource_status_value => {
    if (resource_status_value !== '') {
      if (
        resource_status_value === 'queue_resourcing' &&
        place.doc?.place_manager !== undefined &&
        place.doc?.place_manager !== '' &&
        ticket.resourced?.length < 1
      ) {
        const newResourced = [...newResourcedContacts];
        newResourced.push(place.doc?.place_manager);
        setNewResourcedContacts(newResourced);
        setTicket({
          ...ticket,
          resource_status: resource_status_value,
          resourced: newResourced,
        });
      } else {
        setTicket({...ticket, resource_status: resource_status_value});
      }
    } else {
      setNewResourcedContacts([]);
      setTicket({
        ...ticket,
        resource_status: resource_status_value,
        resourced: [],
      });
    }
    setIsEdited(true);
  };

  const createNewTempUser = async (contact, timestamp) => {
    const contactInfo = {
      _id: `temp-${contact._id}`,
      time_when_created: timestamp,
      roles: contact.roles,
      name: `${contact.name} (temporary)`,
      phone_number: contact.phone_number,
      email: contact.email,
      title: contact.title,
      company: contact.company,
      auto_mode: contact.auto_mode,
      dark_mode: contact.dark_mode,
    };

    const userInfo = {
      _id: `org.couchdb.user:temp-${contact._id}`,
      time_when_created: timestamp,
      name: `temp-${contact._id}`,
      type: 'user',
      roles: contact.roles,
      password: generator.generate({
        length: 24,
        numbers: true,
        symbols: false,
        lowercase: true,
        uppercase: true,
      }),
    };

    const handleContact = () => {
      databases.contacts
        .get(contactInfo._id)
        .then(result => {
          return alertUser(contact, userInfo.password);
        })
        .catch(err => {
          if (err.status === 404) {
            databases.contacts
              .post(contactInfo)
              .then(result => {
                return alertUser(contact, userInfo.password);
              })
              .catch(err => {
                console.log('Contact add error:', err);
                toast(
                  `${t('toast.error_updating_ticket')} #${ticket._id}`,
                  err,
                );
              });
          }
        });
    };

    databases.users
      .get(userInfo._id)
      .then(result => {
        return databases.users
          .put({...result, password: userInfo.password})
          .then(result => {
            handleContact();
          })
          .catch(err => {
            console.log('User pw change error:', err);
          });
      })
      .catch(err => {
        console.log('User get error:', err);
        if (err.status === 404) {
          return databases.users
            .post(userInfo)
            .then(result => {
              handleContact();
            })
            .catch(err => {
              console.log('User post error:', err);
            });
        }
      });
  };

  const {mgmt} = useConfig();
  const alertUser = (contact, pw) => {
    console.log('sending resource mail');
    const timestamp = Math.round(new Date() / 1000).toString();
    const message = {
      to: [contact._id],
      subject: `LinkApp EV-tiketti #${ticket?._id}`,
      body: `Sinulle on osoitettu työ \n\nKohde: ${
        ticket?.place?.name
          ? ticket?.place?.name
          : place?.doc?.name
          ? place.doc?.name
          : ''
      }\nLatausasemassa: ${station?.name ?? '-'}\nConnector: ${
        ticket?.errors[0]?.CONNECTOR ?? '-'
      }\nLatausaseman tyyppi: ${station?.type ?? '-'}
      \nVikatyyppi: ${ticket?.error?.name ?? '-'}
      \nSLA: ${ticket?.sla ?? '-'}
      \nAlkanut: ${
        ticket?.errors[0]?.ALARM_START_TIME
          ? DateTimeString(ticket?.errors[0]?.ALARM_START_TIME)
          : ''
      }\n\nViestin ohjasi käyttäjä: ${
        user?.name
      }\n\nVoit suorittaa sinulle osoitetun työn osoitteessa: ${mgmt}\n Lisätietoa voit kysyä Enerimin valvomosta +358 29 020 9500.`,
      time: timestamp,
    };
    axios.post(`${integrator}/mail/out`, message, {
      headers: {
        Authorization:
          'Basic ' +
          Buffer.from(
            process.env.REACT_APP_IG_USERNAME +
              ':' +
              process.env.REACT_APP_IG_PASSWORD,
            'utf-8',
          ).toString('base64'),
      },
    });
    handleSave(ticket, [message]);
    setNewResourcedContacts([]);
    // }
  };

  const handleResourcingAlert = () => {
    const timestamp = Math.round(new Date() / 1000).toString();
    if (newResourcedContacts.length > 0) {
      databases.contacts
        .allDocs({
          include_docs: true,
          keys: newResourcedContacts.map(item => item.id),
        })
        .then(result => {
          result.rows.forEach(contact => {
            if (contact.doc.roles.includes('field_person')) {
              createNewTempUser(contact.doc, timestamp);
            } else {
              alertUser(contact.doc, false);
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  // Handle save
  const handleSave = async (ticket, messages, documentationSave = false) => {
    const currentDateTime = new Date();
    const timestamp = Math.round(currentDateTime / 1000).toString();

    //TICKET STATUS CHECK START
    let status = ticket.ticket_status ?? '';
    await GetNewTicketStatus(
      ticket,
      status,
      props.activeStep,
      false,
      '',
      documentationSave,
    ).then(new_status => {
      status = new_status;
    });

    let ticketCopy = {...ticket};
    if (status !== '' && status !== ticket.ticket_status) {
      ticketCopy = {...ticket, ticket_status: status};
    }
    //TICKET STATUS CHECK END

    console.log(ticketCopy, status);
    // const ticketCopy = { ...ticket }
    if (match.url !== '/new-ticket') {
      databases.tickets
        .get(ticket._id)
        .then(result => {
          let newLogId =
            ticket.log?.length > 0
              ? (
                  parseInt(
                    Math.max.apply(
                      Math,
                      ticket.log?.map(x => x.id),
                    ),
                  ) + 1
                ).toString()
              : '1';

          // Update _rev
          ticketCopy._rev = result._rev;

          if (status === 'documented') {
            ticketCopy.time_when_documented = timestamp;
          }

          // Update messages
          if (messages?.length > 0) {
            const newMessages = messages.concat(
              ticketCopy.alert_messages ? ticketCopy.alert_messages : [],
            );
            ticketCopy.alert_messages = newMessages;
          }

          // Check that log exists, and initialize if not
          if (!ticketCopy.log) {
            ticketCopy.log = [];
          }

          let eventName = `save_step_${ticket.stepper_step}`;
          if (eventName === 'save_step_4' && status === 'repair_finished') {
            eventName = 'save_step_4_2';
          }

          // Update log
          ticketCopy.log = [
            ...ticketCopy.log,
            {
              id: newLogId,
              time: timestamp,
              user: user,
              app: 'management',
              event: eventName,
            },
          ];

          // Add resourced timestamp if there is selected contact(s)
          ticketCopy.time_when_resourced = handleResourcingTime(
            ticketCopy,
            timestamp,
          );

          return databases.tickets.put(ticketCopy);
        })
        .then(result => {
          setTicket({...ticketCopy, _rev: result._rev});
          console.log(
            `${t('toast.ticket')} #${result.id} ${t('toast.updated')}`,
          );
          toast(`${t('toast.ticket')} #${result.id} ${t('toast.updated')}`);
          setIsEdited(false);
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_ticket')} #${ticket._id}:`,
            err,
          );
          toast(`${t('toast.error_updating_ticket')} #${ticket._id}`, err);
        });
    } else {
      // Add resourced timestamp if there is selected contact(s)
      ticketCopy.time_when_resourced = handleResourcingTime(
        ticketCopy,
        timestamp,
      );
      let newId =
        ticketCopy.log?.length > 0
          ? (
              parseInt(
                Math.max.apply(
                  Math,
                  ticketCopy.log?.map(x => x.id),
                ),
              ) + 1
            ).toString()
          : '1';
      ticketCopy = {
        ...ticketCopy,
        log: ticketCopy.log
          ? [
              ...ticketCopy.log,
              {
                id: newId,
                time: timestamp,
                user: user,
                app: 'management',
                event: `created_manual`,
              },
            ]
          : [
              {
                id: newId,
                time: timestamp,
                user: user,
                app: 'management',
                event: `created_manual`,
              },
            ],
      };
      if (!ticketCopy.errors[0]) {
        ticketCopy.errors[0] = {
          ALARM: 'on',
          STATION_ID: ticketCopy.station.id.toString() ?? '',
          ALARM_END_TIME: '',
          ALARM_START_TIME: currentDateTime.toISOString().slice(0, -5) + 'Z',
          CONNECTOR: '1',
          DESCRIPTION: '',
          SUMMARY: '',
          APP_TYPE: '',
          ALERTNAME: '',
          CATEGORY: '',
          SEVERITY: '',
        };
      }

      databases.tickets
        .post(ticketCopy)
        .then(result => {
          console.log(
            `${t('toast.ticket')} #${ticketCopy._id} ${t('toast.updated')}`,
          );
          toast(
            `${t('toast.ticket')} #${ticketCopy._id} ${t('toast.updated')}`,
          );
          setIsEdited(false);
          setTicket({});
          props.history.replace(`/tickets/${ticketCopy._id}`);
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_ticket')} #${ticketCopy._id}:`,
            err,
          );
          toast(`${t('toast.error_updating_ticket')} #${ticketCopy._id}`, err);
        });
    }
  };

  // Handle alert messages send
  const sendMessages = () => {
    setIsButtonActive(false);

    // Check if teams of mail
    if (alertUseType === 'teams') {
      const messages = [teamsAlertMessages];

      messages.forEach((message, i) => {
        message.time = Math.round(new Date() / 1000).toString();

        setIsButtonActive(true);

        axios
          .post(
            `${integrator}/teams/out`,
            {
              url: teamsAlertMessageRecipient,
              payload: message.body,
            },
            {
              headers: {
                Authorization:
                  'Basic ' +
                  Buffer.from(
                    process.env.REACT_APP_IG_USERNAME +
                      ':' +
                      process.env.REACT_APP_IG_PASSWORD,
                    'utf-8',
                  ).toString('base64'),
              },
            },
          )
          .then(res => {
            if (i + 1 === messages.length) {
              const messagesToSave = messages.map((message, i) => {
                message.body = JSON.stringify(message.body);
                return message;
              });
              handleSave(ticket, messagesToSave);
              setTeamsAlertMessages([]);
              // setAlertMessageRecipients([])
              setIsButtonActive(true);
            }
          })
          .catch(err => {
            console.log(err);
            setIsButtonActive(true);
          });
      });
    } else {
      const messages = [...alertMessages].concat(manualMessages);
      // const messages = [...manualMessages]

      if (messages.find(x => x.subject.includes('%') || x.body.includes('%'))) {
        toast(t('toast.no_fm_service'));
        setIsButtonActive(true);
      } else {
        messages.forEach((message, i) => {
          message.time = Math.round(new Date() / 1000).toString();

          setIsButtonActive(true);
          axios
            .post(`${integrator}/mail/out`, message, {
              headers: {
                Authorization:
                  'Basic ' +
                  Buffer.from(
                    process.env.REACT_APP_IG_USERNAME +
                      ':' +
                      process.env.REACT_APP_IG_PASSWORD,
                    'utf-8',
                  ).toString('base64'),
              },
            })
            .then(() => {
              if (i + 1 === messages.length) {
                handleSave(ticket, messages);
                setAlertMessages([]);
                // setAlertMessageRecipients([])
                setIsButtonActive(true);
                const content = `Sijainti: ${
                  ticket?.place?.name
                    ? ticket?.place?.name
                    : place?.doc?.name
                    ? place.doc?.name
                    : ''
                }\nSyy: Häiriö latauspistoolissa\n
                Alkanut: ${
                  ticket?.errors[0]?.ALARM_START_TIME
                    ? DateTimeString(ticket?.errors[0]?.ALARM_START_TIME)
                    : ''
                }\nArvioitu korjausaika: \nKorjattu: \n\nLisätietoja: linkappsd@telemast.com\n\nTerveisin\nTelemast Nordic Oy\n0105011000`;
                setAlertMessageContent(content);
                const subject = `Häiriöviesti ${
                  ticket?.place?.name
                    ? ticket?.place?.name
                    : place?.doc?.name
                    ? place.doc?.name
                    : ''
                }`;
                setAlertMessageSubject(subject);
              }
            })
            .catch(err => {
              console.log(err);
              setIsButtonActive(true);
            });
        });
      }
    }
  };

  const DateTimeLocal = (timestamp, pretty = false) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minute =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const dateString = pretty
      ? `${day}.${month}.${year}, ${hour}:${minute}`
      : `${year}-${month}-${day}T${hour}:${minute}`;

    return {dateString};
  };

  // Handle first time opening of ticket and set status to open
  useEffect(() => {
    if (
      ticket &&
      (ticket.ticket_status === 'faulted' ||
        ticket.ticket_status === '' ||
        !ticket.ticket_status)
    ) {
      GetNewTicketStatus(ticket, 'faulted', props.activeStep ?? 0).then(
        new_status => {
          handleTicketStatusUpdate(ticket, new_status);
        },
      );
    }
  }, []);

  // Handle closed ticket, after timeout redirect back to list
  const returnToTickets = ticket => {
    if (ticket.ticket_status === 'closed') {
      setTimeout(() => {
        history.go(-1);
      }, 3000);
    }
  };

  const handleTicketStatusUpdate = (ticket, new_status) => {
    console.log(ticket, new_status);
    if (match.url !== '/new-ticket') {
      setTicket({...ticket, ticket_status: new_status});
      setIsEdited(true);
      databases.tickets
        .get(ticket._id)
        .then(result => {
          let ticketCopy = {
            ...ticket,
            _rev: result._rev,
            ticket_status: new_status,
          };
          const timestamp = Math.round(new Date() / 1000).toString();
          let newId =
            ticket.log?.length > 0
              ? (
                  parseInt(
                    Math.max.apply(
                      Math,
                      ticket.log?.map(x => x.id),
                    ),
                  ) + 1
                ).toString()
              : '1';

          ticketCopy = {
            ...ticketCopy,
            ticket_status: new_status,
            log: ticket.log
              ? [
                  ...ticket.log,
                  {
                    id: newId,
                    time: timestamp,
                    user: user,
                    app: 'management',
                    event: `Työn tila muutettu: ${new_status}`,
                  },
                ]
              : [
                  {
                    id: newId,
                    time: timestamp,
                    user: user,
                    app: 'management',
                    event: `Työn tila muutettu: ${new_status}`,
                  },
                ],
          };
          return databases.tickets.put(ticketCopy);
        })
        .then(result => {
          console.log(
            `${t('toast.ticket')} #${ticket._id} ${t('toast.updated')}`,
          );
          toast(`${t('toast.ticket')} #${ticket._id} ${t('toast.updated')}`);
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_ticket')} #${ticket._id}:`,
            err,
          );
          toast(`${t('toast.error_updating_ticket')} #${ticket._id}`, err);
        });
    }
  };

  const getAlertMessageContent = () => {
    if (alertUseType === 'teams') {
      return (
        <>
          <IonText>
            <p className="label ion-margin-none">
              {t('tickets.message_recipients')}
            </p>
          </IonText>
          {(station.type === 'uc' &&
            (place.doc?.customer_service_teams === undefined ||
              place.doc?.customer_service_teams === '')) ||
          ((station.type === 'kempower' || station.type === 'ocpp') &&
            (place.doc?.lm_delivery_teams === undefined ||
              place.doc?.lm_delivery_teams === '')) ? (
            <IonText color="danger">
              <p className="ion-margin-top">Teams address missing</p>
            </IonText>
          ) : (
            <>
              <IonList className="ion-margin-bottom">
                <IonItem
                  key="ac-teams-channel"
                  lines="none"
                  onClick={() => {
                    console.log('set recipient as ac');
                  }}>
                  <IonLabel>{t('place.customer_service_teams')}</IonLabel>
                  <IonCheckbox
                    slot="start"
                    checked={station.type === 'uc'}
                    disabled={station.type !== 'uc'}
                  />
                </IonItem>
                <IonItem
                  key="dc-teams-channel"
                  lines="none"
                  onClick={() => {
                    console.log('Set recipient as dc');
                  }}>
                  <IonLabel>{t('place.lm_delivery_teams')}</IonLabel>
                  <IonCheckbox
                    slot="start"
                    checked={
                      station.type === 'kempower' || station.type === 'ocpp'
                    }
                    disabled={
                      station.type !== 'kempower' && station.type !== 'ocpp'
                    }
                  />
                </IonItem>
              </IonList>
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div className="ion-margin-bottom-half">
                    <label htmlFor="alert-message">
                      {t('tickets.message_subject')}
                    </label>
                  </div>
                  <textarea
                    rows="1"
                    disabled={true}
                    id="alert-message-subject"
                    value={`Häiriöviesti ${place.doc?.name ?? '-'}`}
                  />
                </FormControl>
              </div>
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div className="ion-margin-bottom-half">
                    <label htmlFor="teams-alert-message">
                      {t('tickets.repair_estimate')}
                    </label>
                  </div>
                  {estimatedRepairTime && (
                    <input
                      value={estimatedRepairTime}
                      type="datetime-local"
                      onChange={handleDateTimeChange}></input>
                  )}
                </FormControl>
              </div>
              <div className="ticket-step-row">
                <FormControl className="ticket-step-input">
                  <div className="ion-margin-bottom-half">
                    <label htmlFor="teams-alert-message">
                      {t('tickets.message_content')}
                    </label>
                  </div>
                  <textarea
                    rows="12"
                    disabled={true}
                    id="teams-alert-message"
                    value={JSON.stringify(teamsAlertMessageContent)}
                  />
                </FormControl>
              </div>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <IonText>
            <p className="label ion-margin-none">
              {t('tickets.message_recipients')}
            </p>
          </IonText>
          <IonList className="ion-margin-bottom">
            {ticket.resourced.map((resourced, i) => {
              const index = alertMessageRecipients.findIndex(
                x => x.id === resourced.id,
              );
              let disabled = false;
              // if (fmService.doc?.emails.length === 0) {
              // 	disabled = true
              // }
              if (ticket.is_active !== true) {
                disabled = true;
              }
              if (!permissions?.tickets) {
                disabled = true;
              }
              return (
                <IonItem
                  key={i}
                  lines="none"
                  onClick={() => {
                    const recipients = [...alertMessageRecipients];
                    if (index > -1) {
                      recipients.splice(index, 1);
                      setAlertMessageRecipients(recipients);
                    } else {
                      setAlertMessageRecipients([...recipients, resourced]);
                    }
                  }}>
                  <IonLabel>{resourced.id}</IonLabel>
                  <IonCheckbox
                    slot="start"
                    checked={index > -1}
                    disabled={disabled}
                  />
                </IonItem>
              );
            })}
          </IonList>
          <div className="ticket-step-row">
            <FormControl className="ticket-step-input">
              <div className="ion-margin-bottom-half">
                <label htmlFor="more-recipients">
                  {t('tickets.more_recipients')}
                </label>
              </div>
              <textarea
                rows="1"
                disabled={
                  ticket.is_active === true ? !permissions?.tickets : true
                }
                id="more-recipients"
                value={manualMessageRecipients}
                onChange={e => {
                  setManualMessageRecipients(e.target.value);
                }}
                placeholder={t('tickets.recipients_placeholder')}
              />
            </FormControl>
          </div>
          <div className="ticket-step-row">
            <FormControl className="ticket-step-input">
              <div className="ion-margin-bottom-half">
                <label htmlFor="alert-message">
                  {t('tickets.message_subject')}
                </label>
              </div>
              <textarea
                rows="1"
                disabled={
                  ticket.is_active === true ? !permissions?.tickets : true
                }
                id="alert-message-subject"
                value={alertMessageSubject}
                onChange={e => {
                  const messages = [...alertMessages];
                  messages.forEach(message => {
                    message.subject = e.target.value;
                  });
                  setAlertMessages(messages);
                  setAlertMessageSubject(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="ticket-step-row">
            <FormControl className="ticket-step-input">
              <div className="ion-margin-bottom-half">
                <label htmlFor="alert-message">
                  {t('tickets.message_content')}
                </label>
              </div>
              <textarea
                rows="12"
                disabled={
                  ticket.is_active === true ? !permissions?.tickets : true
                }
                id="alert-message"
                value={alertMessageContent}
                onChange={e => {
                  const messages = [...alertMessages];
                  messages.forEach(message => {
                    message.body_2 = e.target.value;
                  });
                  setAlertMessages(messages);
                  setAlertMessageContent(e.target.value);
                }}
              />
            </FormControl>
          </div>
        </>
      );
    }
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <>
            <IonText>
              <h2>{t('tickets.add_work_details')}</h2>
            </IonText>
            <div className="ticket-step-row">
              {/* ------- Place select ------------------------------------------------------------------------------------ */}
              <FormControl className="ticket-step-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="place">
                    {t('places.place')}
                    {match.url !== '/new-ticket' &&
                      !_.isEmpty(ticket.place) &&
                      props.places.findIndex(
                        place => place.doc.site_id === ticket.place.site_id,
                      ) === -1 && <MissingValueAlert />}
                  </label>
                </div>
                <NativeSelect
                  disabled={
                    ticket.is_active === true ? !permissions?.tickets : true
                  }
                  value={
                    ticket.place?.site_id
                      ? JSON.stringify(ticket.place)
                      : JSON.stringify({
                          name: place.doc?.name,
                          site_id: place.doc?.site_id,
                        })
                  }
                  onChange={e => {
                    setIsEdited(true);
                    setTicket({...ticket, place: JSON.parse(e.target.value)});
                  }}
                  inputProps={{
                    name: 'place',
                    id: 'place',
                  }}>
                  <option value="">Valitse kohde ...</option>
                  {props.places.map((place, i) => {
                    return (
                      <option
                        key={i}
                        value={JSON.stringify({
                          name: place.doc.name,
                          site_id: place.doc.site_id,
                        })}>
                        {place.doc?.name}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </div>
            <div className="ticket-step-row">
              {/* ------- Station select ------------------------------------------------------------------------------------------- */}
              <FormControl className="ticket-step-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="station">
                    {t('tickets.charging_station')}
                    {match.url !== '/new-ticket' &&
                      !_.isEmpty(ticket.station) &&
                      stations.findIndex(
                        station => station.id === ticket.station.id,
                      ) === -1 && <MissingValueAlert />}
                  </label>
                </div>
                <NativeSelect
                  disabled={
                    ticket.is_active === true ? !permissions?.tickets : true
                  }
                  value={
                    ticket.station
                      ? JSON.stringify(ticket.station)
                      : JSON.stringify({id: station.id, name: station.name})
                  }
                  onChange={e => {
                    setIsEdited(true);
                    setTicket({...ticket, station: JSON.parse(e.target.value)});
                  }}
                  inputProps={{
                    name: 'station',
                    id: 'station',
                  }}>
                  <option value={JSON.stringify({})}>
                    {t('tickets.choose')}{' '}
                    {t('tickets.charging_station').toLowerCase()} ...
                  </option>
                  {!_.isEmpty(ticket.station) && (
                    <option value={JSON.stringify(ticket.station)}>
                      {ticket.station.name}
                    </option>
                  )}
                  {stations &&
                    stations.length > 0 &&
                    stations
                      .filter(station => {
                        return ticket.station?.id !== station.id;
                      })
                      .map((station, i) => {
                        return (
                          <option
                            key={i}
                            value={JSON.stringify({
                              id: station.id,
                              name: station.name,
                            })}>
                            {station.name}
                          </option>
                        );
                      })}
                </NativeSelect>
              </FormControl>
            </div>

            <div className="ticket-step-row">
              {/* ------- Error / maintenance guide / accessories select ------------------------------------------------------------------------------------------- */}
              <FormControl className="ticket-step-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="error">
                    {t('places.error')}
                    {match.url !== '/new-ticket' &&
                      !_.isEmpty(ticket.error) &&
                      props.guides.findIndex(
                        guide => guide.doc._id === ticket.error.id,
                      ) === -1 && <MissingValueAlert />}
                  </label>
                </div>
                <NativeSelect
                  disabled={
                    ticket.is_active === true ? !permissions?.tickets : true
                  }
                  value={JSON.stringify(ticket.error)}
                  onChange={e => {
                    setIsEdited(true);
                    const error = JSON.parse(e.target.value);
                    const guide = props.guides.find(
                      guide => guide.doc._id === error.id,
                    );
                    setTicket({
                      ...ticket,
                      error: JSON.parse(e.target.value),
                      flow:
                        guide?.doc?.flows?.length > 0
                          ? guide?.doc?.flows[0]
                          : {},
                      maintenance_phases:
                        guide?.doc?.phases?.length > 0
                          ? guide?.doc?.phases
                          : [],
                      accessories:
                        guide?.doc?.accessories?.length > 0
                          ? guide?.doc?.accessories
                          : [],
                      flow_step: '0',
                    });
                  }}
                  inputProps={{
                    name: 'error',
                    id: 'error',
                  }}>
                  <option value={JSON.stringify({})} />
                  {Object.keys(ticket.error ?? {}).length > 0 && (
                    <option value={JSON.stringify(ticket.error)}>
                      {ticket.error.name}
                    </option>
                  )}
                  {props.guides
                    .filter(guide => {
                      return ticket.error?.id !== guide.doc._id;
                    })
                    .map((guide, i) => {
                      return (
                        <option
                          key={i}
                          value={JSON.stringify({
                            name: guide.doc.name,
                            id: guide.doc._id,
                          })}>
                          {guide.doc.name}
                        </option>
                      );
                    })}
                </NativeSelect>
              </FormControl>
            </div>
            <div className="ticket-step-row">
              {/* ------- SLA select ------------------------------------------------------------------------------------------- */}
              <FormControl className="ticket-step-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="sla">
                    {t('tickets.sla')}{' '}
                    {slas.findIndex(sla => sla === ticket.sla) === -1 && (
                      <MissingValueAlert />
                    )}
                  </label>
                </div>
                <NativeSelect
                  disabled={
                    ticket.is_active === true ? !permissions?.tickets : true
                  }
                  value={ticket.sla}
                  onChange={e => {
                    setIsEdited(true);
                    setTicket({
                      ...ticket,
                      sla:
                        e.target.value !== ''
                          ? parseInt(e.target.value)
                          : e.target.value,
                    });
                  }}
                  inputProps={{
                    name: 'sla',
                    id: 'sla',
                  }}>
                  {ticket.sla !== '' ? (
                    <option value={ticket.sla}>
                      {ticket.sla ? `${ticket.sla} h` : ''}
                    </option>
                  ) : (
                    <option value={''}>
                      {t('app.select')} {t('tickets.sla')} ...
                    </option>
                  )}
                  {slas
                    .filter(sla => {
                      return ticket.sla !== sla;
                    })
                    .map((sla, i) => {
                      return (
                        <option key={i} value={sla}>
                          {sla} h
                        </option>
                      );
                    })}
                </NativeSelect>
              </FormControl>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <IonText>
              <h2>{t('tickets.diagnostics_title')}</h2>
              <Diagnostics
                ticket={ticket}
                setTicket={setTicket}
                handleSave={handleSave}
                handleTicketStatusUpdate={handleTicketStatusUpdate}
                isEdited={isEdited}
                setIsEdited={setIsEdited}
                match={match}
                history={props.history}
                guides={props.guides}
                permissions={permissions}
              />
            </IonText>
            <div className="ticket-step-row">
              {/* ------- SLA select ------------------------------------------------------------------------------------------- */}
              <FormControl className="ticket-step-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="sla">
                    {t('tickets.sla')}{' '}
                    {slas.findIndex(sla => sla === ticket.sla) === -1 && (
                      <MissingValueAlert />
                    )}
                  </label>
                </div>
                <NativeSelect
                  disabled={
                    ticket.is_active === true ? !permissions?.tickets : true
                  }
                  value={ticket.sla}
                  onChange={e => {
                    setIsEdited(true);
                    setTicket({
                      ...ticket,
                      sla:
                        e.target.value !== ''
                          ? parseInt(e.target.value)
                          : e.target.value,
                    });
                  }}
                  inputProps={{
                    name: 'sla',
                    id: 'sla',
                  }}>
                  {ticket.sla !== '' && (
                    <option value={ticket.sla}>
                      {ticket.sla ? `${ticket.sla} h` : ''}
                    </option>
                  )}
                  {slas
                    .filter(sla => {
                      return ticket.sla !== sla;
                    })
                    .map((sla, i) => {
                      return (
                        <option key={i} value={sla}>
                          {sla} h
                        </option>
                      );
                    })}
                </NativeSelect>
              </FormControl>
            </div>
          </>
        );
      case 2:
        return (
          <>
            {/* ------- State of resourcing ------------------------------------------------------------------------------------------------ */}
            <div className="ticket-step-row">
              <FormControl className="ticket-step-input">
                <div className="ion-margin-bottom-half">
                  <label htmlFor="resourcing-state-label-placeholder">
                    {t('tickets.resourcing_state')}
                  </label>
                </div>
                <NativeSelect
                  disabled={
                    ticket.is_active === true ? !permissions?.tickets : true
                  }
                  value={ticket.resource_status}
                  onChange={e => {
                    // if (e.target.value !== "") {
                    handleChangeResourcingState(e.target.value);
                    // }
                  }}
                  inputProps={{
                    name: 'resourcing-state-label-placeholder',
                    id: 'resourcing-state-label-placeholder',
                  }}>
                  <option aria-label="None" value="">
                    {t('tickets.choose')}
                  </option>
                  <option value="resource">{t('tickets.resource')}</option>
                  <option value="queue_resourcing">
                    {t('tickets.queue_resourcing')}
                  </option>
                </NativeSelect>
              </FormControl>
            </div>
            {ticket.resource_status && (
              <div className="ticket-step-row">
                <h2 className="ion-margin-none">
                  {t('tickets.resourcing_title')}
                </h2>
              </div>
            )}
            {/* ------- Resourced manager select ------------------------------------------------------------------------------------------- */}
            {ticket.resource_status === 'queue_resourcing' &&
              ticket.resourced
                .filter(el => el.management)
                .map((resourcedContact, i) => {
                  return (
                    <div className="ticket-step-row" key={i}>
                      <FormControl className="ticket-step-input">
                        <div className="ion-margin-bottom-half">
                          <label htmlFor="manager-contact-label-placeholder">
                            {t('roles.manager')}
                          </label>
                        </div>
                        <NativeSelect
                          disabled={
                            !ticket.resource_status ||
                            ticket.resource_status === '' ||
                            ticket.is_active === true
                              ? !permissions?.tickets
                              : true
                          }
                          value={
                            JSON.stringify(resourcedContact) ||
                            JSON.stringify(place.doc?.place_manager)
                          }
                          onChange={e => {
                            handleContactChange(
                              resourcedContact,
                              e.target.value,
                            );
                          }}
                          inputProps={{
                            name: 'manager-contact-label-placeholder',
                            id: 'manager-contact-label-placeholder',
                          }}>
                          <option aria-label="None" value="" />
                          <option value={JSON.stringify(resourcedContact)}>
                            {resourcedContact.name}
                          </option>
                          {props.contacts
                            .filter(contact =>
                              contact.doc?.roles.includes('manager'),
                            )
                            .filter(contact => {
                              return (
                                ticket.resourced.findIndex(
                                  resourced => resourced.id === contact.doc._id,
                                ) === -1 && !contact.doc._id.includes('temp-')
                              );
                            })
                            .map((contact, i) => {
                              return (
                                <option
                                  key={i}
                                  value={JSON.stringify({
                                    id: contact.doc._id,
                                    name: contact.doc.name,
                                    management: true,
                                  })}>
                                  {contact.doc.name}
                                </option>
                              );
                            })}
                        </NativeSelect>
                      </FormControl>
                    </div>
                  );
                })}
            {ticket.resource_status === 'queue_resourcing' &&
              ticket.resourced.filter(el => el.management).length < 1 && (
                <div className="ticket-step-row">
                  <FormControl className="ticket-step-input">
                    <div className="ion-margin-bottom-half">
                      <label htmlFor="manager-contact-label-placeholder">
                        {t('roles.manager')}
                      </label>
                    </div>
                    <NativeSelect
                      disabled={
                        ticket.is_active === true ? !permissions?.tickets : true
                      }
                      value={JSON.stringify(place.doc?.place_manager) || ''}
                      onChange={e => {
                        // handleContactAdd(e.target.value, true);
                        handleContactChange(
                          JSON.stringify(place.doc?.place_manager) || '',
                          e.target.value,
                        );
                      }}
                      inputProps={{
                        name: 'manager-contact-label-placeholder',
                        id: 'manager-contact-label-placeholder',
                      }}>
                      <option aria-label="-" value="" />
                      {props.contacts
                        .filter(contact =>
                          contact.doc?.roles.includes('manager'),
                        )
                        .filter(contact => {
                          return (
                            ticket.resourced.findIndex(
                              resourced => resourced.id === contact.doc._id,
                            ) === -1 && !contact.doc._id.includes('temp-')
                          );
                        })
                        .map((contact, i) => {
                          return (
                            <option
                              key={i}
                              value={JSON.stringify({
                                id: contact.doc._id,
                                name: contact.doc.name,
                                management: true,
                              })}>
                              {contact.doc.name}
                            </option>
                          );
                        })}
                    </NativeSelect>
                  </FormControl>
                </div>
              )}
            {/* ------- Resourced contact select ------------------------------------------------------------------------------------------- */}
            {ticket.resource_status === 'resource' &&
              ticket.resourced
                .filter(el => !el.management)
                .map((resourcedContact, i) => {
                  return (
                    <div className="ticket-step-row" key={i}>
                      <FormControl className="ticket-step-input">
                        <div className="ion-margin-bottom-half">
                          <label htmlFor="resourcing-native-label-placeholder">
                            {t('tickets.field_maintenance')} {i + 1}
                            {props.contacts.findIndex(
                              contact =>
                                contact.doc._id === resourcedContact.id,
                            ) === -1 && <MissingValueAlert />}
                          </label>
                        </div>
                        <NativeSelect
                          disabled={
                            ticket.is_active === true
                              ? !permissions?.tickets
                              : true
                          }
                          value={JSON.stringify(resourcedContact)}
                          onChange={e =>
                            handleContactChange(
                              resourcedContact,
                              e.target.value,
                            )
                          }
                          inputProps={{
                            name: 'resourcing-native-label-placeholder',
                            id: 'resourcing-native-label-placeholder',
                          }}>
                          <option aria-label="None" value="" />
                          <option value={JSON.stringify(resourcedContact)}>
                            {resourcedContact.name}
                          </option>
                          {props.contacts
                            .filter(contact => {
                              return (
                                contact.doc._id !== resourcedContact.id &&
                                ticket.resourced.findIndex(
                                  resourced => resourced.id === contact.doc._id,
                                ) === -1 &&
                                !contact.doc._id.includes('temp-')
                              );
                            })
                            .map((contact, i) => {
                              return (
                                <option
                                  key={i}
                                  value={JSON.stringify({
                                    id: contact.doc._id,
                                    name: contact.doc.name,
                                  })}>
                                  {contact.doc.name}
                                </option>
                              );
                            })}
                        </NativeSelect>
                        <div>
                          <IonButton
                            className="ion-margin-none"
                            fill="clear"
                            color="primary"
                            routerLink={`/contacts/${resourcedContact.id}`}>
                            <IonIcon
                              color="primary"
                              icon={personCircleOutline}
                              slot="start"
                            />
                            {t('tickets.show_contact')}
                          </IonButton>
                        </div>
                      </FormControl>
                    </div>
                  );
                })}
            {/* ------- New resourced contact select ------------------------------------------------------------------------------------------- */}
            {ticket.resource_status === 'resource' &&
              (showNewResourcedContact ||
                ticket.resourced.filter(el => !el.management).length < 1) && (
                <div className="ticket-step-row">
                  <FormControl className="ticket-step-input">
                    <div className="ion-margin-bottom-half">
                      <label htmlFor="new-contact-label-placeholder">
                        {t('tickets.field_maintenance')}{' '}
                        {ticket?.resourced?.filter(el => !el.management)
                          .length + 1}
                      </label>
                    </div>
                    <NativeSelect
                      disabled={
                        ticket.is_active === true ? !permissions?.tickets : true
                      }
                      value=""
                      onChange={e => {
                        if (e.target.value !== '') {
                          handleContactAdd(e.target.value);
                        }
                      }}
                      inputProps={{
                        name: 'new-contact-label-placeholder',
                        id: 'new-contact-label-placeholder',
                      }}>
                      <option aria-label="None" value="" />
                      {props.contacts
                        .filter(contact => {
                          return (
                            ticket.resourced.findIndex(
                              resourced => resourced.id === contact.doc._id,
                            ) === -1 && !contact.doc._id.includes('temp-')
                          );
                        })
                        .map((contact, i) => {
                          return (
                            <option
                              key={i}
                              value={JSON.stringify({
                                id: contact.doc._id,
                                name: contact.doc.name,
                              })}>
                              {contact.doc.name}
                            </option>
                          );
                        })}
                    </NativeSelect>
                  </FormControl>
                </div>
              )}
            {ticket.resource_status === 'resource' &&
              (showNewResourcedContact ||
                ticket.resourced.filter(el => !el.management).length > 0) && (
                <button
                  type="button"
                  className="add-contact-button"
                  fill="clear"
                  color="primary"
                  onClick={() => {
                    handleShowContactAdd();
                  }}>
                  Lisää uusi työntekijä
                </button>
              )}
          </>
        );
      case 3:
        return (
          <>
            {/* ------- Alert message ------------------------------------------------------------------------------------------- */}
            <IonText>
              <h2>{t('tickets.send_alert')}</h2>
            </IonText>
            <IonSegment
              value={alertUseType}
              className="segment-blue ion-padding-bottom"
              onIonChange={e =>
                handleAlertMessageChannelChange(e.detail.value)
              }>
              <IonSegmentButton value="teams">
                <IonLabel>Teams</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="mail">
                <IonLabel>Mail</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            {!_.isEmpty(ticket.place) ? (
              getAlertMessageContent()
            ) : (
              <IonText>
                <p>{t('tickets.select_place')}</p>
              </IonText>
            )}
          </>
        );
      case 4:
        return (
          <>
            {/* ------- Documentation photos ------------------------------------------------------------------------------------------- */}
            {ticket.ticket_status === 'documented' && (
              <div className="alert-success">
                <h5>{t('tickets.ticket_documented')}</h5>
              </div>
            )}
            <IonText>
              <h2>{t('tickets.add_work_details')}</h2>
            </IonText>
            {/* <IonText>
							<h3>
								{t("tickets.photos")} ({ticket.images.length})
							</h3>
						</IonText> */}
            <div className="ticket-inner-wrap">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <IonIcon icon={chevronDownOutline} color="primary" />
                  }>
                  <IonText>
                    <h3 className="ion-margin-none">
                      {t('tickets.photos')} (
                      {ticket._attachments
                        ? Object.keys(ticket._attachments).length
                        : '0'}
                      )
                    </h3>
                  </IonText>
                </AccordionSummary>
                <AccordionDetails>
                  {ticket._attachments &&
                    ticket.images.length > 0 &&
                    ticket.images.map((image, i) => {
                      let attachment;
                      if (ticket._attachments && image.attachment_id !== '') {
                        attachment = ticket._attachments[image.attachment_id];
                      }
                      return (
                        <IonItem
                          key={i}
                          lines="none"
                          className="ion-margin-bottom">
                          <Link
                            className="attachment-link"
                            to={`/tickets/${ticket._id}/attachments/${image.attachment_id}`}>
                            {/* <IonThumbnail slot="start">{attachment && <IonImg src={`data:${attachment.content_type};base64,${attachment.data}`} alt="" />}</IonThumbnail> */}
                            <IonThumbnail slot="start">
                              {attachment && attachment.digest && (
                                <IonImg
                                  src={`data:${attachment.content_type};base64,${attachment.data}`}
                                  alt=""
                                />
                              )}
                              {attachment && !attachment.digest && (
                                <IonImg
                                  src={window.URL.createObjectURL(
                                    attachment.data,
                                  )}
                                  alt=""
                                />
                              )}
                            </IonThumbnail>
                            <IonText className="ion-margin-left">
                              <p className="ion-margin-none">{image.text}</p>
                            </IonText>
                          </Link>
                          <a
                            slot="end"
                            className="ion-margin-none"
                            href={`data:${attachment.content_type};base64,${attachment.data}`}
                            download={image.attachment_id}>
                            <IonButton size="small" fill="clear" color="medium">
                              <IonIcon
                                slot="icon-only"
                                icon={downloadOutline}
                              />
                            </IonButton>
                          </a>
                          <IonButton
                            disabled={
                              ticket.is_active === true
                                ? !permissions?.canDelete
                                : true
                            }
                            className="ion-margin-none"
                            slot="end"
                            onClick={() =>
                              handleImageDelete(image.attachment_id)
                            }
                            size="small"
                            fill="clear"
                            color="medium">
                            <IonIcon slot="icon-only" icon={trashOutline} />
                          </IonButton>
                        </IonItem>
                      );
                    })}
                  <div className="file-upload">
                    <div className="file-input-container">
                      <div className="file-input-wrap">
                        {newImage.name && (
                          <p className="ion-margin-none ion-margin-right-half">
                            {newImage.name}
                          </p>
                        )}
                        <IonButton
                          size="default"
                          fill="clear"
                          color={newImage.name ? 'medium' : 'primary'}
                          onClick={() => fileInput.current.click()}>
                          <IonIcon
                            className="ion-margin-right-half"
                            slot="start"
                            icon={newImage.name ? syncOutline : imageOutline}
                          />
                          {newImage.name
                            ? t('app.change_image')
                            : t('app.add_image')}
                        </IonButton>
                        <input
                          ref={fileInput}
                          type="file"
                          accept="image/*"
                          hidden
                          value=""
                          onChange={e => handleCompressedUpload(e)}
                        />
                      </div>
                      {newImage.name && (
                        <div className="file-input-wrap">
                          <textarea
                            placeholder="Kirjoita kuvateksti"
                            value={imageCaption}
                            onChange={e => setImageCaption(e.target.value)}
                            autoComplete="off"
                          />
                          <IonButton
                            className="image-upload-button"
                            size="large"
                            fill="clear"
                            onClick={() => handleImageUpload()}>
                            <IonIcon slot="icon-only" icon={sendOutline} />
                          </IonButton>
                        </div>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* <IonList>
								{ticket._attachments &&
									ticket.images.length > 0 &&
									ticket.images.map((image, i) => {
										let attachment
										if (ticket._attachments && image.attachment_id !== "") {
											attachment = ticket._attachments[image.attachment_id]
										}
										return (
											<IonItem key={i} lines="none" className="ion-margin-bottom">
												<Link className="attachment-link" to={`/tickets/${ticket._id}/attachments/${image.attachment_id}`}>
													<IonThumbnail slot="start">{attachment && <IonImg src={`data:${attachment.content_type};base64,${attachment.data}`} alt="" />}</IonThumbnail>
													<IonText className="ion-margin-left">
														<p className="ion-margin-none">{image.text}</p>
													</IonText>
												</Link>
												<a slot="end" className="ion-margin-none" href={`data:${attachment.content_type};base64,${attachment.data}`} download={image.attachment_id}>
													<IonButton size="small" fill="clear" color="medium">
														<IonIcon slot="icon-only" icon={downloadOutline} />
													</IonButton>
												</a>
												<IonButton disabled={ticket.is_active === true ? !permissions?.canDelete : true} className="ion-margin-none" slot="end" onClick={() => handleImageDelete(image.attachment_id)} size="small" fill="clear" color="medium">
													<IonIcon slot="icon-only" icon={trashOutline} />
												</IonButton>
											</IonItem>
										)
									})}
							</IonList> */}
            </div>
            <Divider className="ion-margin-top" />
            {/* ------- Documentation comments ------------------------------------------------------------------------------------------- */}
            {/* <IonText>
							<h3>
								{t("tickets.comments")} ({ticket.comments.length})
							</h3>
						</IonText> */}
            <div className="ticket-inner-wrap">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <IonIcon icon={chevronDownOutline} color="primary" />
                  }>
                  <IonText>
                    <h3 className="ion-margin-none">
                      {t('tickets.comments')} ({ticket.comments.length})
                    </h3>
                  </IonText>
                </AccordionSummary>
                <AccordionDetails>
                  {ticket.comments.map((comment, i) => {
                    const contactIndex = props.contacts.findIndex(
                      contact => contact.doc._id === comment.contact_id,
                    );
                    const contact = props.contacts[contactIndex];
                    return (
                      <CommentCard
                        disabled={
                          ticket.is_active === true
                            ? !permissions?.tickets
                            : true
                        }
                        key={i}
                        contact={contact && contact.doc}
                        comment={comment}
                        ticket={ticket}
                        setTicket={setTicket}
                        setIsEdited={setIsEdited}
                        handleDelete={handleCommentDelete}
                        permissions={permissions}
                      />
                    );
                  })}
                  <div className="file-upload">
                    <textarea
                      placeholder={t('app.write_comment')}
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                      autoComplete="off"
                    />
                    <IonButton
                      className="file-upload-button"
                      disabled={comment === ''}
                      color="primary"
                      size="large"
                      fill="clear"
                      onClick={() => {
                        handleCommentUpload();
                        setComment('');
                      }}>
                      <IonIcon slot="icon-only" icon={sendOutline} />
                    </IonButton>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* <IonList>
								{ticket.comments.map((comment, i) => {
									const contactIndex = props.contacts.findIndex((contact) => contact.doc._id === comment.contact_id)
									const contact = props.contacts[contactIndex]
									return (
										<CommentCard
											disabled={ticket.is_active === true ? !permissions?.tickets : true}
											key={i}
											contact={contact && contact.doc}
											comment={comment}
											ticket={ticket}
											setTicket={setTicket}
											setIsEdited={setIsEdited}
											handleDelete={handleCommentDelete}
											permissions={permissions}
										/>
									)
								})}
							</IonList> */}
            </div>
            <Divider className="ion-margin-top ion-margin-bottom" />
            {/* ------- Documentation maintenance phases ------------------------------------------------------------------------------------------- */}
            <IonText>
              <h3>
                {t('tickets.maintenance_phases')} (
                {
                  ticket.maintenance_phases.filter(item => {
                    return item.is_checked === true;
                  }).length
                }
                )
              </h3>
            </IonText>
            <div className="ticket-step-row">
              <IonList>
                {ticket.maintenance_phases
                  .filter(phase => {
                    return phase.is_checked === true;
                  })
                  .map((phase, i) => {
                    return (
                      <IonItem key={i} lines="full">
                        <IonText slot="start">{i + 1}.</IonText>
                        <IonLabel>{phase.text}</IonLabel>
                      </IonItem>
                    );
                  })}
              </IonList>
            </div>
            <div className="ticket-step-row"></div>
            {/* ------- Documentation used accessories ------------------------------------------------------------------------------------------- */}
            <IonText>
              <h3>
                {t('tickets.used_accessories')} (
                {
                  ticket.accessories.filter(item => {
                    return item.is_used === true;
                  }).length
                }
                )
              </h3>
            </IonText>
            <div className="ticket-step-row">
              <IonList>
                {ticket.accessories
                  .filter(accessory => {
                    return accessory.is_used === true;
                  })
                  .map((accessory, i) => {
                    return (
                      <IonItem key={i} lines="full">
                        <IonText slot="start">{i + 1}.</IonText>
                        <IonLabel>{accessory.name}</IonLabel>
                      </IonItem>
                    );
                  })}
              </IonList>
            </div>
            <div className="ticket-step-row">
              {/* ------- Summary text ------------------------------------------------------------------------------------------- */}
              {/* <FormControl className="ticket-step-input">
								<div className="ion-margin-bottom-half">
									<label htmlFor="summary">{t("tickets.summary")}</label>
								</div>
								<textarea
									disabled={ticket.is_active === true ? !permissions?.tickets : true}
									id="summary"
									name="summary"
									value={ticket.summary}
									onChange={(e) => {
										setIsEdited(true)
										setTicket({ ...ticket, summary: e.target.value })
									}}
								/>
							</FormControl> */}
            </div>
          </>
        );
      case 5:
        return (
          <>
            <IonText>
              <p>{t('tickets.last_step')}</p>
            </IonText>
          </>
        );
      default:
        return (
          <>
            <IonText>
              <p>{t('tickets.unknown_step')}</p>
            </IonText>
          </>
        );
    }
  };

  return (
    <>
      {getStepContent(props.activeStep)}
      {props.activeStep <= props.steps.length - 1 && props.activeStep !== 3 && (
        <div className="ticket-step-row">
          {/* ------- More info text ------------------------------------------------------------------------------------------- */}
          <FormControl className="ticket-step-input">
            <div className="ion-margin-bottom-half">
              <label htmlFor="more-info">{t('tickets.more_info')}</label>
            </div>
            <textarea
              disabled={
                ticket.is_active === true ? !permissions?.tickets : true
              }
              id="more-info"
              name="more-info"
              value={ticket.more_info}
              onChange={e => {
                setIsEdited(true);
                setTicket({...ticket, more_info: e.target.value});
              }}
            />
          </FormControl>
        </div>
      )}
      <StepperFooter
        isEdited={isEdited}
        setIsEdited={setIsEdited}
        history={props.history}
        match={match}
        steps={props.steps}
        activeStep={props.activeStep}
        setActiveStep={props.setActiveStep}
        handleActiveStep={props.handleActiveStep}
        initialState={ticket}
        currentState={ticket}
        handleSave={handleSave}
        handleResourcingAlert={handleResourcingAlert}
        setTicket={setTicket}
        alertMessages={alertMessages}
        manualMessages={manualMessages}
        sendMessages={sendMessages}
        isButtonActive={isButtonActive}
        permissions={permissions}
        alertUseType={alertUseType}
        setTeamsAlertMessageRecipient={setTeamsAlertMessageRecipient}
        returnToTickets={returnToTickets}
      />
    </>
  );
};

export default TicketSteps;

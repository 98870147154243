import React, {useRef, useState, useEffect} from 'react';
import {
  IonText,
  IonList,
  IonItem,
  IonThumbnail,
  IonButton,
  IonIcon,
  IonImg,
  IonRow,
  IonCol,
  IonGrid,
} from '@ionic/react';
import {
  trashOutline,
  downloadOutline,
  addOutline,
  syncOutline,
} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import useToast from '../../hooks/useToast';
import Compressor from 'compressorjs';

const PictureList = ({
  place,
  setPlace,
  permissions,
  isEdited,
  setIsEdited,
  listName,
  tabName,
  selectionType,
  fmServiceId,
  savePlaceChanges,
}) => {
  const {t} = useTranslation('link_app');
  const fileInput = useRef(null);
  const toast = useToast();
  const attachments = place._attachments;

  // States for input fieds
  const [image, setImage] = useState({});
  const [caption, setCaption] = useState('');
  const [images, setImages] = useState([]);

  useEffect(() => {
    //  Get images & attachments
    if (place[tabName] && place[tabName][listName]) {
      setImages(place[tabName][listName].images);
    } else {
      setImages([]);
    }
  }, [place, tabName, listName]);

  // Compress image before upload
  const handleCompressedUpload = e => {
    const img = e.target.files[0];
    new Compressor(img, {
      quality: 0.6, // 0.6 can also be used, but it is not recommended to go below
      success: result => {
        // result has the compressed file
        setImage(result);
      },
    });
  };

  const addImage = () => {
    const images = place[tabName][listName]?.images
      ? [...place[tabName][listName].images]
      : [];

    if (!images.find(x => x.attachment_id === image.name)) {
      let newId =
        images?.length > 0
          ? (
              parseInt(
                Math.max.apply(
                  Math,
                  images.map(x => x.id),
                ),
              ) + 1
            ).toString()
          : '1';
      const newImage = {
        id: newId,
        attachment_id:
          image.name !== 'image.jpg'
            ? image.name.replace(/ /g, '-')
            : `${Math.round(new Date() / 1000)}.jpg`,
        text: caption,
      };
      images.push(newImage);
      const newAttachments = {
        ...place._attachments,
        [image.name !== 'image.jpg'
          ? image.name.replace(/ /g, '-')
          : `${Math.round(new Date() / 1000)}.jpg`]: {
          content_type: image.type,
          data: image,
        },
      };
      const placeToSave = {
        ...place,
        [tabName]: {
          ...place[tabName],
          [listName]: {...place[tabName][listName], images: images},
        },
        _attachments: newAttachments,
      };
      setPlace(placeToSave);
      setImage({});
      setCaption('');
      setIsEdited(true);
    } else {
      toast(t('toast.image_already_added'));
    }
  };

  const removeImage = imageId => {
    const images = [...place[tabName][listName].images];
    const imageIndex = images.findIndex(x => x.attachment_id === imageId);
    images.splice(imageIndex, 1);
    const attachments = {...place._attachments};
    delete attachments[imageId];
    setPlace({
      ...place,
      [tabName]: {
        ...place[tabName],
        [listName]: {...place[tabName][listName], images: images},
      },
      _attachments: attachments,
    });
    setIsEdited(true);
  };

  return (
    <IonGrid className="ion-padding-none ion-margin-top">
      <IonRow>
        <IonCol>
          <IonList>
            {images?.map((image, i) => {
              let attachment;
              if (attachments && image.attachment_id !== '') {
                attachment = attachments[image.attachment_id];
              }
              return (
                <IonItem key={i} lines="none" className="ion-margin-bottom">
                  <Link
                    className="attachment-link"
                    to={`/places/${selectionType}/${
                      fmServiceId ? fmServiceId : place._id
                    }/attachments/${image.attachment_id}`}>
                    <IonThumbnail slot="start">
                      {attachment && attachment.digest && (
                        <IonImg
                          src={`data:${attachment.content_type};base64,${attachment.data}`}
                          alt=""
                        />
                      )}
                      {attachment && !attachment.digest && (
                        <IonImg
                          src={window.URL.createObjectURL(attachment.data)}
                          alt=""
                        />
                      )}
                    </IonThumbnail>
                    <IonText className="ion-margin-left">{image.text}</IonText>
                  </Link>
                  <a
                    slot="end"
                    className="ion-margin-none"
                    href={`data:${attachment?.content_type};base64,${attachment?.data}`}
                    download={image.attachment_id}>
                    <IonButton size="small" fill="clear" color="medium">
                      <IonIcon slot="icon-only" icon={downloadOutline} />
                    </IonButton>
                  </a>
                  <IonButton
                    disabled={!permissions?.canDelete}
                    className="ion-margin-none"
                    slot="end"
                    onClick={() => removeImage(image.attachment_id)}
                    size="small"
                    fill="clear"
                    color="medium">
                    <IonIcon slot="icon-only" icon={trashOutline} />
                  </IonButton>
                </IonItem>
              );
            })}
          </IonList>
          <div className="ion-margin-top">
            <div className="file-upload">
              <div className="file-input-container">
                <div className="file-input-wrap">
                  {image.name && (
                    <p className="ion-margin-none ion-margin-right-half">
                      {image.name}
                    </p>
                  )}
                  <div className="input-wrap ion-text-right">
                    <IonButton
                      disabled={!permissions?.places}
                      size="default"
                      fill="clear"
                      color={image.name ? 'medium' : 'primary'}
                      onClick={() => fileInput.current.click()}>
                      <IonIcon
                        className="ion-margin-right-half"
                        slot="start"
                        icon={image.name ? syncOutline : addOutline}
                      />
                      {image.name ? t('app.change_image') : t('app.add_image')}
                    </IonButton>
                    {image.name && (
                      <IonButton
                        disabled={!permissions?.places}
                        size="default"
                        fill="clear"
                        color={image.name ? 'medium' : 'primary'}
                        onClick={() => {
                          setImage({});
                          setCaption('');
                        }}>
                        {t('app.clear')}
                      </IonButton>
                    )}
                    {!image.name && (
                      <IonButton
                        disabled={!isEdited}
                        size="default"
                        fill="solid"
                        color="success"
                        onClick={() => {
                          savePlaceChanges(place);
                          setIsEdited(false);
                        }}>
                        {t('app.save')}
                      </IonButton>
                    )}
                    <input
                      ref={fileInput}
                      type="file"
                      accept="image/*"
                      hidden
                      value=""
                      onChange={e => handleCompressedUpload(e)}
                    />
                  </div>
                </div>
              </div>
              {image.name && (
                <textarea
                  placeholder={t('app.add_caption')}
                  value={caption}
                  onChange={e => setCaption(e.target.value)}
                  autoComplete="off"
                />
              )}
              {image.name && (
                <div className="ion-text-right">
                  <IonButton
                    disabled={!permissions?.places}
                    className="ion-margin-top"
                    size="default"
                    fill="clear"
                    color="primary"
                    onClick={() => addImage()}>
                    <IonIcon
                      className="ion-margin-right-half"
                      slot="start"
                      icon={addOutline}
                    />
                    {t('app.add')}
                  </IonButton>
                </div>
              )}
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default PictureList;

import React, {useState, useEffect, useContext} from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
} from '@ionic/react';
import {lockClosedOutline, closeOutline} from 'ionicons/icons';
import {Paper, NativeSelect, FormControl, TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useToast from '../../hooks/useToast';
import roles from '../../data/roles';
import {DatabaseContext} from '../context/DatabaseContext';
import generator from 'generate-password';

const ContactInfo = props => {
  const {t} = useTranslation('link_app');
  const match = props.match;
  const permissions = props.permissions;
  const toast = useToast();

  // Databases
  const databases = useContext(DatabaseContext);

  // Copy contact to local state
  const [contact, setContact] = useState(props.doc);
  useEffect(() => {
    setContact(props.doc);
  }, [match, props.doc]);

  // Fetch places
  const [places, setPlaces] = useState([]);
  useEffect(() => {
    databases.places
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setPlaces(docs);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.places]);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  // PW input state
  const [pw, setPw] = useState('');
  useEffect(() => {
    setPw(
      generator.generate({
        length: 24,
        numbers: true,
        symbols: true,
        lowercase: true,
        uppercase: true,
      }),
    );
  }, [match]);

  // New PW state
  const [isPwActive, setIsPwActive] = useState(false);
  const [newPw, setNewPw] = useState('');
  useEffect(() => {
    setNewPw(
      generator.generate({
        length: 24,
        numbers: true,
        symbols: true,
        lowercase: true,
        uppercase: true,
      }),
    );
  }, [match, isPwActive]);

  // Save edited contact
  const handleSave = () => {
    if (match.url !== '/new-contact') {
      databases.contacts
        .get(contact._id)
        .then(() => {
          return databases.contacts.put(contact);
        })
        .then(() => {
          console.log(
            `${t('toast.contact')} "${contact.name}" ${t('toast.updated')}`,
          );
          toast(
            `${t('toast.contact')} "${contact.name}" ${t('toast.updated')}`,
          );
          setIsEdited(false);
          return databases.contacts.get(contact._id);
        })
        .then(doc => {
          // Update edited contact state to match new _rev
          setContact(doc);

          if (isPwActive && newPw.length >= 12) {
            databases.users
              .get(`org.couchdb.user:${doc._id}`)
              .then(result => {
                return databases.users.put({...result, password: newPw});
              })
              .then(result => {
                setIsPwActive(false);
              })
              .catch(err => {
                console.log(err);
              });
          } else if (isPwActive && newPw.length < 12) {
            toast(t('toast.password_too_short'));
          }
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_contact')} "${contact.name}":`,
            err,
          );
          toast(`${t('toast.error_updating_contact')} "${contact.name}"`, err);
        });
    } else {
      const contactCopy = {...contact, _id: contact.email};
      databases.contacts
        .put(contactCopy)
        .then(() => {
          console.log(
            `${t('toast.contact')} "${contact.name}" ${t('toast.updated')}`,
          );
          toast(
            `${t('toast.contact')} "${contact.name}" ${t('toast.updated')}`,
          );
          setIsEdited(false);

          if (contact.roles.length > 0 && pw.length >= 12) {
            const newUser = {
              _id: `org.couchdb.user:${contactCopy._id}`,
              name: contactCopy._id,
              type: 'user',
              roles: contactCopy.roles,
              password: pw,
            };
            databases.users
              .put(newUser)
              .then(response => {
                console.log(response);
                props.history.push('/contacts');
              })
              .catch(err => {
                console.log(err);
                toast(
                  `${t('toast.error_updating_contact')} "${newUser.name}"`,
                  err,
                );
              });
          } else if (contact.roles.length > 0 && pw.length < 12) {
            toast(t('toast.password_too_short'));
          } else {
            props.history.push('/contacts');
          }
        })
        .catch(err => {
          console.log(
            `${t('toast.error_updating_contact')} "${contact.name}":`,
            err,
          );
          toast(`${t('toast.error_updating_contact')} "${contact.name}"`, err);
        });
    }
  };

  return (
    <>
      <Paper style={{marginTop: '32px'}}>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-margin-none">
              <div className="ion-avatar-container">
                <div className="contact-avatar ion-margin-bottom">
                  <h1 className="ion-margin-none">
                    {contact.name !== '' &&
                    contact.name.split(' ').length >= 1 ? (
                      <>
                        {contact.name.split(' ').length >= 1 &&
                          contact.name.split(' ')[0].charAt(0)}
                        {contact.name.split(' ').length > 1 &&
                          contact.name.split(' ')[1].charAt(0)}
                      </>
                    ) : (
                      '?'
                    )}
                  </h1>
                </div>
                <IonText>
                  <h4 className="ion-margin-bottom-half">
                    {contact.name !== '' ? contact.name : t('contacts.name')}
                  </h4>
                </IonText>
                <IonText>
                  <p className="ion-margin-none">
                    {contact.title !== '' ? contact.title : t('contacts.title')}
                  </p>
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonList>
          <IonItem lines="none" className="ion-margin-top ion-margin-bottom">
            <IonText>
              <h4 className="ion-margin-none">{t('contacts.details')}</h4>
            </IonText>
          </IonItem>
          <IonItem lines="none">
            <div className="row">
              <FormControl className="row-input">
                <div>
                  <label htmlFor="name">{t('contacts.name')}</label>
                </div>
                <TextField
                  id="name"
                  value={contact.name}
                  onChange={e => {
                    setIsEdited(true);
                    setContact({...contact, name: e.target.value});
                  }}
                  disabled={!permissions?.contacts}
                />
              </FormControl>
            </div>
          </IonItem>

          <IonItem lines="none">
            <div className="row">
              <FormControl className="row-input">
                <div>
                  <label htmlFor="title">{t('contacts.title')}</label>
                </div>
                <TextField
                  id="title"
                  value={contact.title}
                  onChange={e => {
                    setIsEdited(true);
                    setContact({...contact, title: e.target.value});
                  }}
                  disabled={!permissions?.contacts}
                />
              </FormControl>
            </div>
          </IonItem>

          <IonItem lines="none">
            <div className="row">
              <FormControl className="row-input">
                <div>
                  <label htmlFor="company">{t('contacts.company')}</label>
                </div>
                <TextField
                  id="company"
                  value={contact.company}
                  onChange={e => {
                    setIsEdited(true);
                    setContact({...contact, company: e.target.value});
                  }}
                  disabled={!permissions?.contacts}
                />
              </FormControl>
            </div>
          </IonItem>

          <IonItem lines="none">
            <div className="row">
              <FormControl className="row-input">
                <div>
                  <label htmlFor="phone-number">{t('contacts.phone')}</label>
                </div>
                <TextField
                  id="phone-number"
                  value={contact.phone_number}
                  onChange={e => {
                    setIsEdited(true);
                    setContact({...contact, phone_number: e.target.value});
                  }}
                  disabled={!permissions?.contacts}
                />
              </FormControl>
            </div>
          </IonItem>

          <IonItem lines="none">
            <div className="row">
              <FormControl className="row-input">
                <div>
                  <label htmlFor="email">{t('contacts.email')}</label>
                </div>
                <input
                  type="email"
                  id="email"
                  value={contact.email}
                  onChange={e => {
                    setIsEdited(true);
                    setContact({...contact, email: e.target.value});
                  }}
                  disabled={!permissions?.contacts}
                />
                {contact.email === '' && (
                  <IonText className="place-item-errortext" color="danger">
                    {t('helper_texts.field_can_not_be_empty')}
                  </IonText>
                )}
              </FormControl>
            </div>
          </IonItem>

          <IonItem lines="none">
            <div className="row">
              <FormControl className="row-input">
                <div>
                  <label htmlFor="role">{t('contacts.role')}</label>
                </div>
                <NativeSelect
                  id="role"
                  value={contact.roles.toString()}
                  onChange={e => {
                    setIsEdited(true);
                    setContact({
                      ...contact,
                      roles:
                        e.target.value !== 'contact' ? [e.target.value] : [],
                    });
                  }}
                  inputProps={{
                    name: 'role',
                    id: 'role',
                  }}
                  disabled={!permissions?.contacts}>
                  {roles.map((role, i) => {
                    return (
                      <option key={i} value={role}>
                        {t(`roles.${role}`)}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </div>
          </IonItem>
          {contact.roles.includes('ikea') && (
            <IonItem lines="none">
              <div className="row">
                <FormControl className="row-input">
                  <div>
                    <label htmlFor="ikea_location">
                      IKEA {t('contacts.role')}
                    </label>
                  </div>
                  <NativeSelect
                    id="ikea_location"
                    value={contact.ikea_location}
                    onChange={e => {
                      setIsEdited(true);
                      setContact({
                        ...contact,
                        ikea_location: e.target.value,
                      });
                    }}
                    inputProps={{
                      name: 'ikea_location',
                      id: 'ikea_location',
                    }}
                    disabled={!permissions?.contacts}>
                    {places.length > 0 &&
                      places.map((place, i) => {
                        return (
                          <option
                            key={place?.doc?.name}
                            value={place?.doc?.site_id}>
                            {place?.doc?.name}
                          </option>
                        );
                      })}
                    <option key={'manager'} value={'manager'}>
                      Manager
                    </option>
                  </NativeSelect>
                </FormControl>
              </div>
            </IonItem>
          )}
          {contact.roles.length > 0 &&
            contact.roles[0] !== 'maintenance_organization' && (
              <>
                <IonItem lines="none" className="ion-margin-bottom-half">
                  <IonText>
                    <h4 className="ion-margin-none">
                      {t('contacts.login_details')}
                    </h4>
                  </IonText>
                </IonItem>
                {match.url !== '/new-contact' ? (
                  <>
                    {!isPwActive && (
                      <IonItem
                        button
                        lines="none"
                        className="ion-padding-top-half ion-padding-bottom-half"
                        disabled={!permissions?.contacts}
                        onClick={() => {
                          setIsPwActive(true);
                          setIsEdited(true);
                        }}>
                        <IonIcon
                          icon={lockClosedOutline}
                          color="primary"
                          slot="start"
                          className="ion-margin-right"
                        />
                        <IonLabel>{t('contacts.change_password')}</IonLabel>
                      </IonItem>
                    )}
                    {isPwActive && (
                      <IonItem lines="none" className="ion-margin-bottom-half">
                        <div className="row">
                          <FormControl className="row-input">
                            <div>
                              <label htmlFor="new-pw">
                                {t('contacts.new_password')}
                              </label>
                            </div>
                            <input
                              id="new-pw"
                              className="ticket-step-input"
                              type="text"
                              value={newPw}
                              onChange={e => {
                                setNewPw(e.target.value);
                                setIsEdited(true);
                              }}
                              disabled={!permissions?.contacts}
                            />
                            {newPw.length < 12 && (
                              <IonText
                                className="place-item-errortext"
                                color="danger">
                                {t('toast.password_too_short')}
                              </IonText>
                            )}
                          </FormControl>
                        </div>
                        <IonButton
                          fill="clear"
                          className="ion-margin-none ion-margin-left-half"
                          onClick={() => setIsPwActive(false)}>
                          <IonIcon
                            slot="icon-only"
                            color="medium"
                            icon={closeOutline}
                          />
                        </IonButton>
                      </IonItem>
                    )}
                  </>
                ) : (
                  <IonItem lines="none" className="ion-margin-bottom-half">
                    <div className="row">
                      <FormControl className="row-input">
                        <div>
                          <label htmlFor="pw">{t('contacts.password')}</label>
                        </div>
                        <input
                          id="pw"
                          className="ticket-step-input"
                          type="text"
                          placeholder={t('contacts.add_password')}
                          value={pw}
                          onChange={e => {
                            setPw(e.target.value);
                            setIsEdited(true);
                          }}
                          disabled={!permissions?.contacts}
                        />
                        {pw.length < 12 && (
                          <IonText
                            className="place-item-errortext"
                            color="danger">
                            {t('toast.password_too_short')}
                          </IonText>
                        )}
                      </FormControl>
                    </div>
                  </IonItem>
                )}
              </>
            )}
        </IonList>
      </Paper>
      <div>
        {/* <IonButton
					style={{ marginTop: "16px", marginBottom: "32px" }}
					onClick={() => {
						setContact(props.doc)
						props.history.push("/contacts")
					}}
					className="ion-margin-right"
					fill="outline"
					color="danger"
				>
					{t("app.abort")}
				</IonButton> */}
        <IonButton
          style={{marginTop: '16px', marginBottom: '32px'}}
          disabled={!isEdited && !contact.email !== ''}
          onClick={() => handleSave()}
          className="ion-margin-right"
          fill="solid"
          color="success">
          {t('app.save')}
        </IonButton>
      </div>
    </>
  );
};

export default ContactInfo;

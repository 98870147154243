import React, {useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import ContactInfo from '../../components/contacts/ContactInfo';
import {useTranslation} from 'react-i18next';
import {AuthContext} from '../../components/context/AuthContext';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import useTitleEffect from '../../hooks/useTitle';


const NewContact = props => {
  const {match, permissions} = props;
  const {t} = useTranslation('link_app');
  const doc = {
    _id: '',
    roles: [],
    name: '',
    phone_number: '',
    email: '',
    title: '',
    company: '',
    auto_mode: false,
    dark_mode: false,
  };

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Databases
  const databases = useContext(DatabaseContext);

  useTitleEffect(t('contacts.new_contact'));
  useEffect(() => {
    databases.contacts.info().then(res => {
      if (res.error === 'unauthorized') {
        setIsAuth(false);
      }
    });
  }, [match, t, databases.contacts, setIsAuth]);

  return (
    <IonPage>
      <Header title={t('contacts.new_contact')} backButton />
      <IonContent>
        <div className="container-narrow">
          <ContactInfo
            doc={doc}
            match={match}
            history={props.history}
            permissions={permissions}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NewContact;

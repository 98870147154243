import React, {useState, useContext, useEffect} from 'react';
import Header from '../../components/navigation/Header';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonIcon,
} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {trashOutline} from 'ionicons/icons';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import Loading from '../../components/utils/Loading';
import useAlert from '../../hooks/useAlert';
import useToast from '../../hooks/useToast';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';


const Accessories = props => {
  // Misc vars
  const {match, isLoading, permissions} = props;
  const [segment, setSegment] = useState('accessories');
  const {t} = useTranslation('link_app');
  // const [, height] = useWindowSize()
  const databases = useContext(DatabaseContext);
  const toast = useToast();
  const alert = useAlert();

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  useTitleEffect(t('nav.accessories'));

  // Search state
  const [search, setSearch] = useState('');

  // Fetching state
  const [isFetching, setIsFetching] = useState(true);

  // Fetch accessories
  const [accessories, setAccessories] = useState([]);
  useEffect(() => {
    databases.accessories
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setAccessories(docs);
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.accessories, match, isLoading, setIsAuth]);

  // Fetch all maintenance guides
  const [guides, setGuides] = useState([]);
  useEffect(() => {
    databases.guides
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setGuides(docs);
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.guides, match, isLoading]);

  // Fetch all software presets
  const [softwarePresets, setSoftwarePresets] = useState([]);
  useEffect(() => {
    databases.software_presets
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setSoftwarePresets(docs);
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, [databases.software_presets, match, isLoading]);

  let filteredAccessories = [...accessories];
  if (search !== '') {
    filteredAccessories = filteredAccessories.filter(accessory => {
      return JSON.stringify(accessory.doc)
        .toLowerCase()
        .includes(search.toLowerCase());
    });
  }
  let filteredGuides = [...guides];
  if (search !== '') {
    filteredGuides = filteredGuides.filter(guide => {
      return JSON.stringify(guide.doc)
        .toLowerCase()
        .includes(search.toLowerCase());
    });
  }
  let filteredSoftwarePresets = [...softwarePresets];
  if (search !== '') {
    filteredSoftwarePresets = filteredSoftwarePresets.filter(preset => {
      return JSON.stringify(preset.doc)
        .toLowerCase()
        .includes(search.toLowerCase());
    });
  }

  const deleteAccessory = (accessoryId, accessoryName) => {
    databases.accessories
      .get(accessoryId)
      .then(result => {
        return databases.accessories.put({
          _id: result._id,
          _rev: result._rev,
          _deleted: true,
        });
      })
      .then(() => {
        console.log(
          `${t('toast.accessory')} "${accessoryName}" ${t(
            'toast.removed_from_database',
          )}`,
        );
        toast(
          `${t('toast.accessory')} "${accessoryName}" ${t(
            'toast.removed_from_database',
          )}`,
        );
      })
      .catch(err => {
        console.log(
          `${t('toast.error_updating_accessory')} #${accessoryId}:`,
          err,
        );
        toast(`${t('toast.error_updating_accessory')} #${accessoryId}`, err);
      });
  };

  const deleteGuide = (guideId, guideName) => {
    databases.guides
      .get(guideId)
      .then(result => {
        return databases.guides.put({
          _id: result._id,
          _rev: result._rev,
          _deleted: true,
        });
      })
      .then(() => {
        console.log(
          `${t('toast.error_type')} "${guideName}" ${t(
            'toast.removed_from_database',
          )}`,
        );
        toast(
          `${t('toast.error_type')} "${guideName}" ${t(
            'toast.removed_from_database',
          )}`,
        );
      })
      .catch(err => {
        console.log(
          `${t('toast.error_updating_error_type')} "${guideName}":`,
          err,
        );
        toast(`${t('toast.error_updating_error_type')} "${guideName}"`, err);
      });
  };

  const deletePreset = (presetId, presetName) => {
    databases.software_presets
      .get(presetId)
      .then(result => {
        return databases.software_presets.put({
          _id: result._id,
          _rev: result._rev,
          _deleted: true,
        });
      })
      .then(() => {
        console.log(
          `${t('toast.software_preset')} "${presetName}" ${t(
            'toast.removed_from_database',
          )}`,
        );
        toast(
          `${t('toast.software_preset')} "${presetName}" ${t(
            'toast.removed_from_database',
          )}`,
        );
      })
      .catch(err => {
        console.log(
          `${t('toast.error_updating_software_preset')} "${presetName}":`,
          err,
        );
        toast(
          `${t('toast.error_updating_software_preset')} "${presetName}"`,
          err,
        );
      });
  };

  // Custom table head style
  const StyledTableHeadCell = withStyles(() => ({
    head: {
      backgroundColor: '#FAFAFA',
      padding: '0.5rem',
      border: 'none !important',
    },
  }))(TableCell);

  return (
    <IonPage>
      <Header
        title={t('nav.accessories')}
        searchBar
        search={search}
        setSearch={setSearch}
        placeholder={t('accessories.search')}
        segments={[
          {name: t('accessories.accessories'), value: 'accessories'},
          {name: t('accessories.errors'), value: 'errors'},
          // { name: t("accessories.software_presets"), value: "software_presets" }
        ]}
        segment={segment}
        setSegment={setSegment}
        permissions={permissions}
        buttons={[
          {
            link: '/new-accessory',
            permission: 'guides',
            name: t('accessories.new_accessory'),
          },
          {
            link: '/new-error-type',
            permission: 'guides',
            name: t('accessories.new_error'),
          },
          // { link: "/new-software-preset", permission: "guides", name: t("accessories.new_software_preset")}
        ]}
      />
      <IonContent>
        {!isFetching ? (
          <IonGrid className="">
            <IonRow>
              {/* <IonCol className="half-n-half accessories" size="4" style={{ height: `${height - 130}px` }}> */}
              <IonCol className="ion-padding-left ion-padding-right">
                {segment === 'accessories' && (
                  <>
                    <div className="half-fixed-header">
                      <IonText className="half-fixed-header-title ion-padding-none">
                        <h1>{t('accessories.accessories')}</h1>
                      </IonText>
                    </div>
                    <div className="half-inner-wrap ion-margin-none">
                      <TableContainer>
                        <Table className="accessories-table">
                          <TableHead className="accessories-table-head">
                            <TableRow>
                              <StyledTableHeadCell>
                                {t('accessories.name')}
                              </StyledTableHeadCell>
                              <StyledTableHeadCell>
                                {t('accessories.type')}
                              </StyledTableHeadCell>
                              <StyledTableHeadCell>
                                {t('accessories.manuals')}
                              </StyledTableHeadCell>
                              <StyledTableHeadCell></StyledTableHeadCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredAccessories.map((accessory, i) => {
                              return (
                                <TableRow key={i} component="tr" hover={true}>
                                  <TableCell>
                                    <Link
                                      to={`/accessories/${accessory.doc._id}`}>
                                      {accessory.doc.name
                                        ? accessory.doc.name
                                        : '-'}
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    <Link
                                      to={`/accessories/${accessory.doc._id}`}>
                                      {accessory.doc.type
                                        ? accessory.doc.type
                                        : '-'}
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    <Link
                                      to={`/accessories/${accessory.doc._id}`}>
                                      {accessory.doc.manuals.length > 0
                                        ? accessory.doc.manuals.length
                                        : '-'}
                                    </Link>
                                  </TableCell>
                                  <TableCell align="right">
                                    <IonButton
                                      size="default"
                                      fill="clear"
                                      color="medium"
                                      disabled={!permissions?.canDelete}
                                      onClick={() =>
                                        alert(
                                          t('alert.notice'),
                                          `${t(
                                            'alert.are_you_sure_you_want_to_delete',
                                          )} ${t('alert.accessory')} "${
                                            accessory.doc.name
                                          }"?`,
                                          [
                                            t('app.go_back'),
                                            {
                                              text: 'Ok',
                                              handler: () =>
                                                deleteAccessory(
                                                  accessory.doc._id,
                                                  accessory.doc.name,
                                                ),
                                            },
                                          ],
                                        )
                                      }>
                                      <IonIcon
                                        style={{height: '22px'}}
                                        slot="icon-only"
                                        icon={trashOutline}
                                      />
                                    </IonButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}
                {segment === 'errors' && (
                  <>
                    <div className="half-fixed-header">
                      <IonText className="half-fixed-header-title ion-padding-none">
                        <h1>{t('accessories.errors')}</h1>
                      </IonText>
                    </div>
                    <div className="half-inner-wrap ion-margin-none">
                      <TableContainer>
                        <Table className="accessories-table">
                          <TableHead className="accessories-table-head">
                            <TableRow>
                              <StyledTableHeadCell>
                                {t('accessories.name')}
                              </StyledTableHeadCell>
                              <StyledTableHeadCell>
                                {t('accessories.flows')}
                              </StyledTableHeadCell>
                              <StyledTableHeadCell>
                                {t('accessories.phases')}
                              </StyledTableHeadCell>
                              <StyledTableHeadCell>
                                {t('accessories.accessories')}
                              </StyledTableHeadCell>
                              <StyledTableHeadCell></StyledTableHeadCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredGuides.map((guide, i) => {
                              return (
                                <TableRow key={i} component="tr" hover={true}>
                                  <TableCell>
                                    <Link to={`/error-types/${guide.doc._id}`}>
                                      {guide.doc.name ? guide.doc.name : '-'}
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    <Link to={`/error-types/${guide.doc._id}`}>
                                      {guide.doc.flows.length > 0
                                        ? guide.doc.flows.length
                                        : '-'}
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    <Link to={`/error-types/${guide.doc._id}`}>
                                      {guide.doc.phases.length > 0
                                        ? guide.doc.phases.length
                                        : '-'}
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    <Link to={`/error-types/${guide.doc._id}`}>
                                      {guide.doc.accessories.length > 0
                                        ? guide.doc.accessories.length
                                        : '-'}
                                    </Link>
                                  </TableCell>
                                  <TableCell align="right">
                                    <IonButton
                                      size="default"
                                      fill="clear"
                                      color="medium"
                                      disabled={!permissions?.canDelete}
                                      onClick={() =>
                                        alert(
                                          t('alert.notice'),
                                          `${t(
                                            'alert.are_you_sure_you_want_to_delete',
                                          )} ${t('alert.guide')} "${
                                            guide.doc.name
                                          }"?`,
                                          [
                                            t('app.go_back'),
                                            {
                                              text: 'Ok',
                                              handler: () =>
                                                deleteGuide(
                                                  guide.doc._id,
                                                  guide.doc.name,
                                                ),
                                            },
                                          ],
                                        )
                                      }>
                                      <IonIcon
                                        style={{height: '22px'}}
                                        slot="icon-only"
                                        icon={trashOutline}
                                      />
                                    </IonButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}
                {/* {segment === "software_presets" && (
									<>
										<div className="half-fixed-header">
											<IonText className="half-fixed-header-title ion-padding-none">
												<h1>{t("accessories.software_presets")}</h1>
											</IonText>
										</div>
										<div className="half-inner-wrap ion-margin-none">
											<TableContainer>
												<Table className="accessories-table">
													<TableHead className="accessories-table-head">
														<TableRow>
															<StyledTableHeadCell>{t("accessories.name")}</StyledTableHeadCell>
															<StyledTableHeadCell>{t("accessories.version")}</StyledTableHeadCell>
															<StyledTableHeadCell></StyledTableHeadCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{filteredSoftwarePresets.map((preset, i) => {
															return (
																<TableRow key={i} component="tr" hover={true}>
																	<TableCell>
																		<Link to={`/software-presets/${preset.doc._id}`}>{preset.doc.name ? preset.doc.name : "-"}</Link>
																	</TableCell>
																	<TableCell>
																		<Link to={`/software-presets/${preset.doc._id}`}>{preset.doc.version ? preset.doc.version : "-"}</Link>
																	</TableCell>
																	<TableCell align="right">
																		<IonButton
																			size="default"
																			fill="clear"
																			color="medium"
																			disabled={!permissions?.canDelete}
																			onClick={() =>
																				alert(t("alert.notice"), `${t("alert.are_you_sure_you_want_to_delete")} ${t("alert.software_preset")} "${preset.doc.name}"?`, [
																					t("app.go_back"),
																					{ text: "Ok", handler: () => deletePreset(preset.doc._id, preset.doc.name) },
																				])
																			}
																		>
																			<IonIcon style={{ height: "22px" }} slot="icon-only" icon={trashOutline} />
																		</IonButton>
																	</TableCell>
																</TableRow>
															)
														})}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
									</>
								)} */}
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Accessories;

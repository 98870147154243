import React, {useState, useEffect} from 'react';
import {FormControl, TextField} from '@material-ui/core';
import {IonText, IonGrid, IonRow, IonCol, IonButton} from '@ionic/react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import ReactMapGL, {NavigationControl, Marker, StaticMap} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {ReactComponent as MarkerIcon} from '../../assets/marker.svg';
import {ReactComponent as ChargerIcon} from '../../assets/charging_icon_maps.svg';

const PlaceLocation = props => {
  const {t} = useTranslation('link_app');
  // const match = props.match
  const permissions = props.permissions;

  // Copy place to local state
  const [place, setPlace] = useState(props.place);

  // Edited state
  const [isEdited, setIsEdited] = useState(false);

  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 8,
  });
  const [mapStyle, setMapStyle] = useState('default');
  const MAPBOX_TOKEN =
    'pk.eyJ1Ijoib2xsaWthcmtrYWluZW4iLCJhIjoiY2tpN2NjMGxwMzNjZjMwbHR4Y2tjbWtrcyJ9.pXEKiASHCH_D4hzCaquTyw';

  // Set map viewport
  useEffect(() => {
    if (viewport.longitude === 0 && viewport.latitude === 0) {
      const lat = place.coordinates ? parseFloat(place.coordinates[0]) : false;
      const long = place.coordinates ? parseFloat(place.coordinates[1]) : false;

      if (lat && long) {
        setViewport({
          zoom: 8,
          latitude: lat,
          longitude: long,
        });
      } else {
        setPlace({...place, coordinates: ['', '']});
        setViewport({latitude: 63.978652, longitude: 25.7587, zoom: 8});
      }
    }
  }, [viewport, place]);

  return (
    <>
      <IonText>
        <h2>{t('place.location_on_map')}</h2>
      </IonText>
      <IonGrid className="ion-padding-none">
        <div className="row">
          <div className="row-input">
            <IonText>
              <ReactMapGL
                className="places-map"
                {...viewport}
                width="250px"
                height="250px"
                style={{position: 'relative'}}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                mapStyle={
                  mapStyle === 'default'
                    ? 'mapbox://styles/mapbox/outdoors-v11'
                    : 'mapbox://styles/mapbox/satellite-v9'
                }>
                {place &&
                  place.coordinates &&
                  place.coordinates[0] !== '' &&
                  place.coordinates[1] !== '' && (
                    <Marker
                      latitude={parseFloat(place.coordinates[0])}
                      longitude={parseFloat(place.coordinates[1])}
                      offsetLeft={-24}
                      offsetTop={-60}>
                      <div className="marker-inner-wrap">
                        <Link to={`/places/${place._id}`}>
                          <MarkerIcon className="map-marker white" />
                          <div className="marker-status">
                            <ChargerIcon
                              className="marker-icon"
                              color="light"
                            />
                            {/* <IonIcon className="marker-icon" color="light" icon={ChargerIcon} /> */}
                          </div>
                          <div className="marker-title">
                            <p
                              className={
                                mapStyle === 'default' ? 'map' : 'sat'
                              }>
                              {place.name}
                            </p>
                          </div>
                        </Link>
                      </div>
                    </Marker>
                  )}
                <NavigationControl
                  style={{top: '16px', right: '16px'}}
                  showCompass={false}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '94px',
                    right: '16px',
                  }}>
                  <div className="mapboxgl-ctrl mapboxgl-ctrl-group overflow-hidden">
                    <button
                      onClick={() => {
                        mapStyle === 'default'
                          ? setMapStyle('satellite')
                          : setMapStyle('default');
                      }}
                      className="mapboxgl-ctrl-map-style">
                      <StaticMap
                        {...viewport}
                        width="40px"
                        height="40px"
                        onViewportChange={setViewport}
                        mapboxApiAccessToken={MAPBOX_TOKEN}
                        mapStyle={
                          mapStyle === 'satellite'
                            ? 'mapbox://styles/mapbox/outdoors-v11'
                            : 'mapbox://styles/mapbox/satellite-v9'
                        }
                      />
                      {mapStyle === 'default' ? <p>sat</p> : <p>map</p>}
                    </button>
                  </div>
                </div>
              </ReactMapGL>
            </IonText>
          </div>
          <div className="location-coordinates row-input">
            <div className="row">
              <FormControl className="">
                {/* Place Name */}
                <div>
                  <label htmlFor="name">{t('place.lattitude') + ' *'}</label>
                </div>
                <TextField
                  id="latitude"
                  value={place.coordinates ? place.coordinates[0] : ''}
                  onChange={e => {
                    setPlace({
                      ...place,
                      coordinates: [e.target.value, place.coordinates[1]],
                    });
                    setIsEdited(true);
                  }}
                  disabled={!permissions?.places}
                />
              </FormControl>
            </div>
            <div className="row">
              <FormControl className="">
                {/* Place Site-ID */}
                <div>
                  <label htmlFor="site-id">{t('place.longitude')}</label>
                </div>
                <TextField
                  id="longitude"
                  disabled={!permissions?.places}
                  value={place.coordinates ? place.coordinates[1] : ''}
                  onChange={e => {
                    setPlace({
                      ...place,
                      coordinates: [place.coordinates[0], e.target.value],
                    });
                    setIsEdited(true);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>

        <IonRow>
          {/* <IonCol>
						<IonButton routerLink="/places" className="ion-margin-right" fill="outline" color="danger">
							{t("app.abort")}
						</IonButton>
					</IonCol> */}
          <IonCol>
            <IonButton
              onClick={() => {
                props.savePlaceChanges(place);
                setIsEdited(false);
              }}
              fill="solid"
              color="success"
              disabled={!isEdited}>
              {t('app.save')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default PlaceLocation;

import {useContext} from 'react';
import {useIonToast} from '@ionic/react';
import {AuthContext} from '../components/context/AuthContext';

const useToast = () => {
  const [present] = useIonToast();
  const [, setIsAuth] = useContext(AuthContext);

  const toast = async (message, err) => {
    if (err && err.status === 401) {
      setIsAuth(false);
    }

    return present({
      duration: 5000,
      message,
    });
  };

  return toast;
};

export default useToast;

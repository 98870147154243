import React, {useState, useContext, useEffect, useCallback} from 'react';
import {IonPage, IonContent, IonButton, IonIcon} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {UserContext} from '../../components/context/UserContext';
import {ReactComponent as LogoBlack} from '../../assets/logo-black.svg';
import {ReactComponent as LogoWhite} from '../../assets/logo-white.svg';
import {eyeOutline, eyeOffOutline} from 'ionicons/icons';
import {Paper} from '@material-ui/core';
import useToast from '../../hooks/useToast';
import {AuthContext} from '../../components/context/AuthContext';
import {Link} from 'react-router-dom';
import useTitleEffect from '../../hooks/useTitle';
import useConfig from '../../hooks/useConfig';


const LogIn = () => {
  const {t} = useTranslation('link_app');
  const [, setUser] = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [pw, setPw] = useState('');
  const [isPwVisible, setIsPwVisible] = useState(false);
  const [, setIsAuth] = useContext(AuthContext);
  const toast = useToast();

  useTitleEffect(t('app.login'));
  const {db} = useConfig();

  const logIn = useCallback(
    async e => {
      e.preventDefault();
      await fetch(
        `${db}/_session`,
        {
          method: 'POST',
          body: JSON.stringify({
            username: username,
            password: pw,
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        },
      )
        .then(res => {
          if (res.ok) {
            setUser({
              name: username,
            });
            setIsAuth(true);
          } else {
            toast(t('toast.wrong_username_or_password'));
          }
        })
        .catch(err => {
          toast(t('toast.unhandled_error'));
        });
    },
    [pw, setUser, t, username, setIsAuth, toast],
  );

  useEffect(() => {
    const listener = e => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        logIn(e);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [logIn]);

  return (
    <IonPage>
      <IonContent>
        <div className="login">
          <Paper>
            <div className="login-wrap ion-text-center">
              <LogoBlack className="ion-margin-bottom-half logo-black" />
              <LogoWhite className="ion-margin-bottom-half logo-white" />
              <p>Work management application</p>
              <form onSubmit={e => logIn(e)} className="ion-text-center">
                <input
                  className="ion-margin-bottom normal-padding"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  autoComplete="off"
                  type="text"
                  placeholder={t('app.username')}
                />
                <div className="pw-wrap">
                  <input
                    className="ion-margin-none"
                    value={pw}
                    onChange={e => setPw(e.target.value)}
                    autoComplete="off"
                    type={isPwVisible ? 'text' : 'password'}
                    placeholder={t('app.password')}
                  />
                  <IonButton
                    onClick={() => setIsPwVisible(!isPwVisible)}
                    fill="clear"
                    color="medium">
                    <IonIcon
                      style={{fontSize: '22px'}}
                      slot="icon-only"
                      icon={isPwVisible ? eyeOffOutline : eyeOutline}></IonIcon>
                  </IonButton>
                </div>
                <IonButton className="ion-margin-top" type="submit">
                  {t('app.login')}
                </IonButton>
              </form>
            </div>
            <div className="ion-margin-top ion-text-end">
              <Link to={`/forgot-password`}>Forgot password?</Link>
            </div>
          </Paper>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LogIn;

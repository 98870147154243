import React, {useState, useEffect, useContext} from 'react';
import Header from '../../components/navigation/Header';
import {IonPage, IonContent} from '@ionic/react';
import ErrorTypeInfo from '../../components/accessories/ErrorTypeInfo';
import {useTranslation} from 'react-i18next';
import {DatabaseContext} from '../../components/context/DatabaseContext';
import {AuthContext} from '../../components/context/AuthContext';
import useTitleEffect from '../../hooks/useTitle';


const NewErrorType = props => {
  // Misc vars
  const {match, permissions} = props;
  const {t} = useTranslation('link_app');

  useTitleEffect(t('error_types.new_error_type'));

  // Databases
  const databases = useContext(DatabaseContext);

  // Auth check
  const [, setIsAuth] = useContext(AuthContext);

  // Blank error type
  const doc = {
    _id: '',
    name: '',
    accessories: [],
    phases: [],
    flows: [],
  };

  // Fetch accessories
  const [accessories, setAccessories] = useState([]);
  useEffect(() => {
    databases.accessories
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setAccessories(docs);
      })
      .catch(err => {
        console.log(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.accessories, setIsAuth]);

  return (
    <IonPage>
      <Header title={t('error_types.new_error_type')} backButton />
      <IonContent>
        <div className="container-narrow">
          <ErrorTypeInfo
            doc={doc}
            match={match}
            history={props.history}
            accessories={accessories}
            permissions={permissions}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NewErrorType;
